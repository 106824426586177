import { Box } from "@mui/material";
import React from "react";
import { BuyerIntentBox, BuyerIntentInnerBox, InnerCardTitle } from "./LeaderTrackerStyles";

const buyerIntent = [
  {
    company: "Talentou Inc",
    location: "Boston",
    industry: "Technology",
    revenue: "500M",
    score: 914,
    sdr: "Anton Ciby",
  },
  {
    company: "Apex Financial Group",
    location: "New York",
    industry: "Finance",
    revenue: "200M",
    score: 878,
    sdr: "Joseph James",
  },
  {
    company: "Codio Technologies",
    location: "Massachusetts",
    industry: "Technology",
    revenue: "325M",
    score: 711,
    sdr: "Anton Ciby",
  },
  {},
];

const BuyerIntentTable = () => {
  return (
    <BuyerIntentBox>
      <InnerCardTitle
        sx={{
          fontSize: "22px",
          paddingBottom: "20px",
          "@media (max-width:  1536px)": {
            fontSize: "14px",
            paddingBottom: "11px", // Applies when screen width is 1366px or below
          },
        }}
      >
        Top Companies By Buyer Intent
      </InnerCardTitle>
      <BuyerIntentInnerBox>
        <Box
          sx={{
            border: "1px solid #E0E0E0",
            borderRadius: "8px",
            maxHeight: "100%",
            overflowY: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": { width: "3px" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#bbb",
              borderRadius: "2px",
            },
          }}
        >
          <table className="buyer-intent-table">
            <thead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 2,
              }}
            >
              <th>Companies</th>
              <th>Location</th>
              <th>Industry</th>
              <th>Revenue</th>
              <th>Buyer Intent</th>
              <th>SDR</th>
            </thead>
            <tbody>
              {buyerIntent.map((data, i) => (
                <tr key={i}>
                  <td>{data.company}</td>
                  <td>{data.location}</td>
                  <td>{data.industry}</td>
                  <td>{data.revenue}</td>
                  <td>{data.score}</td>
                  <td>{data.sdr}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </BuyerIntentInnerBox>
    </BuyerIntentBox>
  );
};

export default BuyerIntentTable;
