import React, { useRef, useState } from "react";
import {
  DeleteProfileButton,
  DescTypography,
  DetailsBox,
  DetailsOuterBox,
  EditIconBox,
  EmailBox,
  HeadingTypography,
  InputFieldBox,
  NameBox,
  ProfileBox,
  ProfilePictureBox,
  SaveButton,
} from "./AccountPageStyles";
import Profile from "../../../assets/ProfileIcon.svg";
import Mail from "../../../assets/MailIcon.svg";
import DefaultImage from "../../../assets/Image.svg";
import EditIcon from "../../../assets/EditIcon.svg";
import { InputAdornment } from "@mui/material";
import { Box } from "@mui/system";

const AccountDetails = () => {
  const [profileImage, setProfileImage] = useState(DefaultImage);
  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size;
      if (
        (fileType === "image/jpeg" || fileType === "image/png") &&
        fileSize <= 2 * 1024 * 1024
      ) {
        const imageUrl = URL.createObjectURL(file);
        setProfileImage(imageUrl);
      } else {
        alert("Invalid file. Only JPG and PNG under 2MB are allowed.");
      }
    }
  };

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  return (
    <DetailsOuterBox>
      <ProfileBox>
        <HeadingTypography>Profile Picture</HeadingTypography>

        <DescTypography>
          Supported formats: JPG, PNG. Maximum size: 2MB.
        </DescTypography>

        {/* Profile Image Container */}
        <Box sx={{ position: "relative", display: "inline-block" }}>
          <ProfilePictureBox
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={profileImage}
              alt="Profile"
              style={{
                width: profileImage === DefaultImage ? "30%" : "100%",
                height: profileImage === DefaultImage ? "30%" : "100%",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </ProfilePictureBox>

          <input
            type="file"
            accept="image/jpeg, image/png"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />

          <EditIconBox
            component="img"
            src={EditIcon}
            alt="Edit"
            onClick={handleEditClick}
          />
        </Box>

        <DeleteProfileButton
          variant="outlined"
          onClick={() => setProfileImage(DefaultImage)}
        >
          Delete Picture
        </DeleteProfileButton>
      </ProfileBox>

      <DetailsBox>
        <NameBox>
          <HeadingTypography>Name</HeadingTypography>

          <DescTypography>The name associated with this account</DescTypography>

          <InputFieldBox
            placeholder="User Name"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={Profile}
                      alt="Profile"
                      style={{
                        width: "20px",
                        height: "20px",
                        display: "block",
                        border: "none",
                        marginRight: "-5px",
                      }}
                    />
                  </InputAdornment>
                ),
              },
            }}
          />
        </NameBox>

        <EmailBox>
          <HeadingTypography>Email</HeadingTypography>

          <DescTypography>
            The email address associated with this account
          </DescTypography>

          <InputFieldBox
            placeholder="User Name"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={Mail}
                      alt="Mail"
                      style={{
                        width: "20px",
                        height: "20px",
                        display: "block",
                        border: "none",
                        marginRight: "-5px",
                      }}
                    />
                  </InputAdornment>
                ),
              },
            }}
          />
        </EmailBox>

        <SaveButton variant="outlined">Save</SaveButton>
      </DetailsBox>
    </DetailsOuterBox>
  );
};

export default AccountDetails;
