import {
    GET_NOTIFICATION_START,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_ERROR,
  } from "../Actions/NotificationAction";
  
  const initialState = {
    notificationData: null,
    loading: false,
    error: null,
  };
  
  const getNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_NOTIFICATION_START:
        return { ...state, loading: true, error: null };
      case GET_NOTIFICATION_SUCCESS:
        return { ...state, loading: false, notificationData: action.payload };
      case GET_NOTIFICATION_ERROR:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default getNotificationReducer;
  