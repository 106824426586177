import React from "react";
import { Box } from "@mui/material";
import refreshIcon from "../../../assets/refresh.svg";
import {
  StyledHeading,
  ColorInputContainer,
  ColorBox,
  HiddenColorInput,
  ColorPreview,
  ColorHex,
  ColorLabel,
  ButtonContainer,
  OutlinedBtn,
  ContainedBtn,
} from "./ThemePageCss";

const ThemeColorPicker = ({ colors, onColorChange, resetColors }) => {
  return (
    <Box>
      <StyledHeading>Theme Color</StyledHeading>
      <Box display="flex" gap={2} mb={3} mt={0.5}>
        {Object.entries(colors).map(([key, value]) => (
          <ColorInputContainer key={key}>
            <ColorLabel>
              {key.charAt(0).toUpperCase() + key.slice(1)} Color
            </ColorLabel>
            <ColorBox>
              <ColorPreview
                sx={{ backgroundColor: value }}
                onClick={() => document.getElementById(key).click()}
              />
              <HiddenColorInput
                id={key}
                type="color"
                value={value}
                onChange={(e) => onColorChange(key, e.target.value)}
              />
              <ColorHex>{value.toUpperCase()}</ColorHex>
            </ColorBox>
          </ColorInputContainer>
        ))}
      </Box>

      <ButtonContainer>
        <ContainedBtn btnColor="#009872" sx={{ padding: "8.5px 45px" }}>
          Save
        </ContainedBtn>
        <OutlinedBtn btnColor="#D1D1D2" textColor="#252527" onClick={resetColors}>
          <img src={refreshIcon} alt="" />
          Reset Colors
        </OutlinedBtn>
      </ButtonContainer>
    </Box>
  );
};

export default ThemeColorPicker;