import React from "react";
import {
  GreetingsBox,
  GreetingsBox2,
  SalesPlanBox,
} from "../../Dashboard/SalesPlanCss";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid2";
import { Paper, Typography } from "@mui/material";
import UpcomingActivates from "./UpcomingActivites";
import ContactProgressPlan from "./ContactProgress";

const HappyProspectingPage = () => {
  return (
    <SalesPlanBox>
      {/* Greeting Section */}
      <Box>
        <GreetingsBox>Happy Prospecting</GreetingsBox>
        <GreetingsBox2>
          Momentum and media are everything! Engage, connect, and keep the
          energy high - your next breakthrough is just a message away.
        </GreetingsBox2>
        {/* <HeadingBox sx={{marginBlock:'2.5rem'}}>Lorem Ipsum </HeadingBox> */}
        <Grid container spacing={2} sx={{height:"70vh",alignItems:"center",justifyContent:"center" }}>
          <Grid size={{ xs: 6, md: 6 }} sx={{ paddingLeft: "24px" }}>
            <Box
              sx={{
                // height: "378px",
                background: "#F3F1FB",
                borderRadius: "12px",
                padding: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: "22px",
                  lineHeight: "100%",
                  letterSpacing: "0%",
                }}
              >
                Contact Plan Progress
              </Typography>
              <Paper
                sx={{
                  padding: "20px",
                  marginTop: "20px",
                  background: "#ffff",
                  // height: "292px",
                  borderRadius: "16px",
                }}
              >
                <ContactProgressPlan />
              </Paper>
            </Box>
          </Grid>
          <Grid size={{ xs: 6, md: 6 }} sx={{ paddingRight: "24px" }}>
            <Box
              sx={{
                // height: "378px",
                background: "#EBF6F0",
                borderRadius: "12px",
                padding: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: "22px",
                  lineHeight: "100%",
                  letterSpacing: "0%",
                }}
              >
                Upcoming Activities
              </Typography>
              <Paper
                sx={{
                  padding: "20px",
                  marginTop: "20px",
                  background: "#ffff",
                  // height:"292px",
                  borderRadius: "16px",
                }}
              >
                <UpcomingActivates />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SalesPlanBox>
  );
};

export default HappyProspectingPage;
