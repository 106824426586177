import { Background, BuddyImg, TextImg } from "./LoginPageStyle";
import { Box, Button, Typography } from "@mui/material";

import {
  PiqualAiBot,
  PiqualLogo,
  LoginFrameNew,
} from "../../Constants/Constant";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { useToast } from "../../utils/ToastContext";
import { useAuth } from "../../AuthContext/AuthContext";
import { setUserData } from "../../Redux/Actions/userActions";
import { API, siteKey } from "../../global";
import ReCAPTCHA from "react-google-recaptcha";
import {SalesLeadEULA, SDRLeadEULA } from "../../Constants/EULA";
import { jwtDecode } from "jwt-decode";
import { setAuthData } from "../../Constants/Funtions/commonFunctions";

const EULA = () => {
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const { login } = useAuth();
  const { token } = useParams();
  const decodedToken = jwtDecode(token)

  const userData = useSelector((state) => state?.sdrData?.sdrUserData);
  const [loading, setLoading] = useState(false);

  const agreeEULA = async () => {
    const tokens = await recaptchaRef?.current?.executeAsync();
    const obj = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      password: userData.password,
      eula_accepted: true,
      captcha_token: tokens,
    };
    setLoading(true)
    fetch(`${API}/auth/invite-register`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          const userData = res.result;
          dispatch(
            setUserData({
              firstName: userData.first_name,
              lastName: userData.last_name,
            })
          );
          setAuthData(userData)
          login(userData.token);
          if (!userData.active_project_id) {
            navigate("/error");
          } else {
            if (userData.active_role_key === "piqai_sdr") {
              if (!userData.show_welcome_slider) {
                navigate("/piqual-ai/sdr-dashboard");
              } else {
                navigate("/piqual-ai/onboarding");
              }
            } else if(userData.active_role_key === "piqai_sales_lead") {
              if (!userData.show_welcome_slider) {
                navigate("/piqual-ai/dashboard");
              } else {
                navigate("/piqual-ai/onboarding");
              }
            }
            else if(userData.active_role_key === "piqai_admin") {
                navigate("/piqual-ai/audit-log");
             
            }
          }    
        } else {
          showToast("error", `${res.error.msg}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };
  return (
    <Background>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          // padding: "0px 38px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "35px",
            bgcolor: "white",
            padding: "24px 16px 16px 16px",
            borderRadius: "0 0 8px 8px",
          }}
        >
          <img src={PiqualLogo} alt="" />
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            width: { xs: "30%", sm: "30%", md: "35%", lg: "35%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "60px",
            padding: "50px",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <TextImg src={LoginFrameNew} alt="" />
            <BuddyImg src={PiqualAiBot} alt="" />
          </Box>
          <Box>
            <Box
              sx={{
                bgcolor: "#F9F9F9",
                padding: "24px 24px 0 24px",
                borderRadius: "8px",
                height: "35vh",
                overflowY: "auto",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": { width: "3px" },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#bbb",
                  borderRadius: "2px",
                },
                border: "1px solid #E8E8E8",
              }}
            >
              <Typography sx={{ lineHeight: "1.6", color: "#000" }}>
                {decodedToken?.user_role_key === "piqai_sales_lead" ? (
                  <SalesLeadEULA />
                ) : (
                  <SDRLeadEULA />
                )}
              </Typography>
            </Box>{" "}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "100%",
                paddingTop: "24px",
              }}
            >
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  width: "50%",
                  height: "48px",
                  borderRadius: "4px",
                  color: "#252527",
                  border: "1px solid #D1D1D2",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={agreeEULA}
                loading={loading}
                sx={{
                  width: "50%",
                  height: "48px",
                  background: "#109D79 0% 0% no-repeat padding-box",
                  borderRadius: "4px",
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              >
                I Agree
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <ReCAPTCHA sitekey={`${siteKey}`} size="invisible" ref={recaptchaRef} />
    </Background>
  );
};

export default EULA;
