import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { AIBuddy } from "../../../Constants/Constant";

const ChatHeader = () => {
  return (
    <Box sx={{ height: "72px", display: "flex",alignItems:"center" }}>
      <img className="buddy-1-icon" loading="lazy" alt="" src={AIBuddy} />
      <Typography
        className="project-aws"
        sx={{ fontWeight: "500", padding: "0px 10px",fontSize:"1.25rem" }}
      >
        Piqual AI
      </Typography>
    </Box>
  );
};

export default ChatHeader;
