import React, { useEffect, useRef, useState } from "react";
import {  Box, Button } from "@mui/material";
import {
  AttachmentIcon,
  AttachmentIconWhite,
  RecordingIcon,
  SendIcon,
} from "../../../Constants/Constant";
import "../SalesLead/MarketPlanLayout.css";
import CloseIcon from "@mui/icons-material/Close";
import { API } from "../../../global";
import AttachButton from "../../Reusable/AttachmentButton/attachButton";
import { useLocation, useNavigate } from "react-router-dom";
import ChatSlider from "../Common/ChatSlider";
import { useDispatch } from "react-redux";
import { setProjectDetails } from "../../../Redux/Actions/projectDeatils";
import { setProjectStatusDetails } from "../../../Redux/Actions/projectStatusAction";
import { setSdrDataBaseDetails } from "../../../Redux/Actions/SdrDatabaseDetailsAction";
import { useToast } from "../../../utils/ToastContext";
import ChatHeader from "../Common/ChatHeader";
import { stripHtmlTags } from "../../../Constants/Funtions/commonFunctions";
import { sdrTabData } from "../../../Constants/data";
import useClickOutside from "../../../CustomHooks/OutsideClickHook";
import ChatWindow from "../../Reusable/ChatWindow";


const SDRChatInterface = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {showToast} = useToast()
  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");
  const chatWindowRef = useRef(null);
  const chatWindowMainRef = useRef(null);
  const [showFirstBox, setShowFirstBox] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [currentQuestionData, setCurrentQuestionData] = useState(null);
  const [showAttachment, setShowAttachment] = useState(false);
  const attachmentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const salesPlanType = localStorage.getItem("sales_plan_type");
  const [answerSuggestion, setAnswerSuggestion] = useState(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState([]);
  const [completedStatus, setCompltedStatus] = useState("");
  const [countDown, setCountDown] = useState("");
  const [suggestionClickType, setSuggestionClickType] = useState(false);
  const [isResized, setIsResized] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [placeholderText, setPlaceholderSet] = useState('Send Message')
  const salesPlanChatType = localStorage.getItem('sales_plan_chat_type');
  const [showCopyBUtton,setShowCopyButton] = useState(false)
   const inputRef = useRef(null)
   const intervalRef = useRef(null); 
  
  const [tabs] = useState(sdrTabData);

  useClickOutside(attachmentRef, () => setShowAttachment(false));

  useEffect(() => {
   
    startConversation();

    return () => {
      clearInterval(intervalRef.current); // Clear the interval on unmount
    };
    // eslint-disable-next-line
  }, []);

  const location = useLocation();
  const [showDownArrowBtn, setShowDownArrouwBtn] = useState(false);

// Declare useRef at the top level
const previousUserMessageCount = useRef(0);

useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (!chatWindow) return;

    // Extract chat window properties
    const { clientHeight } = chatWindow;

    // Check if the user is at the bottom (10px buffer)
    // const isUserAtBottom = scrollTop + clientHeight >= scrollHeight - 10;

    // Get last AI and user messages
    const aiMessages = chatWindow.querySelectorAll(".company-and-contact");
    const lastAiMessage = aiMessages.length > 0 ? aiMessages[aiMessages.length - 1] : null;

    const userMessages = chatWindow.querySelectorAll(".messages-inner-box");
    // const lastUserMessage = userMessages.length > 0 ? userMessages[userMessages.length - 1] : null;

    // Detect if a new user message was sent
    const isNewUserMessageSent = userMessages.length > previousUserMessageCount.current;
    previousUserMessageCount.current = userMessages.length; // Update count

    // 🔹 1️⃣ Stay still while typing (do not scroll)
    if (!isNewUserMessageSent) return;

    // 🔹 2️⃣ If the user sends a new message, scroll to the bottom
    setTimeout(() => {
        chatWindow.scrollTo({
          top: chatWindowRef.current.scrollHeight,
            behavior: "smooth",
        });
    }, 50); // Small delay to ensure the message is rendered before scrolling

    // 🔹 3️⃣ If the user is scrolled up and AI sends a long message, scroll to its top
    if (lastAiMessage) {
        const lastAiMessageHeight = lastAiMessage.clientHeight;
        if (lastAiMessageHeight > clientHeight) {
            chatWindow.scrollTo({
                top: lastAiMessage.offsetTop,
                behavior: "smooth",
            });
            return;
        }
    }

}, [messageList, loading, answerSuggestion, selectedSuggestion]);

// Runs when messages update

 // Runs when messages update

  // Store a flag when the page is refreshed or navigated
  useEffect(() => {
    sessionStorage.setItem("chatReloaded", "true");
  }, [location.pathname]);

  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (!chatWindow) return;

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = chatWindow;
        const isUserAtBottom = scrollTop + clientHeight >= scrollHeight - 60;

        // Only show the button if the user is NOT at the bottom
        setShowDownArrouwBtn(!isUserAtBottom);
    };

    // Run on mount & when messages update
    handleScroll(); // Ensure correct state on first render

    chatWindow.addEventListener("scroll", handleScroll);
    return () => chatWindow.removeEventListener("scroll", handleScroll);
}, [messageList]); // Runs when new messages arrive

  const startConversation = () => {
    fetch(`${API}/chat/sdr/start-conversation`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        new_project: type === "new" ? true : false,
        conversation_type: salesPlanChatType,
        prospect_id : localStorage.getItem('prospect_id')
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        try {
          const project = res.result;
          setCurrentQuestionData(project);
          if (
            project.conversation_history &&
            project.conversation_history.length !== 0
          ) {
            setHistoryData(project.conversation_history)
           
          } else {
            const {
              prospect_conversation_id,
              prospect_project_id,
              prospect_id,
              prospect_type,
              task_id,
              task_type
            } = localStorage;
            const chatObj = {
              message: type === "review" ? "Yes" : "",
              conversation_code: project?.conversation_code || prospect_conversation_id,
              project_id: project?.project_id || prospect_project_id,
              prospect_id: prospect_id !== "undefined" ? prospect_id :"",
              conversation_type: salesPlanChatType,
              prospect_type: prospect_type !== "undefined" ? prospect_type : "",
              task_id,
              task_type
            };
            setMessageList([]);
            getQuestions(chatObj);
            getProjectDetails(chatObj.project_id);
            getProjectDetailsData(chatObj.project_id);
            getSDRtDBData(chatObj.project_id)
          }
        } catch (err) {
         setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const setHistoryData = (project) => {
    let historyList = [];
    setLoading(false);
    project.forEach((data) => {
      const historyObj = {
        msg: data.response,
        type: data.message_from,
        acknowledgeComment: data.acknowledge_comment,
        answer_suggestions: data.answer_suggestions,
        conversation_type: salesPlanChatType,
        additional_response: data.additional_response,
      };
      setAnswerSuggestion(data.answer_suggestions);
      setShowSlider(data.show_slider);
      if (data.show_slider) {
        setPlaceholderSet(
          "Move the slider to update the count then press 'Send' or hit 'Enter'."
        );
      } else if (data.answer_suggestions.length !== 0 || !data.show_slider) {
        setPlaceholderSet(
          "Type something… or tap a suggestion. Hit Enter to send!"
        );
      }

      historyList.push(historyObj);
    });
    setShowFirstBox(true);
    setMessageList(historyList);
    getProjectDetails(project.project_id);
    getProjectDetailsData(project.project_id);
    getSDRtDBData(project.project_id);
  };

  const getProjectDetails = (projectId) => {
    fetch(`${API}/project/get-project-details`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectId }),
    })
      .then((response) => response.json())
      .then((res) => {
        // setLoading(false);

        if (res.success) {
          if (res.result) {
            const projectData = res.result;
            let projectList = [];
            // eslint-disable-next-line
            tabs?.map((tab) => {
              // eslint-disable-next-line
              projectData?.map((item) => {
                if (tab.key === item.plan_type) {
                  const obj = {
                    value: tab.value,
                    label: tab.label,
                    content: item.content,
                    contentString :item.content_string,
                    key : tab.key,
                    status: item.data_collection_status,
                    disabled: false,
                  };
                  projectList.push(obj);
                }
              });
            });
            dispatch(setProjectDetails(projectList));
          }
        }
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  const getProjectDetailsData = (projectId) => {
    fetch(`${API}/project/get-project-detail-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: localStorage.getItem('project_id'),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatch(setProjectStatusDetails(res.result));
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  const getSDRtDBData = (projectId) => {
    const outreachType = localStorage.getItem('sales_plan_chat_type')
    let apiEndPoint=''
    if(outreachType === 'outreach'){
      apiEndPoint ="get-prospect-outreach-stats"
    }else{
      apiEndPoint = 'get-prospect-db-stats'
    }
    fetch(`${API}/project/${apiEndPoint}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: localStorage.getItem('project_id'),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {

          dispatch(setSdrDataBaseDetails(res.result));
 
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };


  const getQuestions = (chatObj) => {
    setLoading(true);
    setAnswerSuggestion([]);
    setShowSlider(false);
   
    fetch(`${API}/chat/sdr/conversation`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(chatObj),
    })
      .then((response) => response.json())
      .then((res) => {
        localStorage.setItem("loading", false);
        try {
          if (res.success) {
            const question = res.result;
            
            if(question.conversation_history && question.conversation_history.length !==0){
              setHistoryData(question.conversation_history)
              setShowFirstBox(false);

            }else{
              setLoading(false);
              setMessageListData(question,chatObj)
            }
            
          } else {
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };


  const setMessageListData = (question,chatObj) => {
    setCompltedStatus(question.data_collection_status);
    localStorage.setItem('sales_plan_chat_type',question.conversation_type || localStorage.getItem('sales_plan_chat_type'))
    if (type === "review") {
      setShowFirstBox(false);
    }
    if (question.data_collection_status === "completed") {
      setShowFirstBox(false);
      let count = 5; // Start count
      intervalRef.current = setInterval(() => {
        count--; // Decrease count by 1
        setCountDown(count);
        if (count < 0) {
          clearInterval(intervalRef.current); // Stop when count reaches 0
        
          navigate("/piqual-ai/sdr-dashboard");
        }
      }, 1000); // Run every 1000ms (1 second)
    }
    setCurrentQuestionData(question);
    if (question.show_slider) {
      setPlaceholderSet( "Move the slider to update the count then press 'Send' or hit 'Enter'.");
    } else if (question.answer_suggestions.length !== 0 || !question.show_slider) {
      if(question.click_send){
        setPlaceholderSet("Type something… or tap a suggestion.")
      }else{
        setPlaceholderSet("Type something… or tap a suggestion. Hit Enter to send!")
      }
    }
    let industryDropdownData = [];
    // eslint-disable-next-line
    const industryData = question?.target_industries?.map((data) => {
      industryDropdownData.push(data.name);
    });
    // setIndustryValue(industryDropdownData);
    setAnswerSuggestion(question?.answer_suggestions);
    setShowCopyButton(question.copy_button)
    setShowFirstBox(true);

    setSuggestionClickType(question.click_send);
    setShowSlider(question.show_slider);
    const chatResponse = {
      msg: question.response,
      type: question.message_from,
      acknowledgeComment: question.acknowledge_comment,
      answer_suggestions: question.answer_suggestions,
      conversation_type: salesPlanChatType,
      additional_response: question.additional_response,
      industry: industryData,
    };
    setMessageList((prevMessageList) => [...prevMessageList, chatResponse]);
    getProjectDetails(chatObj.project_id);
    getProjectDetailsData(chatObj.project_id);
    getSDRtDBData(chatObj.project_id)
  };

  const handleKeyDown = (event) => {
    localStorage.setItem("inputKey", "added");
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();

      // Add a new line to the input message
      setInputMessage((prevMessage) => {
        const updatedMessage = prevMessage + "\n";

        // Dynamically adjust textarea height
        const textarea = event.target; // The textarea element triggering the event
        handleTextareaResize(textarea);

        // Scroll to the bottom to show the new line
        setTimeout(() => {
          textarea.scrollTop = textarea.scrollHeight; // Scroll to the bottom
        }, 0);

        return updatedMessage;
      });
    } else if (event.key === "Enter") {
      // Prevent default behavior
      event.preventDefault();

      handleInputMessage();
    }
  };

  const handleTextareaResize = (textarea) => {
    textarea.style.height = "20px";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`; // Max 150px height

    textarea.style.padding = "0px";
    setIsResized(textarea.scrollHeight > 40); // Adjust 40px as needed
  };


  const handleInputMessage = () => {
    setSelectedSuggestion([]);
    if (inputMessage) {
      const obj = {
        msg: inputMessage,
        type: "user",
        qNumber: Math.random(),
      };
      setMessageList((prevMessageList) => [...prevMessageList, obj]);
      const chatObj = {
        message: stripHtmlTags(inputMessage),
        conversation_code: currentQuestionData.conversation_code,
        project_id: currentQuestionData.project_id,
        company_id : currentQuestionData?.company_id,
        contact_id : currentQuestionData?.contact_id,
        conversation_type: salesPlanChatType,
        prospect_id :currentQuestionData.prospect_id ||  localStorage.getItem('prospect_id'),
        prospect_type : currentQuestionData.prospect_type || localStorage.getItem('prospect_type'),
        task_id : currentQuestionData.task_id ? currentQuestionData.task_id :"",
        task_type :currentQuestionData.task_type ? currentQuestionData.task_type :""
      };
      setPlaceholderSet('Send Message')

      getQuestions(chatObj);

      setInputMessage("");

      // Reset textarea height after message is sent
      setTimeout(() => {
        const textarea = document.querySelector(".message-input");
        if (textarea) {
          textarea.style.height = "20px"; // Reset height
          textarea.style.paddingTop = "0px"; // Reset padding if needed
        }
        setIsResized(false); // Ensure alignment goes back to center
      }, 0);
    }
  };

  const getFileUrl = (files) => {
    // console.log("files",files)
    const htmlMessage = files
      ?.map(
        (file) =>
          `Uploaded  <a href="${file.fileUrl}" target="_blank" rel="noopener noreferrer" style="color: white; font-weight: bold;"><strong>${file.name}</strong></a>`
      )
      .join("<br><br>");
    let msgData = [];
    let blobData = [];

    files.forEach((data) => {
      blobData.push(data.fileUrl);
      msgData.push(data.name);
    });
    const obj = {
      msg: htmlMessage,
      type: "user",
      qNumber: Math.random(),
    };
    setMessageList((prevMessageList) => [...prevMessageList, obj]);

    const chatObj = {
      message: msgData.join(","),
      conversation_code: currentQuestionData.conversation_code,
      project_id: currentQuestionData.project_id,
      company_id : currentQuestionData?.company_id,
      contact_id : currentQuestionData?.contact_id,
      blob_url: blobData,
      conversation_type: salesPlanChatType,
      prospect_id : currentQuestionData?.prospect_id || localStorage.getItem('prospect_id'),
      prospect_type : currentQuestionData.prospect_type || localStorage.getItem('prospect_type'),
      task_id : currentQuestionData.task_id ? currentQuestionData.task_id :"",
      task_type :currentQuestionData.task_type ? currentQuestionData.task_type :""
    };
    getQuestions(chatObj);
  };

  const handleAttachButtonClick = () => {
    setShowAttachment((prev) => !prev); //  Toggle visibility
  };

  const addStatus = (dataValue) => {
    if (suggestionClickType) {
      setAnswerSuggestion(null);
      const obj = {
        msg: dataValue,
        type: "user",
        qNumber: Math.random(),
      };
      setMessageList((prevMessageList) => [...prevMessageList, obj]);
      const chatObj = {
        message: dataValue,
        conversation_code: currentQuestionData.conversation_code,
        project_id: currentQuestionData.project_id,
        company_id : currentQuestionData?.company_id,
        contact_id : currentQuestionData?.contact_id,
        conversation_type: salesPlanChatType,
        prospect_id : currentQuestionData?.prospect_id || localStorage.getItem('prospect_id'),
        prospect_type : currentQuestionData.prospect_type || localStorage.getItem('prospect_type'),
        task_id : currentQuestionData.task_id ? currentQuestionData.task_id :"",
        task_type :currentQuestionData.task_type ? currentQuestionData.task_type :""
      };

      getQuestions(chatObj);
    } else {
      setSelectedSuggestion((prevMessageList) => [
        ...prevMessageList,
        dataValue,
      ]);
      setInputMessage((prev) => (prev ? `${prev}, ${dataValue}` : dataValue));
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(
            inputRef.current.value.length,
            inputRef.current.value.length
          );
        }
      }, 0);
    }
  };

  const removeSuggestion = (data) => {
    let inputString ='';
     setSelectedSuggestion((prevSelected) => {
 
       const updatedList = prevSelected.filter((item) => item !== data);
 
       // Remove the corresponding value from inputMessage
       setInputMessage((prevContent) => {
         // Create a regex to remove the specific dataValue from inputMessage
         const regex = new RegExp(`<b>${data.value} </b></br>.*?</br>`, "g");
         return prevContent.replace(regex, "");
       });
       inputString = updatedList;
       return updatedList;
     });
     
     let clearedInputData = inputString.map(str => str.replace(/[.,]/g, "")); /*added for remove unwanted comma and full stop*/
     setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          inputRef.current.value.length,
          inputRef.current.value.length
        );
      }
    }, 0);
     setInputMessage(clearedInputData.length !==0 ? clearedInputData.join(',') : "" );
   };


  const handleSuggestionInput = (e) => {
    handleTextareaResize(e.target);
    setInputMessage(e.target.value);
    if (answerSuggestion?.length !== 0) {
      setSelectedSuggestion(e.target.value.split(","));

    }
  };

  const countAdded = (data) => {
    setInputMessage(data);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          inputRef.current.value.length,
          inputRef.current.value.length
        );
      }
    }, 0);
  };
  const handleScrollBtn = () => {
    if (chatWindowRef.current) {
        chatWindowRef.current.scrollTo({
            top: chatWindowRef.current.scrollHeight,
            behavior: "smooth",
        });
    }
    if (chatWindowMainRef.current) {
        chatWindowMainRef.current.scrollTo({
            top: chatWindowMainRef.current.scrollHeight,
            behavior: "smooth",
        });
    }
      // Delay setting false to ensure smooth transition
      setTimeout(() => {
        setShowDownArrouwBtn(false);
      }, 500); // Adjust timeout if needed
};

  const handleCopy = async(data) => {
   
    const plainText = data.replace(/\*\*/g, "").trim();

    try {
      await navigator.clipboard.writeText(plainText);
      showToast("success","Content copied to clipboard")
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        background:
          "linear-gradient(180deg, rgba(86, 187, 107, 0.05), rgba(0, 152, 114, 0.05))",
        border: "1px solid #C5E7DC",
        borderRadius: "10px",
        zIndex: -1,
      }}
    >
      {/* Piqual Buddy box */}
      <ChatHeader />

      {/* Chat area Box */}
      <ChatWindow
        messageList={messageList}
        loading={loading}
        completedStatus={completedStatus}
        showDownArrowBtn={showDownArrowBtn}
        handleScrollBtn={handleScrollBtn}
        countDownTitle={"Outreach"}
        type={""}
        countDown={countDown}
        showCopyButton={showCopyBUtton} // Controls whether the button appears
        handleCopy={handleCopy}
        ref={chatWindowRef}
      />

      {/* Attachment Component*/}
      <Box sx={{ display: "flex", position: "relative" }} ref={attachmentRef}>
        {showAttachment ? (
          <Box
            sx={{
              position: "absolute",
              bottom: "150%", // Position above the button
              right: "-6.5vw",
              transform: "translateX(-50%)",
              border: "2px solid rgba(0, 0, 0, 0.1)",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <AttachButton
              onClose={() => setShowAttachment(false)}
              getFileUrl={getFileUrl}
            />
          </Box>
        ) : (
          ""
        )}{" "}
      </Box>

      {/* Message Box*/}
      <Box
        sx={{
          flexShrink: 0,
          // maxHeight: "25vh",
          overflowY: "auto",
          display: "grid",
          padding: "8px",
          transition: "max-height 0.3s ease-in-out",
        }}
      >
        <Box className="message-content">
          <Box
            className="message-actions"
            sx={{
              display: "flex",
              //  flexDirection: showFirstBox ? "column" : "row",
              alignItems: showFirstBox
                ? "flex-end"
                : isResized
                ? "flex-end"
                : "center", // Dynamic alignment
              width: "100%",
              flexGrow: 1,
              position: "relative",
              // maxHeight: "200px",
            }}
          >
            {/* Input Field and Suggestions Button */}
            <Box
              sx={{
                width: "100%",
                height: "auto",
                overflowY: "hidden",
                marginBottom:
                  selectedSuggestion.length !== 0 ? "-20px " : "-20px",
              }}
            >
              <textarea
                rows={1}
                placeholder={placeholderText}
                className="message-input"
                disabled={!showFirstBox}
                ref={inputRef}
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  height: selectedSuggestion[0] === true ? "60px" : "25px",
                  maxHeight: showFirstBox ? "120px" : "150px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  resize: "none",
                  display: "flex",
                  alignItems: "center",
                  padding: inputMessage ? "10px" : "0px",
                  lineHeight: "1.5",
                  marginLeft: "6px",
                  fontSize: "16px",
                }}
                value={inputMessage}
                onChange={(e) => {
                  handleSuggestionInput(e);
                }}
                onInput={(e) => handleTextareaResize(e.target)}
                onKeyDown={handleKeyDown}
              />
              {/* Suggestions Button */}
              {showFirstBox && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                    overflow: "hidden",
                  }}
                >
                  {!showSlider &&
                    answerSuggestion?.map((dataValue) => (
                      <Box
                        sx={{ padding: "2px", maxWidth: "100%" }}
                        key={dataValue}
                      >
                        <Button
                          onClick={() => {
                            if (!selectedSuggestion.includes(dataValue)) {
                              addStatus(dataValue);
                            }
                          }}
                          sx={{
                            background: "#fff",
                            justifyContent: "flex-start",
                            height: "auto",
                            width: "auto",
                            border: selectedSuggestion.includes(dataValue)
                              ? "1px solid #009872"
                              : "1px solid #A3A3A4",
                            color: selectedSuggestion.includes(dataValue)
                              ? "#009872"
                              : "#818182",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "100%",
                            "&:hover": {
                              background: "#FFFFFF",
                              border: "1px solid #009872",
                              color: "#009872",
                            },
                          }}
                          endIcon={
                            selectedSuggestion?.includes(dataValue) && (
                              <CloseIcon
                                onClick={(event) => {
                                  event.stopPropagation();
                                  removeSuggestion(dataValue);
                                }}
                                style={{ color: "#818182", fontSize: "15px" }}
                              />
                            )
                          }
                        >
                          {dataValue}
                        </Button>
                      </Box>
                    ))}
                </Box>
              )}

              {showSlider && <ChatSlider countAdded={countAdded} />}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: showFirstBox ? "space-between" : "flex-end",
              }}
            >
              {/* Attachment and Mic button */}
              <Box
                sx={{
                  display: "flex",
                  paddingTop: showFirstBox && "10px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginLeft: showFirstBox ? "auto" : 0,
                  // marginBottom: "-3px",
                  gap: "2px",
                }}
              >
                {salesPlanType === "message_plan" ||
                salesPlanType === "media_plan" ? (
                  <Box
                    className="attachmentButton"
                    sx={{
                      backgroundColor: showAttachment ? "#009872" : "",
                      padding: showAttachment ? "2px" : "",
                    }}
                    onClick={handleAttachButtonClick}
                  >
                    <img
                      className="AttachmentIcon"
                      loading="lazy"
                      alt=""
                      src={
                        showAttachment ? AttachmentIconWhite : AttachmentIcon
                      }
                    />
                  </Box>
                ) : null}

                <Box className="attachmentButton">
                  <img
                    className="AttachmentIcon"
                    loading="lazy"
                    alt=""
                    src={RecordingIcon}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="keyboard-dropdown">
            <button
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
              onClick={handleInputMessage}
              disabled={!showFirstBox}
            >
              <img loading="lazy" alt="" src={SendIcon} />
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SDRChatInterface;
