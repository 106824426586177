
import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Box } from '@mui/material';

import { warnIcon } from '../../../Constants/Constant';

const DeleteModal = ({ isOpen, setIsOpen, handleDelete }) => {

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDeleteClick = () => {
    handleDelete();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "400px",
          flexDirection: "column",
          padding: "20px"
        }}
      >
        <IconButton sx={{ bgcolor: "#FFF1F1" }}>
          <img src={warnIcon} alt='' style={{ margin: "10px" }} />
        </IconButton>
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: "28px", padding: "5px" }}>{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ textAlign: "center", padding: "0px" }}
            id="alert-dialog-description"
          >
            Do you really want to delete this suggestion? This process cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ width: "100%" }}>
          <Button
            sx={{
              border: "1px solid #D1D1D2",
              color: "#252527",
              textTransform: "capitalize",
              width: "50%"
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              bgcolor: "#FF4949",
              color: "#ffff",
              textTransform: "capitalize",
              width: "50%"
            }}
            onClick={handleDeleteClick}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default DeleteModal
