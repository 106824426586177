import { Box, Button, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const SalesPlanBox = styled(Box)({
  width: "100%",
});

const GreetingsBox = styled(Typography)(({ theme }) => ({
  color: "#ff7d34",
  marginBottom: "0.3125rem",
  paddingBottom: "0.125rem",
  fontWeight: 500,
  marginLeft: "1.25rem",
  marginTop: "1.25rem",
  fontFamily: "Roboto",
  fontSize: "1.75rem",

  "@media (resolution: 120dpi)": {
    fontSize: "1.5rem",
    marginTop: "0.75rem",
    paddingBottom: "0rem",
  },

  "@media (resolution: 2dppx)": {
    marginBottom: "0rem !important",
  },

  "@media (width: 1366px)": {
    marginTop: "0.75rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.15rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "1.25rem",
    paddingBottom: "0rem",
    marginTop: "1rem",
  },
}));

const GreetingsBox2 = styled(Typography)(({ theme }) => ({
  width: "78%",
  paddingBlock: "0.3125rem",
  marginBlock: "0.3125rem",
  fontWeight: 400,
  marginLeft: "1.25rem",
  fontFamily: "Roboto",
  fontSize: " 1.375rem",
  color: "#6A6A6B",
  wordBreak:"keep-all",

  "@media (resolution: 120dpi)": {
    width: "90%",
    fontSize: " 1.25rem",
    paddingBlock: "0rem",
  },
  "@media (width: 1366px)": {
    marginBlock: "0.1rem",
    paddingBlock: "0.1rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "0.9375rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "1rem",
    width: "85%",
    paddingBlock: "0.1rem",
  },
}));

const HeadingBox = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  paddingBlock: "0.2rem",
  marginLeft: "1.25rem",
  fontFamily: "Roboto",
  fontSize: "1.75rem",

  "@media (resolution: 120dpi)": {
    fontSize: "1.5rem",
    paddingBlock: "0rem",
    paddingBottom:"0.5rem",
  },

  "@media (resolution: 2dppx)": {
    fontSize: "1.25rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "1.25rem",
    paddingBlock: "0rem",
  },
}));

const PlansGrid = styled(Grid)(({ theme, allCompleted, isSideBarOpened }) => ({
  marginBlock: allCompleted ? "1.5625rem " : "1.75rem",
  alignItems: allCompleted ? "flex-start" : "center",
  marginBottom: "2.5%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-evenly",

  "@media (resolution: 120dpi)": {
    marginBottom: "2%",
  },
  "@media (resolution: 2ddpx)": {
    margin: allCompleted ? "1rem" : "0.9375rem",
    marginBottom: "1.5%",
  },

  "@media (resolution: 144dpi)": {
    marginBottom: "1%",
  },

  [theme.breakpoints.down(1400)]: {
    margin: "1% 0 1.5%",
    justifyContent: "flex-start",
  },
}));

const PlanInnerBox = styled(Box)(
  ({ theme, isActive, isSideBarOpened, planStatus }) => ({
    height: isActive ? "50vh" : planStatus ? "45vh" : "32vh",
    width: "fit-content",
    padding: isSideBarOpened
      ? "1.25rem 1.25rem 0.5rem"
      : isActive
        ? "1.5rem 1.25rem 1rem "
        : "1.5rem 1.25rem 0.5rem",
    border: isActive ? "0.125rem solid #ff7d34" : "0.125rem solid #E8E8E8",
    boxShadow: isActive ? "0px 0px 0.35rem 0px #FF7D34" : "none",
    borderRadius: "0.375rem",
    textAlign: "left",
    backgroundColor: "#fff",
    transition: "all 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: isActive ? "4%" : planStatus ? "0.5%" : "5%",
    overflow: "hidden",

    "@media (resolution: 120dpi)": {
      gap: isActive ? "1%" : planStatus ? "0.5%" : "5%",
      marginTop: "-1rem",
      height: isActive ? "50vh" : planStatus ? "47vh" : "35vh",
      padding: isSideBarOpened ? "1rem" : "",
    },

    "@media (resolution: 2dppx)": {
      height: isActive ? "50vh" : planStatus ? "48vh" : "32vh",
      gap: isActive ? "2%" : planStatus ? "0%" : "5%",
      padding: isSideBarOpened
        ? "1.25rem 1.25rem 0.5rem"
        : isActive
          ? "1.5rem 1.25rem 1.5rem "
          : "1.5rem 1rem 1rem",
    },

    // HD (1366x768)
    [theme.breakpoints.down(1400)]: {
      height:
        isSideBarOpened && isActive
          ? "60vh"
          : isActive
            ? "62vh"
            : planStatus
              ? "58.5vh"
              : "40vh",
      marginRight: "-2.25rem",
      marginLeft: "-1rem",
      marginBlock: "-2.4rem",
      transform: "scale(0.8)",
      gap: isActive ? "10%" : planStatus ? "0.3%" : "4%",
      padding: isSideBarOpened
        ? "1rem 1.25rem"
        : isActive
          ? "1.25rem 2rem"
          : "1.25rem 2rem",
    },
    // //  2K (2560x1440)
    [theme.breakpoints.between(1921, 2560)]: {
      height: isActive ? "55vh" : planStatus ? "50vh" : "45vh",
    },
    //  4K (3840x2160)
    [theme.breakpoints.between(2561, 3840)]: {
      height: isActive ? "60vh" : planStatus ? "55vh" : "45vh",
    },
  })
);

const TitleBox = styled(Typography)(({ theme, isActive }) => ({
  fontWeight: 600,
  fontFamily: "Roboto",
  fontSize: "1.75rem",
  margin: "0.0625rem 0 0.3125rem 0",

  "@media (resolution: 120dpi)": {
    fontSize: "1.5rem",
  },

  "@media (resolution: 2dppx)": {
    fontSize: "1.125rem",
  },

  [theme.breakpoints.down(1400)]: {
     fontSize: "1.25rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: isActive ? "1rem" : "0.9375rem",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "2rem",
  },
}));

const DescriptionBox = styled(Typography)(({ theme, isActive }) => ({
  fontWeight: 400,
  fontFamily: "Roboto, sans-serif",
  fontSize: isActive ? "1.375rem" : "1.25rem",
  // textOverflow: "ellipsis",
  maxWidth: "100%",
  // overflow: "hidden",
  // display: "-webkit-box",
  // WebkitBoxOrient: "vertical",
  // WebkitLineClamp: 2, // Adjust this to control the number of visible lines
  cursor:"pointer",

  "@media (resolution: 120dpi)": {
    fontSize: isActive ? "1.125rem" : "1rem",
    // overflow: "hidden",
    // display: "-webkit-box",
    // WebkitBoxOrient: "vertical",
    // WebkitLineClamp: 3,
  },

  "@media (resolution: 2dppx)": {
    fontSize: isActive ? "1rem" : "0.875rem",
  },

  [theme.breakpoints.down(1400)]: {
    fontSize: "1rem",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
 },

  [theme.breakpoints.down("md")]: {
    fontSize: isActive ? "1rem" : "0.9375rem",
  },
}));

const GetStartedButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff7d34",
  padding: "0.7rem 1.5rem 0.7rem 1.25rem",
  marginTop: "-0.625rem",
  boxShadow: "0px 0px 0.25rem 0px #FF7D34",
  textTransform: "none",
  textWrap: "wrap",
  width: "fit-content",
  fontWeight: 600,
  fontFamily: "Roboto, sans-serif",
  fontSize: "1.25rem",
  color: "#fff",

  "@media (resolution: 120dpi)": {
    fontSize: "1rem",
    padding: "0.5rem 1.5rem 0.5rem 1.25rem",
  }, 

  "@media (resolution: 2dppx)": {
    fontSize: "0.875rem",
  },

  "&:hover": {
    backgroundColor: "#EF4207",
  },

  [theme.breakpoints.down(1400)]: {
    fontSize: " 0.875rem",
 },

  [theme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
    padding: "0.625rem 1.5rem",
  },
}));

const ImageBox = styled(Box)(
  ({ theme, allCompleted, isActive, planStatus }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: allCompleted
      ? "#E1F7E2"
      : planStatus
        ? "#E1F7E2"
        : isActive
          ? "#FFF5ED"
          : "#F9F9F9",
    padding: "3.5%",
    borderRadius: "50%",
    width: isActive ? "5rem" : "3rem",
    height: isActive ? "5rem" : "3rem",
    aspectRatio: "1 / 1",

    "@media (resolution: 120dpi)": {
      width: isActive ? "4rem" : "2.5rem",
      height: isActive ? "4rem" : "2.5rem",
      padding: "2.5%",
    },

    "media(resolution: 2dppx)": {
      width: isActive ? "4rem" : "2.5rem",
      height: isActive ? "4rem" : "2.5rem",
    },

    [theme.breakpoints.down("md")]: {
      width: "2.5rem",
      height: "2.5rem",
    },

    [theme.breakpoints.down("sm")]: {
      width: "2.25rem",
      height: "2.25rem",
    },

    [theme.breakpoints.down(1400)]: {
      width: isActive ? "4rem" : "3rem",
      height: isActive ? "4rem" : "3rem",
    },
  })
);

const PlanIcon = styled("img")(({ theme }) => ({
  width: "55%",
  height: "55%",
  objectFit: "contain",

  "@media (resolution: 2dppx)": {
    width: "55%",
    height: "55%",
  },

  [theme.breakpoints.down("md")]: {
    width: "60%",
    height: "60%",
  },

  [theme.breakpoints.down("sm")]: {
    width: "55%",
    height: "55%",
  },
}));

const CompletedIcon = styled("img")(({ theme }) => ({
  width: "65%",
  height: "65%",
  objectFit: "contain",

  "@media (resolution: 2dppx)": {
    width: "55%",
    height: "55%",
  },

  [theme.breakpoints.down("md")]: {
    width: "60%",
    height: "60%",
  },

  [theme.breakpoints.down("sm")]: {
    width: "55%",
    height: "55%",
  },
}));

const RightArrowIcon = styled("img")({
  marginLeft: "0.375rem",
  height: "0.8125rem",
});

const ViewPlanBox = styled(Box)({
  // height:'1vh',
  // width: "fit-content",
  cursor: "pointer",
  border: "1px solid #009872",
  borderRadius: "0.25rem",
  display: "flex",
  alignItems: "center",

  "&:hover": {
    backgroundColor: "#EBFEF6",
  },
});

const EnhaneScoreBox = styled(Box)({
  // height:'5vh',
  // width: "fit-content",
  cursor: "pointer",
  border: "1px solid #009872",
  borderRadius: "0.25rem",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#EBFEF6",
  },
});

const ViewPlanButton = styled(Button)(({ theme, isSideBarOpened }) => ({
  fontFamily: "Roboto",
  fontWeight: "600",
  wordBreak: "keep-all",
  border: "none",
  color: "#009872",
  textTransform: "none",
  fontSize: "1rem",
  padding: isSideBarOpened ? "0.675rem" : "0.675rem 1.5rem",

  "@media (min-resolution: 120dpi)": {
    padding: "0.25rem 0.5rem",
  },

  "@media (min-resolution: 144dpi)": {
    fontSize: "0.9rem",
  },

  "@media (resolution: 2dppx)": {
    fontSize: "0.9rem",
    padding: isSideBarOpened ? "0.25rem" : "0.25rem 0.75rem",
  },

  "&:hover": {
    backgroundColor: "#EBFEF6",
  },

  [theme.breakpoints.down(1400)]: {
    padding: "0.5rem 0.75rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
    padding: "0px 0.625rem",
  },
}));

const EnhaneScoreButton = styled(Button)(({ theme, isSideBarOpened }) => ({
  fontFamily: "Roboto",
  fontWeight: "600",
  wordBreak: "keep-all",
  border: "none",
  color: "#009872",
  textTransform: "none",
  fontSize: "1rem",
  padding: "0.675rem 1.5rem",

  "@media (min-resolution: 120dpi)": {
    padding: "0.25rem 0.5rem",
  },

  "@media (min-resolution: 144dpi)": {
    fontSize: "0.9rem",
  },

  "@media (resolution: 2dppx)": {
    fontSize: "0.9rem",
    padding: isSideBarOpened ? "0.25rem" : "0.25rem 0.75rem",
  },

  "&:hover": {
    backgroundColor: "#EBFEF6",
  },

  [theme.breakpoints.down(1400)]: {
    padding: "0.5rem 0.75rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
    padding: "0px 0.625rem",
  },
}));

const DividerLine = styled("hr")(({ theme }) => ({
  margin: "6% -7% 3%",
  border: "1px solid #E8E8E8",

  [theme.breakpoints.down("md")]: {
    margin: "6% -10% 2%",
  },
}));

const ProgressPercent = styled("span")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontFamily: "Roboto",
  fontWeight: 700,
  fontSize: "16px",

  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
}));

const ScoreTypography = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 700,
  fontSize: "1rem",
  marginBottom: "0.3125rem",
});

const ScoreDescription = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#525252",

  [theme.breakpoints.down(1400)]: {
    fontSize: "0.75rem",
  },
}));

const ScorePlanName = styled("span")({
  textTransform: "lowercase",
});

const AssignSDRButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#009872",
  textTransform: "none",
  color: "white",
  fontSize: "1rem",
  padding: "0.5rem 2rem",

  "@media (min-resolution: 144dpi)": {
    fontSize: "0.8rem",
  },

  "&:hover": {
    backgroundColor: "#009872",
    cursor: "pointer",
  },

  [theme.breakpoints.down(1400)]: {
    fontSize: "0.8rem",
  },
}));

export {
  SalesPlanBox,
  PlanInnerBox,
  GreetingsBox,
  GreetingsBox2,
  DescriptionBox,
  TitleBox,
  HeadingBox,
  PlansGrid,
  GetStartedButton,
  ImageBox,
  PlanIcon,
  CompletedIcon,
  RightArrowIcon,
  ViewPlanBox,
  EnhaneScoreBox,
  ViewPlanButton,
  EnhaneScoreButton,
  DividerLine,
  ProgressPercent,
  ScoreTypography,
  ScoreDescription,
  ScorePlanName,
  AssignSDRButton,
};
