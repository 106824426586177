import { SET_SDR_DB_DETAILS } from "../Actions/SdrDatabaseDetailsAction";


const initialState = {
  sdrDatabaseData: null
};

const sdrDatabaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SDR_DB_DETAILS:
      return {
        ...state,
        sdrDatabaseData: action.payload
      };
    default:
      return state;
  }
};

export default sdrDatabaseReducer;