import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Box, useMediaQuery } from "@mui/material";
import { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function SdrDropDown() {
  const [personName, setPersonName] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width:1536px)"); // ✅ Checks screen size

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        padding: "0px",
        marginTop: "5px",
        boxShadow: "none",
        border: "1px solid #E8E8E8",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: isSmallScreen ? 150 : 200, // ✅ Responsive width
        fontSize: isSmallScreen ? "12px" : "inherit", // ✅ Responsive font size
        scrollbarWidth: "thin",
      },
    },
  };

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          width: isSmallScreen ? 150 : 200, // ✅ Responsive width
        }}
        size="small"
      >
        <Select
          multiple
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Box
                  sx={{
                    fontWeight: 500,
                    fontSize: isSmallScreen ? "12px" : "14px", // ✅ Responsive font size
                  }}
                >
                  All SDR's
                </Box>
              );
            }
            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          sx={{
            height: isSmallScreen ? "30px" : "35px", // ✅ Responsive height
            border: "1px solid #3C3C3E",
            color: "#3C3C3E",
            borderRadius: "4px",
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: "none" },
            "&:focus": { outline: "none" },
          }}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              sx={{
                height:isSmallScreen ?"40px":"50px",
                fontWeight: 500,
                padding:"0px 3px",
                "&.Mui-focused, &.Mui-selected, &:hover": {
                  backgroundColor: "#EBFEF6 !important",
                },
              }}
            >
              <Checkbox
                checked={personName.includes(name)}
                sx={{
                  color: "#D1D1D2",
                  "&.Mui-checked": { color: "#009872" },
                  transform: isSmallScreen ? "scale(0.8)" : "scale(1)", // ✅ Adjust checkbox size
                }}
              />
              <ListItemText
              sx={{
                "& .MuiTypography-root": {
                  fontSize: isSmallScreen ? "12px" : "14px", // ✅ Adjust font size
                },
              }}
                primary={name}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
