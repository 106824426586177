import * as React from 'react';
import { InputLabel, MenuItem, FormControl, Select, Paper } from '@mui/material';

const DropDown = ({label}) => {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Paper
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 200,
        height: 30,
        border: '1px solid #D3D3D3',
        borderRadius: '12px',
        boxShadow: 'none',
        padding: '8px',
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={handleChange}
          sx={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            borderRadius: '12px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </Paper>
  );
};

export default DropDown;
