import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Box, useTheme } from "@mui/material";


  const keyMapping = {
    meetings_booked_weekly: "Meetings Booked",
    emails_daily: "Emails to be sent",
    linkedin_messages_daily: "LinkedIn touchpoints",
    calls_daily: "Calls to be made",
    new_companies_daily: "Companies to be added",
    new_contacts_daily: "Contacts to be added",
  };


const MeasurePlan = ({ sdr_kpis }) => {
  const theme = useTheme();
  const location = useLocation();
  const isViewPlan = location.pathname.includes("view-plan");
  const isOutReach = location.pathname.includes("outreach");
const rowLabel = useMemo(() => [
  "Meetings Booked",
  "Emails to be sent",
  "LinkedIn touchpoints",
  "Calls to be made",
  "Companies to be added",
  "Contacts to be added",
], []);

  const sdrArr = useMemo(() => Object.entries(sdr_kpis || {}).map(([key, value]) => ({ [key]: value })), [sdr_kpis]);

  const dailyData = useMemo(() => {
    return rowLabel.map((label) => {
      const apiKey = Object.keys(keyMapping).find((key) => keyMapping[key] === label);
      const match = sdrArr.find((item) => item.hasOwnProperty(apiKey));
      return match ? match[apiKey] : null;
    });
  }, [sdrArr, rowLabel]);

const getTransformedData = (multiplier, isDaily = false) => 
  dailyData.map((value, index) => {
    if (rowLabel[index] === "Meetings Booked") {
      return isDaily 
        ? "-" 
        : value !== null 
          ? Math.round((value / 5) * multiplier).toLocaleString() // Ensure whole numbers
          : null;
    }
    return value !== null ? (value * multiplier).toLocaleString() : null;
  });

  const timeframes = [
    { label: "Daily", data:  getTransformedData(1, true) },
    { label: "Weekly", data: getTransformedData(5)  },
    { label: "Monthly", data: getTransformedData(22) },
    { label: "Quarterly", data: getTransformedData(66) },
    { label: "Yearly", data: getTransformedData(264) },
  ];

  const RenderColumn = ({ title, data }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid  #E8E8E8",
        borderRadius: "8px",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid  #E8E8E8",
          padding: "17.5px 20px",
          backgroundColor: "#FFF5ED",
          color: "#FF7D34",
         fontSize: (isViewPlan || isOutReach) ? "20px":"14px",
          fontWeight: 700,
          borderRadius: "8px 8px 0 0",
     
          [theme.breakpoints.down("xl")]: {
            fontSize: "14px",
            padding: "17.5px 20px",
          },
        }}
      >
        {title}
      </Box>
      {data.map((value, i) => (
        <Box
          key={i}
          sx={{
            borderBottom: i + 1 === data.length ? "none" : "1px solid  #E8E8E8",
            padding: (isViewPlan || isOutReach)? "20px": "9.5px 20px",
            color: "#009872",
           fontSize: (isViewPlan || isOutReach) ? "20px":"14px",
            fontWeight: 500,
          
            [theme.breakpoints.down("xl")]: {
              fontSize: "14px",
              padding:"9.5px 20px",
            },
          }}
        >
          {value !== null ? value : "-"}
        </Box>
      ))}
    </div>
  );

  if (!sdrArr.length) {
    return <Box sx={{ height: "100%" }}>No records found</Box>;
  }

  return (
    <Box sx={{ height: "100%" }}>
      {!isViewPlan && !isOutReach && ( <Box sx={{color:'#707070',pt:1}}>Here's the daily and the weekly target for each SDR.</Box>)}
     

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          gap: "10px",
          width: "100%",
          textAlign: "center",
          paddingTop: "10px",
          overflowX: "auto",
          fontFamily:"roboto",
          scrollbarWidth: "thin", // Ensures a thin scrollbar in Firefox
          "&::-webkit-scrollbar": {
            width: "3px", // Thin scrollbar for Chrome/Safari
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#bbb", // Scrollbar color
            borderRadius: "2px", // Rounded edges
          },
        }}
      >
        {/* Milestone Column */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid  #E8E8E8",
            borderRadius: "8px",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid  #E8E8E8",
              padding: "17.5px 20px",
              width: (isViewPlan || isOutReach) ? "86%": "241px",
              backgroundColor: "#FFF5ED",
              color: "#FF7D34",
              fontSize: (isViewPlan || isOutReach) ? "20px":"14px",
              fontWeight: 700,
          
              [theme.breakpoints.down("xl")]: {
                fontSize: "14px",
                padding: "17.5px 20px",
              },
            }}
          >
            Milestone
          </Box>
          {rowLabel.map((label, i) => (
            <Box
              key={i}
              sx={{
                borderBottom: i + 1 === rowLabel.length ? "none" : "1px solid  #E8E8E8",
                 padding: (isViewPlan || isOutReach)? "20px":"9.5px 20px",
                fontWeight: label === "Meetings Booked" ? 500 : "normal",
                color:label === "Meetings Booked" ? "#3C3C3E":"#6A6A6B",
                fontSize: (isViewPlan || isOutReach) ? "20px":"13.5px",
            
                [theme.breakpoints.down("xl")]: {
                  fontSize: "13.5px",
                  padding:"9.5px 20px"
                },
              }}
            >
              {label}
            </Box>
          ))}
        </div>

        {/* Data Columns */}
        {timeframes.map((tf, index) => (
          <RenderColumn key={index} title={tf.label} data={tf.data} />
        ))}
      </div>
    </Box>
  );
};


export default MeasurePlan;