
import React from 'react'
import Grid from "@mui/material/Grid2";
import SDRChatInterface from './SDRChatInterface';
// import YourSalesPlanLayout from './YourSalesPlanLauout';
import SDRsalesPlanLayout from './SDRSalesPlanLayout';

const SDRMainLayout = () => {
  return (
        <Grid container>
          <Grid
            sx={{
              height:{
                xs :"90vh",
                sm :"90vh",
                md :"90vh",
                lg:"89vh",
                xl:"90vh"
              } ,
    
              paddingLeft: "24px",
              paddingTop: "24px",
              paddingBottom:"4px",
              paddingRight: "12px",
            }}
            size={6}
          >
            {/* <MarketPlanLayout /> */}
            <SDRChatInterface/>
          </Grid>
    
          <Grid
            sx={{
              height:{
                xs :"90vh",
                sm :"90vh",
                md :"90vh",
                lg:"89vh",
                xl:"90vh"
              } ,
    
              paddingTop: "24px",
              paddingRight: "24px",
              paddingBottom:"2px",
              paddingLeft: "12px",
            }}
            size={6}
          >
            <SDRsalesPlanLayout/>
          </Grid>
        </Grid>
      );
}

export default SDRMainLayout
