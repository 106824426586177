import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid2, Typography } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { TitleBox } from "../ChatInterfaceStyles";

const RadialBarChartComponent = ({touchpointsData}) => {
 const title = localStorage.getItem("sales_plan_chat_type");
  const [expanded, setExpanded] = useState(
    localStorage.getItem("accordionState") === "expanded" || false
  );
  const handleAccordionChange = (_, isExpanded) => {
    setExpanded(isExpanded);
    localStorage.setItem(
      "accordionState",
      isExpanded ? "expanded" : "collapsed"
    );
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionChange}
      sx={{
        backgroundColor: "#EEF4FF",
        boxShadow: "none",
        "&.Mui-expanded:last-of-type": {
          marginBottom: 0,
          borderRadius: "10px",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "&.Mui-expanded": {
            minHeight: "45px",
          },
        }}
      >
        <TitleBox>
          {title === "db-companies"
            ? "Measure of targets for database build"
            : "Your Outreach via Media So Far"}
        </TitleBox>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0px 20px 20px 20px",
        }}
      >
        <Grid2
          container
          // spacing={2}
          rowSpacing={1}
          columnSpacing={{
            xs: 1,
            md: 1.3,
            xl: 1.5,
          }}
          columns={12}
          sx={{}}
        >
          {touchpointsData?.map((item, index) => {
            const chartData = ["daily", "weekly", "monthly"]
              .filter((key) => item[key])
              .map((key) => ({
                name: key.charAt(0).toUpperCase() + key.slice(1),
                value: item[key].value,
                max: item[key].max,
                fill: item[key].fill,
                bg: item[key].bg,
              }));
         
            const dailyValue = Math.min(
              (item.daily.value / item.daily.max) * 100,
              100
            );
            const weeklyValue = Math.min(
              (item.weekly.value / item.weekly.max) * 100,
              100
            );
            return (
              <Grid2
                key={index}
                sx={{
                  width: "49%",
                  "@media(resolution : 120dpi)": {
                    width: "48%",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    alignItems: "center",
                    padding: "10px",
                    flexDirection: { md: "row" },
                  }}
                >
                  {/* Chart Container */}
                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100px",
                        md: "120px",
                        xl: "130px",
                        xxl: "130px",
                      },
                      height: {
                        xs: "100%",
                        sm: "100px",
                        md: "130px",
                        xl: "130px",
                        xxl: "130px",
                      },
                    }}
                  >
                    
                    <div>
                      

                      <div key={index}>
                        <PieChart width={120} height={120}>
                          {/* Weekly Pie - Outer Layer */}
                          <Pie
                            data={[
                              { name: "Weekly Progress", value: weeklyValue },
                              { name: "Remaining", value: 100 - weeklyValue },
                            ]}
                            cx="50%"
                            cy="50%"
                            innerRadius={40}
                            outerRadius={50}
                            startAngle={90}
                            endAngle={-270}
                            dataKey="value"
                            cornerRadius={4}
                          >
                            <Cell fill={item.weekly.fill}    style={{
                                stroke:
                                weeklyValue === 100 ? "#56BB6B" : "",
                              }}/>
                            <Cell fill={item.weekly.bg} />
                          </Pie>

                          {/* Daily Pie - Inner Layer */}
                          <Pie
                            data={[
                              { name: "Daily Progress", value: dailyValue },
                              { name: "Remaining", value: 100 - dailyValue },
                            ]}
                            cx="50%"
                            cy="50%"
                            innerRadius={25}
                            outerRadius={35}
                            startAngle={90}
                            endAngle={-270}
                            dataKey="value"
                            cornerRadius={4}
                          >
                            <Cell
                              style={{
                                stroke:
                                dailyValue  === 100 ? "#56BB6B" : "",
                              }}
                              fill={item.daily.fill}
                            />
                            <Cell
                              fill={item.daily.bg}
                             
                            />
                          </Pie>
                        </PieChart>
                      </div>
                    </div>
                  </Box>

                  {/* Text Content */}
                  <Box
                    sx={
                      {
                        // marginLeft: {
                        //   xs: "0px",
                        //   sm: "0px",
                        //   md: "0px",
                        //   xl: "10px",
                        //   xxl: "10px",
                        // },
                        // gap: {
                        //   xs: "0px",
                        //   sm: "0px",
                        //   md: "0px",
                        //   xl: "20px",
                        //   xxl: "20px",
                        // },
                      }
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "14px",
                          md: "14px",
                          xl: "16px",
                          xxl: "20px",
                        },
                        // color:{
                        //   xs: "red",
                        //   sm: "green",
                        //   md: "blue",
                        //   xl: "yellow",
                        //   xxl: "black",
                        // },
                        textOverflow: "ellipsis",
                        textWrap: "wrap",
                        fontWeight: "500",
                      }}
                    >
                      {item.title}
                    </Typography>
                    {chartData.map((item, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 0.5,
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: 8, sm: 8, md: 8, xl: 8, xxl: 12 },
                            height: { xs: 8, sm: 8, md: 8, xl: 8, xxl: 12 },
                            bgcolor: item.fill,
                            borderRadius: "50%",
                            mr: 1,
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "14px",
                              xl: "20px",
                              xxl: "20px",
                            },
                            fontWeight: "400",
                            color: "#3C3C3E",
                          }}
                        >
                          {item.name} Target
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "auto",
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "14px",
                              xl: "20px",
                              xxl: "20px",
                            },
                            fontWeight: "400",
                            color: "#3C3C3E",
                          }}
                        >
                          {item.value}/{item.max}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid2>
            );
          })}
        </Grid2>
      </AccordionDetails>
    </Accordion>
  );
};

export default RadialBarChartComponent;
