import { SET_SDR_OUTREACH_MEDIA_DETAILS } from "../Actions/SdrOutReachMediaDetailsAction";



const initialState = {
  sdrOutReachMediaData: null
};

const sdrOutReachMediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SDR_OUTREACH_MEDIA_DETAILS:
      return {
        ...state,
        sdrOutReachMediaData: action.payload
      };
    default:
      return state;
  }
};

export default sdrOutReachMediaReducer;