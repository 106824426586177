
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ element, requiredRole }) => {
  const { isAuthenticated } = useAuth();
  const userRole = localStorage.getItem("role");
  const token = localStorage.getItem("token");

  const isTokenExpired = (token) => {
    if (!token) return true; // Treat missing token as expired
    try {
      const decoded = jwtDecode(token);
      return decoded.exp * 1000 < Date.now(); // Compare expiry time with current time
    } catch (error) {
      return true; // If decoding fails, treat as expired
    }
  };

  // Redirect to login if token is expired
  if (!isAuthenticated || isTokenExpired(token)) {
    localStorage.clear(); // Clear local storage
    return <Navigate to="/" replace />;
  }

  if (requiredRole && !requiredRole.includes(userRole)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return element;
};

export default ProtectedRoute;
