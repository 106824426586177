import React, { useEffect, useState } from "react";
import { Background, BuddyImg, StyleLogo, TextImg } from "./LoginPageStyle";
import { Box } from "@mui/system";
import {
  LoginFrameNew,
  PiqualAiBot,
  PiqualLogo,
} from "../../Constants/Constant";
import { Button, TextField, Typography } from "@mui/material";
import styles from "./LoginVariation.module.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToast } from "../../utils/ToastContext";
import { API } from "../../global";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const { showToast } = useToast();
  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");
  const navigate =useNavigate()
  const [checkEmail, setCheckEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const schema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is a required field.")
      .matches(/^[^\s#[\]()]*\.com$/, "Please enter a valid email."),
  });

  useEffect(()=>{
   if(type === 'success'){
    setCheckEmail(true);
   }
  },[type])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onsubmit" });

  const onSubmit = async (data) => {
    setLoading(true);
    const requestBody = { email: data.email.toLowerCase() };

    fetch(`${API}/auth/send-password-reset-link`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          navigate('/forgot-password?type=success')
          showToast(
            "success",
            "A password reset link has been sent to your email."
          );
        } else {
          showToast(
            "error",
            response.error?.msg || "Something went wrong. Please try again."
          );
        }
      })
      .catch(() => {
        setLoading(false);
        showToast("error", "An error occurred. Please try again.");
      });
  };

  const handleNavigateLogin =()=>{
    navigate('/')
  }

  return (
    <Background>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          // padding: "0px 38px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "35px",
            bgcolor: "white",
            padding: "24px 16px 16px 16px",
            borderRadius: "0 0 8px 8px",

            "@media (min-resolution: 1.25dppx)": {
              padding: "20px 10px 10px 10px",
            },
          }}
        >
          <StyleLogo src={PiqualLogo} alt="" />
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            // width: "35%",
            width: { xs: "30%", sm: "30%", md: "35%", lg: "35%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "15px",
            padding: "50px",
            borderRadius: "12px",

            "@media (min-resolution: 1.5dppx)": {
              // 150% scale
              height: "400px",
              gap: "10px",
            },
          }}
        >
          <Box sx={{ position: "relative" }}>
            <TextImg src={LoginFrameNew} alt="" />
            <BuddyImg src={PiqualAiBot} alt="" />
          </Box>

          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "500",
              marginTop:"20px",
              fontSize: {
                xs: "20px",
                sm: "20px",
                md: "20px",
                lg: "30px",
                xl: "36px",
              },
            }}
          >
            {checkEmail ? "Check Your Email!" : "Forgot Your Password"}
          </Typography>

          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "20px",
                lg: "20px",
                xl: "20px",
              },
            }}
          >
            {checkEmail
              ? "We just sent you an email with a link to reset your password"
              : "Please enter your registered email address"}
          </Typography>
          {checkEmail && (
            <Typography
              onClick={handleNavigateLogin}
              sx={{
                fontFamily: "Roboto",
                color:"#009872",
                cursor:"pointer",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "14px",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Click here to login
            </Typography>
          )}
          {!checkEmail && (
            <form
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* Email Field */}
              <TextField
                placeholder="Enter Email Address"
                variant="outlined"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
                slotProps={{
                  formHelperText: {
                    sx: { marginLeft: "0px" },
                  },
                  input: {
                    sx: {
                      height: "48px",
                      paddingRight: "12px",
                      borderRadius: "8px",
                      fontSize: "20px",
                      color: "#1A1A1C",
                      fontFamily: "Roboto",
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow:
                          "0 0 0px 1000px white inset !important",
                        backgroundColor: "transparent !important",
                        color: "#1A1A1C !important",
                      },
                      "& input::placeholder": {
                        fontSize: "20px"
                      },
                      "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus":
                        {
                          WebkitBoxShadow:
                            "0 0 0px 1000px white inset !important",
                          backgroundColor: "transparent !important",
                          color: "#1A1A1C !important",
                        },
                    },
                  },
                }}
               
              />

              {/* Password Field */}

              {/* Submit Button */}
              <Button
                className={styles.button}
                disableElevation
                variant="contained"
                type="submit"
                //   disabled={loading}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "20px",
                  fontFamily: "Roboto",
                  background: "#009872",
                  borderRadius: "4px",
                  "&:hover": { backgroundColor: "#007a5e" }, 
                  width: 440,
                  height: 48,
                }}
              >
                {loading ? "Loading..." : " Request Reset Link"}
              </Button>
              <Box className={styles.loginWrapper}>
                <Box onClick={handleNavigateLogin} className={styles.forgotPassword}>Back to Login</Box>
              </Box>
            </form>
          )}
        </Box>
      </Box>
    </Background>
  );
};

export default ForgotPassword;
