import { combineReducers } from "redux";
import setProjectDetailsReducer from "./Reducer/projectDeatils";
import setProjectDetailsStatusReducer from "./Reducer/projectStatusReducer";
import userReducer from "./Reducer/userReducer";
import sdrUserReducer from "./Reducer/sdrRegistrationReducer";
import sdrDatabaseReducer from "./Reducer/sdrDatabaseDetailsReducer";
import sdrOutReachMediaReducer from "./Reducer/sdrOutReachMediaDetailsReducer";
import getNotificationReducer from "./Reducer/NotificationReducer";

const rootReducer = combineReducers({
  // other reducers
  setProjectDetailsReducer : setProjectDetailsReducer,
  setProjectDetailsStatusReducer : setProjectDetailsStatusReducer,
  user: userReducer,
  sdrData : sdrUserReducer,
  sdrDbData:sdrDatabaseReducer,
  sdrOutreachMediaData:sdrOutReachMediaReducer,
  notificationData :getNotificationReducer
});

export default rootReducer;