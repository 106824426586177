import React  from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  // Dialog,
  // DialogTitle,
  // DialogContent,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
// import mammoth from "mammoth";
// import { Box } from "@mui/system";
import { StyledTableCell, HeaderTableCell } from "./OutReachStyle";

const CollateralsTable = ({ tableData }) => {
  const sasToken = localStorage.getItem("sas_token");
  // const [docxContent, setDocxContent] = useState("");
  // const [openDialog, setOpenDialog] = useState(false);

  
  const getSignedUrl = (url) =>
    url ? (sasToken ? `${url}?${sasToken}` : url) : null;

  const handleView = async (url, fileType) => {
    const signedUrl = getSignedUrl(url);
    if (!signedUrl) {
      console.warn("No document URL available");
      return;
    }

    try {
      const response = await fetch(signedUrl);
      const blob = await response.blob();

      if (fileType === "DOCX") {
        // Convert DOCX to HTML using Mammoth
        const reader = new FileReader();
        // reader.onload = async (event) => {
        //   // const arrayBuffer = event.target.result;
        //   // const { value } = await mammoth.convertToHtml({ arrayBuffer });
        //   // setDocxContent(value);
        //   // setOpenDialog(true);
        // };
        reader.readAsArrayBuffer(blob);
      } else {
        // Open other file types in a new tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank", "noopener,noreferrer");
      }
    } catch (error) {
      console.error("Error opening document:", error);
    }
  };

  const handleDownload = async (url, fileName) => {
    const signedUrl = getSignedUrl(url);
    if (!signedUrl) {
      console.warn("No document URL available");
      return;
    }

    try {
      const response = await fetch(signedUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName || "document";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ border: "1px solid #DDDEE0" }}>
        <TableHead>
          <TableRow>
            <HeaderTableCell>Collateral Name</HeaderTableCell>
            <HeaderTableCell>Type</HeaderTableCell>
            <HeaderTableCell>Uploaded On</HeaderTableCell>
            <HeaderTableCell>Uploaded By</HeaderTableCell>
            <HeaderTableCell sx={{ textAlign: "center" }}>
              Actions
            </HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map(
            ({ document_name, created_at, creator, document_url }, index) => {
              const fileType = document_name?.split(".").pop().toUpperCase();
              const uploadedOn = dayjs(created_at)?.format("MM-DD-YYYY");
              const uploadedBy = `${creator?.first_name || ""} ${creator?.last_name || ""
                }`.trim(); // Handle missing names

              return (
                 <TableRow key={index}>
                  <StyledTableCell>{document_name}</StyledTableCell>
                  <StyledTableCell>{fileType}</StyledTableCell>
                  <StyledTableCell>{uploadedOn}</StyledTableCell>
                  <StyledTableCell>{uploadedBy}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "center" }}>
                    <IconButton onClick={() => handleView(document_url)}>
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        handleDownload(document_url, document_name)
                      }
                    >
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </StyledTableCell>
                  
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollateralsTable;
