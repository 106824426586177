import { Box, Card, styled, Typography } from "@mui/material";

const MainBox = styled(Box)({
  padding: "20px",
  height: "80vh",
  "@media (max-width: 1366px)": {
    height: "73vh", // Applies when screen width is 1366px or below
    padding: "10px",
  },
});

const LeaderTrackerMainBox = styled(Box)(({ flx, bgcolr }) => ({
  flex: flx,
  backgroundColor: bgcolr,
  borderRadius: "12px",
  padding: "24px",
  "@media (max-width: 1366px)": {
    padding: "15px", // Applies when screen width is 1366px or below
  },
}));

const LeadTrackerTitle = styled(Typography)({
  fontSize: "24px",
  fontWeight: 500,
  color: "#0D162F",
  "@media (max-width: 1536px)": {
    fontSize: "16px", // Applies when screen width is 1366px or below
  },
});

const LeadTrackerOutterBox = styled(Box)(({ mgnTop }) => ({
  display: "flex",
  marginTop: mgnTop,
  flexDirection: "column",
  gap: "20px",

  "@media (max-width: 1536px)": {
    gap: "10px", // Applies when screen width is 1366px or below
    marginTop: mgnTop,
  },
}));

const InnerCard = styled(Card)({
  padding: "20px",
  display: "flex",
  gap: "5px",
  justifyContent: "space-between",
  boxShadow: "none",
  border: "1px solid #E0E0E0",
  borderRadius: "12px",
  alignItems: "center",
  cursor: "pointer",
  "@media (max-width: 1536px)": {
    padding: "14px", // Applies when screen width is 1366px or below
  },
});

const InnerCardTitle = styled(Typography)({
  color: "#0D162F",
  fontWeight: 500,
  fontSize: "20px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap", // Ensures text is on a single line
  maxWidth: "350px", // Limits the width for truncation
  "@media (max-width: 1536px)": {
    fontSize: "15px", // Applies when screen width is 1366px or below
    maxWidth: "300px", // Limits the width for truncation
  },
});

const InnerCardContent = styled(Typography)({
  fontSize: "18px",
  color: "#565C6D",
  "@media (max-width:  1536px)": {
    fontSize: "13px", // Applies when screen width is 1366px or below
  },
});

const InnerCardCount = styled(Box)({
  color: "#0D162F",
  fontSize: "40px",
  fontWeight: 500,
  "@media (max-width:  1536px)": {
    fontSize: "30px", // Applies when screen width is 1366px or below
  },
});

const CustomBox = styled(Box)({
  border: "1px solid #D1D1D2",
  padding: "20px",
  borderRadius: "12px",
  "@media (max-width:  1536px)": {
    padding: "14px", // Applies when screen width is 1366px or below
  },
});

const CompanyOutterBox = styled(Box)(({ wdth }) => ({
  width: wdth,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  "@media (max-width:  1536px)": {
    gap: "10px", // Applies when screen width is 1366px or below
  },
}));

const LegendBox = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  fontSize:"16px",
  fontWeight:500,
  fontFamily:"roboto",
  "@media (max-width:  1536px)": {
    fontSize:"14px",
  }
});

const LegendClr = styled(Box)(({ bgclr }) => ({
  height: "10px",
  width: "10px",
  borderRadius: "50%",
  backgroundColor: bgclr,
 
}));

const BuyerIntentBox = styled(Box)({
flex:1,
  backgroundColor: "#F3F1FB",
  borderRadius: "12px",
  padding: "20px",
  "@media (max-width:  1536px)": {
    padding: "10px", // Applies when screen width is 1366px or below
  },
});

const BuyerIntentInnerBox = styled(Box)({
  height: "67%",
  backgroundColor: "white",
  padding: "16px",
  borderRadius: "8px",
  "@media (max-width:  1536px)": {
    padding: "10px", // Applies when screen width is 1366px or below
  },
});

const InSightTitle = styled(Typography)({
  fontSize: "18px", fontWeight: 500 ,
  "@media (max-width:  1536px)": {
    fontSize: "14px", // Applies when screen width is 1366px or below
  },
});

const BadgeBox = styled(Box)(({ data }) => ({
  backgroundColor: "#FFFFFF",
  border: data.status ? "1px solid #5FC968" : "1px solid #FF6464",
  borderRadius: "25px",
  padding: "6px",
  fontSize: "14px",
  color: data.status ? "#3AB044" : "#FF4949",
  fontWeight: 500,
  fontFamily: "roboto",
  display: "flex",
  gap: "5px",
  alignItems: "center",
  
  "@media (max-width:  1536px)": {
  
    padding: "5px",
    fontSize: "11px", // Applies when screen width is 1366px or below
  },

}));

const Insightlist=styled("li")({
  padding: "10px 0 0 0",
  "@media (max-width:  1536px)": {
    padding: "5px 0 0 0",
    fontSize:"12px"
  }
})

export {
  MainBox,
  LeaderTrackerMainBox,
  InnerCard,
  InnerCardTitle,
  InnerCardContent,
  InnerCardCount,
  LeadTrackerTitle,
  LeadTrackerOutterBox,
  CustomBox,
  CompanyOutterBox,
  LegendBox,
  LegendClr,
  BuyerIntentBox,
  BuyerIntentInnerBox,
  BadgeBox,
  InSightTitle,
  Insightlist
};
