const hostName = window.location.hostname;
let endPoint = "https://piqualai-api-dev.ignithocloud.com/api/v1";
let sitekey = "6Lfa89UqAAAAAAkWvRo4VGgrx7np-ez_NeTTWT7h";
if (hostName === "localhost") {
    // endPoint = "http://localhost:5555/api/v1";
    endPoint = "https://piqualai-api-dev.ignithocloud.com/api/v1";
    sitekey ="6Lfi5tQqAAAAAGTcXhw-XWfluf5hYn8BxLzH84r2";
}

export const API = endPoint;
export const siteKey= sitekey;
//export const DOMAIN = https://idadev-api.ignithocloud.com
// export const API = "https://idadev-api.ignithocloud.com/api/v1";
