import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const ErrorPage = () => {
  const navigate = useNavigate();
  const logoutApp = () => {
    const salesLeadViewed = localStorage.getItem("sales-lead-home-page-viewed");
    const sdrHomeViewed = localStorage.getItem("sdr-home-page-viewed");
  
    localStorage.clear(); // Clears all localStorage items
  
    // Restore the two keys
    if (salesLeadViewed === "viewed") {
      localStorage.setItem("sales-lead-home-page-viewed", salesLeadViewed);
    }
    if (sdrHomeViewed === "viewed") {
      localStorage.setItem("sdr-home-page-viewed", sdrHomeViewed);
    }
  
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
        textAlign: 'center',
        p: 4,
      }}
    >
      <Typography variant="h4" sx={{ color: "#0D162F", mb: 2 }}>
        No Active Project
      </Typography>
      <Typography variant="body1" sx={{ color: "#707070", mb: 4 }}>
        You have no active project
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#009872ED" ,textTransform:'capitalize'}}
          onClick={logoutApp}
        >
         Logout
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorPage;
