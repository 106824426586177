import { Box} from "@mui/material";
import React from "react";
import SalesLeadOutreachStatus from "./SalesLeadOutreachStatus";
import { LeaderTrackerMainBox, LeadTrackerTitle } from "./LeaderTrackerStyles";
import SalesLeadCard from "./SalesLeadCard";
import SdrDropDown from "./SdrDropDown";
import CalendarDropDown from "./CalendarDropDown";
import { backArrow } from "../../Constants/Constant";
import { useNavigate } from "react-router-dom";

const SalesLeadTracker = () => {
  const navigate = useNavigate();
  const dBData = [
    {
      title: "Number of Companies added",
      count: 168,
      content: "Compared to last week",
      stats: true,
      path:"/piqual-ai/companies-added",
      graph:true,
    },
    {
      title: "Number of Contacts added",
      count: 248,
      content: "Compared to last week",
      stats: false,
      path:"",
      graph:true,
    },
  ];

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box
      sx={{
        height: "65vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: "5px" ,alignItems:"center"}}>
        
            <img
              className="arrow-back-icon"
              loading="lazy"
              alt="Back"
              onClick={handleBack}
              src={backArrow}
            />
        

          <LeadTrackerTitle>
            Lead Tracker
          </LeadTrackerTitle>
        </Box>

        <Box sx={{ display: "flex", gap: "0px" }}>
          <SdrDropDown />
          <CalendarDropDown />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        
        }}
      >
        <LeaderTrackerMainBox flx={1} bgcolr="#EBF6F0">
          <LeadTrackerTitle>DataBase Status</LeadTrackerTitle>
          <SalesLeadCard data={dBData} />
        </LeaderTrackerMainBox>
        <LeaderTrackerMainBox flx={2} bgcolr="#FFF2EB">
          <LeadTrackerTitle>Outreach Status</LeadTrackerTitle>
          <SalesLeadOutreachStatus />
        </LeaderTrackerMainBox>
      </Box>
    </Box>
  );
};

export default SalesLeadTracker;
