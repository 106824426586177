import { Box, Typography } from "@mui/material";
import React from "react";
import {
  AIBuddy,
  AttachmentIcon,
//   AttachmentIconWhite,
  RecordingIcon,
  SendIcon,
} from "../../../Constants/Constant";

const StartChatScreen = () => {
    // const salesPlanType = localStorage.getItem("sales_plan_type");
  return (
    <Box sx={{ height: "87vh", padding: "20px", display: "flex", gap: "20px" }}>
      <Box
        sx={{
          border: "1px solid #C5E7DC",
          flex: 1,
          bgcolor: "#f5fbf8",
          borderRadius: "12px",
          display:"flex",
          flexDirection:"column",
          justifyContent:"space-between"
        }}
      >
        {/* Piqual Buddy box */}
        <Box
          sx={{
            height: "72px",
            display: "flex",
            borderBottom: "1px solid #C5E7DC",
          }}
        >
          <img className="buddy-1-icon" loading="lazy" alt="" src={AIBuddy} />
          <Typography
            className="project-aws"
            sx={{ fontWeight: "bold", padding: "26.5px 0px 22px 10px" }}
          >
            Piqual AI
          </Typography>
         
        </Box> 
        <Box sx={{display:"flex"}}>
             {/* Attachment Component*/}
          <Box sx={{ display: "flex", position: "relative" }}>
            {/* {showAttachment ? (
          <Box
            sx={{
              position: "absolute",
              bottom: "150%", // Position above the button
              right: "-6.5vw",
              transform: "translateX(-50%)",
              border: "2px solid rgba(0, 0, 0, 0.1)",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <AttachButton
              onClose={() => setShowAttachment(false)}
              getFileUrl={getFileUrl}
            />
          </Box>
        ) : (
          ""
        )}{" "} */}
          </Box>

          {/* Message Box*/}
          <Box
            sx={{
              flexShrink: 0,
              // maxHeight: "25vh",
              overflowY: "auto",
              display: "grid",
              padding: "8px",
              transition: "max-height 0.3s ease-in-out",
              width: "98%",
            }}
          >
            <Box className="message-content">
              <Box
                className="message-actions"
                sx={{
                  display: "flex",
                //   width: "100%",
                  flexGrow: 1,
                  position: "relative",
              
                }}
              >
                {/* Input Field and Suggestions Button */}
                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    overflowY: "hidden",
                 
                    // marginBottom: selectedGeography.length !== 0 ? "-20px " : "-20px",
                  }}
                >
                  <textarea
                    rows={1}
                    placeholder="Type the message"
                    className="message-input"
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      height: "25px",
                      maxHeight: "150px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      resize: "none",
                      display: "flex",
                      alignItems: "center",
                      padding: "0px",
                      lineHeight: "1.5",
                      marginLeft: "6px",
                      fontSize: "16px",
                    }}
                   
                  />
                
                 
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* Attachment and Mic button */}
                  <Box
                    sx={{
                      display: "flex",
                      //   paddingTop: showFirstBox && "10px",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      //   marginLeft: showFirstBox ? "auto" : 0,
                      // marginBottom: "-3px",
                      gap: "2px",
                    }}
                  >
             
                      <Box
                        className="attachmentButton"
                     
                      >
                        <img
                          className="AttachmentIcon"
                          loading="lazy"
                          alt=""
                          src={AttachmentIcon}
                        />
                      </Box>
                  

                    <Box className="attachmentButton">
                      <img
                        className="AttachmentIcon"
                        loading="lazy"
                        alt=""
                        src={RecordingIcon}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="keyboard-dropdown">
                <button
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    cursor: "pointer",
                  }}
                  //   onClick={handleInputMessage}
                >
                  <img loading="lazy" alt="" src={SendIcon} />
                </button>
              </Box>
            </Box>
          </Box>
          </Box>
         
      </Box>
      <Box
        sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <Box
          sx={{
            flex: 1,
            bgcolor: "#EEF4FF",
            border: "1px solid #DDE9FF",
            borderRadius: "12px",
            padding: "24px",
            color: "#707070",
            fontSize: "20px",
            fontWeight: 400,
          }}
        >
          Waiting for input
        </Box>
        <Box
          sx={{
            flex: 1,
            border: "1px solid #DDE9FF",
            borderRadius: "12px",
            padding: "24px",
            color: "#707070",
            fontSize: "20px",
            fontWeight: 400,
          }}
        >
          Waiting for input
        </Box>
      </Box>
    </Box>
  );
};

export default StartChatScreen;
