import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography, Tooltip } from "@mui/material";
import {
  ActionButton,
  HomeBox,
  LabelBox,
  SalesLeadHomeBox,
  Truncatetd,
} from "./SalesLeadHomeStyles";
import { API } from "../../global";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ReviewButton from "../../assets/ReviewButton.svg";
import CheckIconDisabled from "../../assets/CheckIconDisabled.svg";
import CheckIconActive from "../../assets/CheckIconActive.svg";
import ReviewButtonDisabled from "../../assets/ReviewButtonDisabled.svg";
import { useToast } from "../../utils/ToastContext";
import { calculateTimeLeft } from "../../Constants/Funtions/commonFunctions";
import { BeatLoader } from "react-spinners";

const SalesLeadHome = () => {
  const navigate = useNavigate();
  const {showToast} =  useToast()
  const [selectedProspects, setSelectedProspects] = useState([]);
  const [prospects, setProspect] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const projectId = localStorage.getItem("project_id");
  const [autoApprovedData,setAutoApprovedData] = useState([])
  const [loading,setLoading] = useState(false)
  const [approvalLoading,setApprovalLoading] = useState(false)


  const stringAvatar = (firstNameData = "", lastNameData = "") => {
    const firstName = firstNameData ? firstNameData.split("")[0] : "";
    const lastName = lastNameData ? lastNameData.split("")[0] : "";

    return {
      sx: {
        background: "#FF7D34",
        color: "white",
        width: "31px",
        height: "31px",
        fontSize: "15px",
        marginRight: "5px",
      },
      children: `${firstName}${lastName}`,
    };
  };

  useEffect(() => {
    getReviewList();
    getAiReviewList()
    
    // eslint-disable-next-line
  }, []);

  const getReviewList = () => {
    setLoading(true)
    fetch(`${API}/project/get-prospect-review-list`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectId }),
    })
      .then((response) => response.json())
      .then((res) => {
         setLoading(false)

        if (res.success) {
          
          // Sorting function
          res.result.sort((a, b) => {
            return a.prospect_type === "contact" ? -1 : 1;
          });
          setProspect(res.result);
        }
      })
      .catch((error) => {
        setLoading(false)

      });
    // .finally(() => setLoading(false));
  };

  const getAiReviewList = () => {
    setApprovalLoading(true)
    fetch(`${API}/project/get-prospect-review-list`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectId,status:"approved_piqual_ai"}),
    })
      .then((response) => response.json())
      .then((res) => {
         setApprovalLoading(false)

        if (res.success) {
          if(res.result){
            res.result.sort((a, b) => {
              return a.prospect_type === "contact" ? -1 : 1;
            });
            setAutoApprovedData(res.result);
          }
        }
      })
      .catch((error) => {
        setApprovalLoading(false)

      });
    // .finally(() => setLoading(false));
  };


  const updateStatus = (status, prospectData) => {
    if (status === "review") {
      localStorage.setItem("sales_plan_type", prospectData.conversation_type);
      localStorage.setItem("sales_plan_title", 'Review');
      localStorage.setItem("prospect_id", prospectData.prospect_id);
      localStorage.setItem("prospect_type", prospectData.prospect_type);
      navigate("/piqual-ai/sales-plan?type=review");
    } else {
      // eslint-disable-next-line
      selectedProspects.map((data) => {
        if (data.prospect_id === prospectData.prospect_id) {
          data.status = status;
        }
      });
      const obj = {
        project_id: projectId,
        prospect_id: prospectData.prospect_id,
        prospect_type: prospectData.prospect_type,
        status: status,
      };
      updateData(obj);
    }
  };
  const approveFullData = () => {
    // eslint-disable-next-line
    selectedProspects.map((data) => {
      const obj = {
        project_id: projectId,
        prospect_id: data.prospect_id,
        prospect_type: data.prospect_type,
        status: "approved",
      };
      updateData(obj);
    });
  };

  const updateData = (obj) => {
    fetch(`${API}/project/update-prospect-review-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then(() => {
          try{
            if(obj.status === 'approved'){
              showToast("success", "Approved Successfully");
              setSelectedProspects([])
              getReviewList();
            }
          }catch(err){
            console.log("err",err)
          }
        
      })
      .catch((error) => {
        console.log("error",error)
        // setLoading(false);
      });
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    setSelectedProspects(e.target.checked ? [...prospects] : []);
  };

  const handleSelect = (type, prospect) => {
    // console.log("prospect", prospect);
    setSelectedProspects((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (p) => p.prospect_id === prospect.prospect_id
      );
      if (isAlreadySelected) {
        return prevSelected.filter(
          (p) => p.prospect_id !== prospect.prospect_id
        );
      } else {
        return [...prevSelected, prospect];
      }
    });

    if (type === "approved") {
      updateStatus("approved", prospect);
    }
  };
  return (
    <SalesLeadHomeBox>
      <Box
        sx={{
          display: "flex",
          gap: "1.5rem",
          width: "100%",
          justifyContent: "center",
          alignItems: "stretch",
          marginBottom: "1.5rem",
        }}
      >
        <HomeBox
          bColor="#F3F1FB"
          sx={{
            flex: "0 0 55%",
            maxWidth: "70%",
            // height: "fit-content",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <LabelBox>Pending Activities</LabelBox>
            {prospects.length !== 0 &&
              selectAll &&
              selectedProspects.length !== 0 && (
                <ActionButton
                  variant="outlined"
                  bordercolor="#009872"
                  clr=" #009872"
                  onClick={approveFullData}
                >
                  Approve
                </ActionButton>
              )}
          </Box>
          {loading ? (
            <Box
              sx={{
                padding: "16px",
                marginTop: "20px",
                borderRadius: "8px",
                height: "34.3vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "white",
              }}
            >
              <BeatLoader color="#9884D3" />
            </Box>
          ) : (
            <Box
              sx={{
                bgcolor: "white",
                padding: "16px",
                marginTop: "20px",
                borderRadius: "8px",
                height: "34.3vh",
              }}
            >
              {prospects.length !== 0 ? (
                <Box
                  sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "8px",
                    maxHeight: "290px",
                    minHeight: "34.3vh",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar": { width: "3px" },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#bbb",
                      borderRadius: "2px",
                    },
                  }}
                >
                  <table className="home-table">
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      <tr>
                        <th style={{ width: "5%" }}>
                          <input
                            type="checkbox"
                            checked={
                              selectAll ||
                              prospects.length === selectedProspects.length
                            }
                            onChange={handleSelectAll}
                            style={{
                              accentColor: "#009872",
                              cursor: "pointer",
                              " &hover": {
                                outline: "none",
                                background: "none",
                                accentColor: "#009872",
                              },
                            }}
                          />
                        </th>
                        <th
                          style={{
                            width: "40%",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1.125rem",
                          }}
                        >
                          Prospect
                        </th>
                        <th
                          style={{
                            width: "10%",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1.125rem",
                          }}
                        >
                          SDR
                        </th>
                        <th
                          style={{
                            width: "5%",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1.125rem",
                          }}
                        >
                          Score
                        </th>
                        <th
                          style={{
                            width: "15%",
                            textAlign: "center",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1.125rem",
                          }}
                        >
                          Action
                        </th>
                        <th
                          style={{
                            width: "15%",
                            textAlign: "center",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1.125rem",
                          }}
                        >
                          Auto Approval
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {prospects.map((prospectData) => {
                        // Determine Score Color
                        let scoreColor = "#3AB044";
                        let bgColor = "#56BB6B1F";

                        if (prospectData.score < 500) {
                          scoreColor = "#FF4949";
                          bgColor = "#FF49491F";
                        } else if (
                          prospectData.score >= 500 &&
                          prospectData.score < 750
                        ) {
                          scoreColor = "#F4A100";
                          bgColor = "#F4A1001F";
                        }

                        return (
                          <tr key={prospectData.prospect_id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedProspects.some(
                                  (p) =>
                                    p?.prospect_id === prospectData?.prospect_id
                                )}
                                onChange={() =>
                                  handleSelect("select", prospectData)
                                }
                                style={{
                                  accentColor: "#009872",
                                  cursor: "pointer",
                                  " &hover": {
                                    outline: "none",
                                    background: "none",
                                    accentColor: "#009872",
                                  },
                                }}
                              />
                            </td>

                            {/* Prospect Name */}
                            <Tooltip
                              title={`${prospectData.prospect} ${
                                prospectData.prospect_type === "contact"
                                  ? `(${
                                      prospectData.title
                                        ? prospectData.title
                                        : ""
                                    } - Company : ${
                                      prospectData?.company_details
                                        ?.company_name
                                    })`
                                  : ""
                              }`}
                              arrow
                            >
                              <Truncatetd>
                                {prospectData.prospect}{" "}
                                {prospectData.prospect_type === "contact" &&
                                  `( ${
                                    prospectData.title ? prospectData.title : ""
                                  } - Company : ${
                                    prospectData?.company_details?.company_name
                                  })`}
                              </Truncatetd>
                            </Tooltip>

                            {/* Hardcoded Avatar */}

                            <td>
                              <Box display="flex" alignItems="center" gap="8px">
                                <Tooltip
                                  title={`${
                                    prospectData?.sdr_details?.first_name || ""
                                  } ${
                                    prospectData?.sdr_details?.last_name || ""
                                  }`}
                                >
                                  <Avatar
                                    {...stringAvatar(
                                      prospectData?.sdr_details?.first_name,
                                      prospectData?.sdr_details?.last_name
                                    )}
                                    sx={{
                                      bgcolor: "#fff",
                                      color: "#6A6A6B",
                                      width: "2rem",
                                      height: "2rem",
                                      fontWeight: "400",
                                      border: "1px solid #6A6A6B",
                                      fontSize: "0.875rem",
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </td>

                          {/* Score */}
                          <td className="score-cell">
                            <Box
                              sx={{
                                backgroundColor: bgColor,
                                color: scoreColor,
                                padding: "5px 15px",
                                borderRadius: "50%",
                                fontFamily: "Roboto",
                                fontWeight: 700,
                                fontSize: "1.125rem",
                              }}
                            >
                              {prospectData.score  === undefined || prospectData.score === null ? 0 : prospectData.score}
                            </Box>
                          </td>

                            {/* Action Button */}
                            <td>
                              <div className="button-group">
                                {/* Approve Icon with Tooltip */}
                                <Tooltip title="Approve">
                                  <IconButton
                                    disabled={selectAll}
                                    onClick={() =>
                                      updateStatus("approved", prospectData)
                                    }
                                  >
                                    {selectAll ? (
                                      <img
                                        src={CheckIconDisabled}
                                        alt="Check icon"
                                        style={{ width: 26 }}
                                      />
                                    ) : (
                                      <img
                                        src={CheckIconActive}
                                        alt="Check icon"
                                        style={{ width: 26 }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>

                                {/* Review Icon with Tooltip */}
                                <Tooltip title="Review">
                                  <IconButton
                                    disabled={selectAll}
                                    onClick={() =>
                                      updateStatus("review", prospectData)
                                    }
                                  >
                                    {selectAll ? (
                                      <img
                                        src={ReviewButtonDisabled}
                                        alt="Check icon"
                                        style={{ width: 26 }}
                                      />
                                    ) : (
                                      <img
                                        src={ReviewButton}
                                        alt="review icon"
                                        style={{ width: 26 }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </td>

                            {/* Auto Approval - Hardcoded "60 min left" */}
                            <td
                              style={{
                                fontWeight: 400,
                                textAlign: "center",
                                fontFamily: "Roboto",
                                fontSize: "1rem",
                                color: "#6A6A6B",
                              }}
                            >
                              {calculateTimeLeft(prospectData)} min left
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems:"center",
                    height: "290px",
                  }}
                >
                  <Typography>There are no pending activities at the moment.</Typography>
                </Box>
              )}
            </Box>
          )}
        </HomeBox>

        <HomeBox
          bColor="#EBF6F0"
          sx={{
            flex: "0 0 40%",
            maxWidth: "40%",
          }}
        >
          <LabelBox>Activities Auto Approved by Piqual AI</LabelBox>
          {approvalLoading ? (
            <Box
              sx={{
                padding: "16px",
                marginTop: "20px",
                borderRadius: "8px",
                height: "34.3vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "white",
              }}
            >
              <BeatLoader color="#8FA699"  />
            </Box>
          ) : (
            <Box
              sx={{
                bgcolor: "white",
                padding: "16px",
                marginTop: "20px",
                borderRadius: "8px",
              }}
            >
              {autoApprovedData.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems:"center",
                    height: "34.3vh",
                  }}
                >
                  <Typography>There are no auto-approved activities by Piqual AI at the moment.</Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "8px",
                    maxHeight: "290px",
                    minHeight: "34.3vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarWidth: "thin", // Ensures a thin scrollbar in Firefox
                    "&::-webkit-scrollbar": {
                      width: "3px", // Thin scrollbar for Chrome/Safari
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#bbb", // Scrollbar color
                      borderRadius: "2px", // Rounded edges
                    },
                  }}
                >
                  <table className="home-table">
                    <thead>
                      <tr
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "white",
                          zIndex: 1000,
                        }}
                      >
                        <th
                          style={{
                            width: "40%",
                            backgroundColor: "#EBF6F0",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1.125rem",
                          }}
                        >
                          Prospect
                        </th>
                        <th
                          style={{
                            width: "20%",
                            backgroundColor: "#EBF6F0",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1.125rem",
                          }}
                        >
                          SDR
                        </th>
                        <th
                          style={{
                            width: "15%",
                            backgroundColor: "#EBF6F0",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1.125rem",
                          }}
                        >
                          Score
                        </th>
                        <th
                          style={{
                            width: "10%",
                            backgroundColor: "#EBF6F0",
                            textAlign: "center",
                            fontFamily: "Roboto",
                            fontWeight: 500,
                            fontSize: "1.125rem",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="home-table-body">
                      {autoApprovedData.map((prospectData) => {
                        // Determine Score Color
                        let scoreColor = "#3AB044"; // Default Green
                        let bgColor = "#56BB6B1F"; // Light Green

                      if (prospectData.score < 500) {
                        scoreColor = "#FF4949"; // Red
                        bgColor = "#FF49491F"; // Light Red
                      } else if (
                        prospectData.score >= 500 &&
                        prospectData.score <= 750
                      ) {
                        scoreColor = "#F4A100"; // Yellow
                        bgColor = "#F4A1001F"; // Light Yellow
                      }

                        return (
                          <tr key={prospectData.prospect_id}>
                            {/* Prospect Name */}
                            <Tooltip
                              title={`${prospectData.prospect} ${
                                prospectData.prospect_type === "contact"
                                  ? `(${
                                      prospectData.title
                                        ? prospectData.title
                                        : ""
                                    } - Company : ${
                                      prospectData?.company_details
                                        ?.company_name
                                    })`
                                  : ""
                              }`}
                              arrow
                            >
                              <Truncatetd>
                                {prospectData.prospect}{" "}
                                {prospectData.prospect_type === "contact" &&
                                  `( ${
                                    prospectData.title ? prospectData.title : ""
                                  } - Company : ${
                                    prospectData?.company_details?.company_name
                                  })`}
                              </Truncatetd>
                            </Tooltip>

                            {/* SDR - Hardcoded Avatar */}

                            <td>
                              <Tooltip
                                title={`${
                                  prospectData?.sdr_details?.first_name || ""
                                } ${
                                  prospectData?.sdr_details?.last_name || ""
                                }`}
                              >
                                <Avatar
                                  {...stringAvatar(
                                    prospectData?.sdr_details?.first_name,
                                    prospectData?.sdr_details?.last_name
                                  )}
                                  sx={{
                                    bgcolor: "#fff",
                                    color: "#6A6A6B",
                                    width: "2rem",
                                    height: "2rem",
                                    fontWeight: "400",
                                    border: "1px solid #6A6A6B",
                                    fontSize: "0.875rem",
                                  }}
                                />
                              </Tooltip>
                            </td>

                            {/* Score */}
                            <td className="score-cell">
                              <Box
                                sx={{
                                  backgroundColor: bgColor,
                                  color: scoreColor,
                                  padding: "5px 15px",
                                  borderRadius: "50%",
                                  fontFamily: "Roboto",
                                  fontWeight: 700,
                                  fontSize: "1.125rem",
                                }}
                              >
                                {prospectData.score === undefined ||
                                prospectData.score === null
                                  ? 0
                                  : prospectData.score}
                              </Box>
                            </td>

                            {/* Action Buttons - Reduced Padding */}
                            <td>
                              <div className="button-group">
                                {/* Approve Icon with Tooltip */}
                                {/* <Tooltip title="Approve">
                                <IconButton
                                  disableFocusRipple
                                  disableTouchRipple
                                  disableRipple
                                  disabled={selectAll}
                                  // onClick={() => handleSelect("approved", prospectData)}
                                >
                                  <CheckCircleOutline
                                    sx={{ color: "#009872" }}
                                  />
                                </IconButton>
                              </Tooltip> */}

                                {/* Review Icon with Tooltip */}
                                <Tooltip title="Review">
                                  <IconButton
                                    disableFocusRipple
                                    disableTouchRipple
                                    disableRipple
                                    disabled={selectAll}
                                    onClick={() =>
                                      updateStatus("review", prospectData)
                                    }
                                    // onClick={() => updateStatus("review", prospectData)}
                                  >
                                    {/* <RateReviewIcon sx={{ color: "#FF7D34" }} /> */}
                                    <img
                                      src={ReviewButton}
                                      alt="review icon"
                                      style={{ width: 26 }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </td>

                            {/* Auto Approval - Hardcoded "60 min left" */}
                            {/* <td style={{ fontWeight: 600 }}>60 min left</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          )}
        </HomeBox>
      </Box>

      {/* <ActionButton
        sx={{
          textAlign: "center",
          bgcolor: "#009872",
          width: "fit-content",
          px: "2rem",
          py: "0.5rem",
        }}
        disabled
        variant="contained"
        bordercolor="#009872"
        clr="white"
      >
        Lead Tracker
      </ActionButton> */}
    </SalesLeadHomeBox>
  );
};

export default SalesLeadHome;
