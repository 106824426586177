import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { API } from "../../../global";

const OutreachTable = () => {
  
  const isExpanded = localStorage.getItem("accordionState") === "expanded";
  const [taskData,setTaskData] = useState([])

  useEffect(()=>{
    getCompletedTask()
    // eslint-disable-next-line
  },[])

 const getCompletedTask = () => {
   fetch(`${API}/project/tasks`, {
     method: "POST",
     headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
       "Content-Type": "application/json",
     },
     body: JSON.stringify({
       task_status: "completed",
     }),
   })
     .then((response) => response.json())
     .then((res) => {
       if (res.success) {
        let taskList=[];
        // eslint-disable-next-line
        res.result.map((data)=>{
          let statusData = 'Pending'
          if(data.task_status === 'completed'){
            if(data.task_type === "linkedin" || data.task_type === "email"){
              statusData = "Sent"
            }else{
              statusData = "Completed"

            }
          }
         
          const obj={
            contact :  `${data?.contact_details?.first_name} ${data?.contact_details?.last_name} (${data?.company?.company_name})`,
            status :statusData,
            action :   data?.task_type?.charAt(0).toUpperCase() + data?.task_type?.slice(1).toLowerCase(),
          }
          taskList.push(obj)

        })
        setTaskData(taskList)
       }
     })
     .catch(() => {
       // setLoading(false);
     });
 };

  return (
    <Box
      sx={{
        // width: "94%",
        height: isExpanded ? "43.1vh" : "72.5vh",
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem 1.25rem 0",
      }}
    >
      <Typography fontFamily="Roboto" color="#707070">
        Activities completed
      </Typography>
      <TableContainer
        sx={{
          maxHeight: "100%",
          overflowY: "auto",
          marginTop: "0.5rem",
          border: "1px solid #D1D1D2",
          borderRadius: "5px",
          fontFamily: "Roboto",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "3px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#bbb",
            borderRadius: "2px",
          },
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              // sx={{
              //   position: "sticky",
              //   top: 0,
              //   zIndex: 1,
              // }}
            >
              <TableCell
                sx={{
                  width: "40%",
                  fontWeight: "500",
                  color: "#FF7D34",
                  background: "#FFF5ED",
                  fontSize: "1.125rem",
                }}
              >
                Contact
              </TableCell>
              <TableCell
                sx={{
                  width: "35%",
                  fontWeight: "500",
                  color: "#FF7D34",
                  background: "#FFF5ED",
                  fontSize: "1.125rem",
                  textAlign: "center",
                  borderRight: "1px solid #D1D1D2",
                  borderLeft: "1px solid #D1D1D2",
                }}
              >
                Action
              </TableCell>
              <TableCell
                sx={{
                  width: "25%",
                  fontWeight: "500",
                  color: "#FF7D34",
                  background: "#FFF5ED",
                  fontSize: "1.125rem",
                  textAlign: "center",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskData.length !== 0 ? (
              taskData.map((row, index) => (
                <TableRow key={index} sx={{ height: "1rem" }}>
                  <TableCell
                    sx={{
                      width: "40%",
                      fontSize: "1rem",
                      color: "#212529",
                      padding: "0.5rem",
                      borderRight: "1px solid #D1D1D2",
                    }}
                  >
                    {row.contact}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "25%",
                      fontSize: "1rem",
                      color: "#212529",
                      textAlign: "center",
                      padding: "0.5rem",
                      borderRight: "1px solid #D1D1D2",
                    }}
                  >
                    {row.action}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "25%",
                      fontSize: "1rem",
                      color:
                        row.status === "sent" || "completed"
                          ? "#009872"
                          : "#E63946",
                      textAlign: "center",
                      padding: "0.5rem",
                    }}
                  >
                    {row.status}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography>There are no pending activities at the moment.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OutreachTable;
