import React from "react";

import { Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledTypography,
  StyledAccordionDetails,
} from "../ViewPlan/ViewMediaPlanCss";
import { StyledAccordionTitle } from "./OutReachStyle";
import CollateralsTable from "./CollateralsTable";

const AlignAndAmplify = ({ data, tableData }) => {
  console.log(tableData, "tableData");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <StyledAccordion defaultExpanded sx={{ boxShadow: "none" }}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <StyledAccordionTitle>Value Proposition</StyledAccordionTitle>
        </StyledAccordionSummary>
        <hr
          style={{
            width: "97.2%",
            margin: "0 auto",
            border: "1px solid #E8E8E8",
          }}
        />
        <StyledAccordionDetails>
          <StyledTypography>{data}</StyledTypography>
        </StyledAccordionDetails>
      </StyledAccordion>
      {tableData?.length === 0 ? (
        ""
      ) : (
        <StyledAccordion defaultExpanded sx={{ boxShadow: "none" }}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <StyledAccordionTitle fontWeight="bold">
              Collaterals
            </StyledAccordionTitle>
          </StyledAccordionSummary>
          <hr
            style={{
              width: "97.2%",
              margin: "0 auto",
              border: "1px solid #E8E8E8",
            }}
          />
          <StyledAccordionDetails>
            <CollateralsTable tableData={tableData} />
          </StyledAccordionDetails>
        </StyledAccordion>
      )}
    </Box>
  );
};

export default AlignAndAmplify;
