import React, { useState } from "react";

import { IconButton, Box } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  ActiveBg,
  ArrowButton,
  IconWrapper,
  LogoIcon,
  LogoWrapper,
  SidebarContainer
} from "./ThemePageCss";
import {
  queries,
  piqualAI1,
  SalesPlanIcon,
  mode2,
  SettingNew,
  LogoutIcon,
  HomeIcon,
  ChatIcon,
  notificationIconSideBar
} from "../../../Constants/Constant";

const icons = [
  { id: "home", src: HomeIcon },
  { id: "sales", src: SalesPlanIcon },
  { id: "chat", src: ChatIcon },
  { id: "notifications", src: notificationIconSideBar },
  { id: "mode", src: mode2 },
  { id: "queries", src: queries },
  { id: "settings", src: SettingNew },
  { id: "logout", src: LogoutIcon },
];

const Sidebar = ({ colors }) => {
  const [activeIcon] = useState("home");

  return (
    <SidebarContainer primaryColor={colors.primary}>
      <Box>
        <LogoWrapper>
          <LogoIcon src={piqualAI1} alt="" />
        </LogoWrapper>
        {icons.slice(0, 4).map((icon) => (
          <IconWrapper key={icon.id}>
            {activeIcon === icon.id && <ActiveBg tertiaryColor={colors.tertiary} />}
            <IconButton>
              <img src={icon.src} alt="" />
            </IconButton>
          </IconWrapper>
        ))}
      </Box>
      <ArrowButton primaryColor={colors.primary}>
        <KeyboardArrowRightIcon sx={{ width: "20px", height: "20px", color: colors.primary }} />
      </ArrowButton>
      <Box>
        {icons.slice(4).map((icon) => (
          <IconWrapper key={icon.id}>
            {activeIcon === icon.id && <ActiveBg tertiaryColor={colors.tertiaryColor} />}
            <IconButton>
              <img src={icon.src} alt="" />
            </IconButton>
          </IconWrapper>
        ))}
      </Box>
    </SidebarContainer>
  );
};

export default Sidebar;