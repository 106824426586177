import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { TabContext } from "@mui/lab";
import {
  CustomTab,
  CustomTablist,
  CustomTabPanel,
} from "../ChatInterfaceStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { shallowEqual, useSelector } from "react-redux";
import RadialBarChartComponent from "../Common/RadialBarChart";
import OutreachTable from "../Common/OutreachTable";
import { formatRevenue } from "../../../Constants/Funtions/commonFunctions";
const SDRsalesPlanLayout = () => {
  const [projectDataDetails, setProjectDataDetails] = useState([]);
  const [sdrDataBaseDetails, setSdrDataBaseDetails] = useState([]);

  const tabTitle = localStorage.getItem("sales_plan_title");
  const [value, setValue] = useState("1");

  const projectDetails = useSelector(
    (state) => state.setProjectDetailsReducer,
    shallowEqual
  );

  const sdrDBDetails = useSelector((state) => state?.sdrDbData?.sdrDatabaseData);
  const outreachType = localStorage.getItem("sales_plan_chat_type");


  useEffect(() => {
    let planType = "";

    if (outreachType === "db-companies" || outreachType === 'db-review-view') {
      planType = "market_plan";
    } else if (outreachType === "outreach") {
      planType = "media_plan";
    }

    const updatedProjectDetails = structuredClone(projectDetails);

    const projectData = updatedProjectDetails.projectDetailsData;
    const selectedProject = projectData?.find((item) => item.key === planType);
    if (selectedProject) {
      if (outreachType === "outreach") {
        selectedProject.label = "Outreach Execution";
      } else if (outreachType === "db-companies" || outreachType === 'db-review-view') {
        selectedProject.label = "Market Criteria"; 

        const {
          target_geographies = [],
          target_industries = [],
          target_revenue_range = [],
          target_contact_criteria = [],
        } = selectedProject.content;
        // Check if all arrays are empty
        const isEmpty =
          target_geographies.length === 0 &&
          target_industries.length === 0 &&
          target_revenue_range.length === 0 &&
          target_contact_criteria.length === 0;

        if (isEmpty) {
          selectedProject.content = "";
        } else {
          let lastCountry = "";
          let sections = [];
          

          if (target_geographies?.length) {
            sections?.push(`
              <p style="color: #FF7D34; font-weight: 700; font-size: 20px;">Geographical Locations</p>
              ${target_geographies
                ?.map((geo, index) => {
                  let countrySection = "";
                  if (geo?.country !== lastCountry) {
                    countrySection = `<p><strong>${geo?.country ? geo?.country : ""}</strong></p>`;
                    lastCountry = geo?.country;
                  }
                  return `
                    ${countrySection}
                    <p style="font-weight: 500">${geo?.region ? geo?.region : ""}</p>
                    <p style="color: #707070;">${geo?.states ? geo?.states?.join(", ") : ""}</p>
                  `;
                })
                ?.join("")}
            `);
          }

          if (target_industries?.length) {
            sections.push(`
              <p style="color: #FF7D34; font-weight: 700; font-size: 20px;">Industry Group</p>
              ${target_industries
                ?.map(
                  (industry) => `
                  <p><strong>${industry?.target_industry ? industry?.target_industry : ""}</strong></p>
                  <p style="color: #707070;">${industry?.specific_target_industry ? industry?.specific_target_industry?.join(", ") : "" || ""}</p>
                `
                )
                ?.join("")}
            `);
          }

          if (target_revenue_range?.length) {
            sections.push(`
              <p style="color: #FF7D34; font-weight: 700; font-size: 20px;">Company Criteria</p>
              ${target_revenue_range
                ?.map((revenue) => {
                  if (revenue?.revenue_min && revenue?.revenue_max) {
                    return `
                      <p style="color: #707070;">
                        $${formatRevenue(revenue.revenue_min)} to $${formatRevenue(revenue.revenue_max)}
                      </p>
                    `;
                  } else if (!revenue?.revenue_min && revenue?.revenue_max) {
                    return `
                      <p style="color: #707070;">
                        Below $ ${formatRevenue(revenue.revenue_max)}
                      </p>
                    `;
                  } else if (revenue?.revenue_min && !revenue?.revenue_max) {
                    return `
                      <p style="color: #707070;">
                        Above $ ${formatRevenue(revenue.revenue_min)}
                      </p>
                    `;
                  } else {
                    return `
                      <p style="color: #707070;">
                        No revenue data available
                      </p>
                    `;
                  }
                })
                ?.join("")}
            `);
          }

          if (target_contact_criteria?.length) {
            sections.push(`
              <p style="color: #FF7D34; font-weight: 700; font-size: 20px;">Contact Criteria</p>
              <p style="color: #707070;">${target_contact_criteria ? target_contact_criteria?.join(", ") :""}</p>
            `);
          }

          // Join sections with hr only between them
          selectedProject.content = sections?.join('<hr style="border: 1px solid #E8E8E8;">');
        }
      }

      setProjectDataDetails([selectedProject]);
    }

    // eslint-disable-next-line
  }, [projectDetails]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Load tabs from localStorage when the component mounts
  useEffect(() => {
    const storedTabs = JSON.parse(localStorage.getItem("tabs"));
    if (storedTabs) {
      setProjectDataDetails(storedTabs);
    }

    const outreachType = localStorage.getItem("sales_plan_chat_type");
    if (outreachType === "db-companies" || outreachType === 'db-review-view') {
      setValue("2");
    }

    if (outreachType === "outreach") {
      setValue("3");
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const generateTouchpointsData = (data) => {
      if (!data || typeof data !== "object") return []; // Ensure data is a valid object
      const outreachType =  localStorage.getItem('sales_plan_chat_type')

      if(outreachType === 'db-companies'){
        return Object.entries(data)?.map(([key, value]) => ({

       
          title:
            key?.charAt(0)?.toUpperCase() +
            key?.slice(1)?.replace("_", " ") +
            " added to the database",
          daily: {
            value: value?.daily_achieved || 0,
            max: value?.daily_target || 0,
            fill: "#FF7D34",
            bg: "#FFF5ED",
          },
          weekly: {
            value: value?.weekly_achieved || 0,
            max: value?.weekly_target || 0,
            fill: "#009872",
            bg: "#EBFEF6",
          },
        }));
      }else{
        return Object.entries(data).map(([key, value]) => {
          const formattedKey = key.charAt(0).toUpperCase() + key.slice(1).replaceAll("_", " ");
          const title = `${formattedKey} ${key === "meetings" ? "booked" : "Touchpoints"}`;
        
          return {
            title,
            daily: {
              value: value.daily_achieved || 0,
              max: value.daily_target || 0,
              fill: "#FF7D34",
              bg: "#FFF5ED",
            },
            weekly: {
              value: value.weekly_achieved || 0,
              max: value.weekly_target || 0,
              fill: "#009872",
              bg: "#EBFEF6",
            },
          };
        });
        
      }
     
    };
  
    const touchpointsData = generateTouchpointsData(sdrDBDetails);
    setSdrDataBaseDetails(touchpointsData);
  }, [sdrDBDetails]);
  

const calculateHeight = () => {
  const isExpanded = localStorage.getItem("accordionState") === "expanded";
  const width = window.innerWidth;
  const height = Math.round(window.innerHeight);

  if (width >= 1920) {
    return isExpanded ? `calc(80.5vh - 250px)` : `calc(93.5vh - 180px)`;
  } else if (width >= 1680 && width < 1920) {
    return isExpanded ? `calc(80vh - 246px)` : `calc(93vh - 180px)`;
  } else if (width === 1600 && height >= 630) {
    return isExpanded ? `calc(72vh - 220px)` : `calc(93vh - 170px)`;
  } else if (width === 1440) {
    return isExpanded ? `calc(73.75vh - 220px)` : `calc(93vh - 170px)`;
  } else if (width === 1280 && height >= 850) {
    return isExpanded ? `calc(50vh - 19px)` : `calc(92vh - 160px)`;
  } else if (width === 1280 && height >= 790) {
    return isExpanded ? `calc(47vh - 21px)` : `calc(92vh - 160px)`;
  } else if (width === 1280 && height >= 630) {
    return isExpanded ? `calc(35vh - 10px)` : `calc(90vh - 173px)`;
  } else if (width === 1280 && height >= 599) {
    return isExpanded ? `calc(31vh - 5px)` : `calc(90vh - 173px)`;
  } else if (width >= 1400) {
    return isExpanded ? `calc(75vh - 205px)` : `calc(91vh - 160px)`;
  } else if (width >= 1360) {
    return isExpanded ? `calc(34vh)` : `calc(90.5vh - 160px)`;
  } else {
    return isExpanded ? `calc(48vh - 1px)` : `calc(92vh - 160px)`; // Default for smaller screens
  }
};


  const [contentHeight, setContentHeight] = useState(calculateHeight());

  // Function to update height dynamically when accordion state changes
  const updateContentHeight = () => {
    setContentHeight(calculateHeight());
  };

  // Run effect whenever accordion expands/collapses
  useEffect(() => {
    updateContentHeight(); // Update height on mount

    const interval = setInterval(() => {
      updateContentHeight(); // Continuously check for changes
    }, 500); // Runs every 500ms to detect changes

    return () => clearInterval(interval); // Cleanup

    //eslint-disable-next-line
  }, []);



  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        gap: "0px",
        // marginTop:'10px'
      }}
    >
      <Box
        sx={{
          marginBottom: "24px", // Gap between w and sd
        }}
      >
        <RadialBarChartComponent touchpointsData={sdrDataBaseDetails} />
      </Box>
      <Box
        sx={{
          height: "100%", // Subtract gap size
        }}
      >
        {/* Flexbox Layout for Inner Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            height: "100%",
          }}
        >
          {/* <SalePlan clientDetails={null} /> */}
          {/* <SalesPlanBox /> */}
          <TabContext value={value}>
            <CustomTablist
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              {projectDataDetails?.map((tab) => {
                const updatedStatus =
                  tab?.status === "completed"
                    ? "completed"
                    : tab?.label === tabTitle
                      ? "pending"
                      : "";

                return (
                  <CustomTab
                    // key={tab.value}
                    value={tab?.value}
                    status={updatedStatus}
                    disabled={tab?.disabled}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {updatedStatus === "completed" && (
                          <CheckCircleIcon
                            sx={{
                              color: value === tab?.value ? "white" : "#009872",
                              fontSize: 16,
                            }}
                          />
                        )}
                        <span>{tab?.label}</span>
                      </Box>
                    }
                  />
                );
              })}
            </CustomTablist>

            <Box
              sx={{
                borderStyle: "solid",
                borderWidth: "1px 1px 1px 1px",
                borderRadius: "0px 0px 10px 10px",
                borderColor: "#DDE9FF",
                padding: value === "3" ? "10px 0" : "10px 20px",
                flexGrow: 1,
                maxHeight: contentHeight, // Adjusts dynamically based on screen height
                overflowY: "auto", // Enables vertical scrolling
                scrollbarWidth: "thin", // Ensures a thin scrollbar in Firefox
                "&::-webkit-scrollbar": {
                  width: "6px", // Thin scrollbar for Chrome/Safari
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#bbb", // Scrollbar color
                  borderRadius: "3px", // Rounded edges
                },
              }}
            >
              {projectDataDetails?.map((tab) => (
                <CustomTabPanel value={tab?.value}>
                  {!tab?.content ||
                    (typeof tab?.content === "object" &&
                      Object.keys(tab?.content)?.length === 0) ? (
                    <Box
                      sx={{
                        color: "#707070",
                        fontSize: "14px",
                        fontWeight: 400,
                        paddingTop: "10px",
                        paddingLeft: value === "3" && "20px",
                      }}
                    >
                      {" "}
                      {`Waiting for the ${tab?.label.toLowerCase()}`}
                    </Box>
                  ) : (
                    <Box>
                      {typeof tab?.content === "object" ? (
                        outreachType === "outreach" ? (
                          <OutreachTable
                            type={"sdr"}
                            communication_sequence={
                              tab?.content?.communication_sequence
                            }
                          />
                        ) : (
                          <>{`Waiting for the ${tab?.label.toLowerCase()}`}</>
                        )
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{ __html: tab?.content }}
                        />
                        // <ReactMarkdown>{tab?.content}</ReactMarkdown>
                      )}
                    </Box>
                  )}
                </CustomTabPanel>
              ))}
            </Box>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
};

export default SDRsalesPlanLayout;
