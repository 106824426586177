import { Box, styled, Typography } from "@mui/material";
import { Grid } from "@mui/system";

const ViewPlanBox = styled(Box)(({ theme, allCompleted, isSideBarOpened }) => ({
  width: "100%",
  height: "calc(100vh - 63px)",
  padding: "20px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "20px",

  [theme.breakpoints.up("xl")]: {
    height: "calc(100vh - 72px)",
  },
}));

const PlansGrid = styled(Grid)(({ theme, allCompleted, isSideBarOpened }) => ({
  flexShrink: 0,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  gap: "24px",

  [theme.breakpoints.down("xl")]: {
    gap: "16px"
  },

  [theme.breakpoints.down(1400)]: {
    justifyContent: "flex-start",
  },
}));

const PlanContainerBox = styled(Box)(({ isActive }) => ({
  border: "2px solid #FF7D34",
  padding: "20px",
  width: "100%",
  flexGrow: 1,
  borderRadius: "4px",
  boxShadow: isActive ? "0px 0px 0.25rem 0px #FF7D34" : "none",
  boxSizing: "border-box",
  overflow: "auto",

  /* Custom Scrollbar */
  "&::-webkit-scrollbar": {
    width: "6px", // Scrollbar width
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1", // Track background
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#BDBDBD", // Scrollbar color
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#BDBDBD", // Scrollbar color on hover
  },
}));

const ViewInnerBox = styled(Box)(({ isActive }) => ({
  border: isActive ? "2px solid #ff7d34" : "2px solid #E8E8E8",
  boxShadow: isActive ? "0px 0px 0.25rem 0px #FF7D34" : "none",
  textAlign: "left",
  backgroundColor: "#fff",
  transition: "all 0.3s ease-in-out",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: "4px",
  height: "100%"
}));

const ViewTitle = styled(Typography)(({ theme, isActive }) => ({
  fontWeight: 600,
  fontFamily: "Roboto",
  fontSize: "24px",
  color: isActive ?"#1A1A1C":"#6A6A6B",

  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
}));

const Divider = styled("hr")(({ theme }) => ({
  border: "1px solid #E8E8E8",
  margin: "0px"
}));

const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  padding: "16px 16px 12px",
 
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
    padding: "10px 10px 8px"
  },
}));

const ViewImageBox = styled(Box)(
  ({ theme, allCompleted, isActive, planStatus }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: isActive ? "#FFF5ED" : "#F9F9F9",
    padding: "3.5%",
    borderRadius: "50%",
    width: "64px",
    height: "64px",
    aspectRatio: "1 / 1",

    [theme.breakpoints.down("xl")]: {
      width: "35px",
      height: "35px",
    },
  })
);

const ViewScoreDescription = styled(Typography)(({ theme, isActive }) => ({
  fontSize: "16px",
  color: "#525252",

  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
}));

const ScoreBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",
  padding: "20px",
  [theme.breakpoints.down("xl")]: {
    padding: "10px"
  },
}));

const ScoreTitle = styled(Typography)(({ theme }) => ({
  display: "flex",
  fontWeight: 600,
  fontFamily: "Roboto",
  fontSize: "20px",
  color: "#1A1A1C",

  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
}));

const ViewDescription = styled(Typography)(({ theme, isActive }) => ({
  fontSize: "14px",
  color: "#525252",
  padding: "10px 20px",

  [theme.breakpoints.up("xl")]: {
    fontSize: "14px",
  },
}));

const MessagePlanContent = styled(Typography)(({ theme, isActive }) => ({
  fontSize: "14px",
  color: "#3C3C3E",
  fontFamily: "Roboto",
  fontWeight: "500",

  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

export {
  ViewPlanBox,
  ViewInnerBox,
  PlansGrid,
  ViewTitle,
  Divider,
  TitleBox,
  ViewImageBox,
  ViewScoreDescription,
  ScoreBox,
  ScoreTitle,
  PlanContainerBox,
  ViewDescription,
  MessagePlanContent
}