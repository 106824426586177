import { styled } from "@mui/material/styles";
import { Box, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";

const ContentBox = styled(Box)({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

const StyledAccordion = styled(Accordion)({
  border: "1px solid #E8E8E8",
  backgroundColor: "#FBFBFB",
  boxShadow: "none",
  borderRadius: "6px",
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: "0 !important",
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: "16px 16px 12px",
  margin: "0 !important",
  minHeight: "60px !important",
  "&.Mui-expanded": {
    borderBottom: "none",
    minHeight: "60px !important",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0 !important", // Removes the margin inside the span
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#1A1A1C",
  fontSize:"20px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
}));

const StyledAccordionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  color: "#0D162F",
  fontSize: "16px",

  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  }
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: "12px 16px 16px",
});

export {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionTitle,
  StyledTypography,
  ContentBox,
  StyledAccordionDetails
}