

  export function formatRevenue(num) {
    if (num >= 1_000_000_000) {
      return Math.floor(num / 1_000_000_000) + " Billion"; // Billion
    } else if (num >= 1_000_000) {
      return Math.floor(num / 1_000_000) + " Million"; // Million
    } else if (num >= 1_000) {
      return Math.floor(num / 1_000) + " K"; // Thousand
    } else {
      return num.toString();
    }
  }


 export  const calculateTimeLeft = (data) => {
  const startTime = new Date(data.sent_review_date);
  console.log("startTime",startTime)
  const endTime = new Date(startTime.getTime() + 1 * 60 * 1000); // 1 minute added
  const now = new Date();
  const difference = endTime - now;
  
  return Math.max(0, Math.ceil(difference / 1000 / 60)); // Rounds up to show "1 min left"
  };

  export function generateProjectContent(selectedProject) {
    const {
      target_geographies = [],
      target_industries = [],
      target_revenue_range = [],
      target_contact_criteria = [],
    } = selectedProject?.content || {};
  
    const isEmpty =
      target_geographies.length === 0 &&
      target_industries.length === 0 &&
      target_revenue_range.length === 0 &&
      target_contact_criteria.length === 0;
  
    if (isEmpty) {
      return "";
    }
  
    let lastCountry = "";
    let sections = [];
  
    if (target_geographies.length) {
      sections.push(`
        <p style="color: #FF7D34; font-weight: 700; font-size: 20px;">Geographical Locations</p>
        ${target_geographies
          .map((geo) => {
            let countrySection = "";
            if (geo?.country !== lastCountry) {
              countrySection = `<p><strong>${geo?.country || ""}</strong></p>`;
              lastCountry = geo?.country;
            }
            return `
              ${countrySection}
              <p style="font-weight: 500">${geo?.region || ""}</p>
              <p style="color: #707070;">${geo?.states ? geo?.states.join(", ") : ""}</p>
            `;
          })
          .join("")}
      `);
    }
  
    if (target_industries.length) {
      sections.push(`
        <p style="color: #FF7D34; font-weight: 700; font-size: 20px;">Industry Group</p>
        ${target_industries
          .map(
            (industry) => `
            <p><strong>${industry?.target_industry}</strong></p>
            <p style="color: #707070;">${industry?.specific_target_industry ? industry?.specific_target_industry.join(", ") : ""}</p>
          `
          )
          .join("")}
      `);
    }
  
    if (target_revenue_range.length) {
      sections.push(`
        <p style="color: #FF7D34; font-weight: 700; font-size: 20px;">Company Criteria</p>
        ${target_revenue_range
          .map(
            (revenue) => `
            <p style="color: #707070;">
              ${revenue?.revenue_min ? `$${formatRevenue(revenue?.revenue_min)}` : "0"} 
              to 
              ${revenue?.revenue_max ? `$${formatRevenue(revenue?.revenue_max)}` : "0"}
            </p>
          `
          )
          .join("")}
      `);
    }
  
    if (target_contact_criteria.length) {
      sections.push(`
        <p style="color: #FF7D34; font-weight: 700; font-size: 20px;">Contact Criteria</p>
        <p style="color: #707070;">${target_contact_criteria.join(", ")}</p>
      `);
    }
  
    return sections.join('<hr style="border: 1px solid #E8E8E8;">');
  }

 
  
  export const setAuthData =(userData)=>{
    localStorage.setItem("token", userData.token);
    localStorage.setItem("sas_url", userData.sas_url);
    localStorage.setItem("sas_token", userData.sas_token);
    localStorage.setItem("userId", userData.user_id);
    localStorage.setItem("role", userData.active_role_key);
    localStorage.setItem("first_name", userData.first_name);
    localStorage.setItem("last_name", userData.last_name);
    localStorage.setItem("chat_history", userData.chat_status);
    localStorage.setItem("project_id", userData.active_project_id);
    localStorage.setItem("accordionState","expanded") 
    localStorage.setItem(
      "last_login_date",
      userData.last_login_date ? userData.last_login_date : ""
    );
}


export const stripHtmlTags = (html) => {
  const temp = document.createElement("div");
  temp.innerHTML = html;
  return temp.textContent || temp.innerText || "";
};