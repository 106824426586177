import { useEffect } from "react";

const useClickOutside = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element is NOT inside the referenced element
      if (ref.current && !ref.current.contains(event.target)) {
        callback(); // Execute the callback function
      }
    };

    // Add event listener to detect mouse clicks
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]); // Runs when `ref` or `callback` changes
};

export default useClickOutside;
