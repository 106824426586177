import { Box, Button, styled, Typography } from "@mui/material";

const OuterBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  //   background: "#F0F0F0",
  margin: "2rem 15rem",
});

const ButtonsBox = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  gap: "0.5rem",
  background: "#FFFFFF",
  border: "1px solid #E8E8E8",
  padding: "0.75rem 1rem",
  borderRadius:"8px"
});

const AllButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? "#009872" : "transparent",
  color: active ? "white" : "#6A6A6B",
  border: active ? "" : "1px solid #A3A3A4",
  textTransform: "capitalize",
  fontWeight: active ? "500" : "400",
  fontFamily: "Roboto",
  fontSize: "1rem",
  borderRadius:"4px",
  height:"0.5rem",
  width:"fit-content",
  padding:"1.125rem 1.5rem",
}));

const ReadButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? "#009872" : "transparent",
  color: active ? "white" : "#6A6A6B",
  border: active ? "" : "1px solid #A3A3A4",
  textTransform: "capitalize",
  fontWeight: active ? "500" : "400",
  fontFamily: "Roboto",
  fontSize: "1rem",
  borderRadius:"4px",
  height:"0.4rem",
  width:"fit-content",
  padding:"1.05rem 1.5rem",
}));

const UnReadButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? "#009872" : "transparent",
  color: active ? "white" : "#6A6A6B",
  border: active ? "" : "1px solid #A3A3A4",
  textTransform: "capitalize",
  fontWeight: active ? "500" : "400",
  fontFamily: "Roboto",
  fontSize: "1rem",
  borderRadius:"4px",
  height:"0.4rem",
  width:"fit-content",
  padding:"1.05rem 1.5rem",
}));

const NotificationOuterBox = styled(Box)({
  //   border: "1px solid #E8E8E8",
  // background: "red",
  height: "75vh",
  overflow: "auto",
  scrollbarWidth: "thin", // Ensures a thin scrollbar in Firefox
  "&::-webkit-scrollbar": {
    width: "6px", // Thin scrollbar for Chrome/Safari
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#bbb", // Scrollbar color
    borderRadius: "3px", // Rounded edges
  },
});

const NotificationBox = styled(Box)(({ read }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#818182",
  fontSize: "14px",
  fontFamily: "Roboto",
  cursor: "pointer",
  gap: "1rem",
  padding: "1.25rem",
  backgroundColor: read ? "#fff" : "#EBFEF6",
  border: read ? "1px solid  #D1D1D2" : "1px solid #2FD8A4 ",

  "&:first-child": {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
}));

const DotBox = styled(Box)(({ read }) => ({
  width: "14px",
  display: "flex",
  justifyContent: "center",
  visibility: read ? "hidden" : "visible",
}));

const NameBox = styled(Typography)({
  fontWeight: 500,
  fontFamily: "Roboto",
  fontSize: "1.25rem",
  color: "#000000",
});

const MessageBox = styled(Typography)({
  fontWeight: 400,
  fontFamily: "Roboto",
  fontSize: "1.125rem",
  color: "#1A1A1C",
});

const TimeBox = styled(Typography)({
  fontWeight: 500,
  fontFamily: "Roboto",
  fontSize: "1rem",
  color: "#818182",
});

export {
  OuterBox,
  ButtonsBox,
  AllButton,
  ReadButton,
  UnReadButton,
  NotificationOuterBox,
  NotificationBox,
  DotBox,
  NameBox,
  MessageBox,
  TimeBox,
};
