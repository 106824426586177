import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import ChatInterfaceMainLayout from "./components/ChatInterface/SalesLead/ChatInterfaceMainLayout.jsx";
import LoginPage from "./components/Auth/LoginPage";
import MainLayoutData from "./components/Layout/MainLayoutData";
import SalesLeadDashboard from "./components/Dashboard/SalesLeadDashboard.jsx";
import RegisterPage from "./components/Auth/RegisterPage.jsx";
import ProtectedRoute from "./AuthContext/ProtectedRoute.js";
import UnauthorizedPage from "./RouteConfig/UnauthorizedPage.jsx";
import EULA from "./components/Auth/EULA.jsx";
import SuccessPage from "./components/Auth/SuccessPage.jsx";
import SDRDashboard from "./components/Dashboard/SDRDashboard.jsx";
import ViewPlan from "./components/ViewPlan/ViewPlan.jsx";
// import Settings from "./components/Settings/Settings.jsx";
import ForgotPassword from "./components/Auth/ForgotPassword.jsx";
import ResetPassword from "./components/Auth/ResetPassword.jsx";
import Settings from "./components/Settings/Settings.jsx";
import OutReachDetails from "./components/OutReachDetails/OutReachDetails.jsx";
import OnboardigSlider from "./components/Reusable/OnboardingSlider/OnboardigSlider.jsx";
import HappyProspectingPage from "./components/OutReachDetails/HappyProspecting/HappyProspectingPage.jsx";
import SDRMainLayout from "./components/ChatInterface/SDR/SDRMainLayout.jsx";
import SDRHomePage from "./components/Home/SDRHomePage.jsx";
import SalesLeadHome from "./components/Home/SalesLeadHome.jsx";
import StartChatScreen from "./components/ChatInterface/Common/StartChatScreen.jsx";
import ErrorPage from "./components/Auth/ErrorPage.jsx";
import { jwtDecode } from "jwt-decode";
import Notification from "./components/Notification/Notification.jsx";
import { useToast } from "../src/utils/ToastContext";

import { initGA, logPageView } from "./analytics";
import SalesLeadTracker from "./components/Lead Tracker/SalesLeadTracker.jsx";
import CompanyAdded from "./components/Lead Tracker/CompanyAdded.jsx";
import AccountPage from "./components/Settings/Account/AccountPage.jsx";
import { MembersPage } from "./components/Settings/MembersPage.jsx";
import IntegrationsPage from "./components/Settings/IntegrationsPage.jsx";
import PlanBillingPage from "./components/Settings/PlanBillingPage.jsx";
import CreditUsagePage from "./components/Settings/CreditUsage/CreditUsagePage.jsx";
import ReferralsPage from "./components/Settings/ReferralsPage.jsx";
import ThemePage from "./components/Settings/Theme/ThemePage.jsx";
import AccountDetails from "./components/Settings/Account/AccountDetails.jsx";
import AccountApiKey from "./components/Settings/Account/AccountApiKey.jsx";
import AccountSecurity from "./components/Settings/Account/AccountSecurity.jsx";
import AuditLog from "./components/Auditlog/Auditlog.jsx";

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location.pathname]);

  return null;
};

const App = () => {
  const { showToast } = useToast();
  const isTokenExpired = (token) => {
    if (!token) return true; // Treat missing token as expired

    try {
      const decoded = jwtDecode(token);
      const isExpired = decoded.exp * 1000 < Date.now(); // Compare expiry time with current time
      return isExpired;
    } catch (error) {
      return true; // If decoding fails, treat as expired
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.log("No token found.");
      return; // Exit early if no token is found
    }
    if (isTokenExpired(token)) {
      console.log("Token has expired!");
      showToast("error", "Token has expired.");
      localStorage.clear();
    } else {
      console.log("Token is still valid.");
    }
  }, [showToast]);

  useEffect(() => {
    initGA();
  }, []);

  return (
    // <ToastProvider>
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/piqual-ai" element={<MainLayoutData />}>
          {/* Sales Lead-only routes */}
          <Route
            path="sales-lead-home"
            element={
              <ProtectedRoute
                element={<SalesLeadHome />}
                requiredRole="piqai_sales_lead"
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute
                element={<SalesLeadDashboard />}
                requiredRole="piqai_sales_lead"
              />
            }
          />
          <Route
            path="view-plan"
            element={
              <ProtectedRoute
                element={<ViewPlan />}
                requiredRole="piqai_sales_lead"
              />
            }
          />
          <Route
            path="sales-lead-tracker"
            element={
              <ProtectedRoute
                element={<SalesLeadTracker />}
                requiredRole="piqai_sales_lead"
              />
            }
          />
          <Route
            path="notifications"
            element={
              <ProtectedRoute
                element={<Notification />}
                requiredRole={["piqai_sales_lead", "piqai_sdr"]}
              />
            }
          />
          <Route
            path="audit-log"
            element={
              <ProtectedRoute
                element={<AuditLog />}
                requiredRole={["piqai_admin"]}
              />
            }
          />
          {/* SDR-only routes */}
          <Route
            path="sdr-home"
            element={
              <ProtectedRoute
                element={<SDRHomePage />}
                requiredRole={["piqai_sdr"]}
              />
            }
          />
          <Route
            path="sdr-dashboard"
            element={
              <ProtectedRoute
                element={<SDRDashboard />}
                requiredRole="piqai_sdr"
              />
            }
          />
          <Route
            path="sdr-sales-plan"
            element={
              <ProtectedRoute
                element={<SDRMainLayout />}
                requiredRole={["piqai_sdr"]}
              />
            }
          />
          <Route
            path="outreach"
            element={
              <ProtectedRoute
                element={<OutReachDetails />}
                requiredRole={["piqai_sdr"]}
              />
            }
          />
          <Route
            path="prospecting"
            element={
              <ProtectedRoute
                element={<HappyProspectingPage />}
                requiredRole={["piqai_sdr"]}
              />
            }
          />
          {/* Sales Lead and SDR Common Routes */}
          <Route
            path="sales-plan"
            element={
              <ProtectedRoute
                element={<ChatInterfaceMainLayout />}
                requiredRole={["piqai_sales_lead", "piqai_sdr"]}
              />
            }
          />
          <Route
            path="onboarding"
            element={
              <ProtectedRoute
                element={<OnboardigSlider />}
                requiredRole={["piqai_sales_lead", "piqai_sdr"]}
              />
            }
          />
          <Route
            path="start-chat"
            element={
              <ProtectedRoute
                element={<StartChatScreen />}
                requiredRole={["piqai_sales_lead", "piqai_sdr"]}
              />
            }
          />
          <Route
            path="companies-added"
            element={
              <ProtectedRoute
                element={<CompanyAdded />}
                requiredRole={["piqai_sales_lead", "piqai_sdr"]}
              />
            }
          />
          {/* Settings Page Route */}
          <Route
            path="settings"
            element={
              <ProtectedRoute
                element={<Settings />}
                requiredRole={["piqai_sales_lead", "piqai_sdr"]}
              />
            }
          >
            <Route index element={<Navigate to="account/details" replace />} />

            {/* Account Page Routes */}

            <Route
              path="account"
              element={
                <ProtectedRoute
                  element={<AccountPage />}
                  requiredRole={["piqai_sales_lead", "piqai_sdr"]}
                />
              }
            >
              <Route index element={<Navigate to="details" replace />} />

              <Route
                path="details"
                element={
                  <ProtectedRoute
                    element={<AccountDetails />}
                    requiredRole={["piqai_sales_lead", "piqai_sdr"]}
                  />
                }
              />
              <Route
                path="security"
                element={
                  <ProtectedRoute
                    element={<AccountSecurity />}
                    requiredRole={["piqai_sales_lead"]}
                  />
                }
              />
              <Route
                path="api-key"
                element={
                  <ProtectedRoute
                    element={<AccountApiKey />}
                    requiredRole={["piqai_sales_lead"]}
                  />
                }
              />
            </Route>

            {/* Theme Page Route */}
            <Route
              path="theme"
              element={
                <ProtectedRoute
                  element={<ThemePage />}
                  requiredRole={["piqai_sales_lead"]}
                />
              }
            />

            {/* Members Page Route */}
            <Route
              path="members"
              element={
                <ProtectedRoute
                  element={<MembersPage />}
                  requiredRole={["piqai_sales_lead"]}
                />
              }
            />

            {/* Integrations Page Route */}
            <Route
              path="integrations"
              element={
                <ProtectedRoute
                  element={<IntegrationsPage />}
                  requiredRole={["piqai_sales_lead"]}
                />
              }
            />

            {/* Plan and Billing Page Route */}
            <Route
              path="plan-and-billing"
              element={
                <ProtectedRoute
                  element={<PlanBillingPage />}
                  requiredRole={["piqai_sales_lead"]}
                />
              }
            />

            {/* Credit Usage Page Route */}
            <Route
              path="credit-usage"
              element={
                <ProtectedRoute
                  element={<CreditUsagePage />}
                  requiredRole={["piqai_sales_lead"]}
                />
              }
            />

            {/* Referrals Page Route */}
            <Route
              path="referrals"
              element={
                <ProtectedRoute
                  element={<ReferralsPage />}
                  requiredRole={["piqai_sales_lead", "piqai_sdr"]}
                />
              }
            />
          </Route>
        </Route>

        {/* Comman Routes */}

        <Route path="*" element={<UnauthorizedPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/sign-up/:id/:token" element={<RegisterPage />} />
        <Route path="/end-user-agreement/:token" element={<EULA />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </Router>
    // </ToastProvider>
  );
};

export default App;
