import React from "react";
import {
  DescTypography,
  DetailsOuterBox,
  HeadingTypography,
} from "./AccountPageStyles";

import { Box } from "@mui/system";

const AccountSecurity = () => {
  return (
    <DetailsOuterBox sx={{ marginBlock: "0.5rem" }}>
      <Box>
        <Box sx={{ marginBlock: "1rem" }}>
          <HeadingTypography sx={{ marginBlock: "0.25rem" }}>
            Account created at
          </HeadingTypography>

          <DescTypography>Mar 6, 2025</DescTypography>
        </Box>

        <Box sx={{ marginBlock: "1rem" }}>
          <HeadingTypography sx={{ marginBlock: "0.25rem" }}>
            Updated at
          </HeadingTypography>

          <DescTypography>Mar 11, 2025</DescTypography>
        </Box>

        <Box sx={{ marginBlock: "1rem" }}>
          <HeadingTypography sx={{ marginBlock: "0.25rem" }}>
            Members
          </HeadingTypography>

          <DescTypography>Google</DescTypography>
        </Box>
      </Box>
    </DetailsOuterBox>
  );
};

export default AccountSecurity;
