import React, { useState } from "react";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  Chip,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BtnIcon, SelectBtndownicon, DeleteActionIcon } from "../../Constants/Constant";
import { HeaderCell, TeamMembersName, TeamMembersEmailId } from "./SettingsStyles";

export const MembersPage = () => {
  const [members, setMembers] = useState([
    { name: "Joseph Olassa", email: "joseph.olassa@ignitho.com", role: "Sales Leader", isYou: true },
    { name: "Anton Ciby", email: "anton.ciby@ignitho.com", role: "SDR", isYou: false },
  ]);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  // Sort members by name
  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");

    setMembers((prevMembers) =>
      [...prevMembers].sort((a, b) =>
        sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      )
    );
  };

  // const sortedMembers = [...members]
  // .filter((member) => member.name.toLowerCase().includes(search.toLowerCase()))
  // .sort((a, b) => sortOrder === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));

  const handleRoleChange = (event, email) => {
    setMembers((prevMembers) =>
      prevMembers.map((member) =>
        member.email === email ? { ...member, role: event.target.value } : member
      )
    );
  };

  const handleDelete = (email) => {
    setMembers(members.filter((member) => member.email !== email));
  };

  return (
    <Box>
      <Typography sx={{
        fontSize: { xs: "18px", md: "18px", lg: "20px" },
        fontWeight: 500,
      }}>
        Team Members
      </Typography>

      {/* Search & Sorting Controls */}
      <Stack direction="row" justifyContent="space-between" mt={2} mb={2}>
        <Button sx={{
          border: "1px solid #D1D1D2",
          borderRadius: "4px",
          color: "#252527",

        }}
          onClick={handleSort}
          endIcon={
            <img
              src={BtnIcon}
              style={{
                marginRight: "10px",
              }}
              alt="dropdown icon"
            />
          }
        >Name</Button>

        <TextField
          sx={{
            color: "#A3A3A4",
            width: "266px",
            borderRadius: "4px",
            "& .MuiOutlinedInput-root": {
              height: "36px",
              "& fieldset": {
                borderColor: "#D1D1D2",
                borderRadius: "8px",
              },
              "&:hover fieldset": {
                borderColor: "#D1D1D2", // Keep border color same on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#D1D1D2 !important", // Prevent primary color on focus
              },
            },
          }}
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{
                    color: "#A3A3A4",
                  }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* Members Table */}
      <TableContainer
        sx={{
          border: "1px solid #E0E0E0", // Outer border
          borderRadius: "8px",
          boxShadow: "none",
        }}
        component={Paper}>
        <Table
          sx={{
            borderCollapse: "separate",
          }}>
          <TableHead>
            <TableRow sx={{
              colour: "#212529",
              fontSize: "18px", fontWeight: 500,
              backgroundColor: "#F3F1FB"
            }}>
              <HeaderCell><strong>Name</strong></HeaderCell>
              <HeaderCell><strong>Email ID</strong></HeaderCell>
              <HeaderCell><strong>Role</strong></HeaderCell>
              <HeaderCell><strong>Action</strong></HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members
              .filter((member) => member.name.toLowerCase().includes(search.toLowerCase()))
              .map((member, index, array) => (
                <TableRow key={member.email}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar sx={{
                        bgcolor: member.isYou ? "#009872" : "#FF7D34",
                        width: { xs: "14px", sm: "34px", md: "38px", lg: "42px" }, // Adjust width based on screen size
                        height: { xs: "14px", sm: "34px", md: "38px", lg: "42px" }, // Adjust height based on screen size
                        fontSize: { xs: "14", sm: "16px", md: "16px", lg: "18px" }, // Adjust font size for better visibility
                      }}>
                        {member.name.split(" ").map((n) => n[0]).join("")}
                      </Avatar>
                      <TeamMembersName>{member.name}</TeamMembersName>
                      {member.isYou && (
                        <Chip label="You" size="small" sx={{ backgroundColor: "#e3fcef", color: "#3AB044" }} />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <TeamMembersEmailId>
                      {member.email}
                    </TeamMembersEmailId>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={member.role}
                      IconComponent={() => (<img src={SelectBtndownicon} alt="" style={{ marginRight: "10px" }} />)}
                      sx={{
                        width: "151px",
                        "& .MuiOutlinedInput-root": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#D1D1D2", // Default border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#D1D1D2", // Prevent hover effect
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#D1D1D2 !important", // Remove primary color on focus
                          },
                        },
                        "& .MuiSelect-select": {
                          paddingRight: "32px",
                        },
                      }}
                      onChange={(event) => handleRoleChange(event, member.email)}
                      size="small"
                    >
                      <MenuItem value="Sales Leader">Sales Leader</MenuItem>
                      <MenuItem value="SDR">SDR</MenuItem>
                    </Select>

                  </TableCell>
                  <TableCell>
                    <IconButton color="error" onClick={() => handleDelete(member.email)}>
                      <img src={DeleteActionIcon} alt="" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
