import { styled, TextField, Button } from "@mui/material";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "48px",
    fontSize: "14px",
    borderRadius: "8px",
    fontFamily: "Roboto",
    color: "#1A1A1C",
    width: "100%",

    "@media (max-width: 1440px)": {
      height: "46px",
      fontSize: "14px",
      borderRadius: "8px",
    },
    "@media (min-resolution: 1.1dppx) and (max-width: 1440px)": {
      height: "40px",
      fontSize: "13px",
      borderRadius: "6px",
    },
    "@media (min-resolution: 1.25dppx) and (max-width: 1440px)": {
      height: "35px",
      fontSize: "12px",
      borderRadius: "5px",
    },
    "@media (min-resolution: 1.5dppx) and (max-width: 1440px)": {
      height: "30px",
      fontSize: "12px",
      borderRadius: "4px",
    },

    "& input": {
      paddingRight: "12px",
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0px 1000px white inset !important",
        backgroundColor: "transparent !important",
        color: "#1A1A1C !important",
      },
      "&:-webkit-autofill:hover, &:-webkit-autofill:focus": {
        WebkitBoxShadow: "0 0 0px 1000px white inset !important",
        backgroundColor: "transparent !important",
        color: "#1A1A1C !important",
      },
    },
  },
  "& .MuiFormHelperText-root": {
    marginLeft: "0px",
    "@media (min-resolution: 1.1dppx) and (max-width: 1440px)": {
      fontSize: "10px"
    },
    "@media (min-resolution: 1.25dppx) and (max-width: 1440px)": {
      fontSize: "9px"
    },
    "@media (min-resolution: 1.5dppx) and (max-width: 1440px)": {
      fontSize: "8px"
    },
  },
  "& fieldset": { borderColor: "#d1d1d2" },
  "&:hover fieldset": { borderColor: "#009872" },
});

const StyledButton = styled(Button)({
  height: "48px",
  fontSize: "16px",
  textTransform: "none",
  background: "#009872",
  borderRadius: "8px",
  color: "#fff",
  width: "100%",
  fontFamily: "Roboto",

  "&:hover": { background: "#009872" },

  "@media (max-width: 1440px)": {
    height: "46px",
    fontSize: "14px",
    borderRadius: "8px",
  },
  "@media (min-resolution: 1.1dppx) and (max-width: 1440px)": {
    height: "40px",
    fontSize: "13px",
    borderRadius: "6px",
  },
  "@media (min-resolution: 1.25dppx) and (max-width: 1440px)": {
    height: "35px",
    fontSize: "12px",
    borderRadius: "5px",
  },
  "@media (min-resolution: 1.5dppx) and (max-width: 1440px)": {
    height: "30px",
    fontSize: "12px",
    borderRadius: "4px",
  },
});

export {
  StyledTextField,
  StyledButton,
};