import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { TitleBox } from "../ChatInterfaceStyles";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

const planTypeMap = {
  message_plan: "Own Narrative",
  market_plan: "Nail Your ICP",
  media_plan: "Orchestrate Wins",
  measure_plan: "Track Triumphs",
};

const planOrder = Object.values(planTypeMap); // Extract the correct order of names

const HorizontalBarChart = () => {
  const [planStatus, setPlanStatus] = useState([]);

  const projectDetails = useSelector(
    (state) => state.setProjectDetailsStatusReducer,
    shallowEqual
  );
  const [expanded, setExpanded] = useState(
    localStorage.getItem("accordionState") === "expanded"
  );

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    localStorage.setItem(
      "accordionState",
      isExpanded ? "expanded" : "collapsed"
    );
  };

  useEffect(()=>{
    if(projectDetails?.projectStatusDetails?.length !== 0){
      const updatedData = projectDetails?.projectStatusDetails?.map(({plan_type, avg_completion_percentage}) => ({
        name: planTypeMap[plan_type] || plan_type, 
        value:avg_completion_percentage
      }));
        // Ensure the order matches planTypeMap
      const sortedData = planOrder?.map(name => 
        updatedData?.find(item => item.name === name) || { name, value: 0 }
      );

      setPlanStatus(sortedData);
    }
  }, [projectDetails]);


  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionChange}
      defaultExpanded
      sx={{
        backgroundColor: "#EEF4FF",
        boxShadow: "none",
        "&.Mui-expanded:last-of-type": {
          marginBottom: 0,
          borderRadius: "12px",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "&.Mui-expanded": {
            minHeight: "45px",
          },
        }}
      >
        <TitleBox>Your Sales Plan So Far</TitleBox>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px 24px 24px" }}>
        <ResponsiveContainer
          width="96%"
          height={160}
          style={{
            backgroundColor: "white",
            padding: "16px 20px",
            borderRadius: "8px",
          }}
        >
          <BarChart
            layout="vertical"
            width={500}
            height={300}
            data={planStatus}
            margin={{ top: -10.5, right: 25, left: -50, bottom: -10.5 }}
          >
            <CartesianGrid
              strokeDasharray="0"
              stroke="#E8E8E8"
              vertical={true}
              horizontal={false}
            />
            <XAxis
              type="number"
              domain={[0, 100]}
              stroke="#E8E8E8"
              tickLine={false}
              tick={({ x, y, payload }) => (
                <text
                  x={x -7}
                  y={y + 7}
                  fill="#6A707E"
                  fontWeight={500}
                  fontSize={12}
                  style={{ fontFamily: "roboto" }}
                >
                  {payload.value}
                </text>
              )}
            />
              <YAxis
              dataKey="name"
              type="category"
              stroke="#E8E8E8"
              tickLine={false}
              tickPadding={0}
              width={150}
              tick={{
                fill: "#0D162F",
                fontSize: 12,
                fontFamily: "Roboto",
                fontWeight: 500,
               
              }}
            />

            {/* Bar without hover effect */}
            <Bar
              dataKey="value"
              fill="#56BB6B"
              barSize={15}
              barCategoryGap={8}
              isAnimationActive={false}
              style={{ marginTop: "-10px" }} // Disables interaction
            />
          </BarChart>
        </ResponsiveContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default HorizontalBarChart;