import { API } from "../../global";

export const GET_NOTIFICATION_START = "GET_NOTIFICATION_START";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

export const getNotificationStart = () => ({
  type: GET_NOTIFICATION_START,
});

export const getNotificationSuccess = (data) => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: data,
});

export const getNotificationError = (error) => ({
  type: GET_NOTIFICATION_ERROR,
  payload: error,
});

export const getNotificationList = (payload) => async (dispatch) => {
  dispatch(getNotificationStart(payload)); // Dispatch the start action
  try {
    const response = await fetch(`${API}/project/notifications`, {
      method: "POST", // Specify the HTTP method (e.g., POST, PUT, DELETE, etc.)
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    dispatch(getNotificationSuccess(data)); // Dispatch the success action with payload
    return data
  } catch (error) {
    dispatch(getNotificationError(error.toString())); // Dispatch the error action with payload
  }
};
