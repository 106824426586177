import React, { useState } from "react";
import { Box } from "@mui/material";
import {
  Container,
  SettingsPanel,
  PreviewPanel,
  HorizontalLine,
  PreviewContent,
  Card,
  StyledHeading,
  PreviewContainer,
  HeaderContainer,
  GreetingsBox,
  DescriptionBox,
  Heading,
  CardIcon,
  ContainedBtn,
  CardDescription,
  FlexBox
} from "./ThemePageCss";
import ThemeColorPicker from "./ThemeColorPicker";
import CompanyLogo from "./CompanyLogo";
import CompanyName from "./CompanyName";
import Sidebar from "./Sidebar";
import Header from "./Header";
import messageIcon from "../../../assets/MessagePlanIcon.svg";
import ArrowIcon from "../../../assets/ArrowIcon.svg";
import mediaIcon from "../../../assets/MediaPlanIcon.svg";

const BASE_COLORS = {
  primary: "#009872",
  secondary: "#FF7D34",
  tertiary: "#56B68B",
};

const ThemePage = () => {
  const [colors, setColors] = useState(BASE_COLORS);
  const firstName = localStorage.getItem("first_name");
  let lastName = localStorage.getItem("last_name");
  lastName = lastName === "undefined" || lastName === null ? null : lastName;

  const handleColorChange = (key, value) => {
    setColors((prev) => ({ ...prev, [key]: value }));
  };

  const resetColors = () => {
    setColors(BASE_COLORS);
  };

  return (
    <Container>
      <SettingsPanel>
        <CompanyName />
        <HorizontalLine />
        <CompanyLogo />
        <HorizontalLine />
        <ThemeColorPicker
          colors={colors}
          onColorChange={handleColorChange}
          resetColors={resetColors}
        />
      </SettingsPanel>

      <PreviewPanel>
        <StyledHeading>Preview</StyledHeading>
        <PreviewContainer>
          <Sidebar colors={colors} />
          <PreviewContent>
            <HeaderContainer>
              <Header primaryColor={colors.primary} />
            </HeaderContainer>
            <Box sx={{ padding: "16px" }}>
              <GreetingsBox secondaryColor={colors.secondary}>
                Good Morning, {firstName} {lastName}!
              </GreetingsBox>
              <DescriptionBox>
                Welcome to your launchpad! Define a winning message, lock in your ICP, set the right media mix, and track success with precision. With Piqual AI, every move is smarter, every outreach sharper - lead boldly and drive results!
              </DescriptionBox>
              <Heading>
                As a Sales leader, you need to create a sales plan
              </Heading>
            </Box>
            <FlexBox>
              <Card
                sx={{
                  height: "350px",
                  gap: "24px",
                  border: `2px solid ${colors.secondary}`,
                }}
              >
                <CardIcon sx={{ backgroundColor: "#FFF5ED" }}>
                  <img src={messageIcon} alt="" />
                </CardIcon>
                <Box>
                  <Heading sx={{ marginBottom: "20px" }}>
                    Own Narrative
                  </Heading>
                  <CardDescription>
                    Your unique edge deserves the spotlight. Align messaging across your team and amplify what sets you apart.
                  </CardDescription>
                </Box>
                <ContainedBtn btnColor={colors.secondary}>
                  Start Now
                  <img
                    src={ArrowIcon}
                    alt=""
                    style={{ marginLeft: "6px" }}
                  />
                </ContainedBtn>
              </Card>

              <Card>
                <CardIcon sx={{ backgroundColor: "#F9F9F9" }}>
                  <img src={mediaIcon} alt="" />
                </CardIcon>
                <Heading sx={{ color: "#6A6A6B" }}>
                  Orchestrate Wins
                </Heading>
                <CardDescription>
                  Multi-channel, maximum impact. Set the right outreach rhythm across media - email, LinkedIn, and calls - to drive real results.
                </CardDescription>
              </Card>
            </FlexBox>
          </PreviewContent>
        </PreviewContainer>
      </PreviewPanel>
    </Container>
  );
};

export default ThemePage;