import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "./PlanStyle.css";
import {
  Avatar,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
// import { ExpandIcon, MoveIcon, MsgIcon } from "../../Constants/Constant";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import { API } from "../../global";
import DeleteModal from "../Reusable/DeleteModal/DeleteModal";
import { StyledTextArea } from "./OutReachStyle";
import { useToast } from "../../utils/ToastContext";

const SuggestFeedback = ({
  // customStyles,
  type,
  closeModel,
  plan_type,
  data,
  getProjectDetails,
  
}) => {

   const {showToast} = useToast()
  const ITEM_HEIGHT = 48;

  const [showMenu, setShowMenu] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [comment, setComment] = useState("");
  const [commentId,setCommentId] = useState('')
  const [showComment, setShowComment] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [emptyComment,setEmptyComment] = useState(false);
  const [firstName,setFirstName] = useState(localStorage.getItem("first_name"));
  const [lastName,setLastName] =useState(localStorage.getItem("last_name"))
  const [statusValue,setStatusValue] = useState('')
  // const [expandView, setExpandView] = useState(true);
  const open = Boolean(anchorEl);
  const [showDate, setShowDate] = useState("");


  function formatDate(isoString) {
    const date = new Date(isoString);
    return date.toLocaleString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    }).replace(',', '');
}

  useEffect(() => {
    if (data && data.length !== 0) {
      setComment(data[0]?.comments);
      setCommentId(data[0]?._id)
      setStatusValue(data[0]?.status)
      if(data[0]?.comments){
        setShowComment(true)
        setShowInput(false);
        setShowDate(formatDate(data[0]?.updated_at))
      }
    }

    //eslint-disable-next-line
  }, []);


  useEffect(()=>{
    if(data){
      // eslint-disable-next-line
      data.map((item)=>{
        const fName  = type !== 'sdr' ? item?.user?.first_name : localStorage.getItem("first_name");
        const lName  = type !== 'sdr' ?  item?.user?.last_name : localStorage.getItem("last_name") ;
        setLastName(lName)
        setFirstName(fName)
      })
    }
    // eslint-disable-next-line
  },[data])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const stringAvatar = () => {
    const firstNameData = firstName?.charAt(0) ?? "";
    const lastNameData = lastName?.charAt(0) ?? "";

    return {
      sx: {
        background: "#FF7D34",
        color: "white",
        width: "32px",
        height: "32px",
        fontSize: "15px",
        marginRight: "5px",
      },
      children: `${firstNameData}${lastNameData}`.trim(), // Remove extra space if lastName is empty
    };
  };

  const editFeedback = () => {
    setShowInput(true);
    setShowComment(false)
  };

  const sendFeedback = () => {
if(comment){
  const obj = {
    plan_type: plan_type,
    comments: comment,
    project_id: localStorage.getItem("project_id"),
  };
  setLoading(true);
  setEmptyComment(false);
  fetch(`${API}/project/update-sdr-feedback`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => res.json())
    .then((response) => {
      getProjectDetails();
      setLoading(false);
      setShowComment(true);
      setShowInput(false);
      setShowMenu(true);
      const date = new Date(response.result.updated_at); // Your given date-time
      const formattedDate = date.toLocaleString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      setShowDate(formattedDate);
    })
    .catch(() => {
      setLoading(false);
    });
}
   else{
    setEmptyComment(true)
   }
  };

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  // const expand = () => {
  //   setExpandView(!expandView);
  // };

  // const deleteFeedback = () => {closeModel
  //   setModalOpen(true);
  //   // ();
  // };

  const updateStatus = (status) => {
    const obj = {
      project_id: localStorage.getItem("project_id"),
      plan_type: plan_type,
      status: status,
      comment_id: commentId,
    };
    fetch(`${API}/project/update-sdr-feedback-status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then(() => {
        showToast("success", "Updated successfully");
        closeModel();
      })
      .catch(() => {});
  };

  const handleDelete = () => {
    const obj = {
      comment_id: data[0]?._id,
      project_id: localStorage.getItem("project_id"),
    };
    setLoading(true);

    fetch(`${API}/project/delete-sdr-feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        setShowComment(true);
        setShowInput(false);
        setModalOpen(false);
        getProjectDetails();
        closeModel();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const closeEdit =()=>{
    setShowInput(false)
    setShowComment(true)
  }

  const options = [
    {
      label: "Edit",
      icon: <EditIcon />,
      color: "#6A6A6B",
      onClick: editFeedback,
    },
    // {
    //   label: "Delete",
    //   icon: <DeleteIcon />,
    //   color: "#FF4949",
    //   onClick: deleteFeedback,
    // },
  ];
  const handleKeyDown = (e) => {
    // Shift + Enter → New Line
    if (e.key === "Enter" && e.shiftKey) {
      return; // Let default behavior (new line) happen
    }
    
  };

  return (
    <>
      <DeleteModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleDelete={handleDelete}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 0 15px 0",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: 600,
              color: "#1A1A1C",
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "20px",
                xl: "20px",
              },
            }}
          >
            Feedback
          </Typography>
          {/* <Box sx={{ display: "flex", gap: 1 }}>
            <Button onClick={expand} sx={{ p: 1, minWidth: "auto" }}>
              <img src={ExpandIcon} alt="" />
            </Button>
            <Button onClick={editFeedback} sx={{ p: 0, minWidth: "auto" }}>
              <img src={MsgIcon} alt="" />
            </Button>
            <Button sx={{ p: 0, minWidth: "auto" }}>
              <img src={MoveIcon} alt="" />
            </Button>
          </Box> */}
          <Button
            sx={{
              width: "52px",
              height: "32px",
              border: "1px solid #D1D1D2",
            }}
            onClick={() => closeModel()}
          >
            <CloseIcon sx={{ color: "#6A6A6B" }} />
          </Button>
        </Box>
        {/* {expandView && ( */}
        <>
          <Divider sx={{ marginTop: "5px" }} />
          <Box>
            {showComment && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "20px 0 15px 0",
                      gap: "4px",
                    }}
                  >
                    <Avatar {...stringAvatar()} />
                    <Box
                      sx={{
                        fontWeight: 500,
                        color: "#0d162f",
                        cursor: "pointer",
                        textTransform: "capitalize",
                        marginTop: "-3px",
                        fontSize: {
                          xs: "14px",
                          sm: "14px",
                          md: "16px",
                          lg: "20px",
                          xl: "20px",
                        },
                      }}
                    >
                      {firstName} {lastName}
                    </Box>
                  </Box>
                  {type === "sdr" && (
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon sx={{ color: "#818182" }} />
                    </IconButton>
                  )}

                  {type === "sdr" && (
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      slotProps={{
                        paper: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "15ch",
                          },
                        },
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option.label}
                          onClick={() => {
                            option.onClick(); // Call the respective function
                            handleClose(); // Close the menu after selection
                          }}
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <ListItemIcon sx={{ color: option.color }}>
                            {option.icon}
                          </ListItemIcon>
                          <ListItemText
                            sx={{ color: option.color }}
                            primary={option.label}
                          />
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </Box>
                <Box sx={{ padding: "0 0 0 35px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      color: "#1A1A1C",
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                        xl: "20px",
                      },
                      pb: 2,
                    }}
                  >
                    {comment}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      color: "#818182",
                      marginTop: "5px",
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                        xl: "20px",
                      },
                    }}
                  >
                    {showDate}
                     • Edited
                  </Typography>

                  {type === "sdr" ? (
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontStyle: "italic",
                        color:
                          statusValue === "approved"
                            ? "#28A745" // Green for approved
                            : statusValue === "rejected"
                            ? "#DC3545" // Red for rejected
                            : "#FF7D34", // Default (pending) color
                        marginTop: "5px",
                        fontSize: {
                          xs: "14px",
                          sm: "14px",
                          md: "16px",
                          lg: "16px",
                          xl: "20px",
                        },
                      }}
                    >
                      {statusValue === "approved" && "*Approved by sales lead"}
                      {statusValue === "rejected" && "*Rejected by sales lead"}
                      {((statusValue === "pending" && type === "sdr") || !statusValue)  &&
                        " *Sent to sales lead."}
                    </Typography>
                  ) : (
                    statusValue === "pending" && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          onClick={() => updateStatus("approved")}
                          sx={{
                            border: "1px solid #009872",
                            color: "#009872",
                            textTransform: "capitalize",
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          onClick={() => updateStatus("rejected")}
                          sx={{
                            paddingX: "1.5rem",
                            bgcolor: "#FFFFFF",
                            color: "#FF4949",
                            textTransform: "capitalize",
                            border: "1px solid #FF4949",
                          }}
                        >
                          Reject
                        </Button>
                      </Box>
                    )
                  )}
                </Box>
                {showInput && (
                  <Divider sx={{ marginTop: "5px", marginBottom: "10px" }} />
                )}
              </>
            )}

            {showInput && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                      gap: "4px",
                    }}
                  >
                    <Avatar {...stringAvatar()} />
                    <Box
                      sx={{
                        fontWeight: 600,
                        color: "#1A1A1C",
                        cursor: "pointer",
                        textTransform: "capitalize",
                        // marginTop: "3px",
                        fontSize: {
                          xs: "14px",
                          sm: "14px",
                          md: "16px",
                          lg: "20px",
                          xl: "20px",
                        },
                      }}
                    >
                      {firstName} {lastName}
                    </Box>
                  </Box>
                  {showMenu && type === "sdr" && (
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon sx={{ color: "#818182" }} />
                    </IconButton>
                  )}
                  {type === "sdr" && (
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      slotProps={{
                        paper: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "15ch",
                          },
                        },
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option.label}
                          onClick={() => {
                            option.onClick(); // Call the respective function
                            handleClose(); // Close the menu after selection
                          }}
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <ListItemIcon sx={{ color: option.color }}>
                            {option.icon}
                          </ListItemIcon>
                          <ListItemText
                            sx={{ color: option.color }}
                            primary={option.label}
                          />
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </Box>
                <Box sx={{ padding: "20px 0 0 35px" }}>
                  <StyledTextArea
                    type="message"
                    value={comment}
                    rows={4}
                    placeholder="Type a suggestion"
                    onChange={(e) => handleChange(e)}
                    onKeyDown={handleKeyDown}
                  />
                </Box>
                {emptyComment && (
                  <Box
                    sx={{
                      fontSize: "14px",
                      color: "red",
                      padding: "0px 0 0 35px",
                    }}
                  >
                    Please type a suggestion and continue
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    sx={{
                      border: "1px solid #D1D1D2",
                      width: "52px",
                      height: "32px",
                    }}
                    onClick={() => closeEdit()}
                  >
                    <CloseIcon sx={{ color: "#6A6A6B" }} />
                  </Button>
                  <Button
                    loading={loading}
                    onClick={() => sendFeedback()}
                    sx={{
                      background: "#009872",
                      width: "52px",
                      height: "32px",
                    }}
                  >
                    <SendIcon sx={{ color: "#ffff" }} />
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </>
      </Box>
    </>
  );
};

export default SuggestFeedback;
