import { Box, Button, styled, Typography } from "@mui/material";

const OuterBox = styled(Box)({
  backgroundColor: "#fff",
  padding: "10px",
  width: "23.96vw",
  height: "auto",
  borderRadius: "10px",
  boxShadow: "10px 10px 15px rgba(211, 211, 211, 0.5)",
});

const UploadTextBox = styled(Box)({
  fontFamily: "Roboto, sans-serif",
  fontSize: "16px",
  fontWeight: "500",
  padding: "10px 0 5px 10px",
  color: "black",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  //   backgroundColor: "red",
});

const CloseButton = styled(Button)({
  textTransform: "none",
  backgroundColor: "#fff",
  height: "10%",
  padding: "5px 0 5px 25px",
  left: "0.4vw",
});

const UploadAreaBox = styled(Box)({
  height: "auto",
  margin: "10px",
  textAlign: "center",
  border: "2px dashed #ccc",
  borderRadius: "10px",
  cursor: "pointer",
  "&:hover": { bgcolor: "#f0f0f0" },
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 3,
  padding:'30px 30px 30px 30px'
});

const UploadText = styled(Typography)({
  fontFamily: "Roboto, sans-serif",
  fontSize: "14px",
  fontWeight: "500",
  color: "#141414",
  marginBlock: "5px",
});

const UploadFileText = styled(Typography)({
  fontFamily: "Roboto, sans-serif",
  fontSize: "12px",
  fontWeight: "400",
  color: "#AAAAAA",
  marginBlock: "5px",
});

const UploadFilesOuterBox = styled(Box)({
  height: "15.5%",
  display: "flex",
  flexDirection: "column",
  gap: 6,
  padding: "10px",
  overflowY: "auto",
  //   backgroundColor: "red",
});

const UploadFilesInnerBox = styled(Box)({
  //   backgroundColor: "blue",
  width: "20vw",
  padding: "10px",
  display: "flex",
  border: "2px solid",
  borderRadius: "11px",
  justifyContent: "space-between",
  alignItems: "center",
});

export {
  OuterBox,
  UploadTextBox,
  CloseButton,
  UploadAreaBox,
  UploadText,
  UploadFileText,
  UploadFilesOuterBox,
  UploadFilesInnerBox,
};
