import { Box, styled } from "@mui/material";

const LayoutContainer = styled(Box)({
  display: "flex",
});

const MainContent = styled(Box)(({ sidebarWidth }) => ({
  // padding: "20px",
  width: `calc(100% - ${sidebarWidth})`,
  marginLeft: sidebarWidth,
  transition: "margin-left 0.3s ease"
}));

const HeaderContainer = styled(Box)(({ sidebarWidth, theme }) => ({
  padding: "0px 20px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  height: "100%",
  boxSizing: "border-box",

  [theme.breakpoints.up("xl")]: {
    padding: "14px 20px"
  },
  "@media (min-width: 1600px) and (max-width: 1600px)": {
    padding: "12px 20px",
  },
}));

const LogoBox = styled("img")(({ theme }) => ({
  marginTop: "0px",

  "@media  (min-width: 1280px) and (max-width: 1366px)": {
    marginTop: "13px",
  },

  
}));

export { LayoutContainer, MainContent, HeaderContainer,LogoBox };
