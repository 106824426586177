import React, { useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Background, BuddyImg, TextImg, StyleLogo } from "./LoginPageStyle";
import { Box, Button, TextField, Typography } from "@mui/material";
import styles from "./LoginVariation.module.css";
import { API} from "../../global";

import {
  visibilityOff,
  PiqualAiBot,
  PiqualLogo,
  VisibilityIcon,
  LoginFrameNew,
} from "../../Constants/Constant";
import { useAuth } from "../../AuthContext/AuthContext";
import { setAuthData } from "../../Constants/Funtions/commonFunctions";

const ResetPassword = () => {
  const schema = yup.object({
    password: yup.string().required("Password is a required field."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match.")
      .required("Confirm Password is a required field."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const { id, token } = useParams();
  const navigate = useNavigate();
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const payload = {
      forget_password_key: id,
      password: data.confirmPassword,
    };
    try {
      const response = await fetch(`${API}/auth/reset-password`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      if (result.success) {
        const userData = result.result;
        setAuthData(userData);
        login(userData.token);
        if (!userData.active_project_id) {
          navigate("/error");
        } else {
          if (userData.active_role_key === "piqai_sdr") {
            navigate("/piqual-ai/sdr-dashboard");
          } else {
            navigate("/piqual-ai/dashboard");
          }
        }
      } else {
        console.error("Error:", result.message || "Password reset failed.");
      }
    } catch (error) {
      console.error("Error:", error.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Background>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          // padding: "0px 38px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "35px",
            bgcolor: "white",
            padding: "24px 16px 16px 16px",
            borderRadius: "0 0 8px 8px",

            "@media (min-resolution: 1.25dppx)": {
              padding: "20px 10px 10px 10px",
            },
          }}
        >
          <StyleLogo src={PiqualLogo} alt="" />
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            // width: "35%",
            width: { xs: "30%", sm: "30%", md: "35%", lg: "35%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
            padding: "50px",
            borderRadius: "8px",

            "@media (min-resolution: 1.5dppx)": {
              // 150% scale
              height: "400px",
              gap: "10px",
            },
          }}
        >
          <Box sx={{ position: "relative" }}>
            <TextImg src={LoginFrameNew} alt="" />
            <BuddyImg src={PiqualAiBot} alt="" />
          </Box>

          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: {
                xs: "20px",
                sm: "20px",
                md: "20px",
                lg: "30px",
                xl: "36px",
              },
            }}
          >
            Reset Your Password
          </Typography>

          <form
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Password Field */}
            <TextField
              placeholder="Enter Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              {...register("password")}
              error={errors.password}
              helperText={errors.password?.message}
              slotProps={{
                formHelperText: {
                  sx: { marginLeft: "0px" },
                },
                input: {
                  sx: {
                    height: "48px",
                    paddingRight: "12px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    color: "#1A1A1C",
                    fontFamily: "Roboto",
                  },
                  endAdornment: (
                    <img
                      alt=""
                      width="20px"
                      height="20px"
                      src={showPassword ? VisibilityIcon : visibilityOff}
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  ),
                },
              }}
              sx={{
                width: "100%",
                "& fieldset": { borderColor: "#d1d1d2" },
                "&:hover fieldset": { borderColor: "#009872" },
                "& .Mui-focused fieldset": {
                  borderColor: errors.password
                    ? "#FF0000 !important"
                    : "#009872 !important",
                },
              }}
            />

            <TextField
              placeholder="Confirm Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              {...register("confirmPassword")}
              error={errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              slotProps={{
                formHelperText: {
                  sx: { marginLeft: "0px" },
                },
                input: {
                  sx: {
                    height: "48px",
                    paddingRight: "12px",
                    borderRadius: "8px",
                    fontSize: "14px",
                    color: "#1A1A1C",
                    fontFamily: "Roboto",
                  },
                  endAdornment: (
                    <img
                      alt=""
                      width="20px"
                      height="20px"
                      src={showPassword ? VisibilityIcon : visibilityOff}
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  ),
                },
              }}
              sx={{
                width: "100%",
                "& fieldset": { borderColor: "#d1d1d2" },
                "&:hover fieldset": { borderColor: "#009872" },
                "& .Mui-focused fieldset": {
                  borderColor: errors.password
                    ? "#FF0000 !important"
                    : "#009872 !important",
                },
              }}
            />

            {/* Submit Button */}
            <Button
              className={styles.button}
              disableElevation
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "16px",
                fontFamily: "Roboto",
                background: "#009872",
                borderRadius: "4px",
                "&:hover": { background: "#009872" },
                width: 440,
                height: 48,
              }}
            >
              {loading ? "Loading..." : "Reset"}
            </Button>
          </form>
        </Box>
      </Box>
    </Background>
  );
};

export default ResetPassword;
