import { Box, Button, styled } from "@mui/material";

const SalesLeadHomeBox = styled(Box)({
  margin: "2rem",
  height: "80vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "@media (max-width: 1366px)": {
    height: "85vh", // Adjust width for smaller screens
  },
});

const HomeBox = styled(Box)(({ bColor }) => ({
  backgroundColor: bColor,
  flex: 1,
  padding: "20px",
  borderRadius: "8px",
}));

const LabelBox = styled(Box)({
  fontSize: "22px",
  fontFamily: "Roboto",
  color: "#0D162F",
  fontWeight: 700,
  "@media (max-width: 1366px)": {
    fontSize: "18px", // Adjust width for smaller screens
  },
});

const ActionButton = styled(Button)(({ bordercolor, clr }) => ({
  width: "100px",
  border: `1px solid ${bordercolor}`,
  color: clr,
  textTransform: "capitalize",
  padding: "2px 10px",
  "&:disabled": {
    backgroundColor: "#D1D1D2",
    color: "#818182",
    border: "1px solid #D1D1D2",
  },
  "@media (max-width: 1366px)": {
    width: "fit-content", // Adjust width for smaller screens
  },
}));

const Truncatetd = styled("td")({
  color: "#6A6A6B",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "200px" /* Adjust as needed */,
  "@media (max-width: 1366px)": {
    maxWidth: "100px", // Adjust width for smaller screens
  },
});

export { SalesLeadHomeBox, HomeBox, LabelBox, ActionButton, Truncatetd };
