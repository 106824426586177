import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import HorizontalBarChart from "../Common/HorizontalBarChart";
import { TabContext } from "@mui/lab";
import {
  CustomTab,
  CustomTablist,
  CustomTabPanel,
} from "../ChatInterfaceStyles";
import ReactMarkdown from "react-markdown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { shallowEqual, useSelector } from "react-redux";
import MediaPlan from "./MediaPlan";
import MeasurePlan from "./MeasurePlan";
import { formatRevenue } from "../../../Constants/Funtions/commonFunctions";

const YourSalesPlanLayout = () => {
  // const theme = useTheme();
  const [projectDataDetails, setProjectDataDetails] = useState([]);

  const tabTitle = localStorage.getItem("sales_plan_title");

  const salesPlanType = localStorage.getItem("sales_plan_type");

  const projectDetails = useSelector(
    (state) => state?.setProjectDetailsReducer,
    shallowEqual
  );

  

  useEffect(() => {
    try {
      // Create a deep copy of the projectDetails data to avoid mutating Redux state
      const updatedProjectDetails = structuredClone(projectDetails);

      const projectData = updatedProjectDetails?.projectDetailsData;

      // console.log("projectData48", projectData);

      const selectedProject = projectData?.find(
        (item) => item.key === "market_plan"
      );

      if (selectedProject?.content) {
        const {
          target_geographies = [],
          target_industries = [],
          target_revenue_range = [],
          target_contact_criteria = [],
        } = selectedProject?.content;
      
        // Check if all target arrays are empty
        const isEmpty =
          target_geographies?.length === 0 &&
          target_industries?.length === 0 &&
          target_revenue_range?.length === 0 &&
          target_contact_criteria?.length === 0;
      
        if (isEmpty) {
          selectedProject.content = "";
        } else {
          let lastCountry = "";
          let sections = [];
      
          if (target_geographies?.length) {
            sections?.push(`
              <p style="color: #FF7D34; font-weight: 700; font-size: 18px;margin-bottom:0px">Geographical Locations</p>
              ${target_geographies
                ?.map((geo, index) => {
                  let countrySection = "";
                  if (geo?.country !== lastCountry) {
                    countrySection = `<p style="margin-top:12px"><strong style="font-weight: 500; font-size: 16px;">${geo?.country ? geo?.country : ''}</strong></p>`;
                    lastCountry = geo?.country;
                  }
                  return `
                    ${countrySection}
                    <p style="font-weight: 500; font-size: 16px;margin:0px;">${geo?.region ? geo?.region :""}</p>
                    <p style="color: #707070; font-weight: 400 ;font-size: 16px;margin-block:12px">${geo?.states ? geo?.states?.join(", "):""}</p>
                  `;
                })
                ?.join("")}
            `);
          }
      
          if (target_industries?.length) {
            sections.push(`
              <p style="color: #FF7D34; font-weight: 700; font-size: 18px;margin-top:12px;margin-bottom:0px">Industry Group</p>
              ${target_industries
                ?.map(
                  (industry) => `
                  <p style="margin-top:12px"><strong style="font-weight: 500; font-size: 16px;">${industry?.target_industry}</strong></p>
                  <p style="color: #707070; font-weight: 400 ;font-size: 16px;margin-top:0px;margin-bottom:12px">${industry?.specific_target_industry ? industry?.specific_target_industry?.join(", ") :"" || ""}</p>
                `
                )
                ?.join("")}
            `);
          }
      
          if (target_revenue_range?.length) {
            sections.push(`
              <p style="color: #FF7D34; font-weight: 700; font-size: 18px;margin:0px">Company Criteria</p>
              ${target_revenue_range
                ?.map((revenue) => {
                  if (revenue?.revenue_min && revenue?.revenue_max) {
                    return `
                      <p style="color: #707070; font-weight: 400 ;font-size: 16px;margin-top:10px;margin-bottom:12px" >
                        $${formatRevenue(revenue.revenue_min)} to $${formatRevenue(revenue.revenue_max)}
                      </p>
                    `;
                  } else if (!revenue?.revenue_min && revenue?.revenue_max) {
                    return `
                      <p style="color: #707070; font-weight: 400 ;font-size: 16px;margin-top:10px;margin-bottom:12px">
                        Below $ ${formatRevenue(revenue.revenue_max)}
                      </p>
                    `;
                  } else if (revenue?.revenue_min && !revenue?.revenue_max) {
                    return `
                      <p style="color: #707070; font-weight: 400 ;font-size: 16px;margin-top:10px;margin-bottom:12px">
                        Above $ ${formatRevenue(revenue.revenue_min)}
                      </p>
                    `;
                  } else {
                    return `
                      <p style="color: #707070; font-weight: 400 ;font-size: 16px;margin-top:10px;margin-bottom:12px">
                        No revenue data available
                      </p>
                    `;
                  }
                })
                ?.join("")}
            `);
          }
          
      
          if (target_contact_criteria?.length) {
            sections.push(`
              <p style="color: #FF7D34; font-weight: 700; font-size: 18px;margin:0px">Contact Criteria</p>
              <p style="color: #707070; font-weight: 400 ;font-size: 16px;margin-top:10px;margin-bottom:12px">${target_contact_criteria?.join(", ")}</p>
            `);
          }
      
          // Join sections with hr only between them
          selectedProject.content = sections?.join('<hr style="border: 1px solid #E8E8E8;">');
        }
      }
      setProjectDataDetails(updatedProjectDetails?.projectDetailsData);
    } catch (err) {
    }
  }, [projectDetails]);

  const [value, setValue] = useState("1");

  useEffect(() => {
    const initialActiveTab =
      projectDataDetails?.find((tab) => tab?.label === tabTitle)?.value || "1";
    setValue(initialActiveTab); // Update state when projectDataDetails changes
  }, [projectDataDetails, tabTitle]); // Re-run when data or tabTitle changes

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Load tabs from localStorage when the component mounts
  useEffect(() => {
    const storedTabs = JSON.parse(localStorage.getItem("tabs"));
    if (storedTabs) {
      setProjectDataDetails(storedTabs);
    }

    if (salesPlanType === "message_plan") {
      setValue("1");
    }

    if (salesPlanType === "market_plan") {
      setValue("2");
    }

    if (salesPlanType === "media_plan") {
      setValue("3");
    }

    if (salesPlanType === "measure_plan") {
      setValue("4");
    }

    // eslint-disable-next-line
  }, []);

  const calculateHeight = () => {
    const isExpanded = localStorage.getItem("accordionState") === "expanded";
    const width = window.innerWidth;
    // const height = window.innerHeight;

    if (width >= 1920) {
      return isExpanded ? `calc(79vh - 283px)` : `calc(93.5vh - 180px)`;
    } else if (width >= 1680 && width < 1920) {
      return isExpanded ? `calc(83vh - 250px)` : `calc(93vh - 180px)`;
    } else if (width === 1440 && width < 1680) {
      return isExpanded ? `calc(73.75vh - 230px)` : `calc(93vh - 170px)`;
    } else if (width >= 1400) {
      return isExpanded ? `calc(76vh - 220px)` : `calc(91vh - 160px)`;
    } else if (width >= 1366) {
      return isExpanded ? `calc(69.2vh - 220px)` : `calc(92vh - 160px)`;
    } else {
      return isExpanded ? `calc(69.2vh - 220px)` : `calc(92vh - 160px)`; // Default for smaller screens
    }
  };

  const [contentHeight, setContentHeight] = useState(calculateHeight());

  // Function to update height dynamically when accordion state changes
  const updateContentHeight = () => {
    setContentHeight(calculateHeight());
  };

  // Run effect whenever accordion expands/collapses
  useEffect(() => {
    updateContentHeight(); // Update height on mount

    const interval = setInterval(() => {
      updateContentHeight(); // Continuously check for changes
    }, 500); // Runs every 500ms to detect changes

    return () => clearInterval(interval); // Cleanup

    //eslint-disable-next-line
  }, []);

  // console.log("projectDeatils", projectDataDetails);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        gap: "0px",
        // marginTop:'10px'
      }}
    >
      <Box
        sx={{
          marginBottom: "24px", // Gap between w and sd
        }}
      >
        <HorizontalBarChart />
      </Box>
      <Box
        sx={{
          height: "100%", // Subtract gap size
        }}
      >
        {/* Flexbox Layout for Inner Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            height: "100%",
          }}
        >
          {/* <SalePlan clientDetails={null} /> */}
          {/* <SalesPlanBox /> */}
          <TabContext value={value}>
            <CustomTablist
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              {projectDataDetails?.map((tab) => {
                const updatedStatus =
                  tab.status === "completed"
                    ? "completed"
                    : tab.label === tabTitle
                    ? "pending"
                    : "";

                return (
                  <CustomTab
                    // key={tab.value}
                    value={tab.value}
                    status={updatedStatus}
                    disabled={tab.disabled}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {updatedStatus === "completed" && (
                          <CheckCircleIcon
                            sx={{
                              color: value === tab.value ? "white" : "#009872",
                              fontSize: 16,
                            }}
                          />
                        )}
                        <span>{tab.label}</span>
                      </Box>
                    }
                  />
                );
              })}
            </CustomTablist>

            <Box
              sx={{
                borderStyle: "solid",
                borderWidth: "0 1px 1px 1px",
                borderRadius: "0px 0px 10px 10px",
                borderColor: "#DDE9FF",
                padding: value === "3" ? "10px 0" : "10px 20px",
                flexGrow: 1,
                maxHeight: contentHeight, // Adjusts dynamically based on screen height
                overflowY: "auto", // Enables vertical scrolling
                scrollbarWidth: "thin", // Ensures a thin scrollbar in Firefox
                "&::-webkit-scrollbar": {
                  width: "6px", // Thin scrollbar for Chrome/Safari
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#bbb", // Scrollbar color
                  borderRadius: "3px", // Rounded edges
                },
              }}
            >
              {projectDataDetails?.map((tab) => (
                <CustomTabPanel value={tab.value}>
                  {!tab.content ||
                  (typeof tab.content === "object" &&
                    Object.keys(tab.content).length === 0) ? (
                    <Box
                      sx={{
                        color: "#707070",
                        // color: "red",
                        fontSize: "14px",
                        fontWeight: 400,
                        paddingTop: "10px",
                        paddingLeft: value === "3" && "20px",
                      }}
                    >
                      {" "}
                      {`Waiting for the ${tab.label}`}
                    </Box>
                  ) : (
                    <Box>
                      {typeof tab.content === "object" ? (
                        <>
                          {tab.key === "media_plan" ? (
                            <MediaPlan
                              type={"sales_lead"}
                              communication_sequence={
                                tab?.content?.communication_sequence
                              }
                            />
                          ) : tab.key === "measure_plan" ? (
                            <MeasurePlan sdr_kpis={tab?.content?.sdr_kpis} />
                          ) : (
                            `Waiting for the ${tab.label}`
                          )}
                        </>
                      ) : tab.key === "market_plan" ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: tab?.content }}
                        />
                      ) : (
                        <Box
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            lineHeight: "160%",
                            fontWeight: 400,
                          }}
                        >
                          <ReactMarkdown>{tab.content}</ReactMarkdown>
                        </Box>
                      )}
                    </Box>
                  )}
                </CustomTabPanel>
              ))}
            </Box>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
};

export default YourSalesPlanLayout;
