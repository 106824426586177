import { Box, styled } from "@mui/material";

const HomePageOuterBox = styled(Box)({
  margin: "2rem",
  height: "80vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const HomePageInnerBox = styled(Box)({
  display: "flex",
  gap: "1.5rem",
  width: "100%",
  // height: "80vh",
  justifyContent: "center",
  alignItems: "center",
});

const DbReviewBox = styled(Box)({
  width: "50%",
  background: "#F3F1FB",
  padding: "1rem",
  borderRadius: "0.75rem",
});

const ActivitiesBox = styled(Box)({
  width: "50%",
  background: "#EBF6F0",
  padding: "1rem",
  borderRadius: "0.75rem",
});

export { HomePageOuterBox, HomePageInnerBox, DbReviewBox, ActivitiesBox };
