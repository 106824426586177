// src/components/EULA.js
import React from "react";

export const  SalesLeadEULA = () => {
  return (
    <>
    <div
      style={{
        color: "#009872",
        fontSize: "20px",
        fontWeight: 500,
        textAlign: "center",
      }}
    >
      End User License Agreement (EULA)
    </div>
  
    <p>
      This End User License Agreement ("Agreement") is a legal contract between you ("User" or "Customer") and Piqual, 
      with its principal place of business at Piqual ("Company"). By accessing or using Piqual AI (the "Platform"), 
      you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. 
      If you do not agree with any part of this Agreement, you must not use the Platform.
    </p>
  
    <div
      style={{
        color: "#009872",
        fontSize: "16px",
        fontWeight: 500,
      }}
    >
      1. Grant of License
    </div>
  
    <p>
      <strong>1.1.</strong> Subject to your compliance with the terms of this Agreement, the
      Company grants you a limited, non-exclusive, non-transferable, and
      revocable license to access and use Piqual AI solely for B2B lead
      generation, including but not limited to automating sales development
      representative (SDR) tasks, streamlining lead generation, outreach, and
      qualification processes.
    </p>
  
    <p>
      <strong>1.2.</strong> The license is restricted to your internal business purposes and
      may not be used for resale, redistribution, or any purpose beyond those
      explicitly permitted in this Agreement.
    </p>
  
    <div
      style={{
        color: "#009872",
        fontSize: "16px",
        fontWeight: 500,
      }}
    >
      2. Restrictions on Use
    </div>
  
    <p>2.1. You shall not:</p>
  
    <ul>
      <li>Reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code or underlying algorithms of the Platform.</li>
      <li>Rent, lease, sublicense, sell, or distribute access to the Platform.</li>
      <li>Use the Platform for any illegal or unauthorized purpose.</li>
      <li>Interfere with or disrupt the integrity or performance of the Platform.</li>
      <li>Share your access credentials or allow unauthorized users to access the Platform.</li>
    </ul>
  
    <p>
      <strong>2.2.</strong> Any violation of these restrictions will result in immediate termination of your license.
    </p>
  
    <div
      style={{
        color: "#009872",
        fontSize: "16px",
        fontWeight: 500,
      }}
    >
      3. Data Usage and Privacy
    </div>
  
    <p>
      <strong>3.1.</strong> The Platform may collect and process personal data and business
      information to provide its services. By using the Platform, you consent
      to the collection, use, and storage of data as described in the Company’s
      Privacy Policy.
    </p>
  
    <p>
      <strong>3.2.</strong> The Company will take reasonable measures to protect the
      confidentiality and security of data but shall not be liable for
      unauthorized access or breaches caused by third parties.
    </p>
  
    <div
      style={{
        color: "#009872",
        fontSize: "16px",
        fontWeight: 500,
      }}
    >
      4. Intellectual Property
    </div>
  
    <p>
      <strong>4.1.</strong> The Platform, including its features, functionalities, and
      underlying technology, is the intellectual property of the Company and
      is protected by copyright, trademark, and other applicable laws.
    </p>
  
    <p>
      <strong>4.2.</strong> All rights not expressly granted under this Agreement are reserved
      by the Company. You acquire no ownership or other rights in the Platform
      other than the limited license granted herein.
    </p>
  
    <p>
      For any questions or concerns regarding this Agreement, please contact
      <strong> [Insert Contact Information].</strong>
    </p>
  </>
  
  );
};



export const  SDRLeadEULA = () => {
    return (
        <>
        <div
          style={{
            color: "#009872",
            fontSize: "20px",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          End User License Agreement (EULA)
        </div>
      
        <p>
          This End User License Agreement ("Agreement") is a legal contract between you ("User" or "Customer") and Piqual, 
          with its principal place of business at Piqual ("Company"). By accessing or using Piqual AI (the "Platform"), 
          you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. 
          If you do not agree with any part of this Agreement, you must not use the Platform.
        </p>
      
        <div
          style={{
            color: "#009872",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          1. Grant of License
        </div>
      
        <p>
          <strong>1.1.</strong> Subject to your compliance with the terms of this Agreement, the
          Company grants you a limited, non-exclusive, non-transferable, and
          revocable license to access and use Piqual AI solely for B2B lead
          generation, including but not limited to automating sales development
          representative (SDR) tasks, streamlining lead generation, outreach, and
          qualification processes.
        </p>
      
        <p>
          <strong>1.2.</strong> The license is restricted to your internal business purposes and
          may not be used for resale, redistribution, or any purpose beyond those
          explicitly permitted in this Agreement.
        </p>
      
        <div
          style={{
            color: "#009872",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          2. Restrictions on Use
        </div>
      
        <p>2.1. You shall not:</p>
      
        <ul>
          <li>Reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code or underlying algorithms of the Platform.</li>
          <li>Rent, lease, sublicense, sell, or distribute access to the Platform.</li>
          <li>Use the Platform for any illegal or unauthorized purpose.</li>
          <li>Interfere with or disrupt the integrity or performance of the Platform.</li>
          <li>Share your access credentials or allow unauthorized users to access the Platform.</li>
        </ul>
      
        <p>
          <strong>2.2.</strong> Any violation of these restrictions will result in immediate termination of your license.
        </p>
      
        <div
          style={{
            color: "#009872",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          3. Data Usage and Privacy
        </div>
      
        <p>
          <strong>3.1.</strong> The Platform may collect and process personal data and business
          information to provide its services. By using the Platform, you consent
          to the collection, use, and storage of data as described in the Company’s
          Privacy Policy.
        </p>
      
        <p>
          <strong>3.2.</strong> The Company will take reasonable measures to protect the
          confidentiality and security of data but shall not be liable for
          unauthorized access or breaches caused by third parties.
        </p>
      
        <div
          style={{
            color: "#009872",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          4. Intellectual Property
        </div>
      
        <p>
          <strong>4.1.</strong> The Platform, including its features, functionalities, and
          underlying technology, is the intellectual property of the Company and
          is protected by copyright, trademark, and other applicable laws.
        </p>
      
        <p>
          <strong>4.2.</strong> All rights not expressly granted under this Agreement are reserved
          by the Company. You acquire no ownership or other rights in the Platform
          other than the limited license granted herein.
        </p>
      
        <p>
          For any questions or concerns regarding this Agreement, please contact
          <strong> [Insert Contact Information].</strong>
        </p>
      </>
      
    );
  };
