import { Outlet } from "react-router-dom";
import Sidebar from "../SideBar/Sidebar";
import { LayoutContainer, MainContent, HeaderContainer } from "./LayoutStyles";
import { useState } from "react";
import { AppBar, Box, Divider, IconButton } from "@mui/material";
import Header from "./Header";
import { blinkBot } from "../../Constants/Constant";

const MainLayoutData = () => {
  const [sidebarWidth, setSidebarWidth] = useState("45px");

  return (
    <LayoutContainer>
      <Sidebar sidebarWidth={sidebarWidth} setSidebarWidth={setSidebarWidth} />
      <Box sx={{width:"100%",display:'flex',flexDirection:"column",gap:"30px"}}>
       <MainContent sidebarWidth={sidebarWidth}>
        {/* <Header/> */}
        <AppBar
          sx={{
            boxShadow: "none",
            background: "none",
            color: "black",
          }}
          position="static"
        >
          <HeaderContainer>
            <Header />
          </HeaderContainer>
        </AppBar>
        <Divider sx={{ borderBottomWidth: "1px", borderColor: "#E8E8E8" }} />
        <Outlet context={{ sidebarWidth }} />
         
      </MainContent> 
    <Box sx={{position:'absolute',bottom:"21px",right:"25px"}}>
<IconButton sx={{p:0}}>
  <img src={blinkBot} alt="gif" style={{width:'60px',height:'60px'}}/>
</IconButton>
    </Box>
      </Box>
    
    </LayoutContainer>
  );
};

export default MainLayoutData;
