import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { Background, BuddyImg, TextImg } from "./LoginPageStyle";
import { PiqualAiBot, PiqualLogo, LoginFrameNew } from "../../Constants/Constant";

const SuccessPage = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          if (role === "piqai_sdr") {
            navigate("/piqual-ai/sdr-dashboard");
          } else {
            navigate("/piqual-ai/dashboard");
          }
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [role, navigate]);

  return (
    <Background>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "35px",
            bgcolor: "white",
            padding: "24px 16px 16px 16px",
            borderRadius: "0 0 8px 8px",
          }}
        >
          <img src={PiqualLogo} alt="" />
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            width: { xs: "30%", sm: "30%", md: "35%", lg: "35%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "60px",
            padding: "50px",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <TextImg src={LoginFrameNew} alt="" />
            <BuddyImg src={PiqualAiBot} alt="" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              fontFamily: "roboto",
            }}
          >
            <Box
              sx={{
                fontWeight: "500",
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                  md: "20px",
                  lg: "30px",
                  xl: "36px",
                },
              }}
            >
              Success
            </Box>
            <Box>Congratulations, Your account has been successfully created.</Box>
            <Box>You will be redirected to dashboard in {countdown} seconds.</Box>
          </Box>
        </Box>
      </Box>
    </Background>
  );
};

export default SuccessPage;