import { Box } from "@mui/system";
import React from "react";
import { ItemsOuterBox, OuterBox, SettingsTypography } from "./SettingsStyles";
import SettingsRoute from "./SettingsRoute";
import { Outlet } from "react-router-dom";

const Settings = () => {
  return (
    <Box sx={{ margin: "1.5625rem" }}>
      <SettingsTypography>Settings</SettingsTypography>

      <OuterBox>
        <ItemsOuterBox>
          <SettingsRoute />
        </ItemsOuterBox>
        <Box sx={{ width: "100%" }}>
          <Outlet />
        </Box>
      </OuterBox>
    </Box>
  );
};

export default Settings;
