import React, { useState } from "react";

import { Box } from "@mui/material";

import companyIcon from "../../../assets/companyIcon.svg";
import {
  StyledHeading,
  Description,
  CompanyNameField,
  StyledTextField,
} from "./ThemePageCss";

const CompanyName = () => {
  const [companyName, setCompanyName] = useState("Ignitho Technologies");

  const handleNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  return (
    <Box>
      <StyledHeading>Company Name</StyledHeading>
      <Description>The company name associated with this account</Description>
      <CompanyNameField>
        <img src={companyIcon} alt="" />
        <StyledTextField value={companyName} onChange={handleNameChange} />
      </CompanyNameField>
    </Box>
  );
};

export default CompanyName;