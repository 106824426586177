import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const AccountPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const AccountItems = [
    {
      label: "Your Details",
      path: "/piqual-ai/settings/account/details",
    },
    {
      label: "Security",
      path: "/piqual-ai/settings/account/security",
    },
    {
      label: "API Key",
      path: "/piqual-ai/settings/account/api-key",
    },
  ];
  return (
    <>
      <Box
        sx={{ display: "flex", gap: "2rem", borderBottom: "1px solid #E8E8E8" }}
      >
        {AccountItems.map((item) => (
          <Box sx={{}}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "1.25rem",
                fontWeight: 500,
                textTransform: "capitalize",
                cursor: "pointer",
                color: location.pathname.includes(item.path)
                  ? "#FF7D34"
                  : "#1A1A1C",
                borderBottom: location.pathname.includes(item.path)
                  ? "2px solid #FF7D34"
                  : "",
                paddingBottom: "4px",
              }}
              onClick={() => navigate(item.path)}
            >
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box>
        <Outlet />
      </Box>
    </>
  );
};

export default AccountPage;
