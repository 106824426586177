import {  Paper, Typography } from "@mui/material";

import {
  DbReviewBox,
  HomePageOuterBox,
  ActivitiesBox,
  HomePageInnerBox,
} from "./SDRHomePageCss";
import UpcomingActivities from "../OutReachDetails/HappyProspecting/UpcomingActivites";
import DatabaseReview from "./DatabaseReview";

const SDRHomePage = () => {
  return (
    <HomePageOuterBox>
      <HomePageInnerBox>
        <DbReviewBox>
          <Typography
            fontFamily="Roboto"
            fontSize="22px"
            fontWeight="700"
            mb="1rem"
          >
            Database Review Status
          </Typography>
          <Paper sx={{ padding: "1rem" }}>
            <DatabaseReview />
          </Paper>
        </DbReviewBox>

        <ActivitiesBox>
          <Typography
            fontFamily="Roboto"
            fontSize="22px"
            fontWeight="700"
            mb="1rem"
          >
            Upcoming Activities
          </Typography>
          <Paper sx={{ padding: "1rem" }}>
            <UpcomingActivities />
          </Paper>
        </ActivitiesBox>
      </HomePageInnerBox>

      {/* <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          disableFocusRipple
          disableRipple
          disableTouchRipple
          disabled
          variant="outlined"
          sx={{
            background: "#009872",
            color: "#FFFFFF",
            marginTop: "2rem",
            paddingX: "1.75rem",
            textTransform:'capitalize',
          }}
          onClick={() => console.log("Lead Tracker clicked")}
        >
          Lead Tracker
        </Button>
      </Box> */}
    </HomePageOuterBox>
  );
};

export default SDRHomePage;
