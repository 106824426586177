import { Box, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { callIcon, emailIcon, linkedInIcon } from "../../../Constants/Constant";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MediaPlan = ({ communication_sequence, type }) => {
  const theme = useTheme();
  const [marginTop, setMarginTop] = useState("1.5%");
  const location = useLocation();
  const isOutReach =
    location.pathname.includes("outreach") ||
    location.pathname.includes("view-plan");

  console.log(communication_sequence, "communication_sequence");

  useEffect(() => {
    const updateMarginTop = () => {
      const width = window.innerWidth;
      let newMarginTop;
      if (width < 800) {
        newMarginTop = "3%"; // Smaller screens
      } else if (width <= 1366) {
        newMarginTop =
          isOutReach && communication_sequence?.length >= 5 ? "0.5%" : "1.6%";
      } else {
        newMarginTop =
          isOutReach && communication_sequence?.length > 5
            ? "0.3%"
            : isOutReach
            ? "1.2%"
            : "1.5%";
      }
      setMarginTop(newMarginTop);
    };
    window.addEventListener("resize", updateMarginTop);
    updateMarginTop(); // Call initially
    return () => window.removeEventListener("resize", updateMarginTop);
  }, [isOutReach, communication_sequence]);

  return (
    <Box sx={{ height: "100%" }}>
      {communication_sequence === undefined ? (
        <Box
          sx={{
            color: "#707070",
            fontSize: "14px",
            fontWeight: 400,
            padding: "10px 20px",
          }}
        >
          Waiting for Orchestrate Wins
        </Box>
      ) : (
        <>
          {!isOutReach && (
            <Box sx={{ padding: "0 20px", color: "#707070" }}>
              {type === "sales_lead" &&
                `Here's the daily and the weekly target for each SDR.`}
            </Box>
          )}
          <Box
            sx={{
              border: isOutReach && "1px solid #E8E8E8",
              borderStyle: "solid",
              borderColor: " #E8E8E8",
              borderWidth: isOutReach
                ? "1px 1px 0px 1px"
                : "1px 0px 0px 0px" /* Top and Bottom only */,
              borderRadius: isOutReach && "8px",
              height: "90%",
              mt: 2,
              overflowX: "auto", // Prevent horizontal overflow
              fontSize: isOutReach ? "20px" : "14px",

              [theme.breakpoints.down("xl")]: {
                fontSize: "14px",
              },
            }}
          >
            <table
              className="communication-sequence-table"
              style={{
                position: "relative",
                width: "100%",
                borderCollapse: "collapse",
                fontFamily: "roboto",
              }}
            >
              <thead style={{ fontWeight: 500, color: "#000000" }}>
                <tr>
                  <th style={{ width: isOutReach ? "80px" : "60px" }}>Week</th>
                  <th>Day 1</th>
                  <th>Day 2</th>
                  <th>Day 3</th>
                  <th>Day 4</th>
                  <th>Day 5</th>
                </tr>
              </thead>
              <tbody>
                {communication_sequence?.map((seq, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        fontWeight: 500,
                        color: "#000000",
                        padding: isOutReach ? "10px" : "25px 20px",
                      }}
                    >
                      Week {seq.week_no}
                    </td>
                    <td style={{ padding: "30px" }}></td>
                    <td style={{ padding: "30px" }}></td>
                    <td style={{ padding: "30px" }}></td>
                    <td style={{ padding: "30px" }}></td>
                    <td style={{ padding: "30px" }}></td>
                    <div
                      style={{
                        position: "absolute",
                        left: isOutReach
                          ? "calc(10% + 2px)"
                          : "calc(10% + 35px)", // Adjust dynamically
                        marginTop: marginTop, // Dynamically set margin
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "10px",
                        width: isOutReach ? "85%" : "83%",
                      }}
                    >
                      {seq?.touchpoints?.map((tchpnt, idx) => {
                        return (
                          <Tooltip
                            title={tchpnt.activity}
                            placement="top"
                            key={idx}
                          >
                            <div
                              style={{
                                padding: "12px",
                                backgroundColor:
                                  tchpnt.channel === "email"
                                    ? "#00987214"
                                    : tchpnt.channel === "call"
                                    ? "#56BB6B14"
                                    : "#FF7D3414",
                                borderColor:
                                  tchpnt.channel === "email"
                                    ? "#009872"
                                    : tchpnt.channel === "call"
                                    ? "#56BB6B"
                                    : "#FF7D34",
                                color:
                                  tchpnt.channel === "email"
                                    ? "#009872"
                                    : tchpnt.channel === "call"
                                    ? "#56BB6B"
                                    : "#FF7D34",
                                borderWidth: "1px 1px 1px 4px",
                                borderRadius: "4px",
                                borderStyle: "solid",
                                flex: "1",
                                minWidth: "30px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={
                                  tchpnt.channel === "email"
                                    ? emailIcon
                                    : tchpnt.channel === "call"
                                    ? callIcon
                                    : linkedInIcon
                                }
                                alt=""
                                style={{ width: "16px", height: "16px" }}
                              />
                              <span
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {tchpnt.activity}
                              </span>
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MediaPlan;
