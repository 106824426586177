import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, LinearProgress, IconButton } from "@mui/material";
import {
  CloseButton,
  OuterBox,
  UploadAreaBox,
  UploadFilesInnerBox,
  UploadFilesOuterBox,
  UploadFileText,
  UploadText,
  UploadTextBox,
} from "./attachButtonCss";
import { BlobServiceClient } from "@azure/storage-blob";
import { jwtDecode } from "jwt-decode";

import UploadIcon from "../../../assets/UploadIcon.svg";
import CloseIcon from "../../../assets/CloseIcon.svg";
import DocIcon from "../../../assets/DocIcon.svg";

const AttachButton = ({ onClose, getFileUrl }) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(""); // Error message for validation

  const onDrop = async (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      setErrorMsg("Please upload only .doc or .docx files.");
      return;
    }

    setErrorMsg(""); // Clear previous errors if new files are valid

    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
      progress: 0,
    }));

    setFiles((prev) => [...prev, ...newFiles]);
    setUploading(true);

    await uploadFilesToBlob(newFiles);
  };

  const uploadFilesToBlob = async (filesToUpload) => {
    try {
      const uploadedUrls = [];
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const blobServiceClient = new BlobServiceClient(localStorage.getItem("sas_url"));
      const containerName = decodedToken.tenant_id;
      const containerClient = blobServiceClient.getContainerClient(containerName);

      for (const fileObj of filesToUpload) {
        const { file, name } = fileObj;
        const blobName = `${localStorage.getItem("project_id")}/${Date.now()}-${name.replace(/\s+/g, "")}`;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        const uploadResponse = await blockBlobClient.uploadData(file, {
          blobHTTPHeaders: { blobContentType: file.type || "application/octet-stream" },
        });

        if (uploadResponse._response.status === 201) {
          const fileUrl = blockBlobClient.url;
          uploadedUrls.push({ name, fileUrl });
          updateProgress(name, 100);
        } else {
          console.error("Upload failed for", name);
        }
      }

      getFileUrl(uploadedUrls);
    } catch (error) {
      console.error("Error uploading files:", error);
    }

    setUploading(false);
    setTimeout(() => onClose(), 5000);
  };

  const updateProgress = (fileName, progressValue) => {
    setFiles((prevFiles) =>
      prevFiles.map((f) =>
        f.name === fileName ? { ...f, progress: progressValue } : f
      )
    );
  };

  const removeFile = (fileName) => {
    setFiles((prev) => prev.filter((file) => file.name !== fileName));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    },
    maxSize: 10 * 1024 * 1024,
    multiple: false,
  });

  return (
    <OuterBox>
      <UploadTextBox>
        <Typography>Upload</Typography>
        <CloseButton disableRipple disableFocusRipple disableTouchRipple>
          <img src={CloseIcon} alt="close" onClick={onClose} />
        </CloseButton>
      </UploadTextBox>

      <UploadAreaBox {...getRootProps()}>
        <input {...getInputProps()} />
        <img
          src={UploadIcon}
          alt="upload icon"
          style={{
            backgroundColor: "#F2F2F2",
            height: "12%",
            width: "7%",
            padding: "7px",
          }}
        />
        <UploadText>
          Drag & drop files or{" "}
          <span style={{ color: "#009872" }}>browse files</span>
        </UploadText>
        <UploadFileText>DOC / DOCX Max file size 10MB</UploadFileText>
      </UploadAreaBox>

      {errorMsg && (
        <Typography sx={{ color: "red", paddingLeft: "10px", fontSize: "14px" }}>
          {errorMsg}
        </Typography>
      )}

      {uploading && (
        <Typography sx={{ color: "#141414", paddingLeft: "10px" }}>
          Uploading Files
        </Typography>
      )}

      <UploadFilesOuterBox>
        {files.map((file) => (
          <UploadFilesInnerBox key={file.name}>
            <img src={DocIcon} alt="doc icon" style={{ margin: "3px 8px 3px 5px" }} />
            <Typography
              sx={{
                width: "60%",
                flex: "0 10 auto",
                color: "black",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
                fontSize: "12px",
              }}
            >
              {file.name}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={file.progress}
              sx={{
                width: "40%",
                mr: 10,
                "& .MuiLinearProgress-bar": { backgroundColor: "#009872" },
                backgroundColor: "lightgrey",
                borderRadius: "15px",
              }}
            />
            <IconButton size="small" onClick={() => removeFile(file.name)}>
              <img src={CloseIcon} alt="close icon" fontSize="small" />
            </IconButton>
          </UploadFilesInnerBox>
        ))}
      </UploadFilesOuterBox>
    </OuterBox>
  );
};

export default AttachButton;
