import { SET_PROJECT_DETAILS } from "../Actions/projectDeatils";

const initialState = {
  projectDetailsData: null,
};

const setProjectDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetailsData: action.payload,
      };

    default:
      return state;
  }
};

export default setProjectDetailsReducer;
