import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Theme,
  ThemeActive,
  GroupIcon,
  GroupIconActive,
  Integration,
  IntegrationActive,
  CreditCard,
  CreditCardActive,
  DollarIcon,
  DollarIconActive,
  ReferralIcon,
  ReferralIconActive,
  Profile,
  ProfileActive,
} from "../../Constants/Constant";
import { ItemsInnerBox } from "./SettingsStyles";

const SettingsRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const settingsItems = [
    {
      label: "Account",
      path: "/piqual-ai/settings/account",
      icon: Profile,
      active: ProfileActive,
    },
    {
      label: "Theme",
      path: "/piqual-ai/settings/theme",
      icon: Theme,
      active: ThemeActive,
    },
    {
      label: "Members",
      path: "/piqual-ai/settings/members",
      icon: GroupIcon,
      active: GroupIconActive,
    },
    {
      label: "Integrations",
      path: "/piqual-ai/settings/integrations",
      icon: Integration,
      active: IntegrationActive,
    },
    {
      label: "Plan & Billing",
      path: "/piqual-ai/settings/plan-and-billing",
      icon: CreditCard,
      active: CreditCardActive,
    },
    {
      label: "Credit Usage",
      path: "/piqual-ai/settings/credit-usage",
      icon: DollarIcon,
      active: DollarIconActive,
    },
    {
      label: "Referrals",
      path: "/piqual-ai/settings/referrals",
      icon: ReferralIcon,
      active: ReferralIconActive,
    },
  ];

  return (
    <>
      {settingsItems.map((item) => (
        <ItemsInnerBox
          key={item.path}
          path={item.path}
          location={location.pathname}
          onClick={() => navigate(item.path)}
        >
          <Box
            component="img"
            src={
              location.pathname.includes(item.path) ? item.active : item.icon
            }
            alt={item.label}
            sx={{ width: 24, height: 24 }}
          />
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: location.pathname.includes(item.path) ? 600 : 400,
              fontSize: "1rem",
            }}
          >
            {item.label}
          </Typography>
        </ItemsInnerBox>
      ))}
    </>
  );
};

export default SettingsRoute;
