import { Box, IconButton, styled } from "@mui/material";

const SidebarContainer = styled(({ sidebarWidth, ...other }) => (
  <Box {...other} />
))(({ theme, sidebarWidth }) => ({
  backgroundColor: "#069A71",
  width: sidebarWidth,
  position: "fixed",
  transition: "width 0.3s ease",
  display: "none",
  fontWeight: 500,
  fontSize: "14px",
  color: "#FFFFFF",

  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

const XsSidebar = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  width: "50px",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const ArrowIcon = styled(({ isExpanded, ...other }) => (
  <IconButton {...other} />
))(({ isExpanded }) => ({
  position: "absolute",
  left: isExpanded ? "92.5%" : "75%",
  top: "50%",
  border: "1px solid #00bd71",
  backgroundColor: "white",
  color: "#1bc786",
  transition: "transform 0.3s ease",
  padding: "0px",
  margin: "0px",

  "&:hover": {
    backgroundColor: "#00bd71",
    color: "white",
    transform: "scale(1.5)", // Zoom in on hover
  },
}));

const SideMenuContainer = styled(({ isVisible, ...other }) => (
  <Box {...other} />
))(({ isVisible }) => ({
  opacity: isVisible ? 1 : 0, // Opacity transition for smooth text change
  transition: "opacity 0.3s ease", // Smooth opacity transition
  height: "100vh",
  position: "relative",
}));

const ProfileName = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: "40px",
  marginInline: "7px",
  gap: "10px",
  fontSize: "13px",
  color: "#0D162F",
  cursor: "pointer",
});

const MenuItems = styled(Box)(({ isExpanded }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: isExpanded ? "92%" : "93.5%",
  paddingTop: "8px",
}));

const MainMenu = styled(Box)(({ isExpanded }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: "5px",
  height: isExpanded ? "36px" : "auto",
  padding:isExpanded ?"0px 10px" :"0px"
}));

const ItemsList = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",
});
const SettingsItemsList = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  marginBottom:"1rem"
});

const SettingsItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginInline: "-5px",
  // padding:"0px 12px 12px 12px"
});

const ImgContainer = styled("img")(({ isExpanded, theme }) => ({
  width: isExpanded ?"1.5rem" :"2rem",
  height: isExpanded ?"1.5rem" :" 1.75rem",
  textAlign: "center",
  marginLeft: isExpanded ? "" : "0.25rem",
}));

const PiqAiImg = styled("img")(({ isExpanded, theme }) => ({
  width: "1.5rem",
  height: "1.5rem",
  textAlign: "center",
  [theme.breakpoints.up("xl")]: {
    width: "1.5rem",
    height: "1.5rem",
  },
}));

const ItemName = styled("span")({
  fontSize: "0.875rem",
  fontFamily: "Roboto",
  cursor: "pointer",
});

const LogoIcon = styled(IconButton)({
  backgroundColor: "white",
  borderRadius: "5px",

  "&:hover": {
    backgroundColor: "white",
  },
});

const FullName = styled("span")({
  whiteSpace: "normal",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  maxWidth: "100%",
});

export {
  SidebarContainer,
  ArrowIcon,
  SideMenuContainer,
  ProfileName,
  MenuItems,
  MainMenu,
  ItemsList,
  SettingsItemsList,
  SettingsItem,
  ImgContainer,
  PiqAiImg,
  ItemName,
  LogoIcon,
  XsSidebar,
  FullName,
};
