import { InputBase, IconButton, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

function SearchBar() {
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const search =(e)=>{
    e.preventDefault()
    setValue('')
  }

  return (
    <Paper
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: 300,
        border: "1px solid #D3D3D3",
        borderRadius: "12px",
        boxShadow: "none",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, fontFamily: "Roboto", fontSize: "16px" }}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        value={value || ''}
        onChange={(e) => handleChange(e)}
      />
      <IconButton onClick={(e)=>search(e)} type="submit" aria-label="search">
        <SearchIcon sx={{ color: "#0ABF8E" }} />
      </IconButton>
    </Paper>
  );
}

export default SearchBar;
