
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { API } from "../../../global";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { BeatLoader } from "react-spinners";

const columns = [
  { id: "name", label: "Contact Name", minWidth: 150 },
  { id: "email_status", label: "Email Status", minWidth: 100 },
  {
    id: "linkedin_status",
    label: "LinkedIn Status",
    minWidth: 150,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "call_status",
    label: "Call Status",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];



function formatString(str) {
  return str
    .split("_") // Split by underscore
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join with space
}


export default function ContactProgressPlan() {
  const [rows,setRows] = useState([])
  const [loading,setLoading] = useState(false)
  
  useEffect(() => {
    getTaskDetails();
     // eslint-disable-next-line 
  }, []);

  const getTaskDetails = () => {
    setLoading(true)
    fetch(`${API}/project/prospect-contacts-plan-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
         setLoading(false)

        if (res.success) {
          let taskList = [];
           // eslint-disable-next-line 

           let emailStatus = '';
          // eslint-disable-next-line
          res.result.map((data) => {
            if(data.email === 'email_template_intro_day_1'){
              emailStatus = 'Initial Sent';
            }else{
              emailStatus = data.email;
            }
           const obj={
            name : data.name,
            email_status:data.email ? formatString(emailStatus) : "Not Attempted",
            linkedin_status : data.linkedin ?  formatString(data.linkedin) :  "Not Attempted",
            call_status : data.call ? formatString(data.call) : "Not Attempted"
           }
           taskList.push(obj)
          });
          setRows(taskList)
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  
  return (
    <Paper sx={{ width: "100%", overflow: "auto" }}>
      {loading ? (
        <Box
          sx={{
            maxHeight: 290,
            minHeight: "34.3vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BeatLoader color="#9884D3" />
        </Box>
      ) : (
      <TableContainer sx={{ maxHeight: 290, minHeight: "34.2vh" }}>
        {rows.length !== 0  ? (
           <Table
           stickyHeader
           aria-label="sticky table"
           sx={{ border: "1px solid #E0E0E0" }}
         >
           <TableHead>
             <TableRow>
               {columns.map((column) => (
                 <TableCell
                   sx={{ backgroundColor: "#F3F1FC" }}
                   key={column.id}
                   align={column.align}
                   style={{ minWidth: column.minWidth }}
                 >
                   {column.label}
                 </TableCell>
               ))}
             </TableRow>
           </TableHead>
           <TableBody>
             {rows.map((row) => {
               return (
                 <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                   {columns.map((column) => {
                     const value = row[column.id];
                     return (
                       <TableCell
                         key={column.id}
                         align={column.align}
                         sx={{
                           color: "#6A6A6B",
                           fontFamily: "Roboto",
                           fontWeight: 400,
                           fontSize: "1rem",
                           padding: "0.91rem",
                         }}
                       >
                         {column.format && typeof value === "number"
                           ? column.format(value)
                           : value}
                       </TableCell>
                     );
                   })}
                 </TableRow>
               );
             })}
           </TableBody>
         </Table>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography>No Contact Plan Progress</Typography>
          </Box>
        )}
       
      </TableContainer>
      )}
    </Paper>
  );
}
