import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FadeLoader } from "react-spinners";

const AnalyzingBox = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        // justifyContent: "",
        marginTop: "auto", // Push to bottom
      }}
    >
      <div style={{ transform: "scale(0.5)" }}>
        <FadeLoader color="#36d7b7" />
      </div>
      <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
        Analyzing...
      </Typography>
    </Box>
  );
};

export default AnalyzingBox;
