import { Box, Button, List, ListItem, styled, Typography } from "@mui/material";


const OuterBox = styled(Box)({
  width: "100%",
  overflowX: "hidden",
});

const GreetingsBox = styled(Typography)(({ theme }) => ({
  color: "#ff7d34",
  marginBottom: "0.3125rem",
  paddingBottom: "0.125rem",
  fontWeight: 500,
  marginLeft: "1.25rem",
  marginTop: "1.25rem",
  fontFamily: "Roboto",
  fontSize: "1.75rem",

  "@media (resolution: 2dppx)": {
    marginBottom: "0rem !important",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.125rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "1.125rem",
  },
}));

const GreetingsBox2 = styled(Typography)(({ theme }) => ({
  width: "72%",
  paddingBlock: "0.3125rem",
  marginBlock: "0.3125rem",
  fontWeight: 400,
  marginLeft: "1.25rem",
  fontFamily: "Roboto",
  fontSize: "1.25rem",
  color: "#6A6A6B",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9375rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "0.875rem",
  },
}));

const HeadingBox = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  paddingBlock: "0.1875rem",
  marginLeft: "1.25rem",
  fontFamily: "Roboto",
  fontSize: "1.75rem",

  "@media (resolution: 2dppx)": {
    fontSize: "1.5rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "1.5rem",
  },
}));

const SlidesBox = styled(Box)(({ theme }) => ({
  width: "98.5%",
  marginLeft: "1.25rem",
  overflowX: "hidden",
}));

const SlidesContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "60%",
  height: "28vh",
  margin: "auto",
  marginTop: "5rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "@media (resolution: 120dpi)": {
    marginTop: "2rem",
    height: "35vh",
  },

  "@media (resolution: 144dpi)": {
    marginTop: "1rem",
  },

  [theme.breakpoints.down(1400)]: {
    marginTop: "1rem",
    width: "70%",
    height: "36vh",
  },
}));

const Slides = styled(Box)(
  ({ theme, isHidden, isActive, isLeft, isRight}) => ({
    position: "absolute",
    width: isActive ? "100%" : "85%",
    height: "100%",
    bgcolor: "#FBFBFB",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "1.25rem",
    fontFamily: "Roboto",
    textWrap: "wrap",
    borderRadius: "10px",
    border: "1px solid #E8E8E8",
    backgroundColor:"#FBFBFB",
    transition: "all 0.5s ease-in-out",
    opacity: isHidden ? 0.1 : isActive ? 1 : 0.25,
    transform: isActive
      ? "translateX(0) scale(1)"
      : isLeft
      ? "translateX(-110%) scale(0.9)"
      : isRight
      ? "translateX(110%) scale(0.9)"
      : "translateX(0) scale(0)",

    "@media (resolution: 120dpi)": {
      padding: "10px 0 10px 0px",
     
    },

    "@media (resolution: 144dpi)": {
      padding: "5px 0 15px 0px",
    
    },

    [theme.breakpoints.down(1400)]: {
   fontSize:"16px",
    },
  }),


);

const SlideHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  paddingTop: "0.5rem",
  marginLeft: "1.25rem",
  fontFamily: "Roboto",
  fontSize: "1.75rem",
  color: "#FF7D34",

  "@media (resolution: 144dpi)": {
    paddingTop: "2rem",
  },

  "@media (resolution: 2dppx)": {
    fontSize: "1.5rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "1.5rem",
  },
}));

const DividerLine = styled("hr")(({ theme }) => ({
  width: "97%",
  border: "none",
  borderTop: "1px solid #E8E8E8",
}));

const ContentList = styled(List)(({ theme }) => ({
  textAlign: "left",
}));

const ContentListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: "5px",
}));

const NextButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginInline: "auto",
  display: "block",
  padding: `${theme.spacing(0.3)} ${theme.spacing(10)}`,
  backgroundColor: "#009872",
  color: "white",
  fontWeight: 500,
  fontSize: "1.25rem",
  fontFamily: "Roboto",
  textTransform: "capitalize",
  "&:hover": { backgroundColor: "#00795a" },
  "@media (resolution: 120dpi)": {
    marginTop: "2rem",
  },
  "@media (resolution: 144dpi)": {
    marginTop: "2rem",
  },

  [theme.breakpoints.down(1400)]: {
    marginTop: "1.75rem",
  },
}));

export {
  OuterBox,
  GreetingsBox,
  GreetingsBox2,
  HeadingBox,
  SlidesBox,
  SlidesContainer,
  Slides,
  SlideHeading,
  DividerLine,
  ContentList,
  ContentListItem,
  NextButton,
};
