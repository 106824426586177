// src/AuthContext.js
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { jwtDecode } from "jwt-decode";

import PropTypes from "prop-types";
import { useToast } from "../utils/ToastContext";



function isTokenExpired(token) {
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000; // current time in seconds
  return decodedToken.exp < currentTime;
}

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { showToast } = useToast();

  useEffect(() => {
    if (token) {
      const checkTokenExpiration = () => {
        if (token && isTokenExpired(token)) {
          showToast("error","Session expired. Please log in again")
          setToken(null);
          localStorage.removeItem("token");  
        }
      };

      checkTokenExpiration();
      const interval = setInterval(checkTokenExpiration, 60000); // check every 60 seconds

      return () => clearInterval(interval);
    }
  }, [token,showToast]);

  const login = (newToken) => {
    localStorage.setItem("token", newToken);
    setToken(newToken);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  const isAuthenticated = !!token;

  // Memoize the context value
  const value = useMemo(
    () => ({
      token,
      login,
      logout,
      isAuthenticated,
    }),
    [token, isAuthenticated] // Depend on token and isAuthenticated
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensures children is a valid React node
};

AuthContext.propTypes = {
  token: PropTypes.string,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export const useAuth = () => useContext(AuthContext);
