import { Box, Button, styled } from "@mui/material";

const DropdownContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  minWidth: "100px"
}));

const DropdownButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: "8.5px 16px",
  fontSize: "14px",
  color: "#252527",
  background: "white",
  border: "1px solid #D1D1D2",
  borderRadius: "4px",
  display: "flex",
  gap:"6px",
  alignItems: "center",
  cursor: "pointer",
  textTransform: "none"
}));

const DropdownList = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "100%",
  left: 0,
  width: "100%",
  background: "white",
  border: "1px solid #D1D1D2",
  borderRadius: "4px",
  listStyle: "none",
  margin: "5px 0",
  padding: 0,
  zIndex: 1000,
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
}));

const DropdownItem = styled("li")(({ theme }) => ({
  padding: "10px",
  fontSize: "14px",
  cursor: "pointer",
  color: "#6A6A6B",

  "&:hover": {
    background: "#f5f5f5",
  },
}));

export {
  DropdownContainer,
  DropdownButton,
  DropdownItem,
  DropdownList
}