import React from "react";

const IntegrationsPage = () => {
  return (
    <div>
      IntegrationsPage
      <h1>IntegrationsPage loads here</h1>
    </div>
  );
};

export default IntegrationsPage;
