import { Box, IconButton, styled, Typography, TableCell } from "@mui/material";

const OutReachBox = styled(Box)(({ isActive, isAligned }) => ({
  flex: 1,
  border: isActive ? "2px solid #ff7d34" : isAligned ? "2px solid #009872" : "2px solid #E8E8E8",
  boxShadow: isActive ? "0px 0px 0.25rem 0px #FF7D34" : "none",
  borderRadius: "4px",
  padding: "20px 16px",
  display: "flex",
  gap: "10px",
  alignItems: "center",
  "@media (max-width: 1366px)": {
    padding: "16px 12px",
  },
  cursor: "pointer",
  fontFamily: "Roboto"
}));

const OutReachBoxIconButton = styled(IconButton)(({ isActive, isAligned }) => ({
  padding: "20px",
  backgroundColor: isActive ? "#FFF5ED" : isAligned ? "#EBFEF6" : "#F9F9F9",
  borderRadius: "50%",
  height: "64px",
  width: "64px",
  "@media (max-width: 1366px)": {
    width: "50px",
    height: "50px", // Applies when screen width is 1366px or below
    padding: "10px 10px",
  },
}));

const OutReachTitleBox = styled(Box)(({isActive})=>({
  fontSize: "20px", // Default for screen width above 1366px
  color: isActive ?"#1A1A1C":"#6A6A6B",
  fontWeight: 500,
  "@media (max-width: 1366px)": {
    fontSize: "16px", // Applies when screen width is 1366px or below
  },
}));

const OutReachDescBox = styled(Box)({
  fontSize: "20px", // Default for screen width above 1366px
  color: "#6A6A6B",
  "@media (max-width: 1366px)": {
    fontSize: "14px", // Applies when screen width is 1366px or below
  },
});

const OutReachDetailsBox = styled(Box)({
  border: "2px solid #FF7D34",
  boxShadow: "0px 0px 0.4rem 0rem #FF7D34",
  height: "75%",
  overflowY: "auto",
  scrollbarWidth: "thin", // Ensures a thin scrollbar in Firefox
  "&::-webkit-scrollbar": {
    width: "6px", // Thin scrollbar for Chrome/Safari
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#bbb", // Scrollbar color
    borderRadius: "3px", // Rounded edges
  },
  borderRadius: "4px",
  padding: "20px",
  "@media (max-width: 1366px)": {
    height: "60%", // Applies when screen width is 1366px or below
    padding: "16px",
  },
});

const OutReachBtn = styled(Box)({
  display: "flex",
  gap: "10px",
  justifyContent: "center",
});

const StyledAccordionTitle = styled(Typography)(({ theme }) => ({
  color: "#FF7D34",
  fontSize: "24px",
  fontWeight: 700,
  fontFamily: "Roboto",
  cursor: "pointer",

  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  bgcolor: "#ffffff",
  padding: "11.5px",
  color: "#6A6A6B",
  fontSize:"20px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
}));

const HeaderTableCell = styled(StyledTableCell)(({ theme }) => ({
  fontWeight: 500,
  backgroundColor: "#FFF5ED",
  color: "#212529",
  fontSize:"20px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
}));

const StyledTextArea = styled("textarea")(({ theme }) => ({
  padding: "8px 12px",
  border: "1px solid #D1D1D2",
  borderRadius: "4px",
  width: "100%",
  fontFamily: "Roboto",
  boxSizing: "border-box",
  fontWeight: 400,
  color: "#1A1A1C",
  fontSize: "20px",
  resize: "none",
  outline: "none",
  overflowY: "auto",
  scrollbarWidth: "thin",
  backfaceVisibility: "hidden",
  transform: "translate3d(0, 0, 0)",
  willChange: "transform",
  fontSmooth: "always", // Non-standard, safe to add
  WebkitFontSmoothing: "antialiased", // Smooth in Chrome/Safari
  MozOsxFontSmoothing: "grayscale", // Smooth in Firefox on Mac
  
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#bbb",
    borderRadius: "3px",
  },
  "&::placeholder": {
    fontSize: "20px",
    color: "#A3A3A4",
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
  },

}));



const ViewPlanText = styled(Typography)(({theme})=>({
fontSize:"20px",
[theme.breakpoints.down("xl")]: {
  fontSize: "16px",
},
}));



export {
  OutReachBox,
  OutReachBoxIconButton,
  OutReachTitleBox,
  OutReachDescBox,
  OutReachDetailsBox,
  OutReachBtn,
  StyledAccordionTitle,
  StyledTableCell,
  HeaderTableCell,
  StyledTextArea,
  ViewPlanText
};
