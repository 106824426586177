import React, { useState } from "react";
import { Slider } from "@mui/material";

const ChatSlider = ({countAdded}) => {
  const [value, setValue] = useState(0);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    countAdded(newValue)
  };


  return (
  
      <Slider
        value={value}
        onChange={handleSliderChange}
        min={0}
        max={100}
        step={1}
        sx={{
          color: "#FF7D34",
          width: "90%",
          marginLeft: "20px",
          // marginBottom: "21px"
        }}
      />
   
  );
};

export default ChatSlider;