import React, { useState, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";

import { Background, BuddyImg, TextImg, StyleLogo } from "./LoginPageStyle";
import { StyledButton, StyledTextField } from "./RegisterPageStyle";
import styles from "./LoginVariation.module.css";
import { siteKey } from "../../global";
import { useToast } from "../../utils/ToastContext";
import { setSdrUserData } from "../../Redux/Actions/sdrRegisterAction";
import {
  visibilityOff,
  PiqualAiBot,
  PiqualLogo,
  VisibilityIcon,
  LoginFrameNew,
} from "../../Constants/Constant";

const RegisterPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { showToast } = useToast();
  const { token } = useParams();

  const schema = yup.object({
    firstName: yup
      .string()
      .required("First name is a required field.")
      .matches(/^[A-Za-z]+$/, "First name must contain only alphabets."),
    lastName: yup
      .string()
      .required("Last name is a required field.")
      .matches(/^[A-Za-z]+$/, "Last name must contain only alphabets."),
    password: yup
      .string()
      .required("Password is a required field.")
      .min(8, "Password must be at least 8 characters.")
      .max(20, "Password must be at most 20 characters.")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
      .matches(/\d/, "Password must contain at least one numeric digit.")
      .matches(
        /[.#@!%^&*?_()\-+=]/,
        "Password must contain at least one special character (., @, #, !, %, &, *, ?, ^, (, ), _, -, =, +)."
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match.")
      .required("Password is a required field."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onsubmit" });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const Obj = {
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        confirmPassword: data.confirmPassword
      };
      dispatch(setSdrUserData(Obj))

      navigate(`/end-user-agreement/${token}`)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast("error", "Error occurred. Please try again.");
    }
  };

  return (
    <Background>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "35px",
            bgcolor: "white",
            padding: "24px 16px 16px 16px",
            borderRadius: "0 0 8px 8px",

            "@media (min-resolution: 1.25dppx)": {
              padding: "20px 10px 10px 10px",
            },
          }}
        >
          <StyleLogo src={PiqualLogo} alt="" />
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            width: { xs: "30%", sm: "30%", md: "35%", lg: "35%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: {
              xs: "30px 50px",
              sm: "30px 50px",
              md: "20px 50px",
              lg: "20px 50px",
              xl: "50px",
            },
            borderRadius: "8px",

            "@media (min-resolution: 1.5dppx)": {
              height: "400px",
              gap: "10px",
            }
          }}
        >
          <Box sx={{ position: "relative", marginBottom: "40px" }}>
            <TextImg src={LoginFrameNew} alt="" />
            <BuddyImg src={PiqualAiBot} alt="" />
          </Box>

          <Box
            sx={{
              fontFamily: "Roboto",
              fontWeight: "500",
              marginBottom: "24px",
              fontSize: {
                xs: "20px",
                sm: "20px",
                md: "20px",
                lg: "30px",
                xl: "36px",
              },
            }}
          >
            Registration
          </Box>

          <form
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <StyledTextField
              placeholder="Enter First Name"
              variant="outlined"
              {...register("firstName")}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              sx={{
                width: "100%",
                "& .Mui-focused fieldset": {
                  borderColor: errors.email ? "#FF0000 !important" : "#009872 !important",
                },
              }}
            />

            {/* Last Name Field */}
            <StyledTextField
              placeholder="Enter Last Name"
              variant="outlined"
              {...register("lastName")}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              sx={{
                width: "100%",
                "& .Mui-focused fieldset": {
                  borderColor: errors.email ? "#FF0000 !important" : "#009872 !important",
                },
              }}
            />

            {/* Password Field */}
            <StyledTextField
              placeholder="Enter Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              {...register("password")}
              error={errors.password}
              helperText={errors.password?.message}
              slotProps={{
                input: {
                  endAdornment: (
                    <Box
                      component="img"
                      alt=""
                      src={showPassword ? VisibilityIcon : visibilityOff}
                      onClick={togglePasswordVisibility}
                      sx={{
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                        "@media (min-resolution: 1.25dppx)": {
                          width: "18px",
                          height: "18px",
                        },
                        "@media (min-resolution: 1.5dppx)": {
                          width: "15px",
                          height: "15px",
                        },
                      }}
                    />
                  ),
                },
              }}
              sx={{
                width: "100%",
                "& .Mui-focused fieldset": {
                  borderColor: errors.password ? "#FF0000 !important" : "#009872 !important",
                },
              }}
            />

            {/* Confirm Password Field */}
            <StyledTextField
              placeholder="Confirm Password"
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              {...register("confirmPassword")}
              error={errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              slotProps={{
                input: {
                  endAdornment: (
                    <Box
                      component="img"
                      alt=""
                      src={showConfirmPassword ? VisibilityIcon : visibilityOff}
                      onClick={toggleConfirmPasswordVisibility}
                      sx={{
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                        "@media (min-resolution: 1.25dppx)": {
                          width: "18px",
                          height: "18px",
                        },
                        "@media (min-resolution: 1.5dppx)": {
                          width: "15px",
                          height: "15px",
                        },
                      }}
                    />
                  ),
                },
              }}
              sx={{
                width: "100%",
                "& .Mui-focused fieldset": {
                  borderColor: errors.confirmPassword ? "#FF0000 !important" : "#009872 !important",
                },
              }}
            />

            {/* Submit Button */}
            <StyledButton
              className={styles.button}
              disableElevation
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{               
                width: 440,
              }}
            >
              {loading ? "Loading..." : "Register"}
            </StyledButton>

            <ReCAPTCHA
              sitekey={`${siteKey}`}
              size="invisible"
              ref={recaptchaRef}
            />
          </form>
        </Box>
      </Box>
    </Background>
  );
};

export default RegisterPage;