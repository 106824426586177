import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Background, BuddyImg, TextImg, StyleLogo } from "./LoginPageStyle";
import { Box, Button, TextField, Typography } from "@mui/material";
import styles from "./LoginVariation.module.css";
import { API, siteKey } from "../../global";
import { useToast } from "../../utils/ToastContext";
import ReCAPTCHA from "react-google-recaptcha";

import {
  visibilityOff,
  PiqualAiBot,
  PiqualLogo,
  VisibilityIcon,
  LoginFrameNew,
} from "../../Constants/Constant";
import { useAuth } from "../../AuthContext/AuthContext";
import { setAuthData } from "../../Constants/Funtions/commonFunctions";
import { setUserId } from "../../analytics";

const LoginPage = () => {
  const recaptchaRef = useRef(null);
  const { login } = useAuth()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { showToast } = useToast();
  const isLocalhost = window.location.hostname === "localhost";

  const schema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is a required field.")
      .matches(/^[^\s@]+@[^\s@]+\.(com|ai)$/, "Please enter a valid email."),
    password: yup.string().required("Password is a required field."),
  });
  

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onsubmit" });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const tokens = await recaptchaRef.current.executeAsync();
      const Obj = {
        email: data.email.toLowerCase(),
        password: data.password,
        captcha_token: tokens,
      };

      const response = await fetch(`${API}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Obj),
      });

      const result = await response.json();

      setLoading(false);

      if (result?.success) {
        const userData = result.result;
        setAuthData(userData);
        setUserId(userData.user_id);

        
        login(userData.token);

        const decodedToken = jwtDecode(userData.token);
        const expiryTime = new Date(decodedToken.exp * 1000);

        Cookies.set("token", userData.token, {
          expires: expiryTime,
          secure: !isLocalhost,
          sameSite: isLocalhost ? "Lax" : "Strict",
        });

        if (!userData.active_project_id) {
        navigate("/error");
        } else {
          if (!userData.active_project_id) {
            navigate("/error");
          } else {
            if (userData.active_role_key === "piqai_sdr") {
              if (!userData.show_welcome_slider) {
                navigate("/piqual-ai/sdr-dashboard");
              } else {
                navigate("/piqual-ai/onboarding");
              }
            } else if(userData.active_role_key === "piqai_sales_lead") {
              if (!userData.show_welcome_slider) {
                navigate("/piqual-ai/dashboard");
              } else {
                navigate("/piqual-ai/onboarding");
              }
            }
            else if(userData.active_role_key === "piqai_admin") {
                navigate("/piqual-ai/audit-log");
             
            }
          }
        }
      } else {
        showToast(
          "error",
          result.error?.msg || "Login failed. Please try again."
        );
      }
    } catch (err) {
      setLoading(false);
      showToast("error", "Error occurred. Please try again.");
    }
  };

  return (
    <Background>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          // padding: "0px 38px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "35px",
            bgcolor: "white",
            padding: "24px 16px 16px 16px",
            borderRadius: "0 0 8px 8px",

            "@media (min-resolution: 1.25dppx)": {
              padding: "20px 10px 10px 10px",
            },
          }}
        >
          <StyleLogo src={PiqualLogo} alt="" />
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            // width: "35%",
            width: { xs: "30%", sm: "30%", md: "35%", lg: "35%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "70px",
            padding: "50px",
            borderRadius: "12px",

            "@media (min-resolution: 1.5dppx)": {
              // 150% scale
              height: "400px",
              gap: "10px",
            },
          }}
        >
          <Box sx={{ position: "relative" }}>
            <TextImg src={LoginFrameNew} alt="" />
            <BuddyImg src={PiqualAiBot} alt="" />
          </Box>

          <Box
            sx={{
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: {
                xs: "20px",
                sm: "20px",
                md: "20px",
                lg: "30px",
                xl: "36px",
              },
            }}
          >
            Let’s Get Started
          </Box>

          <form
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Email Field */}
            <TextField
              placeholder="Enter Email Address"
              variant="outlined"
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
              slotProps={{
                formHelperText: {
                  sx: { marginLeft: "0px" },
                },
                input: {
                  sx: {
                    height: "48px",
                    paddingRight: "12px",
                    borderRadius: "8px",
                    fontSize: "20px",
                    color: "#1A1A1C",
                    fontFamily: "Roboto",
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: "0 0 0px 1000px white inset !important",
                      backgroundColor: "transparent !important",
                      color: "#1A1A1C !important",
                    },
                    "& input::placeholder": {
                      fontSize: "20px",
                    },
                    "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus":
                      {
                        WebkitBoxShadow:
                          "0 0 0px 1000px white inset !important",
                        backgroundColor: "transparent !important",
                        color: "#1A1A1C !important",
                      },
                  },
                },
              }}
              sx={{
                width: "100%",
                "& fieldset": { borderColor: "#d1d1d2" },
                "&:hover fieldset": { borderColor: "#009872" },
                "& .Mui-focused fieldset": {
                  borderColor: errors.email
                    ? "#FF0000 !important"
                    : "#009872 !important",
                },
              }}
            />

            {/* Password Field */}
            <TextField
              placeholder="Enter Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              {...register("password")}
              error={errors.password}
              helperText={errors.password?.message}
              slotProps={{
                formHelperText: {
                  sx: { marginLeft: "0px" },
                },
                input: {
                  sx: {
                    height: "48px",
                    paddingRight: "12px",
                    borderRadius: "8px",
                    fontSize: "20px",
                    color: "#1A1A1C",
                    fontFamily: "Roboto",
                  },
                  endAdornment: (
                    <img
                      alt=""
                      width="20px"
                      height="20px"
                      src={showPassword ? VisibilityIcon : visibilityOff}
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  ),
                },
              }}
              sx={{
                width: "100%",
                "& fieldset": { borderColor: "#d1d1d2" },
                "&:hover fieldset": { borderColor: "#009872" },
                "& input::placeholder": {
                  fontSize: "20px",
                },
                "& .Mui-focused fieldset": {
                  borderColor: errors.password
                    ? "#FF0000 !important"
                    : "#009872 !important",
                },
                "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus":
                  {
                    WebkitBoxShadow: "0 0 0px 1000px white inset !important",
                    backgroundColor: "transparent !important",
                    color: "#1A1A1C !important",
                  },
              }}
            />

            {/* Forgot Password Link */}
            <Box className={styles.forgotPasswordWrapper}>
              <Box className={styles.forgotPassword}>
                <Typography
                  component={Link}
                  to="/forgot-password"
                  sx={{ color: "inherit", textDecoration: "none",fontSize:"20px" }}
                >
                  Forgot Password?
                </Typography>
              </Box>
            </Box>
            {/* Submit Button */}
            <Button
              className={styles.button}
              disableElevation
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "20px",
                fontFamily: "Roboto",
                background: "#009872",
                borderRadius: "4px",
                "&:hover": { backgroundColor: "#007a5e" },
                width: 440,
                height: 48,
              }}
            >
              {loading ? "Loading..." : "Login"}
            </Button>

            <ReCAPTCHA
              sitekey={`${siteKey}`}
              size="invisible"
              ref={recaptchaRef}
            />
          </form>
        </Box>
      </Box>
    </Background>
  );
};

export default LoginPage;
