import React from "react";
import ReactMarkdown from "react-markdown";
import { Typography, Box } from "@mui/material";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import "highlight.js/styles/github.css"; // Syntax highlighting
import remarkGfm from "remark-gfm";


const MarkdownTag = ({ msgData }) => {
    // const cleanedMsg = removeFootnotes(msgData); // Clean footnotes
  return (
    <ReactMarkdown
      components={{
        // Paragraphs
        p: ({ node, ...props }) => (
          <Box
            component="p"
            sx={{
              margin: 0,
              fontFamily: "Roboto, sans-serif",
              fontSize: "1rem",
              color: "text.primary",
            }}
            {...props}
          />
        ),
        // Skip Footnotes
        // sup: () => null,

        // // Skip Strikethrough
        // del: () => null,
        // Code Blocks
        pre: ({ node, ...props }) => (
          <Box
            component="pre"
            sx={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              margin: 0,
              padding: "8px",
              backgroundColor: "grey.100",
              borderRadius: "8px",
              fontFamily: "Fira Code, monospace",
              fontSize: "0.9rem",
            }}
            {...props}
          />
        ),
        // Links
        a: ({ node, ...props }) => (
          <Box
            component="a"
            sx={{
              color: "primary.main",
              textDecoration: "underline",
              "&:hover": { color: "primary.dark" },
            }}
            target="_blank"
            rel="noopener noreferrer"
            {...props}
          >
            {props.children}
          </Box>
        ),
        sup: () => null,
        // Headings
        h1: ({ node, ...props }) => (
          <Typography
            variant="h4"
            sx={{ fontFamily: "Poppins, sans-serif", fontWeight: 700 }}
            gutterBottom
            {...props}
          />
        ),
        h2: ({ node, ...props }) => (
          <Typography
            variant="h5"
            sx={{ fontFamily: "Poppins, sans-serif", fontWeight: 600 }}
            gutterBottom
            {...props}
          />
        ),
        // Tables
        table: ({ node, ...props }) => (
          <Box
            component="table"
            sx={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "1rem",
              overflowX: "auto",
            }}
            {...props}
          />
        ),
        thead: ({ node, ...props }) => (
          <Box
            component="thead"
            sx={{
              backgroundColor: "grey.200",
            }}
            {...props}
          />
        ),
        tbody: ({ node, ...props }) => <tbody {...props} />,
        tr: ({ node, ...props }) => (
          <Box
            component="tr"
            sx={{
              borderBottom: "1px solid",
              borderColor: "grey.300",
            }}
            {...props}
          />
        ),
        th: ({ node, ...props }) => (
          <Box
            component="th"
            sx={{
              padding: "8px",
              backgroundColor: "grey.200",
              fontWeight: "bold",
              fontFamily: "Roboto, sans-serif",
              fontSize: "0.9rem",
              textAlign: "left",
            }}
            {...props}
          />
        ),
        td: ({ node, ...props }) => (
          <Box
            component="td"
            sx={{
              padding: "8px",
              fontFamily: "Roboto, sans-serif",
              fontSize: "0.9rem",
              textAlign: "left",
            }}
            {...props}
          />
        ),
      }}
      rehypePlugins={[rehypeRaw, rehypeHighlight,remarkGfm]}
    >
      {msgData}
    </ReactMarkdown>
  );
};

export default MarkdownTag;
