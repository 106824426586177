import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";

// import MessageIcon from "../../assets/MessagePlanIcon.svg";
// import MarketIcon from "../../assets/MarketPlanIcon.svg";
// import MediaIcon from "../../assets/MediaPlanIcon.svg";
// import MeasureIcon from "../../assets/MeasurePlanIcon.svg";
import alignIcon from "../../assets/alignIcon.svg";
import alignGreen from "../../assets/alignGreen.svg";
import alignIconActive from "../../assets/alignIconActive.svg";
import MarketPlanIconActive from "../../assets/MarketPlanIconActive.svg";
import databaseIcon from "../../assets/databaseIcon.svg";
import fineGoldGreen from "../../assets/fineGoldGreen.svg";
import outreachMedia from "../../assets/OutreachMedia.svg";
import measureTarget from "../../assets/measureTarget.svg";
import optimizeGreen from "../../assets/optimizeGreen.svg";
import optimizeActive from "../../assets/optimizeActive.svg";
import happyProspectingGreen from "../../assets/happyProspectingGreen.svg";
import happyProspectingActive from "../../assets/happyProspectingActive.svg";
import {
  OutReachBox,
  OutReachBoxIconButton,
  OutReachBtn,
  OutReachDescBox,
  OutReachDetailsBox,
  OutReachTitleBox,
} from "./OutReachStyle";
import SuggestFeedback from "./SuggestFeedback";
import Modal from "react-modal";
import AlignAndAmplify from "./AlignAndAmplify";
import FindGold from "./FindGold";
import { API } from "../../global";
import MediaPlan from "../ChatInterface/SalesLead/MediaPlan";
import MeasurePlan from "../ChatInterface/SalesLead/MeasurePlan";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "415px", // Set custom width
    height: "auto", // Set custom height
    borderRadius: "4px",
    border: "2px solid #E8E8E8",
    boxSizing: "border-box"
  },
  overlay: {
    backgroundColor: "transparent", // Removes the blocking overlay effect
  },
};

const plans = [
  {
    icon: alignIcon,
    activeIcon: alignIconActive,
    greenIcon: alignGreen,
    isActive: true,
    order: 1,
    title: "Align & Amplify",
    type: "message_plan",
    briefDescription: "Great outreach starts with great messaging.",
  },
  {
    icon: databaseIcon,
    activeIcon: MarketPlanIconActive,
    greenIcon: fineGoldGreen,
    order: 2,
    title: "Find Gold",
    type: "market_plan",
    isActive: false,
    briefDescription: "Not all leads shine - focus on the ones that do.",
  },
  {
    icon: outreachMedia,
    activeIcon: happyProspectingActive,
    greenIcon: happyProspectingGreen,
    order: 3,
    title: "Happy prospecting",
    type: "media_plan",
    isActive: false,
    briefDescription: "Engage, connect, and keep the energy high.",
  },
  {
    icon: measureTarget,
    activeIcon: optimizeActive,
    greenIcon: optimizeGreen,
    order: 4,
    title: "Optimize & Outperform",
    type: "measure_plan",
    isActive: false,
    briefDescription: "Engage, connect, and keep the energy high.",
  },
];

const OutReachDetails = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [planStatus, setPlanStatus] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [projectDataDetails, setProjectDataDetails] = useState([]);
  const navigate = useNavigate();

  const salesPlanType = localStorage.getItem("sales_plan_type");

  useEffect(() => {
    localStorage.setItem("sales_plan_title", "Ignitho");
  }, []);

  const openFeedback = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    getProjectDetails(salesPlanType);
    getProjectDetailStatus();
    // eslint-disable-next-line
  }, []);

  const getProjectDetails = (typeData) => {
    fetch(`${API}/project/get-project-details`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: localStorage.getItem("project_id"),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        // setLoading(false);
        if (res.success) {
          if (res.result) {
            const projectData = res.result;
            let projectList = [];
            // eslint-disable-next-line
            const feedback = projectData.find(
              (plan) => plan.plan_type === typeData
            )?.comments
            const alignedStatus = projectData.find(
              (plan) => plan.plan_type === typeData
            )?.align_status
            let showAlignedBtn = true

            if( alignedStatus?.length !==0 || feedback.length !== 0){
              if(alignedStatus[0]?.is_aligned || feedback[0]?.status === 'pending' ){
                showAlignedBtn =false
              }else{
                showAlignedBtn =true
              }
            }else{
              showAlignedBtn =true
              
            }

           
            
            // eslint-disable-next-line
            plans.map((plan) => {
              // eslint-disable-next-line
              projectData.map((item) => {
                if (plan.type === item.plan_type) {
                  const obj = {
                    value: plan.order,
                    label: plan.type,
                    content: item.content,
                    documents: item.documents,
                    comments: item.comments ? item.comments : [],
                    align: item.align_status ? item.align_status : [],
                    status: item.data_collection_status,
                    disabled: false,
                    showAlignedBtn :showAlignedBtn,
                  };
                  projectList.push(obj);
                }
              });
            });
            setProjectDataDetails(projectList);
          }
        }
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  const getProjectDetailStatus = () => {
    fetch(`${API}/project/get-project-detail-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: localStorage.getItem("project_id"),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setPlanStatus(res.result);
          createUpdatedList(res.result);
        }
      })
      .catch(() => {
        // setLoading(false);
      });
  };

  const createUpdatedList = (backendData) => {
    const listPlan = [];

    // Determine the active index based on the selected plan
    const planIndexMap = {
      message_plan: 0,
      market_plan: 1,
      media_plan: 2,
      measure_plan: 3,
    };

    let activeIndex = planIndexMap[salesPlanType] ?? 0; // Default to 0 if not found
    setSelectedPlan(plans[activeIndex]);
   

    // Step 4: Map backend data and merge with frontend plans
    // eslint-disable-next-line
    plans.forEach((valueData, index) => {
      const matchedData = backendData.find(
        (data) => data.plan_type === valueData.type
      );
      const data = [
        { name: "Progress", value: matchedData.avg_quality_score },
        { name: "Remaining", value: 100 - matchedData.avg_quality_score },
      ];


      if (matchedData) {
        listPlan.push({
          icon: valueData.icon,
          activeIcon: valueData.activeIcon,
          title: valueData.title,
          type: matchedData.plan_type,
          status: matchedData.overall_status,
          briefDescription: valueData.briefDescription,
          isActive: index === activeIndex, // Ensure only one plan is active,
          score: data,
          scoreValue: matchedData.avg_quality_score,
          isAligned: matchedData.is_aligned,
          greenIcon: valueData.greenIcon,
          isViewed :matchedData.is_viewed
        });
      }
    });
    setPlanStatus(listPlan);
  };

  const handleNext = async(selectedPlan) => {
    getProjectDetails(selectedPlan.type)
    setPlanStatus((prevPlans) =>
      prevPlans.map((plan) => ({
        ...plan,
        isActive: plan.title === selectedPlan.title,
      }))
    );
    setSelectedPlan(selectedPlan);
  };

  const handleClose = () => {
    setIsOpen(!modalIsOpen);
    getProjectDetails(salesPlanType);
    getProjectDetailStatus();
  };

  const handleAlign = (alignedStatusValue) => {


    const obj = {
      plan_type: selectedPlan.type,
      project_id: localStorage.getItem("project_id"),
    };
    if (alignedStatusValue.showAlignedBtn) {
      obj.is_aligned = true;
      obj.is_viewed = true;
    } else {
      obj.is_viewed = true;
      obj.is_aligned = alignedStatusValue?.align[0]?.is_aligned || false;
    }
     
      fetch(`${API}/project/update-sdr-align-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((response) => {
          navigate("/piqual-ai/sdr-dashboard");
        })
        .catch(() => { });
 
    

  };
  


const currentIndex1 = planStatus?.findIndex(
  (plan) => plan?.type === selectedPlan.type
);

// If it's the first element, always show the button
const relevantPlan = currentIndex1 === 0 
  ? planStatus[0] 
  : planStatus[currentIndex1 - 1];

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {selectedPlan.type && (
          <SuggestFeedback
            customStyles={customStyles}
            type="sdr"
            closeModel={handleClose}
            plan_type={selectedPlan.type}
            data={
              projectDataDetails?.find(
                (plan) => plan?.label === selectedPlan.type
              )?.comments
            }
            getProjectDetails={getProjectDetails}
          />
        )}
      </Modal>
      <Box
        sx={{
          height: {
            xs: "90vh",
            sm: "90vh",
            md: "90vh",
            lg: "89vh",
            xl: "85vh",
          },
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
          {planStatus.map((plan) => {
            const {
              isActive,
              title,
              activeIcon,
              icon,
              briefDescription,
              greenIcon,
              isAligned
            } = plan;
            return (
              <OutReachBox
                isActive={isActive}
                isAligned={isAligned}
                onClick={() => handleNext(plan)}
              >
                <OutReachBoxIconButton
                  isActive={isActive}
                  isAligned={isAligned}
                >
                  <img src={isActive ? activeIcon : isAligned ? greenIcon : icon} alt="" />
                </OutReachBoxIconButton>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
                >
                  <OutReachTitleBox isActive={isActive}>{title}</OutReachTitleBox>
                  <OutReachDescBox>{briefDescription}</OutReachDescBox>
                </Box>
              </OutReachBox>
            );
          })}
        </Box>

        {selectedPlan && selectedPlan.title && (
          <OutReachDetailsBox>
            {selectedPlan.type === "message_plan" && (
              <AlignAndAmplify
                data={
                  projectDataDetails?.find(
                    (plan) => plan.label === "message_plan"
                  )?.content
                }
                tableData={
                  projectDataDetails?.find(
                    (plan) => plan.label === "message_plan"
                  )?.documents
                }
              />
            )}

            {selectedPlan.type === "market_plan" && (
              <FindGold
                data={
                  projectDataDetails?.find(
                    (plan) => plan.label === "market_plan"
                  )?.content
                }
              />
            )}

            {selectedPlan.type === "media_plan" && (
              <MediaPlan
                communication_sequence={
                  projectDataDetails?.find(
                    (plan) => plan.label === "media_plan"
                  )?.content?.communication_sequence
                }
              />
            )}

            {selectedPlan?.type === "measure_plan" && (
              <MeasurePlan
                sdr_kpis={
                  projectDataDetails?.find((tab) => tab?.content?.sdr_kpis)
                    ?.content?.sdr_kpis || {}
                }
              />
            )}
          </OutReachDetailsBox>
        )}

        <OutReachBtn>
          <Button
            onClick={openFeedback}
            sx={{
              border: "1px solid #009872",
              color: "#009872",
              textTransform: "capitalize",
            }}
          >
            Suggest Feedback
          </Button>
          {(currentIndex1 === 0 || relevantPlan?.isAligned  || relevantPlan?.isViewed )&&
           (
            <Button
            onClick={()=>handleAlign(projectDataDetails?.find(
              (plan) => plan?.label === selectedPlan.type
            ))}
            sx={{
              paddingX: "1.5rem",
              bgcolor: "#009872",
              color: "#ffff",
              textTransform: "capitalize",
            }}
          >
            {projectDataDetails?.find((plan) => plan?.label === selectedPlan.type)?.showAlignedBtn
            ?" I'm Aligned" :" Continue"} 
           
          </Button>
          )}
        
        </OutReachBtn>
      </Box>
    </>
  );
};

export default OutReachDetails;
