import { Button, Tab, styled, TableCell, Select, Tabs, TextField } from "@mui/material";

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 500,
  backgroundColor: "#F3F1FB",
  color: "#212529",
  padding: "10px",
  fontSize: "16px",

  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#6A6A6B",
  padding: "10px",
  fontSize: "14px",

  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  background: "white",
  border: "1px solid #D1D1D2",
  borderRadius: "6px",
  padding: "4px",
  fontSize: "14px",
  minWidth: "120px",
  boxShadow: "none",
  outline: "none",

  "&: hover": {
    borderColor: "#D1D1D2"
  },

  "MuiSelect - icon": {
    color: "#252527"
  }
}));

const OutlinedBtn = styled(Button)(({ theme, btncolor }) => ({
  backgroundColor: "#ffffff",
  color: "#252527",
  fontSize: "14px",
  padding: "8.5px 16px",
  border: `1px solid #D1D1D2`,
  textTransform: "capitalize",

  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7D34",
  },
});

const CustomTab = styled(Tab)(({ theme, isActive }) => ({
  textTransform: "capitalize",
  fontSize: "18px",
  color: "#1A1A1C",

  "&.Mui-selected": {
    color: "#FF7D34",
  },

  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));

const ContainedBtn = styled(Button)(({ theme, btncolor }) => ({
  backgroundColor: "#009872",
  color: "#ffffff",
  fontSize: "14px",
  padding: "8.5px 16px",
  border: `1px solid #D1D1D2`,
  textTransform: "capitalize",

  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));

const SearchBarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "4px",
  border: "1px solid #ccc",
  padding: "8.5px 16px",
  width: "178px",
  transition: "border-color 0.2s ease-in-out",
  gap: "6px" 
}));

const StyledTextField = styled(TextField)({
  flex: 1,
  "& .MuiOutlinedInput-root": {
    padding: 0,
    "& input": {
      padding: 0,
    },
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
});

export {
  HeaderTableCell,
  StyledTableCell,
  CustomSelect,
  OutlinedBtn,
  CustomTab,
  CustomTabs,
  ContainedBtn,
  StyledTextField,
  SearchBarContainer
};