import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FadeLoader } from "react-spinners";
import CheckCircleIcon from "../..//assets/completeCircle.svg";
import ArrowIcon from "../../assets/ArrowIcon.svg";
import GreenRightArrow from "../../assets/GreenRightArrow.svg";
import CampaignIcon from "../../assets/campaign.svg";
import DatabaseIcon from "../../assets/databaseIcons.svg";
import PersonIcon from "../../assets/personIcon.svg";
import FlagIcon from "../../assets/flagIcon.svg";
import DatabaseIconActive from "../../assets/databaseActiveIcon.svg";
import PersonIconActive from "../../assets/personActiveIcon.svg";
import FlagIconActive from "../../assets/flagActiveIcon.svg";

import { useOutletContext } from "react-router-dom";
import {
  AlignButton,
  CompletedIcon,
  DescriptionBox,
  GreetingsBox,
  GreetingsBox2,
  HeadingBox,
  ImageBox,
  PlanIcon,
  PlanInnerBox,
  PlansGrid,
  SalesPlanBox,
  TitleBox,
  ViewPlanButton,
} from "./SDRDashboardCss";
import { useNavigate } from "react-router-dom";
import { API } from "../../global";

const plans = [
  {
    icon: CampaignIcon,
    activeIcon: CampaignIcon,
    isActive: true,
    order: 1,
    title: "Align & Amplify",
    type: "message_plan",
    buttonMessage: "Message",
    briefDescription:
      "Great outreach starts with great messaging. Align your pitch, personalize your touch, and amplify your impact with every interaction.",
    longDescription: `The Message Plan outlines the key messages, value propositions, and tone we will use to engage with prospects. 

    It aims to create compelling, consistent messaging that connects with potential customers, addresses their pain points, and highlights the unique value our product provides, while ensuring alignment with the brand's voice and customer expectations across all touchpoints.`,
  },
  {
    icon: DatabaseIcon,
    activeIcon: DatabaseIconActive,
    order: 2,
    title: "Find Gold",
    type: "market_plan",
    buttonMessage: " Criteria",
    // isActive : true,
    briefDescription:
      "Not all leads shine - focus on the ones that do. Dig into the right market and strike gold by engaging the best-fit prospects.",
    longDescription:
      "The Market Plan defines the target market, outlines segmentation, and details how we will reach potential customers. It focuses on expanding our reach within the mid-market and enterprise sectors by targeting organizations with over 100 employees and annual revenue of $10M+, while also identifying key industries and decision-makers to optimize our outreach efforts.",
  },
  {
    icon: PersonIcon,
    activeIcon: PersonIconActive,
    order: 3,
    title: "Happy Prospecting",
    type: "media_plan",
    buttonMessage: " Media",
    briefDescription:
      "Momentum and media are everything! Engage, connect, and keep the energy high - your next breakthrough is just a message away.",
    longDescription:
      "The Media Plan outlines how we will use various media channels to execute marketing and sales strategies effectively. It aims to increase brand visibility and generate qualified leads by leveraging a mix of digital media channels, while incorporating traditional media platforms to enhance brand recognition, broaden audience reach, and build stronger.",
  },
  {
    icon: FlagIcon,
    activeIcon: FlagIconActive,
    order: 4,
    title: "Optimize & Outperform",
    type: "measure_plan",
    buttonMessage: " KPIs",
    briefDescription:
      "Every conversation teaches you something. Track, tweak, and measure your outreach to improve and outperform every single day.",
    longDescription:
      "The Measure Plan defines how we will track performance and success against sales goals and KPIs. It ensures that all sales and marketing activities are delivering measurable results that align with our overall business goals, while also incorporating ongoing optimization to improve future outcomes and maximize ROI. Regular performance reviews and adjustments ",
  },
];

const SDRDashboard = () => {
  // const theme = useTheme();
  const navigate = useNavigate();
  const [planStatus, setPlanStatus] = useState([]);
  const { sidebarWidth } = useOutletContext();
  const [allPlansAligned, setAllPlansAligned] = useState(false);
  // const [allPlansViewed,setAllPlansViewed]= useState(false)
  const [loading, setLoading] = useState(true);
  // const [allTaskStatusAligned,setAllTaskStatusAlined] = useState(false)
  // const allCompleted = completedPlans.length === plans.length;
  const isSideBarOpened = sidebarWidth === "150px";
  // const activeMarketPlan = localStorage.getItem("active_state");
  const firstName = localStorage.getItem("first_name");
  let lastName = localStorage.getItem("last_name");
  lastName = lastName === "undefined" || lastName === null ? null : lastName;
    const [greeting, setGreeting] = useState("");
    const [taskCount,setTaskCount] = useState(0)

  useEffect(() => {
    const getGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        return "Good Morning";
      } else if (hour < 18) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    };

    setGreeting(getGreeting());
  }, []);

  useEffect(() => {
    getProjectDetailsData();
    getTaskDetails()
    // eslint-disable-next-line
  }, []);

  
     const getTaskDetails = () => {
       setLoading(true);
       fetch(`${API}/project/prospect-contacts-plan-status`, {
         method: "POST",
         headers: {
           Authorization: `Bearer ${localStorage.getItem("token")}`,
           "Content-Type": "application/json",
         },
       })
         .then((response) => response.json())
         .then((res) => {
           setLoading(false);

           if (res.success) {
              setTaskCount(res?.result?.length)
            
           }
         })
         .catch(() => {
           setLoading(false);
         });
     };

  const getProjectDetailsData = () => {
    fetch(`${API}/project/get-project-detail-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: localStorage.getItem("project_id"),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setPlanStatus(res.result);
          createUpdatedList(res.result);
        }
      })
      .catch(() => {
        // setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const createUpdatedList = (backendData) => {
    const listPlan = [];
    let activeIndex = 0; // Default active plan index

    // Step 1: Create a status map from backend data'
    const statusMapView = backendData.reduce((acc, item) => {
      acc[item.plan_type] = Boolean(item.is_viewed); // Ensure proper boolean check
      return acc;
    }, {});

    const statusMapAlign = backendData.reduce((acc, item) => {
      acc[item.plan_type] = Boolean(item.is_aligned); // Ensure proper boolean check
      return acc;
    }, {});

    // Step 2: Check if all plans are completed
    const allViewed = Object.values(statusMapView).every((status) => status);
    // setAllPlansViewed(allViewed)

    const allAligned = Object.values(statusMapAlign).every((status) => status);

    setAllPlansAligned(allAligned);


    if (!allViewed) {
      // Step 3: Determine the last completed plan and set the next one as active
      let foundActive = false;

      plans.forEach((plan, index) => {
        if (statusMapView[plan.type]) {
          activeIndex = index + 1; // Move to the next plan
        } else if (!foundActive) {
          activeIndex = index; // Set first non-completed plan as active
          foundActive = true;
        }
      });


      // Ensure activeIndex doesn't go out of bounds
      activeIndex = Math.min(activeIndex, plans.length - 1);
    }
    // const isAllCompleted = backendData.every(item => item.overall_status === "completed");
    // setAllTaskStatusAlined(isAllCompleted)

    // Step 4: Map backend data and merge with frontend plans
    plans.forEach((valueData, index) => {
      const matchedData = backendData.find(
        (data) => data.plan_type === valueData.type
      );

      console.log("matchedData",matchedData)
      if (matchedData) {
        const data = [
          { name: "Progress", value: matchedData.avg_quality_score },
          { name: "Remaining", value: 100 - matchedData.avg_quality_score },
        ];
        
        listPlan.push({
          icon: valueData.icon,
          activeIcon: valueData.activeIcon,
          title: valueData.title,
          type: matchedData.plan_type,
          buttonMessage: valueData.buttonMessage,
          status: matchedData.overall_status,
          briefDescription: valueData.briefDescription,
          isActive: (!matchedData.is_viewed && index === activeIndex) , // Ensure only one plan is active
          score: data,
          scoreValue: matchedData.avg_quality_score,
          isAligned: matchedData.is_aligned,
          isViewed : matchedData.is_viewed
        });
      }
    });

    setPlanStatus(listPlan);
  };

  const handleNext = (index, type) => {
    localStorage.setItem("sales_plan_type", type.type);
    localStorage.setItem("sales_plan_title", type.title);
    localStorage.setItem("prospect_id", "");
    navigate("/piqual-ai/outreach");
  };

  const buildDataBase =(type)=>{
  if (type === "market_plan") {
      localStorage.setItem("sales_plan_chat_type", 'db-companies')
      navigate("/piqual-ai/sdr-sales-plan");
    } else if (type === "media_plan") {
      localStorage.setItem("sales_plan_chat_type", 'outreach')
      navigate("/piqual-ai/prospecting");
    }
  }


  return (
    <SalesPlanBox>
      {/* Greeting Section */}
      <Box>
        <GreetingsBox>
         {greeting}, {firstName} {lastName}!
        </GreetingsBox>
        <GreetingsBox2>
          Welcome to your launchpad! Your sales leader set the plan - now it’s
          time to execute. Nail your pitch, target the right prospects, and
          track your wins. With Piqual AI, every outreach counts - stay sharp,
          stay fast, and close strong!
        </GreetingsBox2>
        <HeadingBox>
          As a SDR, you need to execute the sales plan defined by your sales
          leader
        </HeadingBox>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <FadeLoader color="#36d7b7" />
        </Box>
      ) : (
        <PlansGrid
          container
          allCompleted={allPlansAligned}
          isSideBarOpened={isSideBarOpened}
        >
          {planStatus.map((plan, index) => {
            const {
              isActive,
              title,
              briefDescription,
              activeIcon,
              icon,
              buttonMessage,
              isAligned,
              type,
              // status,
              isViewed
            } = plan;
            return (
              <Grid
                item
                key={index}
                sx={{
                  width: {
                    xl: "23.5%",
                    lg: "24.6%",
                    md: "30%",
                    sm: "50%",
                  },
                  "@media (resolution: 2dppx)": {
                    width: "23%",
                  },
                }}
              >
                <PlanInnerBox
                  isActive={isActive}
                  isSideBarOpened={isSideBarOpened}
                  planStatus={isViewed && !isActive}
                  isCompleted={isViewed}
                >
                  <ImageBox
                    isActive={isActive}
                    allCompleted={allPlansAligned}
                    planStatus={isAligned}
                  >
                    {isAligned ? (
                      <CompletedIcon src={CheckCircleIcon} alt="Completed" />
                    ) : (
                      <PlanIcon
                        src={isViewed ? activeIcon : icon}
                        alt={title}
                      />
                    )}
                  </ImageBox>
                  <TitleBox isActive={isActive}>{title}</TitleBox>
                  <DescriptionBox
                    color="text.secondary"
                    mb={2}
                    isActive={isActive}
                  >
                    {briefDescription}
                  </DescriptionBox>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly flex-start",
                      gap: "20px",
                      "@media (min-width: 1600px) and (max-width: 1600px)": {
                        gap: "10px",
                      },
                      "@media (width: 1600px) and (height: 800px)": {
                        gap: "5px",
                      },
                    }}
                  >
                    {(isAligned || isActive || isViewed) && (
                      <ViewPlanButton
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        isSideBarOpened={isSideBarOpened}
                        isAligned={isAligned}
                        isActive={isActive}
                        onClick={() => handleNext(index, plan)}
                      >
                        View {buttonMessage}{" "}
                        <img
                          src={isViewed ? GreenRightArrow : ArrowIcon}
                          alt="Arrow"
                          style={{ marginLeft: "6px", height: "40%" }}
                        />
                      </ViewPlanButton>
                    )}
                    {allPlansAligned && (
                      <>
                        {isAligned &&
                          (type === "market_plan" ||
                            (type === "media_plan" && taskCount !== 0)) && (
                            <AlignButton
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              isSideBarOpened={isSideBarOpened}
                              onClick={() => buildDataBase(type)}
                            >
                              {type === "market_plan" ? "Find Gold" : "Jump In"}
                              <img
                                src={ArrowIcon}
                                alt="Arrow"
                                style={{
                                  marginLeft: "6px",
                                  height: "1.5rem",
                                  width: "2rem",
                                }}
                              />
                            </AlignButton>
                          )}
                      </>
                    )}
                  </Box>
                </PlanInnerBox>
              </Grid>
            );
          })}
        </PlansGrid>
      )}
    </SalesPlanBox>
  );
};

export default SDRDashboard;
