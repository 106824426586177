import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "../../Layout/Header.css";
import { header_notification } from "../../../Constants/Constant";
import { useNavigate } from "react-router-dom";
import IgnithoIcon from "../../../assets/IgnithoIcon.svg";
import { useEffect, useState } from "react";
import { API } from "../../../global";

const Header = ({ primaryColor }) => {
  let firstNameData = localStorage.getItem("first_name");
  let lastNameData = localStorage.getItem("last_name");
  lastNameData = lastNameData === "undefined" || lastNameData === null ? null : lastNameData;
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0)

  const userRole = localStorage.getItem("role");

  useEffect(() => {
    getNotificationCount()
  }, [])

  const getNotificationCount = () => {
    fetch(`${API}/project/notifications`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ read_status: "unread", count_only: true }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setNotificationCount(res.result)
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  const showNotification = () => {
    localStorage.setItem("sales_plan_type", "db");
    navigate("/piqual-ai/notifications");
  };

  const stringAvatar = (firstNameData, lastNameData) => {
    const firstName = firstNameData?.split("")[0];
    const lastName =
      lastNameData === "undefined" || lastNameData === null || lastNameData === ""
        ? ""
        : lastNameData.split("")[0];

    return {
      sx: {
        background: primaryColor,
        color: "white",
        width: "31px",
        height: "31px",
        fontSize: "15px",
        marginRight: "5px",
      },
      children: `${firstName}${lastName}`,
    };
  };

  return (
    <>
      <Box className="back-button-wrapper">
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: {
              md: "16px",
              lg: "16px",
              xl: "20px",
            },
          }}
        >
          <img src={IgnithoIcon} alt="logo" style={{ marginTop: "4px" }} />
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          gap: "16px",
          color: "black",
          display: "flex",
          justifyContent: "end",
        }}
        className="week-filter"
      >
        {userRole === "piqai_sales_lead" && (
          <Box>
            <Badge badgeContent={notificationCount} color="error">
              <img
                src={header_notification}
                onClick={showNotification}
                alt="notification"
                style={{
                  width: "17.5px",
                  height: "22.39px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              />
            </Badge>
          </Box>
        )}

        <Button>
          <Avatar
            style={{
              background: primaryColor,
            }}
            {...stringAvatar(firstNameData, lastNameData)}
          />
          <Typography
            sx={{
              fontWeight: "medium",
              color: "#0d162f",
              cursor: "pointer",
              textTransform: "capitalize",
            }}
          >
            {firstNameData} {lastNameData}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  thisWeek: PropTypes.bool,
  sRR: PropTypes.bool,
  addWidgets: PropTypes.bool,

  /** Variant props */
  property1: PropTypes.number,
};

export default Header;