export const removeSuggestion = (data, selectedSuggestions, setSelectedSuggestion, setInputMessage, inputRef) => {
    let updatedList = selectedSuggestions.filter((item) => item !== data);
  
    // Remove the corresponding value from inputMessage
    setInputMessage((prevContent) => {
      const regex = new RegExp(`<b>${data.value} </b></br>.*?</br>`, "g");
      return prevContent.replace(regex, "");
    });
  
    // Remove unwanted commas and full stops
    let clearedInputData = updatedList.map((str) => str.replace(/[.,]/g, ""));
  
    // Update state
    setSelectedSuggestion(updatedList);
    setInputMessage(clearedInputData.length !== 0 ? clearedInputData.join(",") : "");
  
    // Focus input field after update
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(
          inputRef.current.value.length,
          inputRef.current.value.length
        );
      }
    }, 0);
  };
  