import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CompleteMsg = ({planType,type,countDown}) => {
  return (
    <Box sx={{ padding: "10px 20px 20px 20px", width: "90%" }}>
      <Alert variant="outlined" severity="success">
        <b>
          {planType} completed! Taking you to the{" "}
          {type === "review" ? "home page" : "sales plan steps"} in just{" "}
          {countDown} seconds.
        </b>
      </Alert>
    </Box>
  );
};

export default CompleteMsg;
