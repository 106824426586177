import { Box, Button, styled, TextField, Typography } from "@mui/material";

const DetailsOuterBox = styled(Box)(({ theme }) => ({
  marginTop: "1.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "73vh",
  overflow: "auto",
  "@media (resolution: 120dpi)": {
    height: "67vh",
  },
  "@media (resolution: 144dpi)": {
    height: "65vh",
  },
  "@media (min-width: 1280px) and (max-width: 1366px)": {
    height: "66vh",
  },

  "@media (min-width: 1600px) and (max-width: 1600px)": {
    height: "67vh",
  },
}));

const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  paddingBottom: "1.75rem",
  borderBottom: "1px solid #E8E8E8",
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1.25rem",
  fontWeight: 600,
}));

const DescTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontWeight: 400,
  color: "#6A6A6B",
}));

const ProfilePictureBox = styled(Box)(({ theme }) => ({
  marginTop: "0.5rem",
  width: "10rem",
  height: "10rem",
  borderRadius: "50%",
  overflow: "hidden",
  backgroundColor: "#E8E8E8",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const DeleteProfileButton = styled(Button)(({ theme }) => ({
  padding: "5px 1.5rem",
  width: "fit-content",
  marginTop: "0.5rem",
  border: "1px solid #FF4949 ",
  color: "#FF4949",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontWeight: 500,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "#FF4949 ",
  },
}));

const DetailsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
}));

const NameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  paddingBlock: "0.5rem",
}));

const EmailBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  paddingBlock: "0.5rem",
}));

const InputFieldBox = styled(TextField)(({ theme }) => ({
  width: "50%",
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    height: "2.75rem",
    "& input": {
      padding: "8px",
      height: "100%",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D1D1D2",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D1D1D2",
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #D1D1D2",
    borderRadius: "0.5rem",
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#009872",
  borderRadius: "5px",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontWeight: 500,
  color: "#FFFFFF",
  padding: "0.25rem 3rem",
  width: "fit-content",
  marginTop: "0.5rem",
  textTransform: "capitalize",
}));

const EditIconBox = styled(Box)(({ theme }) => ({
  width: "25px",
  height: "25px",
  position: "absolute",
  bottom: "2px",
  left: "10%",
  backgroundColor: "#FF7D34",
  borderRadius: "50%",
  padding: "6px",
  cursor: "pointer",
}));

const CopyButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#009872",
  borderRadius: "5px",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontWeight: 500,
  color: "#FFFFFF",
  height: "2.65rem",
  padding: "0 0.75rem",
  width: "fit-content",
  textTransform: "capitalize",
}));

export {
  DetailsOuterBox,
  ProfileBox,
  HeadingTypography,
  DescTypography,
  ProfilePictureBox,
  DeleteProfileButton,
  DetailsBox,
  NameBox,
  EmailBox,
  InputFieldBox,
  SaveButton,
  EditIconBox,
  CopyButton,
};
