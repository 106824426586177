import React from "react";
import Grid from "@mui/material/Grid2";
import MarketPlanLayout from "./ChatInterfaceLayout";
import YourSalesPlanLayout from "./YourSalesPlanLauout";
// import SDRChatInterface from "./SDRChatInterface";

const ChatInterfaceMainLayout = () => {
  return (
    <Grid container>
      <Grid
        sx={{
          height:{
            xs :"90vh",
            sm :"90vh",
            md :"90vh",
            lg:"89vh",
            xl:"90vh"
          } ,

          paddingLeft: "24px",
          paddingTop: "24px",
          paddingBottom:"4px",
          paddingRight: "12px",
        }}
        size={6}
      >
        <MarketPlanLayout />
      </Grid>

      <Grid
        sx={{
          height:{
            xs :"90vh",
            sm :"90vh",
            md :"90vh",
            lg:"89vh",
            xl:"90vh"
          } ,

          paddingTop: "24px",
          paddingRight: "24px",
          paddingBottom:"2px",
          paddingLeft: "12px",
        }}
        size={6}
      >
        <YourSalesPlanLayout />
      </Grid>
    </Grid>
  );
};

export default ChatInterfaceMainLayout;
