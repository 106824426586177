import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Tooltip,
  Button,
} from "@mui/material";
import { ContentCopy, Replay } from "@mui/icons-material";

import { VisibilityIcon, visibilityOff } from "../../../Constants/Constant";
import {
  CopyButton,
  DescTypography,
  DetailsOuterBox,
  HeadingTypography,
  InputFieldBox,
} from "./AccountPageStyles";

import KeyIcon from "../../../assets/KeyIcon.svg";

const AccountApiKey = () => {
  const [showApiKey, setShowApiKey] = useState(false);
  const [copied, setCopied] = useState(false);

  const apiKey = "sk-xxxxxxxxxxxxxxxxxxxxxxxxxx980";

  const toggleVisibility = () => {
    setShowApiKey((prev) => !prev);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const maskApiKey = (key) => key.replace(/./g, "* ").trim();

  return (
    <DetailsOuterBox sx={{ width: "100%" }}>
      <HeadingTypography>Your Piqual AI API Key</HeadingTypography>
      <DescTypography sx={{ marginBlock: "-0.25rem" }}>
        Do not share your API key with others, or expose it in the browser or
        other client-side code.
      </DescTypography>

      <Box sx={{ marginTop: "1rem", display: "flex", gap: "8px" }}>
        {/* API Key Input Field */}
        <InputFieldBox
          placeholder="Your API Key"
          type="text"
          value={showApiKey ? apiKey : maskApiKey(apiKey)}
          readOnly
          sx={{
            width:"76.5%",
            "& input": { color: "#A3A3A4" },
          }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={KeyIcon}
                    alt="Key Icon"
                    style={{
                      width: "20px",
                      height: "20px",
                      display: "block",
                      border: "none",
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleVisibility} edge="end">
                    <img
                      src={showApiKey ? visibilityOff : VisibilityIcon}
                      alt={showApiKey ? "Hide" : "Show"}
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />

        {/* Copy API Key Button */}
        <Tooltip title={copied ? "Copied!" : "Copy"}>
          <CopyButton
            onClick={handleCopy}
            variant="outlined"
          >
            <ContentCopy sx={{ marginRight: "6px" }} />
            Copy
          </CopyButton>
        </Tooltip>

        {/* Regenerate Key Button */}
        <Button
          variant="outlined"
          sx={{
            height: "2.65rem",
            padding: "0 0.75rem",
            width: "fit-content",
            textTransform: "capitalize",
            color: "black",
            border: "1px solid #D1D1D2",
            "&:hover": { backgroundColor: "#f5f5f5" },
          }}
        >
          <Replay sx={{ marginRight: "6px",transform: "rotate(270deg)" }} />
          Regenerate Key
        </Button>
      </Box>
    </DetailsOuterBox>
  );
};

export default AccountApiKey;
