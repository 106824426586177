import React, { useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import {
  OuterBox,
  ButtonsBox,
  AllButton,
  ReadButton,
  UnReadButton,
  NotificationOuterBox,
  NotificationBox,
  DotBox,
  NameBox,
  MessageBox,
  TimeBox,
} from "./NotificationCss";
import { API } from "../../global";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getNotificationList } from "../../Redux/Actions/NotificationAction";
import { useDispatch } from "react-redux";

const getInitials = (name) => {
  return name
    .split(" ")
    .map((part) => part[0])
    .join("")
    .toUpperCase();
};

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState("all");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem('role')
  const handleFilterChange = (type) => {
    setFilter(type);
    getNotifications(type);
  };
  useEffect(() => {
    getNotifications("all");
    // eslint-disable-next-line
  }, []);

  const getNotifications = (status) => {
    fetch(`${API}/project/notifications`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ read_status: status, count_only: false }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          console.log(res.result);
          const notificationList = [];
          // eslint-disable-next-line
          res.result.map((data) => {
            const obj = {
              id: data._id,
              name: data.message_by,
              message: data.message,
              time: formatDistanceToNow(new Date(data.created_at), {
                addSuffix: true,
              }),
              read: data.read_status,
              type : data.conversation_type              
            };
            notificationList.push(obj);
          });
          setNotifications(notificationList);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  const markAsRead = (id,type) => {
   
    fetch(`${API}/project/notifications-status-update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({notification_id : id }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          getNotifications('all')
           dispatch(getNotificationList({read_status: "unread", count_only: true }))
          localStorage.setItem("sales_plan_type", type);
          localStorage.setItem("sales_plan_title", "View plan");
          if(type === 'db-contacts' || type === 'db-companies' ){
            if (role === "piqai_sales_lead") {
              navigate("/piqual-ai/sales-lead-home");
            } else {
              navigate("/piqual-ai/sdr-home");
            }
          }else{
            navigate("/piqual-ai/view-plan");
          }
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  const filteredNotifications = notifications.filter((notif) => {
    if (filter === "all") return true;
    if (filter === "read") return notif.read;
    if (filter === "unread") return !notif.read;
    return true;
  });

  return (
    <OuterBox>
      <ButtonsBox>
        <AllButton
          onClick={() => handleFilterChange("all")}
          active={filter === "all"}
        >
          All
        </AllButton>
        <ReadButton
          onClick={() => handleFilterChange("read")}
          active={filter === "read"}
        >
          Read
        </ReadButton>
        <UnReadButton
          onClick={() => handleFilterChange("unread")}
          active={filter === "unread"}
        >
          Unread
        </UnReadButton>
      </ButtonsBox>
      <NotificationOuterBox>
        {filteredNotifications.length !== 0 ? (
          <>
            {filteredNotifications.map((notif) => (
              <NotificationBox
                key={notif.id}
                onClick={() => markAsRead(notif.id,notif.type)}
                read={notif.read}
              >
                <DotBox read={notif.read}>
                  <span style={{ color: "#009872" }}>●</span>
                </DotBox>
                <Avatar sx={{ bgcolor: "orange", color: "white" }}>
                  {getInitials(notif.name)}
                </Avatar>
                <Box flexGrow={1}>
                  <NameBox>{notif.name}</NameBox>
                  <MessageBox variant="body2">{notif.message}</MessageBox>
                </Box>
                <TimeBox>{notif.time}</TimeBox>
              </NotificationBox>
            ))}
          </>
        ) : (
          <NotificationBox>No data found</NotificationBox>
        )}
      </NotificationOuterBox>
    </OuterBox>
  );
};

export default Notification;
