import { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography } from "@mui/material";
import { upcomingTaskIcon } from "../../../Constants/Constant";
import { useNavigate } from "react-router-dom";
import { API } from "../../../global";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { BeatLoader } from "react-spinners";

const columns = [
  { id: "description", label: "Description", minWidth: 150 },
  { id: "activity", label: "Activity", minWidth: 100, align: "left" },
  { id: "dueDate", label: "Due Date", minWidth: 100, align: "center" },
  { id: "priority", label: "Priority", minWidth: 50, align: "center" },
  { id: "actions", label: "", minWidth: 50, align: "center" }, // Empty label for icons
];



export default function UpcomingActivities() {
  const navigate = useNavigate();

  const [rows,setRows] = useState([])
  const [loading,setLoading] = useState(false)

  const handleEdit = (row) => {
    localStorage.setItem('task_id',row.task_id)
    localStorage.setItem('task_type',row.task_type);
    localStorage.setItem("sales_plan_chat_type", 'outreach')

    navigate("/piqual-ai/sdr-sales-plan");
  };

  const getPriorityStyles = (row) => {

    if (row?.score >= 750)
      return {
        backgroundColor: "#EB000014",
        color: "#EB0000",
        padding: "8px 20px",
        borderRadius: "40%",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "1.125rem",
      };
    if (row.score >= 500)
      return {
        backgroundColor: "#EC9D0014",
        color: "#EC9D00",
        padding: "8px 12px",
        borderRadius: "40%",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "1.125rem",
      };
    return {
      backgroundColor: "#56BB6B1F",
      color: "#3AB044",
      padding: "8px 20px",
      borderRadius: "40%",
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: "1.125rem",
    };
  };

  useEffect(() => {
    getTaskDetails();
    // eslint-disable-next-line
  }, []);

  const getTaskDetails = async () => {
    try {
        setLoading(true);
        const response = await fetch(`${API}/project/tasks`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        });

        const res = await response.json();
        setLoading(false);

        if (res.success && Array.isArray(res.result)) {
            const taskList = res.result.map((data) => {
                const { company, touch_point_name, contact_details, task_type, task_due_date, project_id, _id } = data;
                const priority = company.buyer_intent_score >= 750
                    ? "High"
                    : company.buyer_intent_score >= 500
                        ? "Medium"
                        : "Low";

                const description = `${touch_point_name
                    ?.split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")} to ${contact_details?.first_name} ${contact_details?.last_name}`;

                return {
                    description,
                    activity: task_type?.charAt(0).toUpperCase() + task_type?.slice(1).toLowerCase(),
                    dueDate: dayjs(task_due_date)?.format("MMM DD, YYYY"),
                    priority,
                    project_id,
                    score: company?.buyer_intent_score,
                    task_id: _id,
                    task_type,
                };
            });

            setRows(taskList);
        }
    } catch (error) {
        console.error("Error fetching task details:", error);
        setLoading(false);
    }
};



  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
       {loading ? (
        <Box
          sx={{
            maxHeight: 290,
            minHeight: "34.3vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BeatLoader color="#8FA699" />
        </Box>
      ) : (
      <TableContainer sx={{ maxHeight: 290, minHeight: "34.3vh",  scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "3px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#bbb",
            borderRadius: "2px",
          }, }}>
        {rows.length !== 0 ? (
            <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ border: "1px solid #E0E0E0" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.label}
                  align={column.align}
                  sx={{
                    backgroundColor: "#EBF6F0",
                    fontWeight: "500",
                    fontFamily: "Roboto",
                    fontSize: "1.125rem",
                    minWidth: column.minWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                sx={{ height: "32px" }}
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
              >
                {columns.map((column) => {
                  if (column.label === "") {
                    return (
                      <TableCell key={column.label} align="center">
                        <IconButton
                          sx={{ width: "10px", height: "10px" }}
                          onClick={() => handleEdit(row)}
                        >
                          <img src={upcomingTaskIcon} alt="" />
                        </IconButton>
                      </TableCell>
                    );
                  }

                  let cellContent = row[column.id] ?? 0;

                  if (column.label === "Priority") {
                    return (
                      <TableCell
                        key={column.label}
                        align={column.align}
                        sx={{ padding: "0.5rem" }}
                      >
                        <span style={getPriorityStyles(row)}>
                          {cellContent}
                        </span>
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell
                      key={column.label}
                      align={column.align}
                      sx={{
                        color: "#6A6A6B",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "1rem",
                        padding: "0.5rem",
                      }}
                    >
                      {cellContent}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        ) : (
            <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography>No Activities</Typography>
          </Box>
        )}
        
      </TableContainer>
      )}
    </Paper>
  );
}
