import {
  Box,
  styled,
  TableCell,
  TableContainer,
  TableHead,
  Checkbox,
  Tab,
  FormControl,
  OutlinedInput,
  MenuItem,
  Chip,
} from "@mui/material";
import { TabList, TabPanel } from "@mui/lab";

const TitleBox = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "roboto",
  marginLeft:"10px",

  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));

const ChartBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",

  [theme.breakpoints.up("xl")]: {
    gap: "20px",
  },
}));

const OuterBoxPlanPage = styled(Box)(({ bgColor, theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  backgroundColor: bgColor,
  padding: "20px",
  borderRadius: "8px",
  boxSizing: "border-box",
  height: "100%",

  [theme.breakpoints.up("xl")]: {
    gap: "20px",
  },
}));

const OuterBox = styled(Box)(({ bgColor, theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  backgroundColor: bgColor,
  padding: "16px",
  borderRadius: "8px",
  boxSizing: "border-box",
  paddingBottom: "20px",
  [theme.breakpoints.up("xl")]: {
    gap: "20px",
  },
}));

const InnerBox = styled(Box)(({ theme }) => ({
  padding: "2px 0px 10px 8px",
  backgroundColor: "#ffffff",
  borderRadius: "10px",
}));

const StyledTableContainer = styled(TableContainer)({
  borderRadius: "10px",
  border: "1px solid #DDDEE0",
  backgroundColor: "#ffffff",
  width: "99%",
  marginTop: "8px",
});

const StyledTableHead = styled(TableHead)({
  border: "1px solid #006EED1A",
});

const StyledTableCellHead = styled(TableCell)({
  fontWeight: "500",
  padding: "8px",
  fontSize: "14px",
  backgroundColor: "#8348CF1A",
});

const StyledTableCell = styled(TableCell)({
  padding: "10px",
  fontSize: "14px",
});

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#9CA4BA",
  "&.Mui-checked": {
    color: "#009872",
  },
  width: "16px",
  height: "16px",
  "& svg": {
    fontSize: "16px",
  },
}));

const StatusContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // backgroundColor: "red",

  height: "98.6%",
  width: "54%",
  // [theme.breakpoints.up("md")]: {
  //   flex: "1 1 49.5%",
  // },
  // [theme.breakpoints.up("xl")]: {
  //   gap: "0px",
  // },
}));

const StatusContainerPlanPage = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "80vh",
  gap: "10px",
  flex: 0.5,
  [theme.breakpoints.up("md")]: {
    flex: "0 0 48%",
  },
  [theme.breakpoints.up("xl")]: {
    gap: "20px",
  },
}));

const ChartContainer = styled(Box)(({ theme }) => ({
  height: "160px",
  width: "200px",
  [theme.breakpoints.up("xl")]: {
    height: "200px",
  },
}));

const ChatBox = styled(Box)({
  flex: "0 0 48%",
  border: "1px solid #00987233",
  backgroundColor: "#56BB6B0D",
  borderRadius: "8px",
  padding: "20px",
  position: "relative",
});

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "15px",
  height: "85vh",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const MainContainerPlanPage = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  // height: "85vh",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const InputContainer = styled(Box)({
  position: "absolute",
  border: "1px solid #C9C9C9",
  backgroundColor: "white",
  borderRadius: "5px",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "90%",
  bottom: "20px",
});

const MessageContainer = styled(Box)({
  minHeight: "80%",
  minWidth: "90%",
  overflowY: "auto",
  // whiteSpace: "pre-wrap", // Preserves whitespace and ensures wrapping
  // overflowWrap: "break-word", // Allows text to wrap inside the box
  paddingBottom: "10px",
});

const SalesPlanOuterBox = styled(Box)({
  // background: "#5593FE1A",
  // padding: "20px", // Optional padding inside SD
  borderRadius: "8px",
  marginTop: "10px",
  //height for Display scaled at 100% and browser scaled at 100%
  "@media (min-resolution: 96dpi)": {
    height: "63.4vh",
  },
  //height for Display scaled at 100% and browser scaled at 110%
  "@media (min-resolution: 105.6dpi)": {
    height: "52.5vh",
  },
  //height for Display scaled at 100% and browser scaled at 125%
  "@media (min-resolution: 120dpi)": {
    height: "56.5vh",
  },
  /* height for Display scaled at 100% and browser scaled at 150% */
  "@media (min-resolution: 144dpi), (min-device-pixel-ratio: 1.5) ": {
    height: "52.5vh",
  },
  //height for Display scaled at 100% and browser scaled at 175%
  "@media (min-resolution: 168dpi)": {
    height: "47vh",
  },
  //height for Display scaled at 125% and browser scaled at 110%
  "@media (min-resolution: 132dpi)": {
    height: "55vh",
  },
  //height for Display scaled at 125% and browser scaled at 125%
  "@media (min-resolution: 150dpi)": {
    height: "51vh",
  },
  //height for Display scaled at 125% and browser scaled at 150%
  "@media (min-resolution: 180dpi)": {
    height: "43.5vh",
  },
});

const CustomTablist = styled(TabList)(({ status }) => ({
  minHeight: "5%",
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
}));

const CustomTab = styled(Tab)(({ theme, status, selected }) => {
  return {
    padding: "5px 10px",
    minHeight: "10%",
    border:
      status === "pending"
        ? "1px solid #FF7D34"
        : status === "completed"
        ? "1px solid #009872"
        : "1px solid #E8E8E8",
    // backgroundColor: status === "pending" ? "#FF7D34" : "#F9F9F9",
    color:
      status === "pending"
        ? "#FF7D34"
        : status === "completed"
        ? "#009872"
        : "#818182",
    fontSize: "1rem",
    fontWeight:500,
    width: "25%",
    textTransform: "capitalize",
    borderRadius: "8px 8px 0 0px",
    fontFamily:"roboto",

    "&.Mui-selected": {
      color: selected ? "white" : "#868A97",
      backgroundColor:
        status === "pending"
          ? "#FF7D34"
          : selected
          ? "#009872ED"
          : "#F1F1F1",
          border:
          status === "pending"
            ? "1px solid #FF7D34"
            : status === "completed"
            ? "1px solid #009872"
            : "1px solid #E8E8E8",
    },

    "&.Mui-disabled":{
      color:"#818182"
    }
  };
});


const CustomTabPanel = styled(TabPanel)({
padding: "0px", 
fontFamily: "roboto",
fontSize:"14px",

})

//chatDropDown-css
const SelectGeography = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  justifyContent: "flex-start",
});

const TitleChatBox = styled(Box)({
  fontSize: "16px",
  fontWeight: 500,
  color: "#0D162F",
});

const ChatFormControl = styled(FormControl)({
  width: 500,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

const ChatOutlinedInput = styled(OutlinedInput)({
  border: "none",
  borderRadius: "8px",
  width: "250px",
  fontSize:"14px",
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #d1d1d1",
  },
  "& .MuiOutlinedInput-input": {
    outline: "none",
    padding: "10px", // Customize padding
    color: "#0D162F", // Customize text color
    // Customize font weight
  },
});

const ChatDropDownMenuItem = styled(MenuItem)({
  "&.MuiMenuItem-root": {
    padding: "0 16px 0px 0px",
    fontSize: "12px !important", // Change font size of menu items
    color: "#1A1A1C", // Text color
    "&:hover": {
      backgroundColor: "transparent", // Hover effect
    },

    "&.Mui-selected ": {
      backgroundColor: "transparent",
    },
  },
});

const DropDownCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },

  "&.Mui-checked": {
    color: "#009872",
  },
});

const IndustrialGroupChip = styled(Chip)({
  margin: "4px 10px 4px 0",
  backgroundColor: "transparent",
  borderRadius: "4px",
  border: "1px solid #009872",
  color: "#009872",
  fontWeight: 500,
  fontSize: "14px",
  "& .MuiChip-deleteIcon": {
    color: "#818182", // Customize the color of the delete icon
    fontSize: "18px", // Adjust icon size
  },
})

export {
  TitleBox,
  OuterBox,
  InnerBox,
  ChartBox,
  StyledTableCell,
  StyledTableCellHead,
  StyledTableContainer,
  StyledTableHead,
  StyledCheckbox,
  StatusContainer,
  ChartContainer,
  ChatBox,
  MainContainer,
  InputContainer,
  MessageContainer,
  MainContainerPlanPage,
  StatusContainerPlanPage,
  OuterBoxPlanPage,
  SalesPlanOuterBox,
  CustomTab,
  CustomTablist,
  SelectGeography,
  TitleChatBox,
  ChatFormControl,
  ChatOutlinedInput,
  ChatDropDownMenuItem,
  DropDownCheckbox,
  IndustrialGroupChip,
  CustomTabPanel
};
