import { Box } from "@mui/material";

import SalesLeadCard from "./SalesLeadCard";
import {
  InnerCard,
  InnerCardContent,
  InnerCardCount,
  InnerCardTitle,
} from "./LeaderTrackerStyles";

const SalesLeadOutreachStatus = () => {
  const outreachData = [
    {
      title: "Number of Companies reached out to",
      count: 168,
      content: "Compared to last week",
      stats: true,
      path: "",
      graph: true,
    },
    {
      title: "Number of Companies reached out to",
      count: 248,
      content: "Compared to last week",
      stats: false,
      path: "",
      graph: true,
    },
  ];

  const meeting = [
    {
      title: "Meetings Booked",
      count: 248,
      content: "Compared to last week",
      stats: false,
      path: "",
      graph: true,
    },
  ];

  const responses = [
    { count: 64, title: "Responses", color: "" },
    {
      count: 38,
      title: "Positive",
      color: "#99E3A3",
    },
    {
      count: 12,
      title: "Negative",
      color: "#F8A3A3",
    },
    {
      count: 14,
      title: "Gone Cold",
      color: "#FFD4A6",
    },
  ];

  return (
    <Box>
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "20px" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <SalesLeadCard data={outreachData} />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <SalesLeadCard data={meeting} />
          <InnerCard>
            <Box sx={{ width: "100%" }}>
              <InnerCardTitle>Number of responses</InnerCardTitle>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {responses.map((data, i) => (
                  <Box
                    sx={{
                      borderLeft: data?.color ? "1px solid #E8E8E8" : "none",
                      paddingLeft: data?.color ? "10px" : "0px",
                    }}
                  >
                    <InnerCardCount>{data.count}</InnerCardCount>
                    <Box
                      sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                    >
                      {data?.color && (
                        <Box
                          sx={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            bgcolor: data?.color,
                          }}
                        ></Box>
                      )}
                      <InnerCardContent>{data.title}</InnerCardContent>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </InnerCard>
        </Box>
      </Box>
    </Box>
  );
};

export default SalesLeadOutreachStatus;
