import { Box, styled } from "@mui/material";
import { LoginBackgroundImg } from "../../Constants/Constant";

const Background = styled(Box)({

  height: "100vh",
  backgroundImage: `url(${LoginBackgroundImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",

});


const TextImg = styled('img')({
  width: "513px",
  height: "120px",
  marginRight: "50px",

  "@media (max-width: 1024px)": { // Small laptops
    width: "250px",
    height: "60px",
  },

  "@media (max-width: 1440px)": { // Regular laptops
    width: "350px",
    height: "90px",
  },

  "@media (min-width: 1440px) and (max-width: 1700px)": { // Larger screens
    width: "400px",
    height: "90px",
  },

  "@media (min-resolution: 1.1dppx)": { // 110% scale
    marginRight: "70px",
  },

  "@media (min-resolution: 1.25dppx)": { // 120% scale
    height: "90px",
  },

  "@media (min-resolution: 1.5dppx)": { // 150% scale
    width: "353px",
    height: "120px",
    marginRight: "50px",

  },
});

const StyleLogo = styled('img')(({ theme }) => ({
  "@media (min-resolution: 1.25dppx)": {
    width: "150px",
  },
  "@media (min-resolution: 1.1dppx)": {
    width: "150px",
  },
  "@media (min-resolution: 144dpi)": {
    width: "150px",
  },
}));

const BuddyImg = styled('img')(({ theme }) => ({
  height: "182px",
  position: "absolute",
  top: -10,
  right: -88,

  "@media (min-width: 1440px) and (max-width: 1700px)": {
    height: "142px",
    right: -120,
  },

  "@media (max-width: 1440px)": { // Adjusts for laptop screens
    height: "132px",
    right: -58,
  },

  "@media (max-width: 1024px)": { // Further adjust for smaller laptops
    height: "80px",
    right: -65,
  },
  "@media (min-resolution: 1.25dppx)": {
    height: "152px !important",
    right: "-70px !important",
    position: "absolute",
    top: -10,
  },
  "@media (min-resolution: 1.1dppx)": {
    height: "152px !important",
    right: "-50px !important",
    position: "absolute",
    top: -10,
  },
  "@media (min-resolution: 1.5dppx)": { // 150% scale
    height: "132px !important",
    top: 10,
    right: "-18 !important",
  },


}));



export { Background, BuddyImg, TextImg, StyleLogo }