import { useEffect, useState } from "react";

import { useOutletContext } from "react-router-dom";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PieChart, Pie, Cell } from "recharts";
import { useTheme, useMediaQuery } from "@mui/material";

import MessageIcon from "../../assets/MessagePlanIcon.svg";
import MarketIcon from "../../assets/MarketPlanIcon.svg";
import MediaIcon from "../../assets/MediaPlanIcon.svg";
import MeasureIcon from "../../assets/MeasurePlanIcon.svg";
import MessageIconActive from "../../assets/MessagePlanIconActive.svg";
import MarketPlanIconActive from "../../assets/MarketPlanIconActive.svg";
import MediaPlanIconActive from "../../assets/MediaPlanIconActive.svg";
import MeasurePlanIconActive from "../../assets/MeasurePlanIconActive.svg";
import Modal from "react-modal";

import {
  PlanIcon,
  ProgressPercent,
  ScorePlanName,
} from "../Dashboard/SalesPlanCss";
import {
  Divider,
  TitleBox,
  ViewInnerBox,
  ViewPlanBox,
  ViewTitle,
  ViewImageBox,
  ViewScoreDescription,
  ScoreBox,
  ScoreTitle,
  PlanContainerBox,
  PlansGrid,
  ViewDescription
} from "./ViewPlanCss";
import { API } from "../../global";
import MeasurePlan from "../ChatInterface/SalesLead/MeasurePlan";
// import ViewMediaPlan from "./ViewMediaPlan";
// import ViewMessagePlan from "./ViewMessagePlan";
// import ViewMarketPlan from "./ViewMarketPlan";
import MediaPlan from "../ChatInterface/SalesLead/MediaPlan";
import FineGold from "../OutReachDetails/FindGold";
import AlignAndAmplify from "../OutReachDetails/AlignAndAmplify";
import SuggestFeedback from "../OutReachDetails/SuggestFeedback";
// import Modal from 'react-modal';
// import SuggestFeedback from "../OutReachDetails/SuggestFeedback";

const plans = [
  {
    icon: MessageIcon,
    activeIcon: MessageIconActive,
    isActive: true,
    order: 1,
    title: "Own Narrative",
    type: "message_plan",
    briefDescription:
      "The Message Plan outlines the key messages, value propositions, and tone we will use to engage with prospects.",
  },
  {
    icon: MarketIcon,
    activeIcon: MarketPlanIconActive,
    order: 2,
    title: "Nail Your ICP",
    type: "market_plan",
    isActive: false,
    briefDescription:
      "The Market Plan defines the target market, outlines segmentation, and details how we will reach potential customers.",
  },
  {
    icon: MediaIcon,
    activeIcon: MediaPlanIconActive,
    order: 3,
    title: "Orchestrate Wins",
    type: "media_plan",
    isActive: false,
    briefDescription:
      "The Media Plan outlines how we will use various media channels to execute marketing and sales strategies effectively.",
  },
  {
    icon: MeasureIcon,
    activeIcon: MeasurePlanIconActive,
    order: 4,
    title: "Track Triumphs",
    type: "measure_plan",
    isActive: false,
    briefDescription:
      "The Measure Plan defines how we will track performance and success against sales goals and KPIs.",
  },
];

const COLORS = ["#FF7D34", "#FFF5ED"];

const ViewPlan = () => {
  const [planStatus, setPlanStatus] = useState([]);
  const { sidebarWidth } = useOutletContext();
  const [allPlansCompleted, setAllPlansCompleted] = useState(false);
  const isSideBarOpened = sidebarWidth === "150px";
  const [projectDataDetails, setProjectDataDetails] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [modalIsOpen, setIsOpen] = useState(false); 
  const salesPlanType = localStorage.getItem("sales_plan_type");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));

  useEffect(() => {
    getProjectDetails();
    getProjectDetailStatus();
    // eslint-disable-next-line
  }, []);

  const getProjectDetails = () => {
    fetch(`${API}/project/get-project-details`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: localStorage.getItem("project_id"),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        // setLoading(false);
        if (res.success) {
          if (res.result) {
            const projectData = res.result;
            let projectList = [];
            // eslint-disable-next-line
            plans.map((plan) => {
              // eslint-disable-next-line
              projectData.map((item) => {
                if (plan.type === item.plan_type) {
                  if(item.comments.length !== 0){
                    setIsOpen(true)
                  }
                  const obj = {
                    value: plan.order,
                    label: plan.type,
                    content: item.content,
                    documents: item.documents,
                    // status: item.data_collection_status,
                    status: item.data_collection_status,
                    disabled: false,
                    comments : item.comments

                  };
                  projectList.push(obj);
                }
              });
            });
            setProjectDataDetails(projectList);
          }
        }
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  const getProjectDetailStatus = () => {
    fetch(`${API}/project/get-project-detail-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: localStorage.getItem("project_id"),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setPlanStatus(res.result);
          createUpdatedList(res.result);
        }
      })
      .catch(() => {
        // setLoading(false);
      });
  };

  const createUpdatedList = (backendData) => {
    const listPlan = [];

    // Determine the active index based on the selected plan
    const planIndexMap = {
      message_plan: 0,
      market_plan: 1,
      media_plan: 2,
      measure_plan: 3,
    };

    let activeIndex = planIndexMap[salesPlanType] ?? 0; // Default to 0 if not found
    setSelectedPlan(plans[activeIndex]);

    // Step 1: Create a status map from backend data
    const statusMap = backendData.reduce((acc, item) => {
      acc[item.plan_type] = item.overall_status;
      return acc;
    }, {});

    // Step 2: Check if all plans are completed
    const allCompleted = plans.every(
      (plan) => statusMap[plan.type] === "completed"
    );

    if (allCompleted) {
      setAllPlansCompleted(true);
    }

    // Step 4: Map backend data and merge with frontend plans
    // eslint-disable-next-line
    plans.forEach((valueData, index) => {
      const matchedData = backendData.find(
        (data) => data.plan_type === valueData.type
      );
      const data = [
        { name: "Progress", value: matchedData.avg_quality_score },
        { name: "Remaining", value: 100 - matchedData.avg_quality_score },
      ];

      if (matchedData) {
        listPlan.push({
          icon: valueData.icon,
          activeIcon: valueData.activeIcon,
          title: valueData.title,
          type: matchedData.plan_type,
          status: matchedData.overall_status,
          briefDescription: valueData.briefDescription,
          isActive: index === activeIndex, // Ensure only one plan is active,
          score: data,
          scoreValue: matchedData.avg_quality_score,
        });
      }
    });
    setPlanStatus(listPlan);
  };

  const handleNext = (selectedPlan) => {
    setPlanStatus((prevPlans) =>
      prevPlans.map((plan) => ({
        ...plan,
        isActive: plan.title === selectedPlan.title,
      }))
    );
    setSelectedPlan(selectedPlan);
  };

  // const openFeedback = () => {
  //   setIsOpen(true);
  // };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "415px", // Set custom width
      height: "auto", // Set custom height
      borderRadius: "4px",
      border: "2px solid #E8E8E8"
    },
    overlay: {
      backgroundColor: "transparent", // Removes the blocking overlay effect
    },
  };

  const handleClose = () => {
    setIsOpen(!modalIsOpen);
  };

  return (
    <>
       <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {selectedPlan.type && (
          <SuggestFeedback
            customStyles={customStyles}
            type="sales_lead"
            closeModel={handleClose}
            plan_type={selectedPlan.type}
            data={
              projectDataDetails?.find(
                (plan) => plan?.label === selectedPlan.type
              )?.comments
            }
            getProjectDetails={getProjectDetails}
          />
        )}
      </Modal>
      <ViewPlanBox>
        {/* Plans Section */}
        <PlansGrid
          allCompleted={allPlansCompleted}
          isSideBarOpened={isSideBarOpened}
        >
          {planStatus.map((plan, index) => {
            const {
              isActive,
              status,
              title,
              activeIcon,
              icon,
              score,
              scoreValue,
              briefDescription
            } = plan;
            const isCompleted = status === "completed";

            return (
              <Grid
                item="true"
                key={index}
                display="flex"
                sx={{
                  width: {
                    xl: "23.5%",
                    lg: "24%",
                    md: "23.8%",
                    sm: "50%",
                  },
                }}
              >
                <ViewInnerBox
                  isActive={isActive}
                  isSideBarOpened={isSideBarOpened}
                  planStatus={isCompleted}
                  onClick={() => handleNext(plan)}
                >
                  <TitleBox>
                    <ViewImageBox
                      isActive={isActive}
                      allCompleted={allPlansCompleted}
                      planStatus={isCompleted}
                    >
                      <PlanIcon src={isActive ? activeIcon : icon} alt="" />
                    </ViewImageBox>
                    <ViewTitle isActive={isActive}>{title}</ViewTitle>
                  </TitleBox>

                  {isCompleted ? (
                    <Box>
                      <Divider />

                      {/* Progress & Score Section */}
                      <ScoreBox>
                        <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <PieChart width={isSmallScreen ? 60 : scoreValue === 100 ? 80 : 70} height={isSmallScreen ? 80 : 100}>
                            <Pie
                              data={score}
                              cx="50%"
                              cy="50%"
                              innerRadius={isSmallScreen ? 20 : 25}
                              outerRadius={isSmallScreen ? 25 : 35}
                              startAngle={90}
                              endAngle={-270}
                              dataKey="value"
                              cornerRadius={4}
                            >
                              {score.map((entry, i) => (
                                <Cell
                                  style={{
                                    stroke: entry.value === 100 ? "#56BB6B" : ""
                                  }}
                                  key={`cell-${i}`}
                                  fill={entry.value === 100 ? "#56BB6B" : COLORS[i % COLORS.length]}
                                />
                              ))}
                            </Pie>
                          </PieChart>
                          <ProgressPercent>{scoreValue}</ProgressPercent>
                        </Box>
                        <Box sx={{ marginLeft: "15px" }}>
                          <ScoreTitle>Score</ScoreTitle>
                          <ViewScoreDescription>
                            Your <ScorePlanName>{title}</ScorePlanName> has been
                            scored. Revise your content to improve the result.
                          </ViewScoreDescription>
                        </Box>
                      </ScoreBox>
                    </Box>
                  ) : (
                    <ViewDescription>
                      {briefDescription}
                    </ViewDescription>
                  )}
                </ViewInnerBox>
              </Grid>
            );
          })}
        </PlansGrid>

        {selectedPlan && selectedPlan.title && (
          <PlanContainerBox>
            {/* Render Different Content Based on the Title */}
            {selectedPlan.type === "message_plan" && (
             <AlignAndAmplify
            
             data={
               projectDataDetails?.find(
                 (plan) => plan.label === "message_plan"
               )?.content
             }
             tableData={
               projectDataDetails?.find(
                 (plan) => plan.label === "message_plan"
               )?.documents
             }
           />
            )}

            {selectedPlan.type === "market_plan" && (
              <FineGold data={projectDataDetails?.find(plan => plan.label === "market_plan")?.content} />
              // <ViewMarketPlan data={projectDataDetails.find(plan => plan.label === "market_plan")?.content} />
            )}

            {selectedPlan.type === "media_plan" && (
              // <ViewMediaPlan data={projectDataDetails.find(plan => plan.label === "Media Plan")?.content} />
              <MediaPlan communication_sequence={projectDataDetails.find(plan => plan.label === "media_plan")?.content?.communication_sequence} />
            )}

            {selectedPlan?.type === "measure_plan" && (
              <MeasurePlan
                sdr_kpis={
                  projectDataDetails?.find((tab) => tab?.content?.sdr_kpis)
                    ?.content?.sdr_kpis || {}
                }
              />
            )}
          </PlanContainerBox>
        )}
        {/* <Button onClick={openFeedback}>Clicck</Button> */}
      </ViewPlanBox>
    </>
  );
};

export default ViewPlan;
