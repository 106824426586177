import {  SET_PROJECT_STATUS_DETAILS } from "../Actions/projectStatusAction";

const initialState = {
    projectStatusDetails : null
};

const setProjectDetailsStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    
      case SET_PROJECT_STATUS_DETAILS:
      return {
        ...state,
        projectStatusDetails: action.payload
      };
    default:
      return state;
  }
};

export default setProjectDetailsStatusReducer;