import React from "react";
import {
  BadgeBox,
  CustomBox,
  InnerCardTitle,
  Insightlist,
  InSightTitle,
} from "./LeaderTrackerStyles";
import { Box, IconButton } from "@mui/material";
// import { positiveTrend, warningTrend } from "../../Constants/Constant";
import MovingIcon from '@mui/icons-material/Moving';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useMediaQuery } from '@mui/material';

const AIInsights = () => {
      const isSmallScreen = useMediaQuery("(max-width: 1536px)");
  const aiSightdata = [
    {
      title: "Pipeline Growth",
      trendStatus: "Positive Trend",
      icon: <MovingIcon sx={{color:"#3AB044",width:"15px",height:"15px"}}/>,
      status: true,
      details: [
        "High-quality leads up 15%.",
        "Codio & Apex show strong intent, aligning with Ignitho’s digital engineering services.",
        "Prioritize follow-ups with tailored messaging on scalability and modernization.",
      ],
    },
    {
      title: "SDR Impact on Outreach",
      trendStatus: "Warning",
      icon: <WarningAmberIcon sx={{color:"#FF6464",width:"15px",height:"15px"}}/>,
      status: false,
      details: [
        "2 SDRs were on leave causing a 12% drop in new company additions.",
        "Cross-train team members to avoid gaps during absences.",
      ],
    },
    {
      title: "Top Outreach Channels",
      trendStatus: "Positive Trend",
      icon: <MovingIcon sx={{color:"#3AB044",width:"15px",height:"15px"}}/>,
      status: true,
      details: [
        "LinkedIn engagement up 25%.",
        "Tech decision-makers are more active here, engaging with thought leadership.",
        "Increase LinkedIn touchpoints,leverage industry.",
        "Increase LinkedIn touchpoints,leverage industry.",
      ],
    },
  ];

  return (
    <CustomBox
      sx={{
        height: "71%",
        "@media (max-width:  1536px)": {
          height: "73%", // Applies when screen width is 1366px or below
        },
        flex:1
      }}
    >
      <Box sx={{ height: "100%" }}>
        <InnerCardTitle sx={{ fontSize: "22px" }}>AI Insights</InnerCardTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: isSmallScreen ?"10px":"20px",
            marginTop: "10px",
            maxHeight: "92%",
            overflowY: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": { width: "3px" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#bbb",
              borderRadius: "2px",
            },
          }}
        >
          {aiSightdata.map((data, i) => (
            <Box
              sx={{
                border: data.status ? "1px solid #5FC968" : "1px solid #FF6464",
                bgcolor: data.status ? "#F2FBF2" : "#FFF1F1",
                borderRadius: "8px",
                padding: "15px 12px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <InSightTitle>{data.title}</InSightTitle>
                <BadgeBox data={data}>
                  <IconButton sx={{p:0}}>
                    {data.icon}
                  </IconButton>
                  {data.trendStatus}
                </BadgeBox>
              </Box>
              <Box>
                {" "}
                <ul
                  style={{
                    margin: "5px 0",
                    padding: "0px 0px 0px 20px",
                    fontFamily: "roboto",
                    color: "#3C3C3E",
                    fontSize: "16px",
                
                  }}
                >
                  {data.details.map((content) => (
                    <Insightlist>
                      {content}
                    </Insightlist>
                  ))}
                </ul>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </CustomBox>
  );
};

export default AIInsights;
