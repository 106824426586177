import { Box, Button, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const SalesPlanBox = styled(Box)({
  width: "100%",
});

const GreetingsBox = styled(Typography)(({ theme }) => ({
  color: "#ff7d34",
  marginBottom: "0.3125rem",
  paddingBottom: "0.125rem",
  fontWeight: 500,
  marginLeft: "1.25rem",
  marginTop: "1.25rem",
  fontFamily: "Roboto",
  fontSize: "1.75rem",

  "@media (resolution: 2dppx)": {
    marginBottom: "0rem !important",
  },

  "@media (min-width: 1280px) and (max-width: 1366px)": {
    marginTop: "0.75rem",
  },

  "@media (min-width: 1600px) and (max-width: 1600px)": {
    marginTop: "0.75rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.125rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "1.125rem",
  },
}));

const GreetingsBox2 = styled(Typography)(({ theme }) => ({
  width: "72%",
  paddingBlock: "0.3125rem",
  marginBlock: "0.3125rem",
  fontWeight: 400,
  marginLeft: "1.25rem",
  fontFamily: "Roboto",
  fontSize: "1.25rem",
  color: "#6A6A6B",

  "@media (min-width: 1280px) and (max-width: 1366px)": {
    marginBlock: "0.1rem",
    paddingBlock: "0.1rem",
  },

  "@media (min-width: 1600px) and (max-width: 1600px)": {
    marginBlock: "0.1rem",
    paddingBlock: "0.1rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "0.9375rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "0.875rem",
  },
}));

const HeadingBox = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  paddingBlock: "0.1875rem",
  marginLeft: "1.25rem",
  fontFamily: "Roboto",
  fontSize: "1.75rem",

  "@media (resolution: 2dppx)": {
    fontSize: "1.5rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "1.5rem",
  },
}));

const PlansGrid = styled(Grid)(({ theme, allCompleted, isSideBarOpened }) => ({
  marginBlock: allCompleted ? "4rem " : "2.5rem",
  alignItems: allCompleted ? "flex-start" : "center",
  marginBottom: "2.5%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-evenly",

  "@media (resolution: 120dpi)": {
    marginBottom: "2%",
  },
  "@media (resolution: 2ddpx)": {
    margin: allCompleted ? "1rem" : "0.9375rem",
    marginBottom: "1.5%",
  },

  "@media (resolution: 144dpi)": {
    marginBottom: "1%",
  },

  "@media (min-width: 1600px) and (max-width: 1600px)": {
    marginBlock: allCompleted ? "1.5rem " : "2rem",
  },

  [theme.breakpoints.down(1400)]: {
    margin: "0.5% 0 1.5%",
    justifyContent: "flex-start",
  },
}));

const PlanInnerBox = styled(Box)(
  ({ theme, isActive, isSideBarOpened, planStatus, isCompleted }) => ({
    height: isActive ? "45vh" : planStatus ? "38vh" : "34vh",
    width: "fit-content",
    padding: isSideBarOpened
      ? "1.25rem 1.25rem 1rem"
      : isActive
      ? "1.5rem 1.25rem 1rem "
      : "1.5rem 1.25rem 1rem",
    border: isActive ? "0.125rem solid #ff7d34" : "0.125rem solid #E8E8E8",
    boxShadow: isActive ? "0px 0px 0.25rem 0px #FF7D34" : "none",
    borderRadius: "0.375rem",
    textAlign: "left",
    backgroundColor: "#fff",
    transition: "all 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    justifyContent: isCompleted ? "space-evenly" : "center",
    gap: isActive ? "6%" : planStatus ? "2.5%" : "5%",
    overflow: "hidden",

    "@media (resolution: 120dpi)": {
      gap: isActive ? "3%" : planStatus ? "0.5%" : "5%",
      marginTop: "-1rem",
      height: isActive ? "52vh" : planStatus ? "47vh" : "44vh",
      padding: isSideBarOpened ? "1rem" : "",
    },
    "@media (resolution: 2dppx)": {
      height: isActive ? "50vh" : planStatus ? "48vh" : "40vh",
      gap: isActive ? "2%" : planStatus ? "0%" : "5%",
      padding: isSideBarOpened
        ? "1.25rem 1.25rem 0.5rem"
        : isActive
        ? "1.5rem 1.25rem 1.5rem "
        : "1.5rem 1rem 1rem",
    },

    "@media (min-width: 1600px) and (max-width: 1600px)": {
      height: isActive ? "52vh" : planStatus ? "42vh" : "38vh",
    },

    "@media (min-width: 1280px) and (max-width: 1280px)": {
      padding: isSideBarOpened
      ? "1.25rem 1.25rem !important"
      : isActive
      ? "1.25rem 1rem !important"
      : "1.25rem 1rem !important",
    },

    // HD (1366x768)
    [theme.breakpoints.down(1400)]: {
      height:
        isSideBarOpened && isActive
          ? "62vh"
          : isActive
          ? "62vh"
          : planStatus
          ? "58vh"
          : "55vh",
      marginRight: "-2.25rem",
      marginLeft: "-1rem",
      marginBlock: "-2.4rem",
      transform: "scale(0.8)",
      gap: isActive ? "6%" : planStatus ? "0.3%" : "4%",
      padding: isSideBarOpened
        ? "1.25rem 1.25rem"
        : isActive
        ? "1.25rem 2rem"
        : "1.25rem 2rem",
    },
    // //  2K (2560x1440)
    [theme.breakpoints.between(1921, 2560)]: {
      height: isActive ? "55vh" : planStatus ? "50vh" : "45vh",
    },
    //  4K (3840x2160)
    [theme.breakpoints.between(2561, 3840)]: {
      height: isActive ? "60vh" : planStatus ? "55vh" : "45vh",
    },
  })
);

const TitleBox = styled(Typography)(({ theme, isActive }) => ({
  fontWeight: 600,
  fontFamily: "Roboto",
  fontSize: "1.5rem",
  margin: "0.0625rem 0 0.3125rem 0",
  "@media (resolution: 2dppx)": {
    fontSize: "1.125rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: isActive ? "1rem" : "0.9375rem",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "2rem",
  },
}));

const DescriptionBox = styled(Typography)(({ theme, isActive }) => ({
  fontWeight: 400,
  fontFamily: "Roboto, sans-serif",
  fontSize: isActive ? "1.125rem" : "1rem",

  "@media (resolution: 2dppx)": {
    fontSize: isActive ? "1rem" : "0.875rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: isActive ? "1rem" : "0.9375rem",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "2rem",
  },
}));

const ViewPlanButton = styled(Button)(
  ({ theme, isSideBarOpened, isAligned, isActive }) => ({
    fontFamily: "Roboto",
    fontWeight: "600",
    wordBreak: "keep-all",
    color: isActive ? "#ffffff" : "#009872",
    textTransform: "none",
    fontSize: "1rem",
    padding: isSideBarOpened ? "0.675rem" : "0.675rem 1.5rem",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    border: isActive ? "1px solid #FF7D34 " : "1px solid #009872",
    borderRadius: "0.25rem",
    backgroundColor: isActive ? "#FF7D34 " : "#ffffff",

    "&:hover": {
      backgroundColor: isActive ? "#EF4207" : "#EBFEF6",
    },

    [theme.breakpoints.down(1400)]: {
      padding: "0.5rem 0.75rem",
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
      padding: "0px 0.625rem",
    },
  })
);

const ImageBox = styled(Box)(
  ({ theme, allCompleted, isActive, planStatus }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: allCompleted
      ? "#E1F7E2"
      : planStatus
      ? "#E1F7E2"
      : isActive
      ? "#FFF5ED"
      : "#F9F9F9",
    padding: "3.5%",
    borderRadius: "50%",
    width: isActive ? "4rem" : "3rem",
    height: isActive ? "4rem" : "3rem",
    aspectRatio: "1 / 1",

    "media(resolution: 2dppx)": {
      width: isActive ? "4rem" : "2.5rem",
      height: isActive ? "4rem" : "2.5rem",
    },

    [theme.breakpoints.down("md")]: {
      width: "2.5rem",
      height: "2.5rem",
    },

    [theme.breakpoints.down("sm")]: {
      width: "2.25rem",
      height: "2.25rem",
    },

    [theme.breakpoints.down(1400)]: {
      width: isActive ? "4rem" : "3rem",
      height: isActive ? "4rem" : "3rem",
    },
  })
);

const PlanIcon = styled("img")(({ theme }) => ({
  width: "65%",
  height: "65%",
  objectFit: "contain",

  "@media (resolution: 2dppx)": {
    width: "55%",
    height: "55%",
  },

  [theme.breakpoints.down("md")]: {
    width: "60%",
    height: "60%",
  },

  [theme.breakpoints.down("sm")]: {
    width: "55%",
    height: "55%",
  },
}));

const CompletedIcon = styled("img")(({ theme }) => ({
  width: "65%",
  height: "65%",
  objectFit: "contain",

  "@media (resolution: 2dppx)": {
    width: "55%",
    height: "55%",
  },

  [theme.breakpoints.down("md")]: {
    width: "60%",
    height: "60%",
  },

  [theme.breakpoints.down("sm")]: {
    width: "55%",
    height: "55%",
  },
}));

const RightArrowIcon = styled("img")({
  marginLeft: "0.375rem",
  height: "0.8125rem",
});


const AlignButton = styled(Button)(({isSideBarOpened})=>({
  backgroundColor: "#FF7D34",
  color: "white",
  textTransform: "capitalize",
  padding: isSideBarOpened ? "0.675rem" : "0.125rem 1rem",
  width: "fit-content",
  fontSize: "1rem",
}));

export {
  SalesPlanBox,
  PlanInnerBox,
  GreetingsBox,
  GreetingsBox2,
  DescriptionBox,
  TitleBox,
  HeadingBox,
  PlansGrid,
  ImageBox,
  PlanIcon,
  CompletedIcon,
  RightArrowIcon,
  ViewPlanButton,
  AlignButton
};
