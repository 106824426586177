import React from "react";
import { CustomBox, LegendBox, LegendClr } from "./LeaderTrackerStyles";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "", uv: null, pv: null },
  { name: "3 Mar", uv: 5, pv: 2 },
  { name: "4 Mar", uv: 3, pv: 6 },
  { name: "5 Mar", uv: 14, pv: 3 },
  { name: "6 Mar", uv: 6, pv: 12 },
  { name: "7 Mar", uv: 8, pv: 16 },
];

const legendData = [
  { clr: "#56BB6B", name: "Antony Ciby" },
  { clr: "#0C9CED", name: "Joseph James" },
];

const CustomLabel = ({ viewBox }) => {
  const { y, x } = viewBox;
  return (
    <foreignObject x={x - 82} y={y - 15} width="80" height="30">
      <div
        style={{
          backgroundColor: "#FFE9D4",
          borderRadius: "4px",
          padding: "5px 0px",
          color: "#FF7D34",
          fontWeight: 500,
          textAlign: "center",
          fontSize: "12px",
          fontFamily: "roboto",
        }}
      >
        Target: 10
      </div>
    </foreignObject>
  );
};

const LineGraphTarget = () => {
  const isSmallScreen = useMediaQuery("(max-width:1536px)");
  return (
    <CustomBox sx={{ flex:2}}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            "@media (max-width: 1536px)": {
              fontSize: "16px",
            },
          }}
        >
          This week progress
        </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          {legendData.map((data) => (
            <LegendBox>
              <LegendClr bgclr={data.clr}></LegendClr>
              {data.name}
            </LegendBox>
          ))}
        </Box>
      </Box>

      <ResponsiveContainer width="100%" height="90%">
        <LineChart
          width={500}
          data={data}
          margin={{ top: 30, right: 50, left: 20, bottom: 5 }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={true}
            vertical={false}
          />
          <XAxis
            dataKey="name"
            tickLine={false}
            tick={{
              fill: "#0D162F",
              fontSize: isSmallScreen ? 12 : 16,
              fontWeight: 500,
            }}
            axisLine={{ stroke: "#D1D1D2" }} // Change X-axis line color
          />
          <YAxis
            tickLine={false}
            tick={{
              fill: "#0D162F",
              fontSize: isSmallScreen ? 12 : 16,
              fontWeight: 500,
            }}
            axisLine={{ stroke: "#D1D1D2" }} // Change X-axis line color
            domain={[0, "dataMax"]} // Start from 0 and extend to max data value
            tickCount={5} // Controls how many ticks appear
            interval="preserveEnd" // Ensures consistent spacing
          />
          <ReferenceLine y={10} stroke="#FF7D34" label={<CustomLabel />} />

          <Line
            type="monotone"
            dataKey="pv"
            stroke="#0C9CED"
            dot={{ fill: "#0C9CED" }}
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="uv"
            stroke="#56BB6B"
            dot={{ fill: "#56BB6B" }}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </CustomBox>
  );
};

export default LineGraphTarget;
