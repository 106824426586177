import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import { Box, OutlinedInput, useMediaQuery } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;

const time = ["Custom Range", "Today", "Yesterday", "This Week", "Last Week"];

export default function CalendarDropDown() {
  const [selectedTime, setSelectedTime] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:1536px)"); // ✅ Hook inside component

  const handleChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        padding: "0px",
        marginTop: "5px",
        boxShadow: "none",
        border: "1px solid #E8E8E8",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: isSmallScreen ? 150 : 200, // ✅ Responsive width
        fontSize: isSmallScreen ? "12px" : "inherit", // ✅ Responsive font size
        scrollbarWidth: "thin",
      },
    },
  };

  return (
    <FormControl
      sx={{
        m: 1,
        width: isSmallScreen ? 150 : 200, // ✅ Responsive width
      }}
      size="small"
    >
      <Select
        value={selectedTime}
        displayEmpty
        onChange={handleChange}
        input={<OutlinedInput />}
        inputProps={{ "aria-label": "Without label" }}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Box sx={{ fontWeight: 500, fontSize: isSmallScreen ? "12px" : "14px" }}>
                This Week
              </Box>
            );
          }
          return <Box>{selected}</Box>;
        }}
        MenuProps={MenuProps}
        sx={{
          height: isSmallScreen ? "30px" : "35px", // ✅ Responsive height
          border: "1px solid #3C3C3E",
          color: "#3C3C3E",
          borderRadius: "4px",
          "& .MuiOutlinedInput-notchedOutline": { border: "none" }, // Removes border
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: "none" }, // Prevents border on focus
          "&:focus": { outline: "none" }, // Removes focus outline
        }}
      >
        {time.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              padding: "8px 10px",
              fontSize: "14px",
              "&.Mui-focused, &.Mui-selected, &:hover": {
                backgroundColor: "#EBFEF6 !important",
              }, // ✅ Optimized hover & selected styles
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
