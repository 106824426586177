import React, { useState, useRef } from "react";

import { ClickAwayListener } from "@mui/material";

import {
  DropdownContainer,
  DropdownButton,
  DropdownItem,
  DropdownList
} from "./CustomDropdownCss";
import arrowDown from "../../../assets/keyboard_arrow_down.svg";

const CustomDropdown = ({ options, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    onChange(option);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <DropdownContainer ref={dropdownRef}>
        <DropdownButton onClick={() => setOpen((prev) => !prev)}>
          {value || options[0]}
          <img src={arrowDown} alt="" />
        </DropdownButton>

        {open && (
          <DropdownList>
            {options.map((option) => (
              <DropdownItem key={option.value} onClick={() => handleSelect(option.label)}>
                {option.label}
              </DropdownItem>
            ))}
          </DropdownList>
        )}
      </DropdownContainer>
    </ClickAwayListener>
  );
};

export default CustomDropdown;