import { useEffect, useRef, useState } from "react";
import { Box,  Tooltip, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FadeLoader } from "react-spinners";
import { PieChart, Pie, Cell } from "recharts";
import CheckCircleIcon from "../..//assets/completeCircle.svg";
import MessageIcon from "../../assets/MessagePlanIconActive.svg";
import MarketIcon from "../../assets/MarketPlanIcon.svg";
import MediaIcon from "../../assets/MediaPlanIcon.svg";
import MeasureIcon from "../../assets/MeasurePlanIcon.svg";
import ArrowIcon from "../../assets/ArrowIcon.svg";
import GreenRightArrow from "../../assets/GreenRightArrow.svg";
import MarketPlanIconActive from "../../assets/MarketPlanIconActive.svg";
import MediaPlanIconActive from "../../assets/MediaPlanIconActive.svg";
import MeasurePlanIconActive from "../../assets/MeasurePlanIconActive.svg";

import { useOutletContext } from "react-router-dom";
import {
  AssignSDRButton,
  CompletedIcon,
  DescriptionBox,
  DividerLine,
  EnhaneScoreBox,
  EnhaneScoreButton,
  GetStartedButton,
  GreetingsBox,
  GreetingsBox2,
  HeadingBox,
  ImageBox,
  PlanIcon,
  PlanInnerBox,
  PlansGrid,
  ProgressPercent,
  RightArrowIcon,
  SalesPlanBox,
  ScoreDescription,
  ScorePlanName,
  ScoreTypography,
  TitleBox,
  ViewPlanBox,
  ViewPlanButton,
} from "./SalesPlanCss";
import { useNavigate } from "react-router-dom";
import { API } from "../../global";

const plans = [
  {
    icon: MessageIcon,
    activeIcon: MessageIcon,
    isActive: true,
    order: 1,
    title: "Own Narrative",
    type: "message_plan",
    briefDescription:
      "Your unique edge deserves the spotlight. Align messaging across your team and amplify what sets you apart.",
    longDescription: `The Message Plan outlines the key messages, value propositions, and tone we will use to engage with prospects. 

    It aims to create compelling, consistent messaging that connects with potential customers, addresses their pain points, and highlights the unique value our product provides, while ensuring alignment with the brand's voice and customer expectations across all touchpoints.`,
  },
  {
    icon: MarketIcon,
    activeIcon: MarketPlanIconActive,
    order: 2,
    title: "Nail Your ICP",
    type: "market_plan",
    // isActive : true,
    briefDescription:
      "Right leads, real results. Define your market and ensure your team focuses where it matters most for conversions.",
    longDescription:
      "The Market Plan defines the target market, outlines segmentation, and details how we will reach potential customers. It focuses on expanding our reach within the mid-market and enterprise sectors by targeting organizations with over 100 employees and annual revenue of $10M+, while also identifying key industries and decision-makers to optimize our outreach efforts.",
  },
  {
    icon: MediaIcon,
    activeIcon: MediaPlanIconActive,
    order: 3,
    title: "Orchestrate Wins",
    type: "media_plan",
    briefDescription:
      "Multi-channel, maximum impact. Set the right outreach rhythm across media - email, LinkedIn, and calls - to drive real results.",
    longDescription:
      "The Media Plan outlines how we will use various media channels to execute marketing and sales strategies effectively. It aims to increase brand visibility and generate qualified leads by leveraging a mix of digital media channels, while incorporating traditional media platforms to enhance brand recognition, broaden audience reach, and build stronger.",
  },
  {
    icon: MeasureIcon,
    activeIcon: MeasurePlanIconActive,
    order: 4,
    title: "Track Triumphs",
    type: "measure_plan",
    briefDescription:
      "What gets measured, gets mastered. Define SDR KPIs that fuel momentum and turn effort into consistent, scalable wins.",
    longDescription:
      "The Measure Plan defines how we will track performance and success against sales goals and KPIs. It ensures that all sales and marketing activities are delivering measurable results that align with our overall business goals, while also incorporating ongoing optimization to improve future outcomes and maximize ROI. Regular performance reviews and adjustments ",
  },
];

const COLORS = ["#FF7D34", "#FFF5ED"];
const SalesLeadDashboard = () => {
  const textRef = useRef(null);
  // const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 1366px)");

  const navigate = useNavigate();
  const [planStatus, setPlanStatus] = useState([]);
  const { sidebarWidth } = useOutletContext();
  const [allPlansCompleted, setAllPlansCompleted] = useState(false);
  const [loading, setLoading] = useState(true);

  // const allCompleted = completedPlans.length === plans.length;
  const isSideBarOpened = sidebarWidth === "150px";
  // const activeMarketPlan = localStorage.getItem("active_state");
  const firstName = localStorage.getItem("first_name");
  let lastName = localStorage.getItem("last_name");
  lastName = lastName === "undefined" || lastName === null ? null : lastName;
  
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const getGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        return "Good Morning";
      } else if (hour < 18) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    };

    setGreeting(getGreeting());
  }, []);


  useEffect(() => {
    getProjectDetailsData();
    // eslint-disable-next-line
  }, []);


  const getProjectDetailsData = () => {
    fetch(`${API}/project/get-project-detail-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: localStorage.getItem("project_id"),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setPlanStatus(res.result);
          createUpdatedList(res.result);
        }
      })
      .catch(() => {
        // setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const createUpdatedList = (backendData) => {
    console.log("backendData",backendData)
    const listPlan = [];
    let activeIndex = 0; // Default active plan index
    // Step 1: Create a status map from backend data
    const statusMap = backendData.reduce((acc, item) => {
      acc[item.plan_type] = item.overall_status;
      return acc;
    }, {});

    // Step 2: Check if all plans are completed
    const allCompleted = plans.every(
      (plan) => statusMap[plan.type] === "completed"
    );
    console.log("activeIndex",activeIndex)

    if (allCompleted) {
      setAllPlansCompleted(true);
    } else {
      // Step 3: Determine the last completed plan and set the next one as active
      plans.forEach((plan, index) => {
        if (statusMap[plan.type] === "completed") {
          activeIndex = index + 1; // Move to the next plan
        }
      });
    }

    // Step 4: Map backend data and merge with frontend plans
    // eslint-disable-next-line
    plans.forEach((valueData, index) => {
      const matchedData = backendData.find(
        (data) => data.plan_type === valueData.type
      );
      const data = [
        { name: "Progress", value: matchedData.avg_quality_score },
        { name: "Remaining", value: 100 - matchedData.avg_quality_score },
      ];
      if (matchedData) {
        listPlan.push({
          icon: valueData.icon,
          activeIcon: valueData.activeIcon,
          title: valueData.title,
          type: matchedData.plan_type,
          status: matchedData.overall_status,
          briefDescription: valueData.briefDescription,
          isActive: !allCompleted && index === activeIndex, // Ensure only one plan is active,
          score: data,
          scoreValue: matchedData.avg_quality_score,
        });
      }
    });

    console.log("listPlan", listPlan);

    setPlanStatus(listPlan);
  };

  const handleNavigate = (index, type) => {
    if (type.status === "completed") {
      localStorage.setItem("sales_plan_type", type.type);
      localStorage.setItem("sales_plan_title", "View plan");
      localStorage.setItem("prospect_id", "");
      localStorage.setItem("prospect_type", "");
      navigate("/piqual-ai/view-plan");
    }
  };

  const handleNext = (index, type) => {
    localStorage.setItem("sales_plan_type", type.type);
    localStorage.setItem("sales_plan_title", type.title);
    localStorage.setItem("prospect_id", "");
    localStorage.setItem("prospect_type", "");
    navigate(`/piqual-ai/sales-plan?type=${type.type}`);
  };

  const enhanceScoreScore = (index, type) => {
    localStorage.setItem("prospect_id", "");
    localStorage.setItem("prospect_type", "");
    localStorage.setItem("sales_plan_type", type.type);
    localStorage.setItem("sales_plan_title", type.title);
    navigate(`/piqual-ai/sales-plan?type=${type.type}`);
  };

  const handleNavigateToChatPage = () => {
    localStorage.setItem("sales_plan_type", "sdr_invite");
    localStorage.setItem("sales_plan_title", "Assign the SDR");
    localStorage.setItem("prospect_id", "");
    localStorage.setItem("prospect_type", "");
    navigate(`/piqual-ai/sales-plan?type=-invite_sdr`);;
  };


  return (
    <SalesPlanBox>
      {/* Greeting Section */}
      <Box>
        <GreetingsBox>
          {greeting}, {firstName} {lastName}!
        </GreetingsBox>
        <GreetingsBox2>
          {allPlansCompleted
            ? "Your strategy is live - now let’s refine and win! The first step is done, but the best outreach plans evolve as you learn what resonates. Piqual AI will provide real-time insights, uncover patterns, and suggest optimizations to keep you ahead of the game. Every message, every channel, every tweak matters - let’s make sure yours is always getting better and bringing in those big deals."
            : "Welcome to your launchpad! Define a winning message, lock in your ICP, set the right media mix, and track success with precision. With Piqual AI, every move is smarter, every outreach sharper - lead boldly and drive results!"}
        </GreetingsBox2>
        <HeadingBox>
          {allPlansCompleted
            ? "As the Sales Leader, you have defined your sales plan. Let's get the SDRs excited and onboarded."
            : "As a Sales leader, you need to create a sales plan"}
        </HeadingBox>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <FadeLoader color="#36d7b7" />
        </Box>
      ) : (
        <>
          <PlansGrid
            container
            allCompleted={allPlansCompleted}
            isSideBarOpened={isSideBarOpened}
          >
            {planStatus.map((plan, index) => {
              const {
                isActive,
                status,
                title,
                briefDescription,
                activeIcon,
                icon,
                score,
                scoreValue,
              } = plan;
              const isCompleted = status === "completed";

              return (
                <Grid
                  item
                  key={index}
                  sx={{
                    width: {
                      xl: "23.5%",
                      lg: "24.6%",
                      md: "30%",
                      sm: "50%",
                    },
                    "@media (resolution: 2dppx)": {
                      width: "23%",
                    },
                  }}
                >
                  <PlanInnerBox
                    isActive={isActive}
                    isSideBarOpened={isSideBarOpened}
                    planStatus={isCompleted}
                  >
                    <ImageBox
                      isActive={isActive}
                      allCompleted={allPlansCompleted}
                      planStatus={isCompleted}
                    >
                      {isCompleted ? (
                        <CompletedIcon src={CheckCircleIcon} alt="Completed" />
                      ) : (
                        <PlanIcon
                          src={isActive ? activeIcon : icon}
                          alt={title}
                        />
                      )}
                    </ImageBox>
                    <TitleBox isActive={isActive}>{title}</TitleBox>
                    <Tooltip
                      title={isSmallScreen && !isActive ? briefDescription : ""}
                      arrow
                    >
                      <DescriptionBox
                        ref={textRef}
                        color="text.secondary"
                        mb={2}
                        isActive={isActive}
                        sx={{
                          color: "text.secondary",
                          mb: 2,
                          height: "130px",
                          lineHeight: "1.3",
                          transition: "height 0.3s ease-in-out",
                          scrollbarWidth: "thin",
                          "&::-webkit-scrollbar": {
                            width: "3px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#bbb",
                            borderRadius: "2px",
                          },
                        }}
                      >
                        {briefDescription}
                      </DescriptionBox>
                    </Tooltip>

                    {isActive && (
                      <GetStartedButton
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        variant="outline"
                        onClick={() => handleNext(index, plan)}
                      >
                        Start Now
                        <img
                          src={ArrowIcon}
                          alt="Arrow"
                          style={{ marginLeft: "6px", height: "30px",width:"30px" }}
                        />
                      </GetStartedButton>
                    )}

                    {isCompleted && (
                      <Box>
                        {/* View and Enhance Score Buttons */}
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <ViewPlanBox
                            onClick={() => handleNavigate(index, plan)}
                          >
                            <ViewPlanButton
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              isSideBarOpened={isSideBarOpened}
                            >
                              View Plan{" "}
                              <RightArrowIcon
                                src={GreenRightArrow}
                                alt="Arrow"
                              />
                            </ViewPlanButton>
                          </ViewPlanBox>
                          <EnhaneScoreBox>
                            <EnhaneScoreButton
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              isSideBarOpened={isSideBarOpened}
                              onClick={() => enhanceScoreScore(index, plan)}
                            >
                              Enhance Score{" "}
                              <RightArrowIcon
                                src={GreenRightArrow}
                                alt="Arrow"
                              />
                            </EnhaneScoreButton>
                          </EnhaneScoreBox>
                        </Box>

                        <DividerLine />

                        {/* Progress & Score Section */}
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box sx={{ position: "relative" }}>
                            <PieChart
                              width={scoreValue === 100 ? 80 : 70}
                              height={
                                window.innerWidth === 1366
                                  ? 70
                                  : window.devicePixelRatio >= 1.5
                                  ? 70
                                  : window.devicePixelRatio >= 1.25
                                  ? 75
                                  : 100
                              }
                            >
                              <Pie
                                data={score}
                                cx="50%"
                                cy="50%"
                                innerRadius={25}
                                outerRadius={35}
                                startAngle={90}
                                endAngle={-270}
                                dataKey="value"
                                cornerRadius={4}
                              >
                                {score.map((entry, i) => (
                                  <Cell
                                    style={{
                                      stroke:
                                        entry.value === 100 ? "#56BB6B" : "",
                                    }}
                                    key={`cell-${i}`}
                                    fill={
                                      entry.value === 100
                                        ? "#56BB6B"
                                        : COLORS[i % COLORS.length]
                                    }
                                  />
                                ))}
                              </Pie>
                            </PieChart>
                            <ProgressPercent>{scoreValue}</ProgressPercent>
                          </Box>
                          <Box sx={{ marginLeft: "15px" }}>
                            <ScoreTypography>Score</ScoreTypography>
                            <ScoreDescription>
                              Your <ScorePlanName>{title}</ScorePlanName> has
                              been scored. Revise your content to improve the
                              result.
                            </ScoreDescription>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </PlanInnerBox>
                </Grid>
              );
            })}
          </PlansGrid>

          {/* Assign SDR Button */}
          {allPlansCompleted && (
            <Box
              sx={{
                display: "block",
                width: "fit-content",
                margin: "0 auto",
                mt: "-0.8%",
              }}
              onClick={handleNavigateToChatPage}
            >
              <AssignSDRButton
                disableFocusRipple
                disableRipple
                disableTouchRipple
                // onClick={handleNavigateToChatPage}
              >
                Assign SDRs
              </AssignSDRButton>
            </Box>
          )}
        </>
      )}
    </SalesPlanBox>
  );
};

export default SalesLeadDashboard;
