import React, { forwardRef } from "react";
import { Box, Tooltip, IconButton } from "@mui/material";
import ReactMarkdown from "react-markdown";
import ScrollDownBtn from "./ScrollDownBtn";
import MarkdownTag from "./MarkdownTag";
import AnalyzingBox from "./AnalyzingBox";
import CompleteMsg from "../ChatInterface/Common/CompleteMsg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ChatWindow = forwardRef(
  ({ messageList, loading, completedStatus, showDownArrowBtn, handleScrollBtn, countDownTitle, type, countDown, showCopyButton, handleCopy }, chatWindowRef) => {
    const CustomText = ({ children }) => {
          return React.Children.map(children, (child) => {
            if (typeof child === "string") {
              return (
                <span
                  style={{
                    color: "#6A6A6B",
                    fontSize: "14px",
                    fontFamily: "Roboto",
                  }}
                >
                  {child}
                </span>
              );
            }
            return child; 
          });
        };
    
    return (
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          borderTop: "1px solid #C5E7DC",
          borderBottom: "1px solid #C5E7DC",
          position: "relative",
        }}
      >
        {showDownArrowBtn && (
          <ScrollDownBtn handleScrollBtn={handleScrollBtn} />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: completedStatus === "completed" ? "90%" : "100%",
          }}
        >
          <Box className="refinement-options" ref={chatWindowRef}>
            {messageList?.map((data, index) =>
              data.type === "AI" ? (
                <React.Fragment key={index}>
                  {data.msg && (
                    <Box className="company-and-contact">
                      <Box className="select-the-geographic">
                        {!data.additional_response &&
                          showCopyButton &&
                          handleCopy && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "10px",
                              }}
                            >
                              <Tooltip title="Copy to clipboard">
                                <IconButton
                                  onClick={() => handleCopy?.(data.msg)}
                                >
                                  <ContentCopyIcon sx={{ color: "#0ABF8E" }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        <MarkdownTag msgData={data.msg} />
                      </Box>
                    </Box>
                  )}

                  {/* {data.additional_response && (
                    <Box className="company-and-contact">
                      <Box className="select-the-geographic">
                        {showCopyButton && handleCopy && (
                          <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                            <Tooltip title="Copy to clipboard">
                              <IconButton onClick={() => handleCopy?.(data.msg)}>
                                <ContentCopyIcon sx={{ color: "#0ABF8E" }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                        <ReactMarkdown
                          components={{
                            p: ({ node, ...props }) => <Box component="p" sx={{ margin: 0 }} {...props} />, 
                            pre: ({ node, ...props }) => <Box component="pre" sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word", margin: 0, padding: 0 }} {...props} />,
                          }}
                        >
                          {data.additional_response}
                        </ReactMarkdown>
                      </Box>
                    </Box>
                  )} */}
                  {data.additional_response && (
                    <Box className="company-and-contact">
                      <Box className="select-the-geographic">
                        {showCopyButton && handleCopy && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: "10px",
                            }}
                          >
                            <Tooltip title="Copy to clipboard">
                              <IconButton
                                onClick={() => handleCopy?.(data.msg)}
                              >
                                <ContentCopyIcon sx={{ color: "#0ABF8E" }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                        <ReactMarkdown
                          components={{
                            strong: ({ children }) => (
                              <span
                                style={{ fontWeight: "500", color: "#0D162F" }}
                              >
                                {children}
                              </span>
                            ),
                            p: ({ children, ...props }) => (
                              <Box component="p" sx={{ margin: 0 }} {...props}>
                                <CustomText>{children}</CustomText>
                              </Box>
                            ),
                            pre: ({ children, ...props }) => (
                              <Box
                                component="pre"
                                sx={{
                                  whiteSpace: "pre-wrap",
                                  wordBreak: "break-word",
                                  margin: 0,
                                  padding: 0,
                                }}
                                {...props}
                              >
                                <CustomText>{children}</CustomText>
                              </Box>
                            ),
                          }}
                        >
                          {data.additional_response}
                        </ReactMarkdown>
                      </Box>
                    </Box>
                  )}
                </React.Fragment>
              ) : (
                <Box key={index} className="message-outer-box">
                  <Box className="messages-inner-box">
                    <div
                      style={{ padding: "0px" }}
                      dangerouslySetInnerHTML={{ __html: data.msg }}
                    />
                  </Box>
                </Box>
              )
            )}

            {loading && <AnalyzingBox />}
          </Box>
          <Box>
            {completedStatus === "completed" && <CompleteMsg planType={countDownTitle} type={type} countDown={countDown} />}
          </Box>
        </Box>
      </Box>
    );
  }
);

export default ChatWindow;
