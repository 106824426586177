import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { upcomingTaskIcon } from "../../Constants/Constant";
import ApprovedIcon from "../../assets/ApprovedIcon.svg";
import RejectedIcon from "../../assets/RejectedIcon.svg";
import AutoApproveIcon from "../../assets/AutoApproveIcon.svg";
import PendingIcon from "../../assets/PendingIcon.svg";
import { API } from "../../global";
import { BeatLoader } from "react-spinners";

const columns = [
  { id: "prospect", label: "Prospect", minWidth: 150 },
  { id: "score", label: "Buyer Intent", minWidth: 100, align: "center" },
  { id: "status", label: "Status", minWidth: 100, align: "center" },
  { id: "actions", label: "", minWidth: 20, align: "center" },
];

const statusIcons = {
  approved_sales_lead: { icon: ApprovedIcon, title: "Approved" },
  rejected_sales_lead: { icon: RejectedIcon, title: "Rejected" },
  approved_piqual_ai: { icon: AutoApproveIcon, title: "Auto Approved" },
  default: { icon: PendingIcon, title: "Under Review" },
};

export default function DatabaseReview() {
  const navigate = useNavigate();
  const projectId = localStorage.getItem("project_id");
  const [loading,setLoading]  = useState(false)
  const [rows, setRow] = useState();

  useEffect(() => {
    getReviewStatus()

    // eslint-disable-next-line
  }, []);

  const getReviewStatus = ()=>{
    setLoading(true)
    fetch(`${API}/project/get-prospect-review-status`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectId }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setLoading(false)
          res.result.sort((a, b) => (a.prospect_type === "contact" ? -1 : 1));
          setRow(res.result);
        }
      }).catch((err)=>{
        setLoading(false)
      });
  }

  const handleEdit = (row) => {
    navigate("/piqual-ai/sdr-sales-plan?type=review");
    localStorage.setItem("prospect_id", row.prospect_id);
    localStorage.setItem("prospect_project_id", row.project_id);
    localStorage.setItem("prospect_conversation_id", row.conversation_code);
    localStorage.setItem("prospect_type", row.prospect_type);
    localStorage.setItem(
      "sales_plan_chat_type",
      row.conversation_type === "db-companies" ? "db-contacts" : row.conversation_type
    );
  };

  const getScoreStyles = (score) => {
    const numericScore = parseInt(score, 10);
    return {
      backgroundColor: numericScore >= 750 ? "#56BB6B1F" : numericScore >= 500 ? "#EC9D0014" : "#FF49491F",
      color: numericScore >= 750 ? "#3AB044" : numericScore >= 500 ? "#F49D0C" : "#FF4949",
      padding: "5px 15px",
      borderRadius: "50%",
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: "1.125rem",
    };
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {loading ? (
        <Box
          sx={{
            maxHeight: 290,
            minHeight: "34.3vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BeatLoader color="#9884D3" />
        </Box>
      ) : (
        <TableContainer
          sx={{
            maxHeight: 290,
            minHeight: "34.3vh",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#bbb",
              borderRadius: "2px",
            },
          }}
        >
          <>
            {rows?.length !== 0 ? (
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ border: "1px solid #E0E0E0" }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map(({ id, label, align, minWidth }) => (
                      <TableCell
                        key={id}
                        align={align}
                        sx={{
                          backgroundColor: "#F3F1FC",
                          fontWeight: "500",
                          fontSize: "1.125rem",
                          minWidth,
                        }}
                      >
                        {label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, index) => (
                    <TableRow key={index} hover>
                      {columns.map(({ id, align }) => (
                        <TableCell
                          key={id}
                          align={align}
                          sx={{
                            padding: "0.5rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: 200,
                          }}
                        >
                          {id === "prospect" && (
                            <Tooltip
                              title={`${row.prospect} ${
                                row.prospect_type === "contact"
                                  ? `(${
                                      row.title ? row.title : ""
                                    }  - Company:  ${
                                      row.company_details?.company_name || ""
                                    })`
                                  : ""
                              }`}
                              arrow
                            >
                              <span>
                                {row.prospect}{" "}
                                {row.prospect_type === "contact"
                                  ? `(${
                                      row.title ? row.title : ""
                                    } - Company: ${
                                      row.company_details?.company_name || ""
                                    })`
                                  : ""}
                              </span>
                            </Tooltip>
                          )}
                          {id === "score" && (
                            <span style={getScoreStyles(row.score)}>
                              {row.score === "undefind" || row.score === null
                                ? 0
                                : row.score}
                            </span>
                          )}
                          {id === "status" && (
                            <Tooltip
                              title={
                                statusIcons[row.status]?.title ||
                                statusIcons.default.title
                              }
                            >
                              <img
                                src={
                                  statusIcons[row.status]?.icon ||
                                  statusIcons.default.icon
                                }
                                alt={statusIcons[row.status]?.title || "Status"}
                                width={28}
                              />
                            </Tooltip>
                          )}
                          {id === "actions" &&
                            row.status === "rejected_sales_lead" && (
                              <Tooltip title="Review">
                                <IconButton onClick={() => handleEdit(row)}>
                                  <img src={upcomingTaskIcon} alt="Edit" />
                                </IconButton>
                              </Tooltip>
                            )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems:"center"}}>
                <Typography>There are no pending activities at the moment.</Typography>
              </Box>
            )}
          </>
        </TableContainer>
      )}
    </Paper>
  );
}
