import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  fontFamily: "Roboto",
}));

const SettingsPanel = styled(Box)(({ theme }) => ({
  flex: "0 0 50%",
  paddingRight: "20px",
  borderRight: "1px solid #E8E8E8",
  boxSizing: "border-box"
}));

const PreviewPanel = styled(Box)(({ theme }) => ({
  flex: "0 0 50%",
  maxWidth: "50%",
  paddingLeft: "20px",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
}));

const PreviewHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
  maxWidth: "100%",
  overflow: "hidden"
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  color: "#1A1A1C",
  fontSize: "20px",
  fontWeight: "600",
  marginBottom: "8px",
  lineHeight: "1"
}));

const Description = styled(Typography)(({ theme }) => ({
  color: "#6A6A6B",
  fontSize: "16px",
  marginBottom: "12px"
}));

const CompanyNameField = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "4px",
  border: "1px solid #ccc",
  padding: "10.5px 12px",
  transition: "border-color 0.2s ease-in-out",
  gap: "8px",
}));

const StyledTextField = styled(TextField)({
  flex: 1,
  "& .MuiOutlinedInput-root": {
    padding: 0,
    "& input": {
      padding: 0,
      color: "#1A1A1C",
    },
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
});

const HorizontalLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: "#E8E8E8",
  margin: "32px 0"
}));

const LogoImgContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  gap: "16px"
}));

const ProfileBg = styled(Box)(({ theme }) => ({
  height: "136px",
  width: "136px",
  borderRadius: "50%",
  backgroundColor: "#E8E8E8",
  position: "relative",
}));

const ProfileImg = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

const EditIcon = styled("img")(({ theme }) => ({
  position: "absolute",
  bottom: "5px",
  right: "-5px",
  cursor: "pointer",
}));

const OutlinedBtn = styled(Button)(({ theme, btnColor, textColor }) => ({
  width: "fit-content",
  backgroundColor: "#ffffff",
  color: textColor ? textColor : btnColor,
  fontSize: "16px",
  padding: "8.5px 16px",
  border: `1px solid ${btnColor}`,
  textTransform: "capitalize",
  fontWeight: "500",
  display: "flex",
  gap: "6px",
}));

const ContainedBtn = styled(Button)(({ theme, btnColor, textColor }) => ({
  width: "fit-content",
  backgroundColor: btnColor,
  color: "#ffffff",
  fontSize: "16px",
  padding: "8.5px 16px",
  border: `1px solid ${btnColor}`,
  textTransform: "capitalize",
  fontWeight: "500",
  display: "flex",
  gap: "6px",
}));

const ColorInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
}));

const ColorBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "6px",
  border: "1px solid #D0D0D1",
  borderRadius: "4px",
  backgroundColor: "#ffffff",
});

const HiddenColorInput = styled("input")({
  position: "absolute",
  width: "0",
  height: "0",
  opacity: "0",
});

const ColorPreview = styled(Box)({
  width: "24px",
  height: "24px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  cursor: "pointer",
});

const ColorHex = styled(Typography)({
  fontSize: "16px",
  color: "#1A1A1C",
  marginLeft: "10px",
  minWidth: "90px",
  textAlign: "left",
});

const ColorLabel = styled(Typography)(({ theme }) => ({
  color: "#6A6A6B",
  fontSize: "16px",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  marginTop: "8px",
}));

const PreviewContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: "hidden"
}));

const SidebarContainer = styled(Box)(({ theme, primaryColor }) => ({
  position: "relative",
  minWidth: "56px",
  width: "56px",
  backgroundColor: primaryColor,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px 0",
  gap: "12px",
  justifyContent: "space-between",
  borderTopLeftRadius: "8px",
  borderBottomLeftRadius: "8px"
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flex: 1,
  border: "1px solid #E8E8E8",
  borderRadius: "8px"
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "8px 0",
  cursor: "pointer",
}));

const ActiveBg = styled(Box)(({ tertiaryColor }) => ({
  position: "absolute",
  width: "40px",
  height: "36px",
  backgroundColor: tertiaryColor,
  borderRadius: "4px",
}));

const LogoWrapper = styled(Box)({
  width: "40px",
  height: "36px",
  backgroundColor: "white",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "16px",
});

const LogoIcon = styled("img")({
  width: "25px",
  height: "26px",
});

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: "10px 20px",
  width: "100%",
  display: "flex",
  boxSizing: "border-box",
  borderBottom: "1px solid #E8E8E8",
  justifyContent: "space-between",

  [theme.breakpoints.up("xl")]: {
    padding: "14.5px 20px"
  },
}));

const GreetingsBox = styled(Typography)(({ theme, secondaryColor }) => ({
  color: secondaryColor,
  marginBottom: "12px",
  fontWeight: 500,
  fontSize: "20px",
}));

const DescriptionBox = styled(Typography)(({ theme }) => ({
  color: "#6A6A6B",
  marginBottom: "14px",
  fontSize: "14px",
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: "#1A1A1C",
  fontSize: "20px",
  fontWeight: 500,
}));

const ArrowButton = styled(IconButton)(({ theme, primaryColor }) => ({
  position: "absolute",
  right: "-12px",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "white",
  borderRadius: "50%",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  zIndex: 10,
  border: `1px solid ${primaryColor}`,
  width: "24px",
  height: "24px",

  "&:hover": {
    backgroundColor: "#ffffff",
  },
}));

const CardIcon = styled(Box)(({ theme }) => ({
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CardDescription = styled(Typography)(({ theme }) => ({
  color: "#6A6A6B",
  fontSize: "14px",
}));

const Card = styled(Box)(({ theme }) => ({
  flex: "0 0 272px",
  width: "272px",
  height: "300px",
  padding: "0 32px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  flexShrink: 0,
  borderRadius: "8px",
  gap: "16px",
  backgroundColor: "#ffffff",
  border: "1px solid #E8E8E8",
}));

const FlexBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "24px",
  paddingLeft: "16px",
}));

export {
  Container,
  SettingsPanel,
  PreviewPanel,
  PreviewHeader,
  StyledHeading,
  Description,
  CompanyNameField,
  StyledTextField,
  HorizontalLine,
  LogoImgContainer,
  ProfileBg,
  ProfileImg,
  EditIcon,
  OutlinedBtn,
  ContainedBtn,
  ColorInputContainer,
  ColorBox,
  HiddenColorInput,
  ColorPreview,
  ColorHex,
  ColorLabel,
  ButtonContainer,
  PreviewContent,
  Card,
  SidebarContainer,
  PreviewContainer,
  IconWrapper,
  ActiveBg,
  LogoWrapper,
  LogoIcon,
  HeaderContainer,
  GreetingsBox,
  DescriptionBox,
  ArrowButton,
  CardIcon,
  Heading,
  CardDescription,
  FlexBox
};