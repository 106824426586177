import React from "react";
import { Box } from "@mui/material";
import imageIcon from "../../../assets/imageIcon.svg";
import profileEditIcon from "../../../assets/profileEditIcon.svg";
import {
  StyledHeading,
  Description,
  LogoImgContainer,
  ProfileBg,
  ProfileImg,
  EditIcon,
  OutlinedBtn,
} from "./ThemePageCss";

const CompanyLogo = () => {
  return (
    <Box>
      <StyledHeading>Company Logo</StyledHeading>
      <Description>Supported formats: JPG, PNG. Maximum size: 2MB.</Description>
      <LogoImgContainer>
        <ProfileBg>
          <ProfileImg src={imageIcon} alt="" />
          <EditIcon src={profileEditIcon} alt="" />
        </ProfileBg>
        <OutlinedBtn btnColor="#FF4949" sx={{ padding: "8.5px 25px" }}>
          Delete Logo
        </OutlinedBtn>
      </LogoImgContainer>
    </Box>
  );
};

export default CompanyLogo;