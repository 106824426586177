import { Box, styled, Typography } from "@mui/material";
import { TableCell } from "@mui/material";

const SettingsTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1.25rem",
  fontWeight: 500,
}));

const OuterBox = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  margin: "0 auto",
  gap: "2rem",
  marginTop: "0.5rem",
  height: "80vh",
  overflow: "hidden",

  "@media (resolution: 120dpi)": {
    height: "75vh",
  },
  "@media (resolution: 144dpi)": {
    height: "76vh",
  },
  "@media (min-width: 1280px) and (max-width: 1366px)": {
    height: "77vh",
  },

  "@media (min-width: 1600px) and (max-width: 1600px)": {
    height: "77vh",
  },
}));

const ItemsOuterBox = styled(Box)(({ theme }) => ({
  width: "20%",
  height:"fit-content",
  borderRadius: "8px",
  border: "1px solid #E8E8E8",
  padding: "0.75rem",
}));

const ItemsInnerBox = styled(Box)(({ theme, location, path }) => ({
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  padding: "0.75rem",
  backgroundColor: location.includes(path) ? "#FFF5ED" : "white",
  color: location.includes(path) ? "#FF7D34" : "black",
  cursor: "pointer",
  fontFamily: "Roboto",
  fontSize: "1rem",
}));

//MembersPage
const HeaderCell = styled(TableCell)({
  color: "#212529",
  fontSize: "18px",
  fontWeight: 500,
});
const TeamMembersName= styled(Typography)({
  fontSize:"16px",
  fontWeight: 500,
  color: "#252527",
 });
 const TeamMembersEmailId= styled(Typography)({
  fontSize:"16px",
  fontWeight: 400,
  color: "#818182",
 });


export { OuterBox, SettingsTypography, ItemsOuterBox, ItemsInnerBox, HeaderCell
,TeamMembersName,
TeamMembersEmailId
 };
