import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import SearchBar from "../Reusable/SerachBar/SearchBar";
import DropDown from "../Reusable/Dropdown/Dropdown";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "100%",
    letterSpacing: "0%",
    color: "#6A6A6B",
  },
}));



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const columns = [
  { id: "date", label: "Date & Time", minWidth: 100, align: "center" },
  { id: "name", label: "Name", minWidth: 100, align: "center" },
  { id: "module", label: "Module", minWidth: 150, align: "center" },
  { id: "activity", label: "Activity", minWidth: 100, align: "center" },
];

const rows = [
  createData("25-03-2025", "Anton", "Login", "Logged in to the system "),
  createData("25-03-2025", "Anton", "Login", "Logged in to the system "),
  createData("25-03-2025", "Anton", "Login", "Logged in to the system "),
  createData("25-03-2025", "Anton", "Login", "Logged in to the system "),
  createData("25-03-2025", "Anton", "Login", "Logged in to the system "),
];

// const onChange =()=>{

// }

const AuditLog = () => {
  return (
    <Box
      sx={{
        padding: "40px",
        background: "#EBF6F0",
        margin: "30px",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "22px",
            lineHeight: "100%",
            letterSpacing: "0%",
          }}
        >
          Audit Log
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <DropDown label={"User"} />
          <DropDown label={"Type"} />
          <SearchBar />
        </Box>
      </Box>

      <Box sx={{ padding: "20px", background: "#ffff", borderRadius: "10px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns.map(({ id, label, align, minWidth }) => (
                  <TableCell
                    key={id}
                    align={align}
                    sx={{
                      backgroundColor: "#EBF6F0",
                      fontWeight: "500",
                      fontSize: "1.125rem",
                      minWidth,
                    }}
                  >
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <StyledTableCell
                    align={"center"}
                    sx={{}}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.calories}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.fat}</StyledTableCell>
                  <StyledTableCell align="center">{row.carbs}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AuditLog;
