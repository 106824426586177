import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./Header.css";
import { backArrow, header_notification } from "../../Constants/Constant";
import { useNavigate, useLocation } from "react-router-dom";
import IgnithoIcon from "../../assets/IgnithoIcon.svg";
import { LogoBox } from "./LayoutStyles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationList } from "../../Redux/Actions/NotificationAction";

const stringAvatar = (firstNameData, lastNameData) => {
  const firstName = firstNameData?.split("")[0];
  const lastName =
    lastNameData === "undefined" || lastNameData === null || lastNameData === ""
      ? ""
      : lastNameData.split("")[0];

  return {
    sx: {
      background: "#FF7D34",
      color: "white",
      width: "31px",
      height: "31px",
      fontSize: "15px",
      marginRight: "5px",
    },
    children: `${firstName}${lastName}`,
  };
};

const Header = () => {
  let firstNameData = localStorage.getItem("first_name");
  let lastNameData = localStorage.getItem("last_name");
  lastNameData = lastNameData === "undefined" || lastNameData === null ? null : lastNameData;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch =  useDispatch();
  const isSalesDashboardPage = location.pathname.includes("/dashboard");
  const isSdrDashboardPage = location.pathname.includes("/sdr-dashboard");
  const isViewPlanPage = location.pathname.includes("/view-plan");
  const isOutReachPage = location.pathname.includes("/outreach");
  const isSalesPlanPage = location.pathname.includes("/sales-plan");
  const isOnboardingPage = location.pathname.includes("/onboarding");
  const [notificationCount,setNotificationCount] = useState(0)
  

  const userRole = localStorage.getItem("role");
  const notificationData = useSelector((state) => state?.notificationData?.notificationData);
  useEffect(()=>{
    getNotificationCount()

     // eslint-disable-next-line 
  },[])

  useEffect(()=>{
    if(notificationData){
      if(notificationData.success){
        setNotificationCount(notificationData.result)
      }
    }
  },[notificationData])

 

  const getNotificationCount = () => {
    dispatch(getNotificationList({read_status: "unread", count_only: true }))
  };

  const handleBack = () => {
    userRole === "piqai_sales_lead"
      ? navigate("/piqual-ai/dashboard")
      : navigate("/piqual-ai/sdr-dashboard");
  };

  const showNotification = () => {
    localStorage.setItem("sales_plan_type", "db");
    navigate("/piqual-ai/notifications");
  };

  return (
    <>
      <Box className="back-button-wrapper">
        {!(
          isSalesDashboardPage ||
          isSdrDashboardPage ||
          isViewPlanPage ||
          isOutReachPage ||
          isOnboardingPage
        ) &&
          isSalesPlanPage && (
            <img
              className="arrow-back-icon"
              loading="lazy"
              alt="Back"
              onClick={handleBack}
              src={backArrow}
            />
          )}

        <Typography
          sx={{
            fontWeight: "500",
            fontSize: {
              md: "16px",
              lg: "16px",
              xl: "20px",
            },
            display:'flex',
            alignItems:'center',
          }}
        >
          {/* {isSalesDashboardPage ? (
            "Sales Plan"
          ) : isSdrDashboardPage || isOutReachPage ? (
            <img src={IgnithoIcon} alt="logo" style={{ marginTop: "10px" }} />
          ) : (
            headerTitle
          )} */}
          <LogoBox src={IgnithoIcon} alt="logo"  />
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          gap: "16px",
          color: "black",
          display: "flex",
          justifyContent: "end",
        }}
        className="week-filter"
      >
     
          <Box>
            <Badge badgeContent={notificationCount} color="error">
              <img
                src={header_notification}
                onClick={showNotification}
                alt="notification"
                style={{
                  width: "17.5px",
                  height: "22.39px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              />
            </Badge>
          </Box>

        <Button>
          <Avatar
            style={{
              background: userRole === "piqai_sdr" ? "#FF7D34" : "#009872",
            }}
            {...stringAvatar(firstNameData, lastNameData)}
          />
          <Typography
            sx={{
              fontWeight: "500",
              color: "#0d162f",
              cursor: "pointer",
              textTransform: "capitalize",
              fontFamily:"Roboto",
              fontSize:"1.125rem"
            }}
          >
            {firstNameData} {lastNameData}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  thisWeek: PropTypes.bool,
  sRR: PropTypes.bool,
  addWidgets: PropTypes.bool,

  /** Variant props */
  property1: PropTypes.number,
};

export default Header;
