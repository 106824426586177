import React from "react";

const PlanBillingPage = () => {
  return (
    <div>
      PlanBillingPage
      <h1>PlanBillingPage loads here</h1>
    </div>
  );
};

export default PlanBillingPage;
