import { Box, IconButton } from "@mui/material";
import React from "react";

import LineGraph from "./LineGraph";
import {
  InnerCard,
  InnerCardTitle,
  InnerCardContent,
  InnerCardCount,
  LeadTrackerOutterBox,
} from "./LeaderTrackerStyles";
import { greenArrow, redArrow } from "../../Constants/Constant";
import { useLocation, useNavigate } from "react-router-dom";

const SalesLeadCard = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation()

  const handleClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <LeadTrackerOutterBox mgnTop={location.pathname="/piqual-ai/companies-added" ? "0":"15px"} >
        {data.map((data, i) => (
          <InnerCard key={i} onClick={() => handleClick(data.path)}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
              <InnerCardTitle>{data.title}</InnerCardTitle>
              <Box sx={{ display: "flex", alignItems: "center", gap: "14px" }}>
                <InnerCardCount>{data.count}</InnerCardCount>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <IconButton sx={{ p: 0 }}>
                    <img src={data.stats ? greenArrow : redArrow} alt="" />
                  </IconButton>
                  <Box
                    sx={{
                      color: data.stats ? "#00BB25" : "#EB0000",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    15%
                  </Box>
                </Box>
              </Box>
              <InnerCardContent>{data.content}</InnerCardContent>
            </Box>
            <Box>
              {data?.graph &&  <LineGraph colr={data.stats ? "#00BB25" : "#EB0000"} />}
             
            </Box>
          </InnerCard>
        ))}
      </LeadTrackerOutterBox>
    </>
  );
};

export default SalesLeadCard;
