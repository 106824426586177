import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import { useSelector } from "react-redux";

import {
  // queries,
  piqualAI1,
  PiqualAi3,
  SalesPlanIcon,
  // mode2,
  // SettingNew,
  LogoutIcon,
  MicIcon,
  HomeIcon,
  auditLogIcon,
  // ChatIcon,
  notificationIconSideBar
} from "../../Constants/Constant";
import {
  SidebarContainer,
  ArrowIcon,
  SideMenuContainer,
  MenuItems,
  MainMenu,
  ItemsList,
  SettingsItemsList,
  SettingsItem,
  ImgContainer,
  ItemName,
  LogoIcon,
  XsSidebar,
  PiqAiImg,
} from "./SidebarStyles";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sidebarWidth, setSidebarWidth } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [textVisible, setTextVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const role = localStorage.getItem("role");
  useEffect(()=>{
    toggleSidebar()
    //eslint-disable-next-line
  },[])

  const toggleSidebar = () => {
    setTextVisible(false);
    setTimeout(() => {
      setSidebarWidth(isExpanded ? "45px" : "150px");
      setIsExpanded((prev) => !prev);

      // Delay to allow transition before showing text
      setTimeout(() => {
        setTextVisible(true);
      }, 200);
    }, 200);
  };

  const logoutApp = () => {
    const salesLeadViewed = localStorage.getItem("sales-lead-home-page-viewed");
    const sdrHomeViewed = localStorage.getItem("sdr-home-page-viewed");
  
    localStorage.clear(); // Clears all localStorage items
  
    // Restore the two keys
    if (salesLeadViewed) {
      localStorage.setItem("sales-lead-home-page-viewed", salesLeadViewed);
    }
    if (sdrHomeViewed) {
      localStorage.setItem("sdr-home-page-viewed", sdrHomeViewed);
    }
  
    navigate("/");
  };

  const menuItems = [
    {
      icon: HomeIcon,
      label: "Home",
      path: role === 'piqai_sales_lead' ? "/piqual-ai/sales-lead-home" : "/piqual-ai/sdr-home",
      active:["/piqual-ai/sales-lead-home","/piqual-ai/sdr-home"],
      permission : ['piqai_sales_lead', "piqai_sdr"]

    },
    {
      icon: role === "piqai_sdr" ? SalesPlanIcon : MicIcon, 
      label: "Sales Plan",
      path:
        role === "piqai_sdr"
          ? "/piqual-ai/sdr-dashboard"
          : "/piqual-ai/dashboard",
      active: [
        "/piqual-ai/sdr-dashboard",
        "/piqual-ai/dashboard",
        "/piqual-ai/outreach",
        "/piqual-ai/sdr-sales-plan",
        "/piqual-ai/sales-plan",
        "/piqual-ai/prospecting"
      ],
      permission : ['piqai_sales_lead', "piqai_sdr"]
    },
    {
      icon: auditLogIcon,
      label: "Audit Log",
      path: "audit-log",
      active:["/piqual-ai/audit-log"],
      permission : ['piqai_admin']
    },
    // {
    //   icon: ChatIcon,
    //   label: "Start Chat",
    //   path: role === 'piqai_sdr' ? "/piqual-ai/sdr-dashboard" : "/piqual-ai/dashboard",
    // },
    {
      icon: notificationIconSideBar,
      label: "Notification",
      path:"/piqual-ai/notifications",
      active:["/piqual-ai/notifications"],
      permission : ['piqai_sales_lead',"piqai_sdr"]
    },
  ];
  const settingsItems = [
    // { icon: mode2, label: "Dark Mode" },
    // { icon: queries, label: "Help" },
    // { icon: SettingNew, label: "Settings", path: "/piqual-ai/settings" },
    { icon: LogoutIcon, label: "Logout", path: "/" },
  ];

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <SidebarContainer sidebarWidth={sidebarWidth}>
        <SideMenuContainer isVisible={textVisible}>
          <Box sx={{ py: 2.25 }}>
            {isExpanded ? (
              <LogoIcon sx={{ mx: 1.5, padding: "4px 10px" }}>
                <img src={PiqualAi3} alt="" />
              </LogoIcon>
            ) : (
              <LogoIcon sx={{ mx: 0.7, padding: "5px" }}>
                <PiqAiImg src={piqualAI1} alt="" />
              </LogoIcon>
            )}
          </Box>

          <MenuItems isExpanded={isExpanded}>
            <ItemsList>
              {menuItems.map((item, index) => {
                return (
                  item.permission.includes(role) &&(
                    <MainMenu
                    key={index}
                    isExpanded={isExpanded}
                    sx={{
                      backgroundColor:
                        Array.isArray(item.active) &&
                        item.active.includes(location.pathname)
                          ? "#56BB6B"
                          : "",
                      mx: isExpanded ? 0.8 : 0.4,
                      "@media (width: 1366px)": {
                        mx: isExpanded ? 1.5 : 0.8,
                        },
                    }}
                    onClick={() => navigate(item.path)}
                  >
                    {/* {item.permission.includes(role) &&
                    } */}
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      sx={{ p: 0}}
                    >
                      <ImgContainer
                        isExpanded={isExpanded}
                        src={item.label === "Sales Plan" ? (role !== "piqai_sdr" ? SalesPlanIcon : MicIcon) : item.icon}
                        alt={item.label}
                      />
                    </IconButton>
                    {isExpanded && (
                      <ItemName
                        sx={{
                          visibility:!item.permission.includes(role) ? 'hidden' : 'visible',
                          paddingLeft: "10px",
                          fontWeight:
                            Array.isArray(item.active) &&
                            item.active.includes(location.pathname)
                              ? 600
                              : 500,
                        }}
                      >
                        {item.label}
                      </ItemName>
                    )}
                  </MainMenu>
                  )
                );
              })}
            </ItemsList>

            <SettingsItemsList
              sx={{
                pb: 3.5,
              }}
            >
              {settingsItems.map((item, index) => (
                <SettingsItem key={index} onClick={() => navigate(item.path)}>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    onClick={item.label === "Logout" ? logoutApp : undefined}
                  >
                    <ImgContainer  src={item.icon} alt={item.label} />
                  </IconButton>
                  {isExpanded && <ItemName>{item.label}</ItemName>}
                </SettingsItem>
              ))}
            </SettingsItemsList>
          </MenuItems>
        </SideMenuContainer>

        <ArrowIcon onClick={toggleSidebar} isExpanded={isExpanded}>
          {isExpanded ? (
            <KeyboardArrowLeftIcon sx={{ width: "20px", height: "20px" }} />
          ) : (
            <KeyboardArrowRightIcon sx={{ width: "20px", height: "20px" }} />
          )}
        </ArrowIcon>
      </SidebarContainer>
      {/* <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Drawer open={open} onClose={toggleDrawer} sx={{width:'150px'}}>
        <List>
          <ListItem button onClick={toggleDrawer}>
            <ListItemText primary="Item 1" />
          </ListItem>
          <ListItem button onClick={toggleDrawer}>
            <ListItemText primary="Item 2" />
          </ListItem>
          <ListItem button onClick={toggleDrawer}>
            <ListItemText primary="Item 3" />
          </ListItem>
        </List>
      </Drawer> */}

      <XsSidebar>
        <IconButton
          sx={{ py: 3 }}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          Drawer
          open={open}
          onClose={toggleDrawer}
          sx={{
            width: 250, // Setting the width here
            flexShrink: 0, // Ensures it doesn't shrink
            "& .MuiDrawer-paper": {
              width: 250, // Also target the Drawer paper component to set width
            },
          }}
          variant="temporary" // Can also be "persistent" depending on your needs
          anchor="left"
        >
          {/* Menu Items */}
          <List>
            {menuItems.map((item, index) => (
              <ListItem button key={index} onClick={toggleDrawer}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>

          {/* Settings Items */}
          <List>
            {settingsItems.map((item, index) => (
              <ListItem button key={index} onClick={toggleDrawer}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </XsSidebar>
    </>
  );
};

export default Sidebar;
