import React, { useState } from "react";

import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  HeaderTableCell,
  StyledTableCell,
  CustomTab,
  CustomTabs,
  OutlinedBtn,
  ContainedBtn,
  SearchBarContainer,
  StyledTextField
} from "./CreditUsageCss";
import downloadIcon from "../../../assets/download.svg";
import searchIcon from "../../../assets/search.svg";
import CustomDropdown from "../../Reusable/CustomDropdown/CustomDropdown";

const CreditUsagePage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [filterCategory, setFilterCategory] = useState("When");
  const [timeRange, setTimeRange] = useState("This week");
  const [integrations, setIntegrations] = useState("Integrations");
  const [owners, setOwners] = useState("Owners");
  const [searchInput, setSearchInput] = useState("");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const workspaceData = [
    { name: "Name 1", owner: "JO", usage: "", creditsUsed: 50 },
    { name: "Name 2", owner: "AC", usage: "", creditsUsed: 42 },
    { name: "Name 3", owner: "JJ", usage: "", creditsUsed: 37 },
    { name: "Name 4", owner: "AK", usage: "", creditsUsed: 25 },
    { name: "Name 5", owner: "VJ", usage: "", creditsUsed: 10 },
  ];

  const integrationData = [{ name: "All Integrations", creditsUsed: 0 }];

  const options = [{ label: "When", value: "when" }]

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <Box>
      <CustomTabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
        <CustomTab
          label="Workspace"
        />
        <CustomTab
          label="Integrations"
        />
      </CustomTabs>
      <Box sx={{ pt: 2 }}>
        {activeTab === 0 && (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: "32px", gap: 2 }}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <CustomDropdown
                  value={filterCategory}
                  onChange={setFilterCategory}
                  options={options}
                />
                <CustomDropdown
                  value={timeRange}
                  onChange={setTimeRange}
                  options={options}
                />
                <CustomDropdown
                  value={integrations}
                  onChange={setIntegrations}
                  options={options}
                />
                <CustomDropdown
                  value={owners}
                  onChange={setOwners}
                  options={options}
                />
                <ContainedBtn>
                  Apply
                </ContainedBtn>
              </Box>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <SearchBarContainer>
                  <img src={searchIcon} alt="" />
                  <StyledTextField
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                    value={searchInput}
                    onChange={handleSearch}
                  />
                </SearchBarContainer>
                <OutlinedBtn
                  sx={{
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <img src={downloadIcon} alt="" />
                  Export
                </OutlinedBtn>
              </Box>
            </Box>

            <TableContainer sx={{ border: "1px solid #E0E0E0", borderRadius: "4px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell>Name</HeaderTableCell>
                    <HeaderTableCell>Owner</HeaderTableCell>
                    <HeaderTableCell>Usage</HeaderTableCell>
                    <HeaderTableCell>Credits Used</HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workspaceData.map((row, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.owner}</StyledTableCell>
                      <StyledTableCell>{row.usage}</StyledTableCell>
                      <StyledTableCell>{row.creditsUsed}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {activeTab === 1 && (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: "32px", alignItems: "center" }}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <CustomDropdown
                  value={filterCategory}
                  onChange={setFilterCategory}
                  options={options}
                />
                <CustomDropdown
                  value={timeRange}
                  onChange={setTimeRange}
                  options={options}
                />
                <ContainedBtn>
                  Apply
                </ContainedBtn>
              </Box>

              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <SearchBarContainer>
                  <img src={searchIcon} alt="" />
                  <StyledTextField
                    variant="outlined"
                    size="small"
                    placeholder="Search"
                    value={searchInput}
                    onChange={handleSearch}
                  />
                </SearchBarContainer>
                <OutlinedBtn
                  sx={{
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <img src={downloadIcon} alt="" />
                  Export
                </OutlinedBtn>
              </Box>
            </Box>
            <TableContainer sx={{ border: "1px solid #E0E0E0", borderRadius: "4px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell>Name</HeaderTableCell>
                    <HeaderTableCell>Credits Used</HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {integrationData.map((row, index) => (
                    <TableRow key={index}>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.creditsUsed}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CreditUsagePage;