const SdrOnboardingContent = [
  {
    heading: "Align & Amplify",
    content: [
      "Use AI-generated email templates and LinkedIn messages but make them yours—add a human touch.",
      "Reference pain points and value props specific to each prospect’s industry.",
      "Keep it concise: The best messages are short, direct, and action-driven.",
    ],
  },
  {
    heading: "Find Gold",
    content: [
      "Your AI-driven lead list is built for success—stick to it. No spray-and-pray.",
      "Review lead details before outreach: Industry, pain points, engagement history.",
      "Prioritize follow-ups—warm leads first, cold leads later.",
    ],
  },
  {
    heading: "Happy Prospecting",
    content: [
      "Emails – First touchpoint; make it sharp. Follow-up with relevant context.",
      "LinkedIn – Engage with their content before reaching out. Build rapport.",
      "Calls – Use insights from past interactions to make every conversation count.",
    ],
  },
  {
    heading: "Optimize & Outperform",
    content: [
      "Check your campaign performance—are your emails getting responses? Are your calls converting?",
      "Use AI insights to tweak your approach—double down on winning messaging.",
      "Learn from top performers on your team and replicate what’s working.",
    ],
  },
];
const SalesLeadOnboardingContent = [
    {
      heading: "Own Narrative",
      content: [
        "Ensure SDRs use the AI-recommended messaging framework for consistency and personalization.",
        "Test variations of messaging—see what resonates with different personas.",
        "Adapt based on responses: Double down on what works, tweak what doesn’t.",
      ],
    },
    {
      heading: "Nail Your ICP",
      content: [
        "Ensure your SDRs focus on the right Ideal Customer Profile (ICP) - SaaS and IT leaders who are decision-makers.",
        "Use AI insights to refine your outreach database—double down on high-intent accounts.",
        "Adjust targeting based on real-time feedback: Drop low-response leads, prioritize engaged ones.",
      ],
    },
    {
      heading: "Orchestrate Wins",
      content: [
        "Guide SDRs on the right mix of email, LinkedIn, and calls to maximize engagement.",
        "Monitor outreach sequences—are emails getting opens? Are calls leading to conversations?",
        " Adjust channel strategies based on performance insights—lean into the most effective ones.",
      ],
    },
    {
      heading: "Track Triumphs",
      content: [
        " Keep a pulse on SDR activity metrics: Emails sent, responses, calls made, meetings booked.",
        " Identify bottlenecks—where are leads dropping off in the pipeline?",
        " Coach SDRs using AI-driven recommendations to sharpen their approach.",
      ],
    },
  ];

const salesLeadTabData = [
  {
    value: "1",
    label: "Own Narrative",
    key: "message_plan",
    status: "in-progress",
    disabled: false,
  },
  {
    value: "2",
    label: "Nail Your ICP",
    key: "market_plan",
    status: "completed",
    disabled: false,
  },
  {
    value: "3",
    label: "Orchestrate Wins",
    key: "media_plan",
    status: "",
    disabled: true,
  },
  {
    value: "4",
    label: "Track Triumphs",
    key: "measure_plan",
    status: "",
    disabled: true,
  },
]

const sdrTabData = [
  {
    value: "1",
    label: "Message Plan",
    key: "message_plan",
    status: "in-progress",
    disabled: false,
  },
  {
    value: "2",
    label: "Market Plan",
    key: "market_plan",
    status: "completed",
    disabled: false,
  },
  {
    value: "3",
    label: "Media Plan",
    key: "media_plan",
    status: "",
    disabled: true,
  },
  {
    value: "4",
    label: "Measure Plan",
    key: "measure_plan",
    status: "",
    disabled: true,
  },
]

export { SdrOnboardingContent,SalesLeadOnboardingContent,salesLeadTabData,sdrTabData };
