import React from "react";

import {  Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTypography,
} from "../ViewPlan/ViewMediaPlanCss";
import { StyledAccordionTitle, ViewPlanText } from "./OutReachStyle";
import { formatRevenue } from "../../Constants/Funtions/commonFunctions";

// Reusable Accordion Section Component
const AccordionSection = ({ title, content, defaultExpanded }) => (
  <StyledAccordion defaultExpanded={defaultExpanded} sx={{ boxShadow: "none" }}>
    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
      <StyledAccordionTitle>{title}</StyledAccordionTitle>
    </StyledAccordionSummary>
    <hr
      style={{ width: "97.2%", margin: "0 auto", border: "1px solid #E8E8E8" }}
    />
    <StyledAccordionDetails>
      <StyledTypography>{content}</StyledTypography>
    </StyledAccordionDetails>
  </StyledAccordion>
);

const FindGold = ({ data }) => {


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: {
          sm: "10px",
          md: "10px",
          lg: "10px",
          xl: "16px",
        },
      }}
    >
      {data?.target_geographies?.length !== 0 && (
        <AccordionSection
          defaultExpanded={true}
          title="Geographical Locations"
          content={data?.target_geographies?.map((geo, index) => (
            <Box
              key={index}
              sx={{ marginBottom: "12px", "&:last-child": { marginBottom: 0 } }}
            >
              <ViewPlanText sx={{ fontWeight: "500", color: "#3C3C3E" }}>
                {geo.country}
              </ViewPlanText>
              <ViewPlanText sx={{ fontWeight: "500", color: "#3C3C3E" }}>
                {geo?.region}
              </ViewPlanText>
              {/* {geo?.states?.map((state, idx) => ( */}
              <ViewPlanText sx={{ color: "#707070" }}>
                {geo?.states?.join(",")}
                {/* {idx < geo?.states?.length - 1 ? ", " : "."} */}
              </ViewPlanText>
              {/* ))} */}
            </Box>
          ))}
        />
      )}

      {/* Industry Group */}
      {data?.target_industries?.length !== 0 && (
        <AccordionSection
          defaultExpanded={true}
          title="Industry Group"
          content={data?.target_industries?.map((industry, index) => (
            <div key={index}>
              <ViewPlanText sx={{ fontWeight: 500 }}>
                {industry?.target_industry}
              </ViewPlanText>
              <ViewPlanText >
                {industry?.specific_target_industry?.join(",")}
              </ViewPlanText>
            </div>
          ))}
        />
      )}

      {/* Revenue Range */}
      {data?.target_revenue_range?.length !== 0 && (
        <AccordionSection
        defaultExpanded={true}
        title="Company Criteria"
        content={data?.target_revenue_range?.map((range, index) => (
          <ViewPlanText key={index}>
            {range?.revenue_min && range?.revenue_max ? (
              `$${formatRevenue(range.revenue_min)} to $${formatRevenue(range.revenue_max)}`
            ) : !range?.revenue_min && range?.revenue_max ? (
              `Below $${formatRevenue(range.revenue_max)}`
            ) : range?.revenue_min && !range?.revenue_max ? (
              `Above $${formatRevenue(range.revenue_min)}`
            ) : (
              "No revenue data available"
            )}
          </ViewPlanText>
        ))}
      />
      )}

      {/* Contact Criteria */}
      {data?.target_contact_criteria?.length !== 0 && (
        <AccordionSection
          defaultExpanded={true}
          title="Contact Criteria"
          content={
            <ViewPlanText>
              {data?.target_contact_criteria.join(",")}
              {/* {index < data?.target_contact_criteria?.length - 1 ? ", " : ". "} */}
            </ViewPlanText>
          }
        />
      )}

      {(data?.target_geographies?.length &&
        data?.target_industries?.length &&
        data?.target_revenue_range?.length &&
        data?.target_contact_criteria?.length) === 0 && (
        <Box
          sx={{
            color: "#6A6A6B",
            padding: "10px 20px",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          Waiting for Nail Your ICP
        </Box>
      )}
    </Box>
  );
};

export default FindGold;
