import { Box} from "@mui/material";
import React from "react";
import { CompanyOutterBox, LeadTrackerTitle, MainBox } from "./LeaderTrackerStyles";
import CalendarDropDown from "./CalendarDropDown";
import SdrDropDown from "./SdrDropDown";
import { backArrow } from "../../Constants/Constant";
import { useNavigate } from "react-router-dom";
import AIInsights from "./AIInsights";
import SalesLeadCard from "./SalesLeadCard";
import BuyerIntentTable from "./BuyerIntentTable";
import LineGraphTarget from "./LineGraphTarget";


const cardData=[{
    title: "Number of Companies added",
    count: 86,
    content: "Compared to last week",
    stats: true,
path:"",graph:false
}]

const CompanyAdded = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <MainBox>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
          <img
            className="arrow-back-icon"
            loading="lazy"
            alt="Back"
            onClick={handleBack}
            src={backArrow}
          />

          <LeadTrackerTitle>Companies Added</LeadTrackerTitle>
        </Box>

        <Box sx={{ display: "flex", gap: "0px" }}>
          <SdrDropDown />
          <CalendarDropDown />
        </Box>
      </Box>
      <Box sx={{display:"flex",gap:"20px",height:"100%", marginTop:"10px", "@media (max-width: 1536px)": {
        gap:"10px",
        marginTop:"10px",
      }}}>
        <CompanyOutterBox wdth="70%" >
            <LineGraphTarget />
            <BuyerIntentTable/>
        </CompanyOutterBox>
        <CompanyOutterBox wdth="30%">
                <SalesLeadCard data={cardData} mgnTop="0" />
          <AIInsights/>
        </CompanyOutterBox>
      </Box>
    </MainBox>
  );
};

export default CompanyAdded;
