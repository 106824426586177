import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Step, Stepper } from "@mui/material";
import {
  ContentList,
  ContentListItem,
  DividerLine,
  GreetingsBox,
  GreetingsBox2,
  HeadingBox,
  NextButton,
  OuterBox,
  SlideHeading,
  Slides,
  SlidesBox,
  SlidesContainer,
} from "./OnboardigSliderCss";

import {
  SdrOnboardingContent,
  SalesLeadOnboardingContent,
} from "../../../Constants/data";
import { useTheme } from "@mui/system";

const OnboardigSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();
  const [greeting, setGreeting] = useState("");
  
  useEffect(() => {
    const getGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        return "Good Morning";
      } else if (hour < 18) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    };

    setGreeting(getGreeting());
  }, []);

  // Get User Role & Content
  const userRole = localStorage.getItem("role");
  const contentData =
    userRole === "piqai_sdr"
      ? SdrOnboardingContent
      : SalesLeadOnboardingContent;

  // Get User Name
  const firstName = localStorage.getItem("first_name");
  let lastName = localStorage.getItem("last_name");
  lastName = lastName === "undefined" || lastName === null ? "" : lastName;

  // Handle Next Slide
  const handleNext = () => {
    if (activeSlide < contentData.length - 1) {
      setActiveSlide((prev) => prev + 1);
    } else {
      if (userRole === "piqai_sales_lead") {
        navigate("/piqual-ai/dashboard");
        localStorage.setItem("sales-lead-home-page-viewed", "viewed");
      } else {
        navigate("/piqual-ai/sdr-dashboard");
        localStorage.setItem("sdr-home-page-viewed", "viewed");
      }
    }
  };
  
   

  return (
    <OuterBox>
      {/* Header */}
      <Box>
        <GreetingsBox>
          {greeting}, {firstName} {lastName}!
        </GreetingsBox>
        <GreetingsBox2>Welcome to your launchpad!</GreetingsBox2>
        <HeadingBox>Here’s what you need to do today:</HeadingBox>
      </Box>

      <SlidesBox>
        {/* Slides Container */}
        <SlidesContainer>
          {contentData.map((slide, index) => {
            const isActive = index === activeSlide;
            const isLeft = index === activeSlide - 1;
            const isRight = index === activeSlide + 1;
            const isHidden = !isActive && !isLeft && !isRight;

            return (
              <Slides
                key={index}
                isHidden={isHidden}
                isActive={isActive}
                isLeft={isLeft}
                isRight={isRight}
              >
                <SlideHeading>{slide.heading}</SlideHeading>
                <DividerLine />
                <ContentList>
                  {slide.content.map((point, i) => (
                    <ContentListItem key={i}>✅ {point}</ContentListItem>
                  ))}
                </ContentList>
              </Slides>
            );
          })}
        </SlidesContainer>

        {/* Stepper (Progress Indicator) */}
        <Stepper
          activeStep={activeSlide}
          alternativeLabel
          sx={{
            width: "30%",
            mt: 9,
            mx: "auto",
            display: "flex",
            justifyContent: "center",
            "& .MuiStep-root": { p: 0, mx: 0.5, flexGrow: 1 },
            "& .MuiStepConnector-line": { borderTopWidth: "0px" },
            "@media (resolution: 120dpi)": {
              marginTop: "2rem",
            },
            "@media (resolution: 144dpi)": {
              marginTop: "2rem",
            },
            [theme.breakpoints.down(1400)]: {
              marginTop: "1.75rem",
            },
          }}
        >
          {contentData.map((_, index) => (
            <Step key={index} sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  width: "100%",
                  height: "8px",
                  bgcolor: index <= activeSlide ? "#FF7D34" : "#FFE9D4",
                  borderRadius: "8px",
                  transition: "background-color 0.3s ease-in-out",
                }}
              />
            </Step>
          ))}
        </Stepper>

        {/* Next Button */}
        <NextButton variant="contained" onClick={handleNext}>
          {activeSlide === contentData.length - 1
            ? "Let's Start Winning"
            : "Next"}
        </NextButton>
      </SlidesBox>
    </OuterBox>
  );
};

export default OnboardigSlider;
