import { Button } from '@mui/material'
import React from 'react'
import { downArrow } from '../../Constants/Constant'

const ScrollDownBtn = ({handleScrollBtn}) => {
  return (
    <Button
      sx={{
        position: "absolute",
        bottom: "0px",
        left: "40%",
        zIndex: "999",
        outline: "none",
        boxShadow: "none",
        animation: "moveUpDown 0.5s infinite alternate ease-in-out", // Add animation
        "&:focus, &:focus-visible, &:hover": {
          outline: "none",
          boxShadow: "none",
        },
        "@keyframes moveUpDown": {
          "0%": {
            transform: "translateY(0px)",
          },
          "100%": {
            transform: "translateY(-5px)", // Moves up by 5px
          },
        },
      }}
        disableRipple
        onClick={handleScrollBtn}
      >
    <img src={downArrow} alt="downArrow" style={{backgroundColor:"#009872", borderRadius:"50%",border:"1px solid #C5E7DC",width:"15px",height:"15px",padding:"5px"}}/>
    </Button>
  )
}

export default ScrollDownBtn